import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import { Input } from '../../Input/input';
import { connect } from "react-redux"

import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';
import AllowCourseMiddleware from '../../../store/middleware/AllowCourseMiddleware';

class ShowAllowedCourseContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            search: "",
            searchSection: "",
            searchedSections: [],
            sections: [

            ],
            filteredSections: [],
            courseContentSections: [],
            allowedCourseSections: [],
            addedSearchedSection: [],
            showLesson: false,
            _id: "",
        }
    }



    componentDidMount = () => {
        const { history } = this.props;
        console.log(history);

        if (history && history.location) {
            const { allowedCourseContent } = history.location.state;
            const { courseContent, _id, sections, batch, quarter, courseName } = allowedCourseContent;
            const { title } = courseContent
            this.setState({
                allowedCourseContent,
                title, batch, quarter, courseName,
                allowedContentId: _id,
                courseContentSections: courseContent.sections,
                allowedCourseSections: sections
            })
        } else {
            this.props.history.push("/onlinevideosmanagement")
        }
    }



    search = (keyword) => {
        const { courseContentSections } = this.state;
        const searchedSections = courseContentSections.filter((item, index) => {
            return item.section.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            search: keyword,
            searchedSections
        })
    }

    addedSearchedSection = (keyword) => {
        const { allowedCourseSections } = this.state;

        const addedSearchedSection = allowedCourseSections.filter((item, index) => {
            return item.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            searchSection: keyword,
            addedSearchedSection
        })
    }

    removeSection = (sectionId, index) => {
        const { allowedContentId, allowedCourseSections } = this.state;
        this.props.removeSection({ sectionId, allowedContentId }).then((data) => {
            if (data.success) {
                allowedCourseSections.map((item, index2) => {
                    if (item._id === sectionId) {
                        allowedCourseSections.splice(index2, 1)
                    }
                })
                this.setState({ allowedCourseSections });
            }
        })
    }

    maintainSection = (_id) => {
        const { allowedContentId } = this.state;
        this.setState({ showLesson: false })
        this.props.addCourseSection({ sectionId: _id, allowedContentId }).then((data) => {
            if (data.success) {
                this.setState({ allowedCourseSections: data.data.sections });

            }
        })
    }


    render() {
        const { errors, search, title, batch, quarter, courseName, searchedSections, addedSearchedSection, searchSection, showLesson, courseContentSections, allowedCourseSections } = this.state;
        const { isLoading, errorMessage } = this.props;
        const finalSections = search ? searchedSections : courseContentSections;
        const finalCourseSection = searchSection ? addedSearchedSection : allowedCourseSections;


        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="legends-container">
                        <fieldset>
                            <legend>Course-Content</legend>
                            <div className="legend-input-container-3">
                                Title: {title}
                            </div>
                            <div className="legend-input-container-3">
                                Batch: {batch}
                            </div>
                            <div className="legend-input-container-3">
                                Course: {courseName}
                            </div><div className="legend-input-container-3">
                                Quarter: {quarter}
                            </div>
                        </fieldset>
                    </div>
                    <div>



                        {allowedCourseSections.length ? <div>
                            <div className="legends-container">
                                <fieldset>
                                    <legend>Allowed-Course-Sections</legend>
                                    <div className="legend-input-container-3">
                                        <Input
                                            label=" Search Section Here "
                                            type="text"
                                            name="searchSection"
                                            id="searchSection"
                                            value={searchSection}
                                            onChange={(ev) => this.addedSearchedSection(ev.target.value)}
                                            errors={errors}
                                        />
                                        <div className="collection-of-lesson">

                                            {finalCourseSection.map((item, index) => {
                                                console.log(item);

                                                return (
                                                    <div key={index} className="lesson-li">
                                                        <div className="lecture-title">{item.title}</div>
                                                        <div className="zIndexBtn">
                                                            <button className="my-btn-3"
                                                                onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewsection/outline", state: { section: item } })}
                                                                type="button">View</button>
                                                            <button className="my-btn-3" onClick={() => {
                                                                this.setState({ search: "", addedSearchedSection: [] })
                                                                window.confirm("Are You Sure You Want To Delete?") && this.removeSection(item._id, index)
                                                            }} type="button">Remove</button>
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>

                                    </div>
                                </fieldset>
                            </div>
                        </div> :
                            <div className="text-Lesson">No Section is allowed in course content yet to show :(</div>
                        }






                        <button className="my-btn-3"
                            onClick={() => this.setState({ showLesson: true })}
                            type="button">Add Section</button>
                        {showLesson &&
                            <div>
                                {courseContentSections.length ? <div>
                                    <div className="legends-container">
                                        <fieldset>
                                            <legend>Section</legend>
                                            <div className="legend-input-container-3">
                                                <Input
                                                    label=" Search Section Here "
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    value={search}
                                                    onChange={(ev) => this.search(ev.target.value)}
                                                    errors={errors}
                                                />
                                                <div className="collection-of-lesson">
                                                    {finalSections.map((item, index) => {
                                                        return (
                                                            <div key={index} className="lesson-li">
                                                                <div className="lecture-title">{item.sequence}. {item.section.title}</div>
                                                                <div className="zIndexBtn">
                                                                    <button className="my-btn-3"
                                                                        onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewsection/section", state: { section: item } })}
                                                                        type="button">View</button>
                                                                    {<button className="my-btn-3" onClick={() => this.maintainSection(item.section._id)} type="button">Add</button>}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </fieldset>
                                    </div>
                                </div> :
                                    <div className="text-Lesson">No Section yet to show :(</div>}
                            </div>
                        }

                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.allowCourseReducer.isLoading,
        isError: state.allowCourseReducer.isError,
        errorMessage: state.allowCourseReducer.errorMessage,
        success: state.allowCourseReducer.success,
        courseContent: state.courseReducer.courseSections,
        allowedCourseContent: state.allowCourseReducer.allowedCourseContent
    }
}
function mapDispatchToProps(dispatch) {
    return {
        addCourseSection: data => {
            return dispatch(AllowCourseMiddleware.addCourseSection(data))
        },
        removeSection: data => {
            return dispatch(AllowCourseMiddleware.removeSection(data))
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllowedCourseContent);
