class AllowCourseActions {
    static ALLOW_COURSE = "ALLOW_COURSE";
    static ALLOW_COURSE_SUCCESSFULL = "ALLOW_COURSE_SUCCESSFULL";
    static ALLOW_COURSE_FAILED = "ALLOW_COURSE_FAILED";

    static GET_ALLOWED_COURSE_CONTENT = "GET_ALLOWED_COURSE_CONTENT";
    static GET_ALLOWED_COURSE_CONTENT_SUCCESSFULL = "GET_ALLOWED_COURSE_CONTENT_SUCCESSFULL";
    static GET_ALLOWED_COURSE_CONTENT_FAILED = "GET_ALLOWED_COURSE_CONTENT_FAILED";

    static DELETE_ALLOWED_COURSE_CONTENT = "DELETE_ALLOWED_COURSE_CONTENT";
    static DELETE_ALLOWED_COURSE_CONTENT_SUCCESSFUL = "DELETE_ALLOWED_COURSE_CONTENT_SUCCESSFUL";
    static DELETE_ALLOWED_COURSE_CONTENT_FAILED = "DELETE_ALLOWED_COURSE_CONTENT_FAILED";

    static ADD_SECTION_IN_ALLOWED_COURSE_CONTENT = "ADD_SECTION_IN_ALLOWED_COURSE_CONTENT";
    static ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL = "ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL";
    static ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED = "ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED";


    static REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT = "REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT";
    static REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL = "REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL";
    static REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED = "REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED";


    static getAllowedCourses(data) {
        return {
            type: this.GET_ALLOWED_COURSE_CONTENT,
            data
        }
    }
    static getAllowedCoursesSuccessfull(data) {
        return {
            type: this.GET_ALLOWED_COURSE_CONTENT_SUCCESSFULL,
            data: data.data
        }
    }
    static getAllowedCoursesFailed(data) {
        return {
            type: this.GET_ALLOWED_COURSE_CONTENT_FAILED,
            data
        }
    }


    static deleteAllowedCourseContents(data) {
        return {
            type: this.DELETE_ALLOWED_COURSE_CONTENT,
            data: data
        }
    }
    static deleteAllowedCourseContentsSuccessul(data) {
        return {
            type: this.DELETE_ALLOWED_COURSE_CONTENT_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static deleteAllowedCourseContentsFailed(data) {
        return {
            type: this.DELETE_ALLOWED_COURSE_CONTENT_FAILED,
            data: data
        }
    }


    static allowCourse(data) {
        return {
            type: this.ALLOW_COURSE,
            data: data
        }
    }
    static allowCourseSuccessful(data) {
        return {
            type: this.ALLOW_COURSE_SUCCESSFULL,
            data: data,
            _id: data._id
        }
    }
    static allowCourseFailed(data) {
        return {
            type: this.ALLOW_COURSE_FAILED,
            data: data
        }
    }



   



    static addSectionInAllowedCourseContent(data) {
        return {
            type: this.ADD_SECTION_IN_ALLOWED_COURSE_CONTENT,
            data: data
        }
    }
    static addSectionInAllowedCourseContentSuccessul(data) {
        return {
            type: this.ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static addSectionInAllowedCourseContentFailed(data) {
        return {
            type: this.ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED,
            data: data
        }
    }

    


    static removeSectionInAllowedCourseContent(data) {
        return {
            type: this.REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT,
            data: data
        }
    }
    static removeSectionInAllowedCourseContentSuccessul(data) {
        return {
            type: this.REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static removeSectionInAllowedCourseContentFailed(data) {
        return {
            type: this.REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED,
            data: data
        }
    }



}

export default AllowCourseActions;