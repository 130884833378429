import BatchConfig from '../actions/batchConfigActions';

function batchConfigReducer(state = {
    batchesConfig: [],
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null,
    edit: false,
    success: false
}, action) {

    switch (action.type) {
        case BatchConfig.ADD_INSTRUCTIONS:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
            };
        case BatchConfig.ADD_INSTRUCTIONS_SUCCESSFUL:
            state.batchesConfig[action.index] = { ...action.data }
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null,
            };
        case BatchConfig.ADD_INSTRUCTIONS_FAILED:

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,

            };

        case BatchConfig.GET_BATCH_CONFIG:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                success: false,
                isLoading: true,
            };
        case BatchConfig.GET_BATCH_CONFIG_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                batchesConfig: action.data,
                errorMessage: null,
            };
        case BatchConfig.GET_BATCH_CONFIG_FAILED:
            return {
                ...state,
                batchesConfig: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };

        case BatchConfig.ADD_BATCH_CONFIG:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
            };
        case BatchConfig.ADD_BATCH_CONFIG_SUCCESSFUL:
            state.batchesConfig.push({ ...action.data })
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null,
            };
        case BatchConfig.ADD_BATCH_CONFIG_FAILED:

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,

            };

        case BatchConfig.EDIT_BATCH_CONFIG:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                edit: true,
                success: false,
                isLoading: true,
            };
        case BatchConfig.EDIT_BATCH_CONFIG_SUCCESSFUL:
            console.log("reducer", action.data);

            state.batchesConfig[action.index] = { ...action.data }
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                edit: false,
                success: action.success,
            };
        case BatchConfig.EDIT_BATCH_CONFIG_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                edit: true,
                success: false,
            };

        case BatchConfig.DELETE_BATCH_CONFIG:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case BatchConfig.DELETE_BATCH_CONFIG_SUCCESSFUL:
            state.batchesConfig.splice(action.index, 1)
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: action.data.message,
            };
        case BatchConfig.DELETE_BATCH_CONFIG_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };
        case BatchConfig.REMOVE_INSTRUCTIONS:

            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case BatchConfig.REMOVE_INSTRUCTIONS_SUCCESSFUL:
            console.log("indexpooooo", action.index);
            console.log("sjhdhdhdhdhdhd", action.data.data);
            state.batchesConfig[action.index] = { ...action.data.data.data }
            return {
                ...state,
                isError: false,
                isLoading: false,
                successMessage: action.success,
            };
        case BatchConfig.REMOVE_INSTRUCTIONS_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };
        default:
            return state;
    }
}
export default batchConfigReducer;