class EntryTestEmailActions {

    static SEARCH_STUDENT = "SEARCH_STUDENT";
    static SEARCH_STUDENT_SUCCESSFUL = "SEARCH_STUDENT_SUCCESSFUL";
    static SEARCH_STUDENT_FAILED = "SEARCH_STUDENT_FAILED";

    static EDIT_EMAIL = "EDIT_EMAIL";
    static EDIT_EMAIL_SUCCESSFUL = "EDIT_EMAIL_SUCCESSFUL";
    static EDIT_EMAIL_FAILED = "EDIT_EMAIL_FAILED";

    static EDIT_EMAIL = "EDIT_EMAIL";
    static EDIT_EMAIL_SUCCESSFUL = "EDIT_EMAIL_SUCCESSFUL";
    static EDIT_EMAIL_FAILED = "EDIT_EMAIL_FAILED";

    static RESEND_EMAIL = "RESEND_EMAIL";
    static RESEND_EMAIL_SUCCESSFUL = "RESEND_EMAIL_SUCCESSFUL";
    static RESEND_EMAIL_FAILED = "RESEND_EMAIL_FAILED";



    static searchStudent(data) {
        return {
            type: this.SEARCH_STUDENT,
            data: data
        }
    }
    static searchStudentSuccessul(data) {
        return {
            type: this.SEARCH_STUDENT_SUCCESSFUL,
            data: data
        }
    }
    static searchStudentFailed(data) {
        return {
            type: this.SEARCH_STUDENT_FAILED,
            data: data
        }
    }

    static editEmail(data) {
        return {
            type: this.EDIT_EMAIL,
            data: data
        }
    }
    static editEmailSuccessul(data) {
        return {
            type: this.EDIT_EMAIL_SUCCESSFUL,
            data: data
        }
    }
    static editEmailFailed(data) {
        return {
            type: this.EDIT_EMAIL_FAILED,
            data: data
        }
    }

    static resendEmail(data) {
        return {
            type: this.RESEND_EMAIL,
            data: data
        }
    }
    static resendEmailSuccessul(data) {
        return {
            type: this.RESEND_EMAIL_SUCCESSFUL,
            data: data.data
        }
    }
    static resendEmailFailed(data) {
        return {
            type: this.RESEND_EMAIL_FAILED,
            data: data
        }
    }


}

export default EntryTestEmailActions;