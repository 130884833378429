import React, { Component, useReducer } from 'react'
import { Select, Input } from "../Input/input"
import StudentMiddleware from "../../store/middleware/studentMiddleware";
import { connect } from "react-redux"
import { Loader } from '../StudentManagment/helper';
class RemoveStudent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            student: null,
            identity: "",
            identityValue: "",
            successMessage:"",
            errors: {
                hasError: false,
                errorsObj: {}
            },
        }
    }
    componentWillReceiveProps(nextProps) {
        const { errors } = this.state;
        const { studentReducer } = nextProps
        if (studentReducer.isError && studentReducer.errorMessage) {
            errors.hasError = true;
            errors.simpleError = studentReducer.errorMessage;
            this.setState({ errors, student: null,successMessage:"", })
            return
        }
        if (studentReducer.student && studentReducer.student.emailVerified) {
            console.log("STUDENT")
            errors.hasError = true;
            errors.simpleError = "Student email is verified and cannot be deleted"
            this.setState({
                errors,
                student: studentReducer.student,
                successMessage:"",
            })
            return
        }
        if (studentReducer.student && !studentReducer.student.emailVerified) {
            console.log("STUDENT")
            errors.hasError = true;
            errors.simpleError = ""
            this.setState({
                errors,
                student: studentReducer.student,
                successMessage:studentReducer.successMessage

            })
            return
        }
        if (studentReducer.deleted) {
            console.log("STUDENT SUCCESS")
            errors.hasError = false;
            errors.simpleError = ""
            this.setState({
                errors,
                student: null,
                identity: "",
                identityValue: "",
                successMessage:studentReducer.successMessage
            })
        }

    }
    deleteStudent = () => {
        const { identity, identityValue } = this.state
        this.props.deleteStudent({ type: identity, value: identityValue })
    }
    submit = (event) => {
        event.preventDefault()
        const { identity, identityValue } = this.state
        if (!identity || !identityValue) {
            const { errors } = this.state
            errors.hasError = true;
            errors.simpleError = "Please select atleast one identity and its value";
            this.setState({
                errors
            })
        }
        else {
            this.props.searchStudent({ type: identity, value: identityValue });
        }
    }
    render() {
        const { errors, student, successMessage } = this.state
        // console.log("STUDENT REDUCER", this.props.studentReducer)
        const { studentReducer: { isLoading } } = this.props
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <div className="student-form-wrapper">
                        <form onSubmit={(ev) => this.submit(ev)} method="post">
                            <h3>Search Student</h3>
                            {errors.hasError && <p className="error">{errors.simpleError}</p>}
                            {successMessage && <span>{successMessage}</span>}
                            <Select
                                label="Select Identity"
                                name="identity"
                                id="identity"
                                options={[
                                    { name: "CNIC", value: "cnic" },
                                    // { name: "Number", value: "phoneNumber" },
                                    // { name: "Roll Number", value: "rollNo" },
                                    { name: "Email", value: "email" },

                                ]}
                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                errors={errors}
                            />

                            <Input
                                label="Value"
                                type="text"
                                name="identityValue"
                                id="identityValue"
                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                errors={errors}

                            />
                            <button className="my-btn-2" type="submit">Search</button>
                        </form>
                    </div>
                    {student && <div className="voucher-table-div" style={{flexDirection:"column"}}>

                        <div >
                            {student && !student.emailVerified && <input className="my-btn-2" type="button" value="Delete" onClick={this.deleteStudent} />}
                        </div>
                        <table className="voucher-table" >
                            <tbody>
                                <tr>
                                    <th>Email</th>
                                    <td>{student.email}</td>
                                </tr>
                                <tr>
                                    <th>CNIC</th>
                                    <td>{student.cnic}</td>
                                </tr>
                                <tr>
                                    <th>Is Email Verified</th>
                                    <td>{student.emailVerified ? "Yes" : "No"}</td>
                                </tr>
                                <tr>
                                    <th>Is Active</th>
                                    <td>{student.isActive ? "Yes" : "No"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ studentReducer }) => ({ studentReducer })
const mapDispatchToProps = (dispatch) => ({
    searchStudent: data => dispatch(StudentMiddleware.searchStudentForDelete(data)),
    deleteStudent: data => dispatch(StudentMiddleware.deleteStudent(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(RemoveStudent)