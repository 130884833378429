class BatchAction {

    static COMPLETE_BATCH ="COMPLETE_BATCH"
    static COMPLETE_BATCH_SUCCESSFUL="COMPLETE_BATCH_SUCCESSFUL"
    static COMPLETE_BATCH_FAILED = "COMPLETE_BATCH_FAILED"

    static START_BATCH_REG ="START_BATCH_REG"
    static START_BATCH_REG_SUCCESSFUL="START_BATCH_REG_SUCCESSFUL"
    static START_BATCH_REG_FAILED = "START_BATCH_REG_FAILED"
    

    static CLOSE_BATCH_REG ="CLOSE_BATCH_REG"
    static CLOSE_BATCH_REG_SUCCESSFUL="CLOSE_BATCH_REG_SUCCESSFUL"
    static CLOSE_BATCH_REG_FAILED = "CLOSE_BATCH_REG_FAILED"

    static GET_BATCH = "GET_BATCH";
    static GET_BATCH_SUCCESSFUL = "GET_BATCH_SUCCESSFUL";
    static GET_BATCH_FAILED = "GET_BATCH_FAILED";

    static DELETE_BATCH = "DELETE_BATCH";
    static DELETE_BATCH_SUCCESSFUL = "DELETE_BATCH_SUCCESSFUL";
    static DELETE_BATCH_FAILED = "DELETE_BATCH_FAILED";

    static EDIT_BATCH = "EDIT_BATCH";
    static EDIT_BATCH_SUCCESSFUL = "EDIT_BATCH_SUCCESSFUL";
    static EDIT_BATCH_FAILED = "EDIT_BATCH_FAILED";

    static ADD_BATCH = "ADD_BATCH";
    static ADD_BATCH_SUCCESSFUL = "ADD_BATCH_SUCCESSFUL";
    static ADD_BATCH_FAILED = "ADD_BATCH_FAILED";


    static completeBatch(data){
        return{
            type:this.COMPLETE_BATCH,
            data:data,

        }
    }
    static completeBatchSuccessfull(data){
        return {
            type:this.COMPLETE_BATCH_SUCCESSFUL,
            data:data.data,
            index:data.index,
        }
    }
    static completeBatchFailed(data){
        return {
            type:this.COMPLETE_BATCH_FAILED,
            data:data,
        }
    }
    static closeBatchReg(data){
        return {
            type:this.CLOSE_BATCH_REG,
            data:data
        }
    }
    static closeBatchRegSuccessfull(data){
        return{
            type:this.CLOSE_BATCH_REG_SUCCESSFUL,
            data:data.data,
            index:data.index
        }
    }
    static closeBatchRegFailed(data){
        return{
            type:this.CLOSE_BATCH_REG_FAILED,
            data:data,
        }
    }
    static startBatchReg(data){
        return {
            type:this.START_BATCH_REG,
            data:data,
        }
    }
    static startBatchRegSuccessfull(data){
        return {
            type:this.START_BATCH_REG_SUCCESSFUL,
            data:data.data,
            index:data.index,
        }
    }
    static startBatchRegFailed(data){
        return{
            type:this.START_BATCH_REG_FAILED,
            data:data.data,
        
        }
    }

    static getBatch(data) {
        return {
            type: this.GET_BATCH,
            data: data
        }
    }
    static getBatchSuccessul(data) {
        return {
            type: this.GET_BATCH_SUCCESSFUL,
            data: data.data
        }
    }
    static getBatchFailed(data) {
        return {
            type: this.GET_BATCH_FAILED,
            data: data
        }
    }


    static deleteBatch(data) {
        return {
            type: this.DELETE_BATCH,

        }
    }
    static deleteBatchSuccessul(data) {
        return {
            type: this.DELETE_BATCH_SUCCESSFUL,
            data: data.data,
            index: data.index
        }
    }
    static deleteBatchFailed(data) {
        return {
            type: this.DELETE_BATCH_FAILED,
            data: data
        }
    }

    static addBatch(data) {
        return {
            type: this.ADD_BATCH,
            data: data
        }
    }
    static addBatchSuccessul(data) {
        return {
            type: this.ADD_BATCH_SUCCESSFUL,
            data: data.data,
            success: data.success
        }
    }
    static addBatchFailed(data) {
        return {
            type: this.ADD_BATCH_FAILED,
            data: data
        }
    }

    static editBatch(data) {
        return {
            type: this.EDIT_BATCH,
            data: data
        }
    }
    static editBatchSuccessul(data) {
        console.log("action successs edit data==========>", data.data);
        return {
            type: this.EDIT_BATCH_SUCCESSFUL,
            data: data.data,
            index: data.index,
            success: data.success
        }
    }
    static editBatchFailed(data) {
        return {
            type: this.EDIT_BATCH_FAILED,
            data: data
        }
    }




}

export default BatchAction