import React, { Component } from 'react';
import { Select, Input } from "../Input/input";
import validateForm from "./helper";
import CourseMiddleware from '../../store/middleware/courseMiddleware';
import timeSlotMiddleware from '../../store/middleware/timeSlotMiddleware';
import LocalStorageManager from '../../services/localStorageManager';
import { connect } from "react-redux";
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import Path from '../../config/path';
import axios from 'axios';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html'

class SendEmailTimeslot extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        Quarters: [],
        centers: [],
        courses: [],
        timings: [],
        center: "",
        time: "",
        batch: "",
        quarter: "",
        course: "",
        emailContent: "",
        emailSubject: "",
        days: [],
        day: "",
        schedulerName: '',
        minutesPerBatch: '',
        noOfEmailsInOneBatch: "",

        errors: {
            hasError: false,
            errorsObj: {},

        },

        successMessage: '',
        errorMessage: ''

    }

 

    onSelect = (ev) => {
        const { timeSlots, courseDetails } = this.props
          
        const selectedValue = ev.target.value
        const selectedName = ev.target.name
        switch (selectedName) {
            case ("batch"): {
                if ((selectedValue !== "Select" || selectedValue !== "") && (timeSlots!==null && courseDetails !== null)) {
                    let courses = []
                    let initials = []
                    timeSlots.filter((timeslot) => timeslot[selectedName] === selectedValue).
                        map((timeslot) => initials.push(timeslot.course))
                   
                    // Removes Duplication from array
                    initials = [...new Set(initials)]

                    courseDetails.map((detail) => {
                        if (initials.includes(detail.initial)) {
                            courses.push({
                                name: detail.name,
                                value: detail.initial
                            })
                        }
                    })
                    this.setState(({ [ev.target.name]: ev.target.value, courses }))
                }

                break
                
            }

            case ("course"): {
                if (selectedValue !== "Select" || selectedValue !== "" ) {
                    const { batch } = this.state
                    let Quarters = []
                    timeSlots.filter((timeslot) => timeslot.batch === batch && timeslot.course === selectedValue).
                        map((timeslot) => Quarters.push(timeslot.quarter))

                    // Removes Duplication from array
                    Quarters = [...new Set(Quarters)]

                    this.setState(({ [ev.target.name]: ev.target.value, Quarters }))
                }
                break
            }

            case ("center"): {
                if (selectedValue !== "Select" || selectedValue !== "") {
                    const { batch, course, quarter } = this.state
                    let days = []
                    const [name, campus] = selectedValue.split(",")
                    timeSlots.filter((timeslot) => timeslot.batch === batch && timeslot.course === course &&
                        timeslot.name === name && timeslot.campus === campus && timeslot.quarter === quarter).
                        map((timeslot) => days.push(timeslot.day))

                    // Removes Duplication from array
                    days = [...new Set(days)]

                    // this.initialState(unchangeValues)
                    this.setState(({ [ev.target.name]: ev.target.value, days, }))
                }
                break
            }

            case ("day"): {
                if (selectedValue !== "Select" || selectedValue !== "") {
                    const { batch, course, center, quarter } = this.state
                    let timings = []
                    const [name, campus] = center.split(",")
                    timeSlots.filter((timeslot) => timeslot.batch === batch && timeslot.course === course &&
                        timeslot.name === name && timeslot.campus === campus && timeslot.quarter === quarter
                        && timeslot.day === selectedValue).
                        map((timeslot) => timings.push(timeslot.time))

                    // Removes Duplication from array
                    timings = [...new Set(timings)]

                    // this.initialState(unchangeValues)
                    this.setState(({ [ev.target.name]: ev.target.value, timings, }))
                }
                break

            }

            case ("time"): {
                if (selectedValue !== "Select" || selectedValue !== "") {
                    const { batch, course, center } = this.state
                    let Quarters = []
                    const [name, campus] = center.split(",")
                    timeSlots.filter((timeslot) => timeslot.batch === batch && timeslot.course === course &&
                        timeslot.name === name && timeslot.campus === campus && timeslot.time === selectedValue).
                        map((timeslot) => Quarters.push(timeslot.quarter))

                    // Removes Duplication from array
                    Quarters = [...new Set(Quarters)]

                    this.setState(({ [ev.target.name]: ev.target.value, Quarters, }))
                }

                break
            }
            case ("quarter"): {
                if (selectedValue !== "Select" || selectedValue !== "") {
                    const { batch, course } = this.state
                    let centers = []
                    timeSlots.filter((timeslot) => timeslot.batch === batch && timeslot.course === course &&
                        timeslot[selectedName] === selectedValue).
                        map((timeslot) => centers.push(`${timeslot.name},${timeslot.campus}`))

                    // Removes Duplication from array
                    centers = [...new Set(centers)]

                    this.setState(({ [ev.target.name]: ev.target.value, centers }))
                }
                break
            }
        }
    }
    
    submit = (ev) => {
        ev.preventDefault();
        
        const { time, batch, quarter, course, emailSubject, center, day, schedulerName, minutesPerBatch, noOfEmailsInOneBatch, emailContent } = this.state
        console.log(schedulerName)
        var err = validateForm({ time, batch, quarter, course, emailSubject, center, day, schedulerName, minutesPerBatch, noOfEmailsInOneBatch })

        if (err.hasError) {
            this.setState({ errors: err })
            return
        }
        this.setState({
            errors: {
                hasError: false,
                errorsObj: {}
            }
        })

        const currTimeSlot = this.props.timeSlots.filter(item => {
            return item.time === time && day === item.day && course === item.course && quarter === item.quarter
        })

        let timeSlotId;

        for (let i = 0; i < currTimeSlot.length; i++) {
            timeSlotId = currTimeSlot[i]._id;
        }

        // For test use below id
        // timeSlotId: "5c7ffa513ca3dc001797d212",
        axios.post(Path.SEND_EMAIL_TIMESLOT, {
            schedulerName,
            batch: noOfEmailsInOneBatch,
            minutes: minutesPerBatch,
            timeSlotId,
            emailSubject,
            template: emailContent
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    successMessage: res.data.message,
                    emailContent: '',
                    emailSubject: '',
                    batch: '',
                    minutesPerBatch: '',
                    noOfEmailsInOneBatch: '',
                    schedulerName: ''
                })
            } else {
                this.setState({
                    errorMessage: res.data.message
                })
            }
        }).catch(err => {
            this.setState({
                errorMessage: 'Please Try Again'
            })
        })
    }

    componentDidMount() {
        this.props.getCourses();
        this.props.getTimeSlot();
    }

    componentWillReceiveProps(nextProps) {
        nextProps.courseDetails ? this.setState({ courseDetails: [...nextProps.courseDetails] }) : this.setState({ courseDetails: [] })
    }
    onEditorStateChange = (editorState) => {

        let contentState = editorState.getCurrentContent();
        let html = stateToHTML(contentState);

        this.setState({
            editorState,
            emailContent: html
        })

    }

    render() {
        const Batch = [{ name: "Batch 1", value: "Batch 1" }]
        const { batch, quarter, errors, courses, course, emailSubject, center, centers, timings, time, Quarters, days, day,
            schedulerName, noOfEmailsInOneBatch, minutesPerBatch, successMessage, errorMessage, editorState } = this.state

        return (
            <div className="container-fluid">
                <div className="container">
                    <div className="form-container">
                        <div className="form-wrapper">
                            <form onSubmit={(ev) => this.submit(ev)} method="post">
                                <h1> Email Students Based on Timeslot </h1>


                                {successMessage && <p className="success">{successMessage}</p>}
                                {errorMessage && <p className="error">{errorMessage}</p>}

                                <Select
                                    label="Batch"
                                    name="batch"
                                    id="batch"
                                    value={batch}
                                    options={Batch}
                                    errors={errors}
                                    onChange={(ev) => { this.onSelect(ev) }}
                                />

                                <Select
                                    label="Courses"
                                    name="course"
                                    id="course"
                                    value={course}
                                    options={courses.length ? courses.map((course, index) => {
                                        return { name: course.name, value: course.value }
                                    }) : []}
                                    errors={errors}
                                    onChange={this.onSelect}
                                    disabled={batch === "Select" || batch === "" ? "disabled" : null}
                                />

                                <Select
                                    label="Quarter"
                                    name="quarter"
                                    id="quarter"
                                    value={quarter}
                                    options={Quarters.length ? Quarters.map((quarter, index) => {
                                        return { name: quarter, value: quarter }
                                    }) : []}
                                    errors={errors}
                                    onChange={this.onSelect}
                                    disabled={batch && course ? null : "disabled"}
                                />

                                <Select
                                    label="Center"
                                    name="center"
                                    id="center"
                                    value={center}
                                    options={centers.length ? centers.map((center, index) => {
                                        return { name: center, value: center }
                                    }) : []}
                                    errors={errors}
                                    onChange={this.onSelect}
                                    disabled={batch && course && quarter ? null : "disabled"}
                                />

                                <Select
                                    label="day"
                                    name="day"
                                    id="day"
                                    value={day}
                                    options={days.length ? days.map((day, index) => {
                                        return { name: day, value: day }
                                    }) : []}
                                    errors={errors}
                                    onChange={this.onSelect}
                                    disabled={batch && course && quarter && center ? null : "disabled"}
                                />

                                <Select
                                    label="time"
                                    name="time"
                                    id="time"
                                    value={time}
                                    options={timings.length ? timings.map((time, index) => {
                                        return { name: time, value: time }
                                    }) : []}
                                    errors={errors}
                                    onChange={this.onSelect}
                                    disabled={batch && course && center && quarter ? null : "disabled"}
                                />

                                <Input
                                    label="Scheduler Name"
                                    type="text"
                                    name="schedulerName"
                                    id="schedulerName"
                                    value={schedulerName}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />

                                <Input
                                    label="minutes Per Batch"
                                    type="number"
                                    name="minutesPerBatch"
                                    id="minutesPerBatch"
                                    value={minutesPerBatch}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />

                                <Input
                                    label="No of Emails in one batch"
                                    type="number"
                                    name="noOfEmailsInOneBatch"
                                    id="noOfEmailsInOneBatch"
                                    value={noOfEmailsInOneBatch}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />

                                <Input
                                    label="Email Subject"
                                    type="text"
                                    name="emailSubject"
                                    id="emailSubject"
                                    value={emailSubject}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />

                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarWrapper"
                                    wrapperClassName="editorWrapper"
                                    editorClassName="emailWrapper"
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                                <br></br>
                                <button className="my-btn-2" type="submit" >Send Email</button>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        courseDetails: state.courseReducer.courses,
        timeSlots: state.timeSlotReducer.timeSlots,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getCourses: data => {
            dispatch(CourseMiddleware.getCourses(data))
        },
        getTimeSlot: data => {
            dispatch(timeSlotMiddleware.getTimeSlot(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SendEmailTimeslot)