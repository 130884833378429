import VoucherActions from '../actions/voucherActions';

function voucherReducer(state = {
    isError: false,
    isLoading: false,
    errorMessage: false,
    vouchers: [],
    voucher: null,
    fetched: false,
    successMessage: null,
    feeCollected:{}
}, action) {
    switch (action.type) {

        case VoucherActions.SEARCH_VOUCHER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                fetched: false,
                vouchers: [],
                voucher: null
            };
        case VoucherActions.SEARCH_VOUCHER_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                vouchers: action.data,
                fetched: true,
                errorMessage: null,
            };
        case VoucherActions.SEARCH_VOUCHER_BY_NO_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                voucher: action.data,
                fetched: true,
                errorMessage: null,
            }
        case VoucherActions.VOUCHER_PAYMENT_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                voucher:null,
                isLoading: false,
                successMessage: action.data,
                fetched: true,
                errorMessage: null,
            }
        case VoucherActions.SEARCH_VOUCHER_FAILED:
            return {
                ...state,
                vouchers: [],
                voucher: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                fetched: true,
                isLoading: false
            };


        case VoucherActions.STATUS_VOUCHER_UPDATE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case VoucherActions.STATUS_VOUCHER_UPDATE_SUCCESSFUL:
            console.log('action ', action);
            state.vouchers.voucherArray[action.index] = { ...state.vouchers.voucherArray[action.index], ...action.data.data }
            return {
                ...state,
                isError: false,
                isLoading: false,
                // vouchers: action.data.data,
                errorMessage: null,
            };
        case VoucherActions.STATUS_VOUCHER_UPDATE_FAILED:
            return {
                ...state,
                voucher: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };

            case VoucherActions.MY_COLLECTION:
                return {
                    ...state,
                    errorMessage: null,
                    successMessage: null,
                    isLoading: true,
                };
            case VoucherActions.MY_COLLECTION_SUCCESSFUL:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    feeCollected:action.data.feeCollected,
                    errorMessage: null,
                };
            case VoucherActions.MY_COLLECTION_FAILED:
                return {
                    ...state,
                    voucher: null,
                    errorMessage: action.data.errorMessage,
                    successMessage: null,
                    isError: true,
                    isLoading: false
                };
    
        default:
            return state;
    }
}
export default voucherReducer;