class TimeSlotAction {


    static GET_TIME_SLOTS = "GET_TIME_SLOTS";
    static GET_TIME_SLOTS_SUCCESSFUL = "GET_TIME_SLOTS_SUCCESSFUL";
    static GET_TIME_SLOTS_FAILED = "GET_TIME_SLOTS_FAILED";



    static DELETE_TIME_SLOTS = "DELETE_TIME_SLOTS";
    static DELETE_TIME_SLOTS_SUCCESSFUL = "DELETE_TIME_SLOTS_SUCCESSFUL";
    static DELETE_TIME_SLOTS_FAILED = "DELETE_TIME_SLOTS_FAILED";


    static EDIT_TIME_SLOTS = "EDIT_TIME_SLOTS";
    static EDIT_TIME_SLOTS_SUCCESSFUL = "EDIT_TIME_SLOTS_SUCCESSFUL";
    static EDIT_TIME_SLOTS_FAILED = "EDIT_TIME_SLOTS_FAILED";

    static ADD_TIME_SLOTS = "ADD_TIME_SLOTS";
    static ADD_TIME_SLOTS_SUCCESSFUL = "ADD_TIME_SLOTS_SUCCESSFUL";
    static ADD_TIME_SLOTS_FAILED = "ADD_TIME_SLOTS_FAILED";

   


    static getTimeSlots(data) {
        return {
            type: this.GET_TIME_SLOTS,
            data: data
        }
    }
    static getTimeSlotsSuccessul(data) {
        return {
            type: this.GET_TIME_SLOTS_SUCCESSFUL,
            data: data.data,
        }
    }
    static getTimeSlotsFailed(data) {
        return {
            type: this.GET_TIME_SLOTS_FAILED,
            data: data
        }
    }



    static deleteTimeSlots(data) {
        return {
            type: this.DELETE_TIME_SLOTS,
            data: data
        }
    }
    static deleteTimeSlotsSuccessul(data) {
        return {
            type: this.DELETE_TIME_SLOTS_SUCCESSFUL,
            data: data,
            index: data.index
        }
    }
    static deleteTimeSlotsFailed(data) {
        return {
            type: this.DELETE_TIME_SLOTS_FAILED,
            data: data
        }
    }




    static editTimeSlots(data) {
        return {
            type: this.EDIT_TIME_SLOTS,
            data: data,
        }
    }
    static editTimeSlotsSuccessul(data) {
        return {
            type: this.EDIT_TIME_SLOTS_SUCCESSFUL,
            data: data.data,
            index: data.index,
            success:data.success            
        }
    }
    static editTimeSlotsFailed(data) {
        return {
            type: this.EDIT_TIME_SLOTS_FAILED,
            data: data
        }
    }




    static addTimeSlots(data) {
        return {
            type: this.ADD_TIME_SLOTS,
            data: data
        }
    }
    static addTimeSlotsSuccessul(data) {
        return {
            type: this.ADD_TIME_SLOTS_SUCCESSFUL,
            data: data.data,
            success:data.success            
        }
    }
    static addTimeSlotsFailed(data) {
        return {
            type: this.ADD_TIME_SLOTS_FAILED,
            data: data
        }
    }
}

export default TimeSlotAction