import EmailActions from '../actions/emailActions';

function emailReducer(state = {
    user: null,
    isError: false,
    isLoading: false,
    errorMessage: false,
    userId: null,
    isLoggedIn:false,
    successMessage: null,
}, action) {
    switch (action.type) {
        
        case EmailActions.EMAIL_SENDING:
            return {
                ...state,
                errorMessage:null,
                successMessage:null,
                isLoading : true
            };
        case EmailActions.EMAIL_SENDING_SUCCESSFUL:
            return {
                ...state,
                isError:false,
                isLoading : false,
                isLoggedIn:true,
                errorMessage:null,
                successMessage: action.data.successMessage
            };
        case EmailActions.EMAIL_SENDING_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage:null,
                isError: true,
                isLoading : false
            };
        
        default:
            return state;
    }
}
export default emailReducer;