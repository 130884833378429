class EmailActions {

    static EMAIL_SENDING = "EMAIL_SENDING";
    static EMAIL_SENDING_SUCCESSFUL = "EMAIL_SENDING_SUCCESSFUL";
    static EMAIL_SENDING_FAILED = "EMAIL_SENDING_FAILED";

    static  emailSending(data){
        return {
            type : this.EMAIL_SENDING,
            data : data
        }
    }
    static emailSendingSuccessul(data){
        return {
            type : this.EMAIL_SENDING_SUCCESSFUL,
            data: data
        }
    }
    static emailSendingFailed(data){
        return {
            type : this.EMAIL_SENDING_FAILED ,
            data: data
        }
    }

}

export default EmailActions;