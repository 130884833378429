import CenterAction from '../actions/centerActions';

function centerReducer(state = {
    centers: null,
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null,
    edit: false,
    success: false
}, action) {

    switch (action.type) {

        case CenterAction.GET_CENTER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                success: false,
                isLoading: true,
            };
        case CenterAction.GET_CENTER_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                centers: action.data,
                errorMessage: null,
            };
        case CenterAction.GET_CENTER_FAILED:
            return {
                ...state,
                centers: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };

        case CenterAction.ADD_CENTER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
            };
        case CenterAction.ADD_CENTER_SUCCESSFUL:
            console.log(action.data);

            state.centers.push({ ...action.data })
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null,
            };
        case CenterAction.ADD_CENTER_FAILED:
            console.log(action.data);

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,

            };

        case CenterAction.EDIT_CENTER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                edit: true,
                success: false,
                isLoading: true,
            };
        case CenterAction.EDIT_CENTER_SUCCESSFUL:
            console.log(action.data);

            state.centers[action.index] = { ...action.data }
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                edit: false,
                success: action.success,
            };
        case CenterAction.EDIT_CENTER_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                edit: true,
                success: false,
            };

        case CenterAction.DELETE_CENTER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case CenterAction.DELETE_CENTER_SUCCESSFUL:
            state.centers.splice(action.index, 1)
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case CenterAction.DELETE_CENTER_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };



        default:
            return state;
    }
}
export default centerReducer;