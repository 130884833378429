class CourseToBatchActions {

    static GET_COURSE_BY_BATCHID = "GET_COURSE_BY_BATCHID";
    static GET_COURSE_BY_BATCHID_SUCCESSFUL = "GET_COURSE_BY_BATCHID_SUCCESSFUL";
    static GET_COURSE_BY_BATCHID_FAILED = "GET_COURSE_BY_BATCHID_FAILED";

    static ADD_COURSE_TO_BATCH = "ADD_COURSE_TO_BATCH";
    static ADD_COURSE_TO_BATCH_SUCCESSFUL = "ADD_COURSE_TO_BATCH_SUCCESSFUL";
    static ADD_COURSE_TO_BATCH_FAILED = "ADD_COURSE_TO_BATCH_FAILED";

    static DELETE_COURSE_FROM_BATCH = "DELETE_COURSE_FROM_BATCH";
    static DELETE_COURSE_FROM_BATCH_SUCCESSFUL = "DELETE_COURSE_FROM_BATCH_SUCCESSFUL";
    static DELETE_COURSE_FROM_BATCH_FAILED = "DELETE_COURSE_FROM_BATCH_FAILED";

    static getCourseByBatchId(data) {
        return {
            type: this.GET_COURSE_BY_BATCHID,
            data: data
        }
    }
    static getCourseByBatchIdSuccessul(data) {
        return {
            type: this.GET_COURSE_BY_BATCHID_SUCCESSFUL,
            data: data.data
        }
    }
    static getCourseByBatchIdFailed(data) {
        return {
            type: this.GET_COURSE_BY_BATCHID_FAILED,
            data: data
        }
    }


    static deleteCourseFromBatch(data) {
        return {
            type: this.DELETE_COURSE_FROM_BATCH,
            data: data
        }
    }
    static deleteCourseFromBatchSuccessul(data) {
        return {
            type: this.DELETE_COURSE_FROM_BATCH_SUCCESSFUL,
            data: data,
        }
    }
    static deleteCourseFromBatchFailed(data) {
        return {
            type: this.DELETE_COURSE_FROM_BATCH_FAILED,
            data: data
        }
    }

    static addCourseToBatch(data) {
        return {
            type: this.ADD_COURSE_TO_BATCH,
            data: data
        }
    }
    static addCourseToBatchSuccessul(data) {
        return {
            type: this.ADD_COURSE_TO_BATCH_SUCCESSFUL,
            data: data.data,
            success: data.success,

        }
    }
    static addCourseToBatchFailed(data) {
        return {
            type: this.ADD_COURSE_TO_BATCH_FAILED,
            data: data
        }
    }
}

export default CourseToBatchActions