/* eslint-disable*/
import React, { Component } from 'react';
import { Input, Select } from "../Input/input";
import { Loader } from "../StudentManagment/helper";
import { connect } from "react-redux";
import { IoIosCloseCircleOutline } from 'react-icons/io'
import BatchMiddleware from '../../store/middleware/batchMidleware';
import BatchConfigMiddleware from '../../store/middleware/batchConfigMiddleware';
import moment from 'moment';
import { validateForm } from "./helper";
import './style.css';

class AddBatchConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
            },
            batch: "",
            attestationDate: "",
            admitCardMessage: "",
            instructionformale: [],
            maleInstruction: "",
            femaleInstruction: "",
            instructionforfemale: [],
            successMessage: "",
            errorMessage: "",
            batches: [],
            edit: false,
            isLoading: false,
            entryTestDate: "",
            batchesConfig: [],
            disable: false,
            telegramLinks:{
                groupName:"",
                iosLink:"",
                androidLink:""
            }

        }
    }
    getBatchNameFromId = (id) => {
        let a = this.state.batches.filter(rec => rec._id == id)
        return a.length > 0 && a[0].name
    }
    makeInstructionList = (instructions) => {
        return <ol>
            {instructions.map((rec, i) => <li key={i}>{rec}</li>)}
        </ol>
    }
    tableSection = (batchesConfig) => {
        return !!batchesConfig.length && (
            <div className="table-wrapper">
                <br />
                <br />
                <br />
                <table className="tds">
                    <tbody>
                        <tr>
                            <th>Batch Name</th>
                            <th>Instructions For Male </th>
                            <th>instructions For Female</th>
                            <th>Attestation Date</th>
                            <th>Entry Test Date</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>

                        {batchesConfig.map((config, index) => {
                            return (
                                <tr key={index}>
                                    <td>{this.getBatchNameFromId(config.batchId)}</td>
                                    <td>{this.makeInstructionList(config.instructionsForMale)}</td>
                                    <td>{this.makeInstructionList(config.instructionsForFemale)}</td>
                                    <td>{moment(config.attestationDate).format('LL')}</td>
                                    <td>{moment(config.entryTestDate).format('LL')}</td>
                                    <td><button type="button" className="my-btn-3" onClick={() => this.edit(config, index)}>Edit</button></td>
                                    <td><button type="button" className="my-btn-3" onClick={() => window.confirm("Are You Sure You Want To Delete This") && this.props.deleteBatchConfig({ batchConfigId: config._id, index })}>Delete</button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            batches: nextProps.batches ? [...nextProps.batches] : [],
            batchesConfig: nextProps.batchesConfig ? [...nextProps.batchesConfig] : [],
            errorMessage: nextProps.errorMessage,
            edit: nextProps.edit,
            successMessage: nextProps.successMessage,
        });
    }
    componentDidMount() {
        this.props.getBatches();
        this.props.getAllBatchesConfig();
    }

    submit = (evt) => {
        evt.preventDefault()
        const batchId = this.state.batch;
        const { instructionforfemale, instructionformale, admitCardMessage, attestationDate, entryTestDate, index, edit, batch, batchConfig , telegramLinks} = this.state;
        const instructionsForFemale = instructionforfemale;
        const instructionsForMale = instructionformale;
        var err = validateForm("all", { batch, admitCardMessage ,telegramLinks });
        if (err.hasError) {
            this.setState({ errors: err })
            return;
        }
        this.setState({
            errors: {
                hasError: false,
                errorsObj: {}
            }
        })
        if (edit) {
            this.props.editBatchConfig({ index, batchId, entryTestDate, attestationDate })
                .then(() => this.resetState())
                .catch(
                    (err) => this.setState({
                        errorMessage: err
                    })
                )
        }
        else {
            this.props.addBatchConfig({ index, batchId, instructionsForFemale, instructionsForMale, admitCardMessage, attestationDate, entryTestDate ,telegramLinks })
                .then(() => this.resetState())
                .catch(
                    (err) => this.setState({
                        errorMessage: err
                    })
                )
        }
    }

    handleMaleInstructionDelete = async (instrct) => {
        const { edit, index, instructionformale } = this.state;
        if (edit) {
            const batchId = this.state.batch;
            const ret = await this.props.removeInstruction({ batchId, instruction: instrct, gender: "male", index })
            if (ret) {
                this.setState({ edit: true })
            } else {
                this.setState({
                    errorMessage: "Something went wrong"
                })
            }

        }
        const newInstructionsFormale = instructionformale.filter(instruction => instruction !== instrct)
        this.setState({ instructionformale: newInstructionsFormale })
    }
    handleFemaleInstructionDelete = async (insctruct) => {
        const { edit, index, instructionforfemale } = this.state;

        if (edit) {
            const batchId = this.state.batch;
            const ret = await this.props.removeInstruction({ batchId, instruction: insctruct, gender: "female", index })
            if (ret) {
                this.setState({ edit: true })
            }
        }
        const newInstructionsForFemale = instructionforfemale.filter(instruction => instruction !== insctruct)
        this.setState({ instructionforfemale: newInstructionsForFemale })
    }

    addInstructions = (ev, instructionType) => {
        if (ev.key == 'Enter') {
            console.log("Enter pressed")
            const newInstruction = ev.target.value
            const { edit } = this.state;
            switch (instructionType) {
                case "instructionformale": {
                    if (edit) {
                        const batchId = this.state.batch;
                        this.props.addInstructions({ batchId, instruction: newInstruction, gender: "male" }).then(() => {
                            this.setState({ edit: true })
                        }
                        ).catch((err) => {
                            console.log("err", err);
                        })
                    }
                    this.setState(({ newInstruction }, (state) => ({ maleInstruction: "", instructionformale: [...state.instructionformale, newInstruction] })));
                    break;
                }
                case "instructionforfemale": {
                    if (edit) {
                        const batchId = this.state.batch;
                        this.props.addInstructions({ batchId, instruction: newInstruction, gender: "female" }).then(() => {
                            this.setState({ edit: true })
                        }
                        ).catch((err) => {
                            console.log("err", err);
                        })
                    }
                    this.setState(({ newInstruction }, (state) => ({ femaleInstruction: "", instructionforfemale: [...state.instructionforfemale, newInstruction] })));
                    break;
                }
                default:
                    return
            }
        }
    }
    
        handleMaleInstructionAddition = (tag) => {
            const { edit } = this.state;
            if (edit) {
                const batchId = this.state.batch;
                this.props.addInstructions({ batchId, instruction: tag.text, gender: "male", index }).then(() => {
                    this.setState({ edit: true })
                }
                ).catch((err) => {
                    console.log("err", err);
                })
            }
            this.setState(state => ({ instructionformale: [...state.instructionformale, tag] }));
        }
        resetState = () => {
            this.setState({
                instructionforfemale: [],
                instructionformale: [],
                maleInstruction: "",
                femaleInstruction: "",
                entryTestDate: "",
                attestationDate: "",
                admitCardMessage: "",
                edit: false,
                batch: "",
                disable: false,
                error: "",
                successMessage: "success",
                telegramLinks:{
                    groupName:"",
                    iosLink:"",
                    androidLink:""
                }
            })
        }
        handleFemaleInstructionAddition = (tag) => {
            const { edit, index } = this.state;
            if (edit) {
                const batchId = this.state.batch;
                this.props.addInstructions({ batchId, instruction: tag.text, gender: "female", index }).then(() => {
                    this.setState({ edit: true })
                }
                ).catch((err) => {
                    console.log("err", err);
                })

            }
            this.setState(state => ({ instructionforfemale: [...state.instructionforfemale, tag] }));
        }

        edit = (batchConfig, index) => {
            this.setState({
                admitCardMessage: batchConfig.admitCardMessage,
                instructionforfemale: batchConfig.instructionsForFemale,
                instructionformale: batchConfig.instructionsForMale,
                attestationDate: new Date(batchConfig.attestationDate).getTime(), attestationDate: new Date(batchConfig.attestationDate).toISOString().slice(0, 10),
                entryTestDate: new Date(batchConfig.entryTestDate).getTime(), entryTestDate: new Date(batchConfig.entryTestDate).toISOString().slice(0, 10),
                batch: batchConfig.batchId,
                disable: true,
                edit: true,
                index,
                errors: {
                    hasError: false,
                    errorsObj: {}
                }
            });
        };
        handleLinks=(e)=>{
            const {telegramLinks} = this.state
            telegramLinks[e.target.name]= e.target.value
            this.setState(telegramLinks)
        }
        render() {
            const { disable, batch, batchesConfig, entryTestDate, attestationDate, admitCardMessage, instructionformale, instructionforfemale, femaleInstruction, maleInstruction, batches, isLoading, errors, edit, errorMessage, success, successMessage , telegramLinks:{groupName , iosLink , androidLink}} = this.state;
            // console.log(batchesConfig && batchesConfig);
            return (
                <div className="container-fluid">
                    {isLoading && <Loader />}
                    <div className="container">
                        <div className="form-container align-items-center">
                            <div className="form-wrapper">
                                <form onSubmit={(ev) => this.Submit(ev)} method="post">
                                    <h3>Add Batch Config</h3>
                                    <div className={errorMessage ? "form-wrapper-1" : "form-wrapper-2"}>
                                        {errorMessage && <p className="error">{errorMessage}</p>}
                                        {success && <p className="success">{successMessage}</p>}
                                        <Select
                                            label="Batch"
                                            name="batch"
                                            id="batch"
                                            disabled={disable}
                                            value={batch}
                                            options={
                                                batches.length ? batches.map((batch, index) => {
                                                    return { name: batch.name, value: batch._id }
                                                }) : []
                                            }
                                            onChange={(ev) => {
                                                return this.setState({ [ev.target.name]: ev.target.value })
                                            }}
                                            errors={errors}
                                        />

                                        <label >
                                            Instructions For Male
                                </label>
                                        {instructionformale.length > 0 && <div className="instructions">
                                            <ol>
                                                {instructionformale && instructionformale.map((instruction, index) => (<li key={index}>{instruction}<IoIosCloseCircleOutline
                                                    size={24}
                                                    onClick={() => this.handleMaleInstructionDelete(instruction)} /></li>))}
                                            </ol>
                                        </div>}

                                        <textarea
                                            className="textarea"
                                            name="maleInstruction"
                                            id="maleInstruction"
                                            placeholder="Enter new Isnstructions"
                                            value={maleInstruction}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            onKeyPress={(ev) => { this.addInstructions(ev, "instructionformale") }}
                                        >
                                        </textarea>
                                        <label >
                                            Instructions For Female
                                </label>
                                        {instructionforfemale.length > 0 && <div className="instructions">
                                            <ol>
                                                {instructionforfemale && instructionforfemale.map((instruction, index) => (<li key={index} >{instruction}<IoIosCloseCircleOutline
                                                    size={24}
                                                    onClick={() => this.handleFemaleInstructionDelete(instruction)} /></li>))}
                                            </ol>
                                        </div>}
                                        <textarea
                                            className="textarea"
                                            name="femaleInstruction"
                                            id="femaleInstruction"
                                            placeholder="Enter new Isnstructions"
                                            value={femaleInstruction}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            onKeyPress={(ev) => { this.addInstructions(ev, "instructionforfemale") }}
                                        >
                                        </textarea>
                                        <Input
                                            label="Admit Card message"
                                            type="text"
                                            name="admitCardMessage"
                                            id="admitCardMessage"
                                            value={admitCardMessage}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                        <Input
                                            label="Attestation Date"
                                            type="date"
                                            name="attestationDate"
                                            id="attestationDate"
                                            value={attestationDate}
                                            onChange={(ev) => {
                                                this.setState({ attestationDate: new Date(ev.target.value).getTime(), attestationDate: new Date(ev.target.value).toISOString().slice(0, 10) })
                                            }}
                                            errors={errors}
                                        />
                                        <Input
                                            label="Entry Test Date"
                                            type="date"
                                            name="entryTestDate"
                                            id="entryTestDate"
                                            value={entryTestDate}
                                            onChange={(ev) => {
                                                this.setState({ entryTestDate: new Date(ev.target.value).getTime(), entryTestDate: new Date(ev.target.value).toISOString().slice(0, 10) })
                                            }}
                                            errors={errors}
                                        />
                                        <Input
                                                label="Telegram Group name"
                                                type="text"
                                                name="groupName"
                                                id="groupName"
                                                value={groupName}
                                                onChange={this.handleLinks}
                                                errors={errors}
                                            />
                                        <Input
                                            label="Telegram Link for IOS"
                                            type="text"
                                            name="iosLink"
                                            id="iosLink"
                                            value={iosLink}
                                            onChange={this.handleLinks}
                                            errors={errors}
                                        />
                                        <Input
                                            label="Telegram link for android"
                                            type="text"
                                            name="androidLink"
                                            id="androidLink"
                                            value={androidLink}
                                            onChange={this.handleLinks}
                                            errors={errors}
                                        />
                                        <button className="my-btn-2" type="submit" onClick={(evt => this.submit(evt))}>{edit ? "Update Config" : "Add Config"}</button>
                                        {edit && <button className="my-btn-2" type="button"
                                            onClick={() => this.resetState()}
                                        >Cancel Update</button>}
                                    </div>
                                </form>
                            </div>
                            {this.tableSection(batchesConfig)}
                        </div>
                    </div>
                </div>
            );
        }
    }
    const mapStateToProps = (state) => {
        return {
            batches: state.batchReducer.batches,
            batchesConfig: state.batchConfigReducer.batchesConfig,
            isLoading: state.batchConfigReducer.isLoading,
            isError: state.batchConfigReducer.isError,
            errorMessage: state.batchConfigReducer.errorMessage,
            success: state.batchConfigReducer.success,
        }
    }
    const mapDispatchToprops = (dispatch) => {
        return ({
            removeInstruction: data => {
                return dispatch(BatchConfigMiddleware.removeInstruction(data));
            },
            getBatches: data => {
                return dispatch(BatchMiddleware.searchBatches(data));
            },
            addBatchConfig: data => {
                return dispatch(BatchConfigMiddleware.addBatchConfig(data))
            },
            getAllBatchesConfig: data => {
                return dispatch(BatchConfigMiddleware.getAll(data));
            },
            deleteBatchConfig: data => {
                return dispatch(BatchConfigMiddleware.deleteBatchConfig(data))
            },
            editBatchConfig: data => {
                return dispatch(BatchConfigMiddleware.editBatchConfig(data))
            },
            addInstructions: data => {
                return dispatch(BatchConfigMiddleware.addInstructions(data));
            },

        })
    }

    export default connect(mapStateToProps, mapDispatchToprops)(AddBatchConfig);