class DashboardActions {

  static STATS = "STATS";
  static STATS_SUCCESSFUL = "STATS_SUCCESSFUL";
  static STATS_FAILED = "STATS_FAILED";

  static stats(data) {
    return {
      type: this.STATS,
      data: data
    }
  }
  static statsSuccessul(data) {
    return {
      type: this.STATS_SUCCESSFUL,
      data: data
    }
  }
  static statsFailed(data) {
    return {
      type: this.STATS_FAILED,
      data: data
    }
  }

}

export default DashboardActions;