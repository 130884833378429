export default class LocalStorageManager {
    static setUser(userObj)  {
        sessionStorage.setItem('localStorageUser', JSON.stringify(userObj));
    }

    static setRoutes(routesObj)  {
        sessionStorage.setItem('routes', JSON.stringify(routesObj));
    }

    static setStats(statsObj)  {
        localStorage.setItem('stats', JSON.stringify(statsObj));
    }

    static getStats()  {
        return JSON.parse(localStorage.getItem('stats'));
    }

    static removeStats()  {
        localStorage.removeItem('stats');
    }

    static clearLocalStorage()  {
        sessionStorage.clear()
    }

    static removeUser()  {
        sessionStorage.removeItem('localStorageUser');
    }

    static removeRoutes()  {
        sessionStorage.removeItem('routes');
    }

    static getUser() {
        return JSON.parse(sessionStorage.getItem('localStorageUser'));
    }

    static getRoutes() {
        return JSON.parse(sessionStorage.getItem('routes'));
    }
}