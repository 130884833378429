import React, { Component } from "react";
import { Select, Input } from "../Input/input"
import EntryTestEmailMiddleware from "../../store/middleware/entryTestEmailMiddleware";
import { connect } from "react-redux"
import { Loader } from "../StudentManagment/helper"
import StudentData from "./StudentData";



class ResendEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModal: false,
            identity: "",
            identityValue: "",
            newEmail: "",
            disabled: true,
            errors: {
                hasError: false,
                errorsObj: {}
            },
            studentData: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        const { errors } = this.state;

        if (nextProps.isError && nextProps.errorMessage) {
            errors.hasError = true;
            errors.simpleError = nextProps.errorMessage;
            this.setState({ errors })
            return
        }

        errors.hasError = false;
        errors.simpleError = ""
        this.setState({
            studentData: { ...nextProps.student },
            newEmail: nextProps.student ? nextProps.student.email : "",
            successMessage: nextProps.successMessage
        })
        console.log(nextProps.successMessage);

    }




    submit = (ev) => {
        ev.preventDefault();
        this.setState({ disabled: true, newEmail: "" })
        const { identity, identityValue } = this.state;
        this.props.search({ type: identity, value: identityValue });
    }



    submitEdit = (ev) => {
        ev.preventDefault();
        let { studentData, newEmail } = this.state;

        const data = {
            studentId: studentData._id,
            email: newEmail
        };

        this.props.updateEmail(data).then((success) => {
            if (success) {
                this.setState({
                    disabled: true
                })
            }
        });


    }
    cancelEdit = () => {
        const { studentData } = this.state
        this.setState({
            newEmail: studentData.email,
            disabled: true
        })
    }
    edit = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value
        })
    }



    render() {
        const { studentData, errors, disabled, successMessage, newEmail } = this.state;
        const { isLoading } = this.props
        console.log(successMessage);

        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <div className="student-form-wrapper">
                        <form onSubmit={(ev) => this.submit(ev)} method="post">
                            <h3>Reset Or Resend Email</h3>

                            <Select
                                label="Select Identity"
                                name="identity"
                                id="identity"
                                options={[
                                    { name: "CNIC", value: "cnic" },
                                    { name: "Number", value: "phoneNumber" },
                                    { name: "Roll Number", value: "rollNo" },
                                    { name: "Email", value: "email" },

                                ]}
                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                errors={errors}
                            />

                            <Input
                                label="Value"
                                type="text"
                                name="identityValue"
                                id="identityValue"
                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                errors={errors}

                            />
                            <button className="my-btn-2" type="submit">Search</button>
                        </form>
                    </div>
                </div>
                {this.props.student && studentData && <StudentData
                    onSubmit={this.submitEdit}
                    errors={errors}
                    enable={() => this.setState({ disabled: false })}
                    newEmail={newEmail}
                    resendEmail={() => this.props.resendEmail({ studentId: studentData._id })}
                    cancelEdit={() => this.cancelEdit()}
                    data={studentData}
                    onChange={(ev) => this.edit(ev)}
                    disabled={disabled}
                />}
                <div className="text-center">{errors.hasError && <p className="error">{errors.simpleError}</p>}
                {successMessage && <p className="success">{successMessage}</p>}</div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        isLoading: state.entryTestEmailReducer.isLoading,
        isError: state.entryTestEmailReducer.isError,
        errorMessage: state.entryTestEmailReducer.errorMessage,
        successMessage: state.entryTestEmailReducer.successMessage,
        student: state.entryTestEmailReducer.student
    };
}

function mapDispatchToProps(dispatch) {
    return {
        search: data => {
            dispatch(EntryTestEmailMiddleware.search(data));
        },
        updateEmail: data => {
            return dispatch(EntryTestEmailMiddleware.updateEmail(data));
        },
        resendEmail: (data) => {
            dispatch(EntryTestEmailMiddleware.resendEmail(data));
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmail);