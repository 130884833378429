import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducer/authreducer';
import studentReducer from './reducer/studentreducer';
import dashboardReducer from './reducer/dashboardreducer';

import timeSlotReducer from './reducer/timeSlotReducer';
import centerReducer from './reducer/centerReducer';
import courseReducer from './reducer/courseReducer';
import voucherReducer from './reducer/voucherReducer';

import emailReducer from './reducer/emailreducer';
import logger from 'redux-logger';
import entryTestEmailReducer from './reducer/entryTestEmailReducer';
import lessonReducer from './reducer/lessonReducer';
import topicReducer from './reducer/topicReducer';
import sectionReducer from './reducer/sectionReducer';
import allowCourseReducer from './reducer/allowCourseReducer';
import batchConfigReducer from './reducer/batchConfigReducer'
import batchReducer from './reducer/batchReducer'
import courseToBatchReducer from "./reducer/courseToBatchReducer"
import quaterReducer from './reducer/quaterReducer'
import studentCourseReducer from './reducer/studentCourseReducer';
import quarterConfigReducer from './reducer/quarterConfigReducer'
import { compose } from 'redux'

const RouteReducer = combineReducers({
    studentCourseReducer,
    quaterReducer,
    courseToBatchReducer,
    batchReducer,
    batchConfigReducer,
    authReducer,
    allowCourseReducer,
    entryTestEmailReducer,
    sectionReducer,
    topicReducer,
    studentReducer,
    lessonReducer,
    dashboardReducer,
    timeSlotReducer,
    centerReducer,
    courseReducer,
    emailReducer,
    voucherReducer,
    quarterConfigReducer
})


// OLD Function Without DEV-TOOLS
function configureStore() {
    return createStore(RouteReducer,
        {},
        applyMiddleware(thunk, logger));
}

// NEW Function With DEV TOOLS
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const configureStore = () => {
//     return createStore(RouteReducer, {}, composeEnhancers(applyMiddleware(thunk)));
// }

const store = configureStore();
export default store;