/* eslint-disable*/
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import LocalStorageManager from '../../services/localStorageManager';
import Path from '../../config/path';
import { Loader } from '../StudentManagment/helper';



class EntryTestResultEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            success: "",
            fail: "",
            textArea: "",
            emailTemplate: "",
            errors: {
                hasError: false,
                errorsObj: {}
            }
        }
    }


    submit = (e) => {
        e.preventDefault();
        const { textArea, emailTemplate } = this.state;
        var user = LocalStorageManager.getUser();
        if (!emailTemplate) {
            return this.setState({ success: "", fail: "Please Create A Template" });
        }
        this.setState({ loader: true, success: "", fail: "" });


        // var formData = new FormData();

        // formData.append("file", file);
        // formData.append("emailTemplate", emailTemplate);
        // formData.append("databaseToken", user.databaseToken);

        let mailData = {
            databaseToken: user.databaseToken,
            template: emailTemplate
        }

        console.log(mailData);
        fetch(Path.ENTRY_TEST_RESULT_EMAIL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mailData)
        }).then(data => data.json())
            .then(data => {
                console.log(data)
                if (!data.success) {
                    return this.setState({ fail: data.message, success: "", loader: false });
                }
                this.setState({ success: data.message, fail: "", loader: false });
                console.log(data);
            }).catch(err => {
                return this.setState({ fail: err.message, success: "", loader: false });
            })
    }




    render() {
        const { emailTemplate, success, fail, loader } = this.state;
        return (
            <div>
                {
                    loader
                    &&
                    <Loader />
                }
                <div className="container">
                    <div className="form-wrapper">

                        <form onSubmit={this.submit}>
                                <h3>
                                    Entry Test Result Email
                                </h3>
                                {fail && <p className="error">{fail}</p>}
                                {success && <p className="success">{success}</p>}
                                <label className="label">
                                    Email Template
                                </label>
                                <textarea required value={emailTemplate} onChange={(e) => this.setState({ emailTemplate: e.target.value })} />

                                <button type="submit" className="my-btn-2">
                                    Submit
                                </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

}



export default (EntryTestResultEmail);