import React, { Component } from "react";
import "./dashboard.css";
import { connect } from "react-redux";
import DashboardMiddleware from "../../store/middleware/dashboardMiddleware";
import { FaFemale, FaMale, FaGraduationCap } from "react-icons/fa";
import LocalStorageManager from "../../services/localStorageManager";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  ChangeComponent = (ev) => {
    ev.preventDefault();
  }

  componentDidMount() {
    let ONE_HOUR = 60 * 60 * 1000;
    if (this.checkingStatsStatus()) {
      let data = LocalStorageManager.getStats();
      let currentDate = data.find(ele => ele._id === "date");
      if ((new Date() - currentDate.value) < ONE_HOUR) {
        this.props.getStats();
      }
    }
    else {
      this.props.getStats();
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.stats && newProps.stats.length) {
      LocalStorageManager.removeStats();
      let obj = newProps.stats;
      obj.push({ _id: 'date', value: new Date() });
      LocalStorageManager.setStats(obj);
    }
  }

  checkingStatsStatus = () => {
    return LocalStorageManager.getStats() ? true : false
  }

  renderStatistics = (val, title, color, icon, key) => {
    return (
      <div key={key} className="col-md-5 col-sm-5">
        <div className="widget" style={{ backgroundColor: color }}>
          <div className="icon-container">
            {icon}
          </div>
          <div className="info">
            <p>{val}</p>
            <h4>{title}</h4>
          </div>
        </div>
      </div>
    )
  }

  getStatsInfo = (val, title, ind) => {
    switch (title) {
      case 'male':
        return this.renderStatistics(val, "Male", "#00acac", <FaMale size={'100px'} />, ind);
      case 'female':
        return this.renderStatistics(val, "Female", "#348fe2", <FaFemale size={'100px'} />, ind);
      case 'AIC':
        return this.renderStatistics(val, "Total Registered in AIC", "#ff5b57", <FaGraduationCap size={'100px'} />, ind);
      case 'CNC':
        return this.renderStatistics(val, "Total Registered in CNC", "#f39c12", <FaGraduationCap size={'100px'} />, ind);
      case 'BCC':
        return this.renderStatistics(val, "Total Registered in BCC", "#00acac", <FaGraduationCap size={'100px'} />, ind);
      case false:
        return this.renderStatistics(val, "Distance Learning (False)", "#00acac", <FaGraduationCap size={'100px'} />, ind);
      case true:
        return this.renderStatistics(val, "Distance Learning (True)", "rgb(52, 143, 226)", <FaGraduationCap size={'100px'} />, ind);
        default:
        return;
    }
  }

  render() {
    const statistics = LocalStorageManager.getStats();
    if (statistics && statistics.length)
      return (
        <div className="container-fluid dashboard-main">
          <div className="container">
            <div className="row">
              {
                statistics.map((val, ind) => {
                  return this.getStatsInfo(val.total, val._id, ind)
                })
              }
            </div>
          </div>
        </div>
      )
    return null;
  }
}


function mapStateToProps(state) {
  return {
    stats: state.dashboardReducer.stats
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStats: data => {
      dispatch(DashboardMiddleware.getStats(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);