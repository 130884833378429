/* eslint-disable*/
import React, { Component } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import DashBoard from "./components/Dashboard/dashboard";
import Home from "./components/Home/home";
import Email from "./components/Emails/emails";

import Navbar from "./components/Navbar/Navbar";
import SideBar from "./components/SideBar/SideBar";
// import Copyright from "./components/Copyright/Copyright";
import NotFound from "./components/404/404"
import StudentManagment from "./components/StudentManagment/StudentManagment"
import history from "./History";
import CreateAdmin from "./components/Create Admin/CreateAdmin";
import LocalStorageManager from './services/localStorageManager';
import { connect } from "react-redux";
import DashboardMiddleware from "./store/middleware/dashboardMiddleware";

import TimeSlot from "./components/TimeSlot/TimeSlot";
import ViewCenters from "./components/ViewCenters/ViewCenters";
import AddCenter from "./components/AddCenter/AddCenter";

import EntryTestResult from './components/EntryTestResult/EntryTestResult';
import EntryTestEmail from "./components/EntryTestMail/EntryTestEmail";
import FindVoucher from "./components/FindVoucher/FindVoucher";
import FeeVoucherUpload from "./components/FeeVoucherSchedular/FeeVoucherUpload";
import ResendEmail from "./components/ResendEmail/ResendEmail";
import PayVoucher from "./components/PayVoucher/PayVoucher";
import OnlineVideosManagement from "./components/OnlineVideosManagement/OnlineVideosManagement";
import ShowLesson from "./components/OnlineVideosManagement/Lesson/ShowLesson";
import Topic from "./components/OnlineVideosManagement/Topic/Topic";
import ShowTopic from "./components/OnlineVideosManagement/Topic/ShowTopic";
import Lesson from "./components/OnlineVideosManagement/Lesson/Lesson";
import Section from "./components/OnlineVideosManagement/Section/Section"
import ShowSection from "./components/OnlineVideosManagement/Section/ShowSection";
import CourseContent from "./components/OnlineVideosManagement/CourseContent/CourseContent";
import ShowCourseContent from "./components/OnlineVideosManagement/CourseContent/ShowCourseContent";
import AllowContent from "./components/OnlineVideosManagement/AllowContent/AllowContent";
import ShowAllowedCourseContent from "./components/OnlineVideosManagement/AllowContent/ShowAllowedCourseContent";
import AddCourse from './components/AddCourse/AddCourse'
import AddBatchConfig from "./components/AddBatchConfig/AddBatchConfig";
import AddBatch from "./components/AddBatch/AddBatch";
import SendEmail from "./components/SendEmail/SendEmail";
import SendEmailViaRoll from "./components/SendEmail/SendEmailViaRoll";
import UpdateStudent from './components/UpdateStudent/UpdateStudent';
import AddCourseToBatch from "./components/AddCourseToBatch/AddCourseToBatch";
import SendEmailTimeslot from "./components/SendEmailTimeslots/SendEmailTimeslot";
import Announcements from "./components/Announcements/Announcements";
import SendSms from './components/SendSms/SendSms';
import FeeCollection from './components/feeCollection/FeeCollection';
import UpdateCourseStatus from "./components/StudentCourseManagement/UpdateCourseStatus/UpdateCourseStatus";
import StudentCourseManagement from "./components/StudentCourseManagement/StudentCourseManagement";
import AddStudentInCourse from "./components/StudentCourseManagement/AddStudentInCourse/AddStudentInCourse";
import ViewQuarter from "./components/ViewQuarter/ViewQuarter";
import RemoveStudent from "./components/RemoveStudent/RemoveStudent";

// React Google Analytics Initializing
//ReactGA.initialize('UA-130584601-1');

function PrivateRoute({ component: Component, isLoggedIn, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => isLoggedIn === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}

class Routers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      isLoggedIn: nextProps.isLoggedIn
    }
  }



  componentDidMount() {
    var user = LocalStorageManager.getUser();
    if (user && user.databaseToken) {
      this.setState({
        isLoggedIn: true
      })
    }
    else {
      this.setState({ isLoggedIn: false })
    }
  }


  render() {
    var user = LocalStorageManager.getUser();
    return (
      <Router history={history}>
        <div className="conatiner-fluid main-page" >
          <Navbar />

          <div className="row main-route-row">


            {(this.state.isLoggedIn || this.props.isLoggedIn) && <SideBar />}

            <div className={(this.state.isLoggedIn || this.props.isLoggedIn) ? "col-sm-9 main-route-tab" : "col-sm-12 main-route-tab"}>
              {/* {this.props.isLoading && <Loader />} */}

              {/* {(this.state.isLoggedIn || this.props.isLoggedIn)
                ? <div className="Logout text-right mt-2 mb-4">
                  <a href="" className="mr-5" onClick={(ev) => { ev.preventDefault(); this.props.getStats() }} >Refresh</a>
                </div>
                : ""} */}

              <Switch>

                <Route exact path="/" component={AdminLogin} />

                { /*
                  <Route exact path="/admin-dashboard" component={DashBoard} /> 
                  <Route exact path="/student" component={StudentManagment} />
                  <Route exact path="/admin" component={CreateAdmin} />
                  */
                }

                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn)} exact path="/home" component={Home} />
                <PrivateRoute isLoggedIn={this.state.isLoggedIn || this.props.isLoggedIn} exact path="/student" component={StudentManagment} />

                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role === "SuperAdmin")} exact path="/admin" component={CreateAdmin} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role === "SuperAdmin")} exact path="/admin-dashboard" component={DashBoard} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role === "SuperAdmin")} exact path="/time-slot" component={TimeSlot} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role === "SuperAdmin" || user.role == "Admin3")} exact path="/add-center" component={AddCenter} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role === "SuperAdmin" || user.role == "Admin3")} exact path="/view-centers" component={ViewCenters} />


                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin")} exact path="/sendemails" component={Email} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin")} exact path="/entrytestupload" component={EntryTestResult} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin")} exact path="/entrytestmail" component={EntryTestEmail} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin" || user.role == "Admin3")} exact path="/find-voucher" component={FindVoucher} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin")} exact path="/feevoucherupload" component={FeeVoucherUpload} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin")} exact path="/resendemail" component={ResendEmail} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin")} exact path="/payvoucher" component={PayVoucher} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/topic" component={Topic} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/viewlesson" component={ShowLesson} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/viewtopic" component={ShowTopic} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement" component={OnlineVideosManagement} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/lesson" component={Lesson} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/section" component={Section} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/viewsection/:context" component={ShowSection} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/courseoutline" component={CourseContent} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/viewcoursecontent" component={ShowCourseContent} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/allowcourseoutline" component={AllowContent} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "VideoAdmin" || user.role == "Admin3")} exact path="/onlinevideosmanagement/viewallowedcoursecontent" component={ShowAllowedCourseContent} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin3")} exact path="/addcourse" component={AddCourse} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin3")} exact path="/addbatchconfig" component={AddBatchConfig} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin3")} exact path="/addbatch" component={AddBatch} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role === "SuperAdmin" || user.role == "Admin3")} exact path="/add-course-to-batch" component={AddCourseToBatch} />
                {/* <Route exact path="/admin" component={Admin} /> */}
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/sendemail" component={SendEmail} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/updatestudent" component={UpdateStudent} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/announcements" component={Announcements} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2")} exact path="/sendsms" component={SendSms} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "AccountAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/feecollection" component={FeeCollection} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/courseManagement" component={StudentCourseManagement} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/courseManagement/addstudentincourse" component={AddStudentInCourse} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/courseManagement/updatecoursestatus" component={UpdateCourseStatus} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin3")} exact path="/viewQuarter" component={ViewQuarter} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin" || user.role == "Admin2" || user.role == "Admin3")} exact path="/removeStudent" component={RemoveStudent} />

                {/* <Route exact path="/viewQuarter" component={ViewQuarter} /> */}

                {/* <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin")} exact path="/sendemailviaroll" component={SendEmailViaRoll} />
                <PrivateRoute isLoggedIn={(this.state.isLoggedIn || this.props.isLoggedIn) && (user && user.role == "SuperAdmin")} exact path="/sendemailviatime" component={SendEmailTimeslot} /> */}

                <Route path="*" component={NotFound} />
              </Switch>

            </div>
          </div>
          {/* <Copyright /> */}
        </div>

      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.authReducer.isLoggedIn,
    isLoading: state.authReducer.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStats: data => {
      dispatch(DashboardMiddleware.getStats(data));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Routers);