class QuarterConfigActions {

    static ADD_QUARTER_CONFIG = "ADD_QUARTER_CONFIG";
    static ADD_QUARTER_CONFIG_SUCCESS = "ADD_QUARTER_CONFIG_SUCCESS";
    static ADD_QUARTER_CONFIG_FAIL = "SEARCH_STUDENT_FAIL";

    static addQuarterConfig = () => ({
        type: QuarterConfigActions.ADD_QUARTER_CONFIG
    })

    static addQuarterConfigSuccess = (payload) => ({
        type: QuarterConfigActions.ADD_QUARTER_CONFIG_SUCCESS,
        payload
    })

    static addQuarterConfigFail = (payload) => ({
        type: QuarterConfigActions.ADD_QUARTER_CONFIG_FAIL,
        payload
    })

  
    
}
export default QuarterConfigActions;