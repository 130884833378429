import EntryTestEmailActions from '../actions/entryTestEmailActions';


function entryTestEmailReducer(state = {
    student: null,
    isError: false,
    isLoading: false,
    errorMessage: false,
    userId: null,
    successMessage: null
}, action) {
    switch (action.type) {

        case EntryTestEmailActions.SEARCH_STUDENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case EntryTestEmailActions.SEARCH_STUDENT_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                successMessage:"",
                student: action.data.data,
                errorMessage: null,
            };
        case EntryTestEmailActions.SEARCH_STUDENT_FAILED:
            return {
                ...state,
                student: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };
        case EntryTestEmailActions.EDIT_EMAIL:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case EntryTestEmailActions.EDIT_EMAIL_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                student:action.data.data.data,
                successMessage:action.data.data.successMessage,
                errorMessage: null,
            };
        case EntryTestEmailActions.EDIT_EMAIL_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };

        case EntryTestEmailActions.RESEND_EMAIL:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case EntryTestEmailActions.RESEND_EMAIL_SUCCESSFUL:
        console.log(action.data);
        
            return {
                ...state,
                isError: false,
                isLoading: false,
                successMessage:action.data.message,
                errorMessage: null,
            };
        case EntryTestEmailActions.RESEND_EMAIL_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };


        default:
            return state;
    }
}
export default entryTestEmailReducer;