import StudentActions from '../actions/studentActions';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'

class StudetnMiddleware {

    static search(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentActions.searchStudent(data));
                axios.post(`${Path.STUDENT_SEARCH}`, {
                    type: data.type,
                    value: data.value,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(StudentActions.searchStudentFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(StudentActions.searchStudentSuccessul({ success: true, data: response.data.userData }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentActions.searchStudentFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static update(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentActions.editStudent(data));
                axios.post(`${Path.STUDENT_EDIT}`, {
                    data: data.userData,
                    studentCnic: data.originalCnic,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(StudentActions.editStudentFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(StudentActions.editStudentSuccessul({ success: true, data: response.data.studentData }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentActions.editStudentFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static withPicUpdate(data, studentData) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentActions.editStudent(data));
                data.append("databaseToken", user.databaseToken)
                axios.post(`${Path.STUDENT_PIC_EDIT}`,
                    data
                )
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(StudentActions.editStudentFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            console.log("studentData", studentData);

                            return { imageUrl: response.data.imageUrl, studentData, oldImageUrl: studentData.userData.imageUrl }
                        }
                    })
                    .then(({ imageUrl, studentData, oldImageUrl }) => {

                        studentData.userData.imageUrl = imageUrl
                        var user = LocalStorageManager.getUser()

                        axios.post(`${Path.STUDENT_EDIT}`, {
                            data: studentData.userData,
                            studentCnic: studentData.originalCnic,
                            oldImageUrl,
                            databaseToken: user.databaseToken
                        })
                            .then((response) => {
                                console.log("response from server ", response);
                                if (!response.data.success) {
                                    dispatch(StudentActions.editStudentFailed({ errorMessage: response.data.message }));
                                }
                                else {
                                    console.log("else", response.data.studentData);

                                    dispatch(StudentActions.editStudentSuccessul({ success: true, data: response.data.studentData }));
                                }
                            })
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentActions.editStudentFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }





    static statusUpdate(data) {
        return dispatch => {

            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentActions.statusStudentUpdate(data));
                axios.post(`${data.rejectreason ? Path.STATUS_REJECTED : Path.STATUS_APPROVED}`, {
                    _id: data._id,
                    rejectreason: data.rejectreason ? data.rejectreason : undefined,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(StudentActions.statusStudentUpdateFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(StudentActions.statusStudentUpdateSuccessul({ success: true, data: response.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentActions.statusStudentUpdateFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static searchStudentForDelete(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentActions.searchStudentForDelete(data));
                axios.post(`${Path.SEARCH_STIDENT_FOR_DELETE}`, {
                    type: data.type,
                    value: data.value,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log("RESPONSE OF SEARCH", response)
                        if (!response.data.success) {
                            dispatch(StudentActions.searchStudentForDeleteFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(StudentActions.searchStudentForDeleteSuccess({ successMessage: response.data.message, data: response.data.student }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentActions.searchStudentForDeleteFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static deleteStudent(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentActions.deleteStudent(data));
                axios.post(`${Path.DELETE_STUDENT}`, {
                    type: data.type,
                    value: data.value,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log("RESPONSE OF SEARCH", response)
                        if (!response.data.success) {
                            dispatch(StudentActions.deleteStudentFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(StudentActions.deleteStudentSuccess({ success: true, successMessage: response.data.message }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentActions.deleteStudentFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

}

export default StudetnMiddleware;