import TimeSlotAction from '../actions/timeSlotAction';

function timeSlotReducer(state = {
    timeSlots: null,
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null,
    success:false,
    edit:false
}, action) {

    switch (action.type) {
        case TimeSlotAction.GET_TIME_SLOTS: 
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case TimeSlotAction.GET_TIME_SLOTS_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                timeSlots: action.data,
                errorMessage: null,
            };
        case TimeSlotAction.GET_TIME_SLOTS_FAILED:
            return {
                ...state,                        
                timeSlots: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };



        case TimeSlotAction.DELETE_TIME_SLOTS:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case TimeSlotAction.DELETE_TIME_SLOTS_SUCCESSFUL:
            state.timeSlots.splice(action.index, 1)
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case TimeSlotAction.DELETE_TIME_SLOTS_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };


        case TimeSlotAction.EDIT_TIME_SLOTS:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success:false,
                edit:true,
            };
        case TimeSlotAction.EDIT_TIME_SLOTS_SUCCESSFUL:
            console.log(action.data);

            state.timeSlots[action.index] = { ...action.data }
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                success:action.success,
                edit:false,
            };
        case TimeSlotAction.EDIT_TIME_SLOTS_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success:false,
                edit:true,
            };


        case TimeSlotAction.ADD_TIME_SLOTS:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                success:false,
                isLoading: true,
            };
            case TimeSlotAction.ADD_TIME_SLOTS_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                success:action.success,
                errorMessage: null,
            };
        case TimeSlotAction.ADD_TIME_SLOTS_FAILED:

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success:false,
                isLoading: false
            };


        default:
            return state;
    }
}
export default timeSlotReducer;