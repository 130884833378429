import CenterAction from '../actions/centerActions';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'
import BatchConfig from '../actions/batchConfigActions';


class BatchConfigMiddleware {
    static getAll(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(BatchConfig.getBatchConfig(data));
                axios.post(`${Path.GET_ALL_BATCH_CONFIG}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(BatchConfig.getBatchConfigFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(BatchConfig.getBatchConfigSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(BatchConfig.getBatchConfigFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static addBatchConfig(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(BatchConfig.addBatchConfig(data));
                return axios.post(`${Path.ADD_BATCH_CONFIG}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(BatchConfig.addBatchConfigFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(BatchConfig.addBatchConfigSuccessul({ success: true, data: response.data.data }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(BatchConfig.addBatchConfigFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static editBatchConfig(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(BatchConfig.editBatchConfig(data));
                return axios.post(`${Path.UPDATE_BATCH_CONFIG}`, {
                    batchId: data.batchId,
                    data: {
                        admitCardMessage: data.admitCardMessage,
                        attestationDate: data.attestationDate,
                        entryTestDate: data.entryTestDate,
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(BatchConfig.editBatchConfigFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(BatchConfig.editBatchConfigSuccessul({ success: true, data: response.data.data, index: data.index }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(BatchConfig.editBatchConfigFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static deleteBatchConfig(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(BatchConfig.deleteBatchConfig(data));
                axios.post(`${Path.DELETE_BATCH_CONFIG}`, {
                    batchConfigId: data.batchConfigId,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(BatchConfig.deleteBatchConfigFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(BatchConfig.deleteBatchConfigSuccessul({ success: true, data: response.data, index: data.index }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(BatchConfig.deleteBatchConfigFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static addInstructions(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(BatchConfig.addInstructions(data));
                return axios.post(`${Path.ADD_INSTRUCTIONS}`, {
                    batchId: data.batchId,
                    gender: data.gender,
                    instruction: data.instruction,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(BatchConfig.addInstructionsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(BatchConfig.addInstructionsSuccessful({ success: true, data: response.data.data, index: data.index }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(BatchConfig.addInstructionsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static removeInstruction(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(BatchConfig.removeInstructions(data));
                return axios.post(`${Path.REMOVE_INSTRUCTIONS}`, {
                    batchId: data.batchId,
                    gender: data.gender,
                    instruction: data.instruction,
                    databaseToken: user.databaseToken
                }).then((response) => {
                    console.log("response", response);

                    if (!response.data.success) {
                        dispatch(BatchConfig.removeInstructionsFailed({ errorMessage: response.data.message }));
                    }
                    else {
                        dispatch(BatchConfig.removeInstructionsSuccessul({ success: true, data: response.data, index: data.index }));
                    }
                    return response.data.success;
                })
                    .catch((err) => {
                        console.log(err);
                        dispatch(BatchConfig.removeInstructionsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default BatchConfigMiddleware;