import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import { Input } from '../../Input/input';
import { connect } from "react-redux"
import LessonMiddleware from '../../../store/middleware/lessonsMiddleware';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';

class ShowLesson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            search: "",
            searchedTopics: [],
            topics: [

            ],
            _id: "",
        }
    }
    componentDidMount = () => {
        const { history } = this.props;
        console.log(history);

        if (history && history.location) {
            const { title, description, _id } = history.location.state;
            this.props.getTopics({ _id });
            this.setState({ title, description, _id })
        } else {
            this.props.history.push("/onlinevideosmanagement")
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            topics: nextProps.topics ? nextProps.topics : []
        })
    }

    search = (keyword) => {
        const { topics } = this.state;
        const searchedTopics = topics.filter((item, index) => {
            return item.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            search: keyword,
            searchedTopics
        })
    }
    deleteTopic = (item) => {
        this.setState({ search: "", searchedLessons: [] })
        window.confirm("Are You Sure You Want To Delete?") &&
            this.props.deleteTopic({ topicId: item._id }).then((success) => {

            })
    }
    render() {
        const { errors, search, searchedTopics, topics, title, description, _id } = this.state;
        const { isLoading, errorMessage } = this.props;
        const finalTopics = search ? searchedTopics : topics
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="legends-container">
                        <fieldset>
                            <legend>Lesson</legend>
                            <div className="legend-input-container-3">
                                <span className="show-lesson-title">{title}</span>
                                <div className="show-lesson-des">
                                    <span >{description}</span>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div>
                        <div className="legends-container">
                            <fieldset>
                                <legend>Topics</legend>
                                <div className="legend-input-container-3">
                                    <div className="legend-upper-group">
                                        <Input
                                            label=" Search Topic Here "
                                            type="text"
                                            name="search"
                                            id="search"
                                            value={search}
                                            onChange={(ev) => this.search(ev.target.value)}
                                            errors={errors}
                                        />
                                        <button className="my-btn-3" onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/topic", state: { lesson: { _id, title, description } } })} type="button">Add Topic</button>
                                    </div>

                                    {finalTopics.length ? finalTopics.map((item, index) => {
                                        return (
                                            <div key={index} className="lesson-li">
                                                <div className="lecture-title">{item.sequence}.       {item.title}   </div>
                                                <div className="zIndexBtn">
                                                    <button className="my-btn-3"
                                                        onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewtopic", state: { topic: item, lesson: { _id, title, description } } })}
                                                        type="button">View</button>
                                                    <button className="my-btn-3" onClick={() => this.deleteTopic(item)} type="button">Delete</button>
                                                </div>
                                            </div>
                                        )
                                    }) : <div className="text-center">No topic yet to show :(</div>}

                                </div>
                            </fieldset>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.lessonReducer.isLoading,
        isError: state.lessonReducer.isError,
        errorMessage: state.lessonReducer.errorMessage,
        success: state.lessonReducer.success,
        topics: state.lessonReducer.topics,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getTopics: data => {
            dispatch(LessonMiddleware.getTopics(data))
        },
        deleteTopic: data => {
            return dispatch(LessonMiddleware.deleteTopic(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowLesson);
