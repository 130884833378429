/*eslint-disable*/
import lessonAction from '../actions/lessonActions';
import topicAction from '../actions/topicActions';

function lessonReducer(state = {
    lessons: [],
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null,
    success: false,
    edit: false
}, action) {

    switch (action.type) {
        case lessonAction.GET_LESSON:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case lessonAction.GET_LESSON_SUCCESSFUL:
            console.log(action.data);

            return {
                ...state,
                isError: false,
                isLoading: false,
                lessons: action.data,
                errorMessage: null,
            };
        case lessonAction.GET_LESSON_FAILED:
            return {
                ...state,
                lessons: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };



            case lessonAction.GET_TOPICS:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case lessonAction.GET_TOPICS_SUCCESSFUL:
            console.log(action.data);

            return {
                ...state,
                isError: false,
                isLoading: false,
                topics: action.data,
                errorMessage: null,
            };
        case lessonAction.GET_TOPICS_FAILED:
            return {
                ...state,
                lessons: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };
            case topicAction.DELETE_TOPIC:

            var index = null;
            state.topics.map((item, index2) => {
                if (item._id === action._id) {
                    index = index2
                }
            })
            state.topics.splice(index, 1)

            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case topicAction.DELETE_TOPIC_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case topicAction.DELETE_TOPIC_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };



        case lessonAction.DELETE_LESSON:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case lessonAction.DELETE_LESSON_SUCCESSFUL:

            var index = null;
            state.lessons.map((item, index2) => {
                if (item._id === action._id) {
                    index = index2
                }
            })
            state.lessons.splice(index, 1)
        
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case lessonAction.DELETE_LESSON_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };


        case lessonAction.EDIT_LESSON:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
                edit: true,
            };
        case lessonAction.EDIT_LESSON_SUCCESSFUL:     
            console.log(action.data);
            
            var index = null;
            state.lessons.map((item, index2) => {
                if (item._id === action._id) {
                    index = index2
                }
            })
            state.lessons[index] = { ...action.data }
            
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                success: action.success,
                edit: false,
            };
        case lessonAction.EDIT_LESSON_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,
                edit: true,
            };


        case lessonAction.ADD_LESSON:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                success: false,
                isLoading: true,
            };
        case lessonAction.ADD_LESSON_SUCCESSFUL:
            state.lessons.push(action.data)
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null,
            };
        case lessonAction.ADD_LESSON_FAILED:

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };


        default:
            return state;
    }
}
export default lessonReducer;