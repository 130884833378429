class StudentCourseActions {

    static SEARCH_STUDENT_COURSE = "SEARCH_STUDENT_COURSE";
    static SEARCH_STUDENT_COURSE_SUCCESSFUL = "SEARCH_STUDENT_COURSE_SUCCESSFUL";
    static SEARCH_STUDENT_COURSE_FAILED = "SEARCH_STUDENT_COURSE_FAILED";

    static UPDATE_STUDENT_COURSE = "UPDATE_STUDENT_COURSE";
    static UPDATE_STUDENT_COURSE_SUCCESSFUL = "UPDATE_STUDENT_COURSE_SUCCESSFUL";
    static UPDATE_STUDENT_COURSE_FAILED = "UPDATE_STUDENT_COURSE_FAILED";

    static ADD_STUDENT_IN_COURSE = "ADD_STUDENT_IN_COURSE";
    static ADD_STUDENT_IN_COURSE_SUCCESSFUL = "ADD_STUDENT_IN_COURSE_SUCCESSFUL";
    static ADD_STUDENT_IN_COURSE_FAILED = "ADD_STUDENT_IN_COURSE_FAILED";

    static CLEAR_STUDENT_COURSES = "CLEAR_STUDENT_COURSES"

    static searchCourse(data) {
        return {
            type: this.SEARCH_STUDENT_COURSE,
            data: data
        }
    }
    static searchCourseSuccessful(data) {
        return {
            type: this.SEARCH_STUDENT_COURSE_SUCCESSFUL,
            data: data.data,
        }
    }
    static searchCourseFailed(data) {
        return {
            type: this.SEARCH_STUDENT_COURSE_FAILED,
            data: data
        }
    }

    static updateStudentCourse(data) {
        return {
            type: this.UPDATE_STUDENT_COURSE,
            data
        }
    }
    static updateStudentCourseSuccessful(data) {
        return {
            type: this.UPDATE_STUDENT_COURSE_SUCCESSFUL,
            data,
        }
    }
    static updateStudentCourseFailed(data) {
        return {
            type: this.UPDATE_STUDENT_COURSE_FAILED,
            data,
        }
    }

    static addStudentInCourse(data) {
        return {
            type: this.ADD_STUDENT_IN_COURSE,
            data
        }
    }
    static addStudentInCourseSuccessful(data) {
        return {
            type: this.ADD_STUDENT_IN_COURSE_SUCCESSFUL,
            data,
        }
    }
    static addStudentInCourseFailed(data) {
        return {
            type: this.ADD_STUDENT_IN_COURSE_FAILED,
            data,
        }
    }

    static clearStudentCourses(){ 
        return { 
            type: this.CLEAR_STUDENT_COURSES, 
        }
    }

}

export default StudentCourseActions;