const COURSE_STATUS = {
    APPLIED: "APPLIED",
    APPROVED: "APPROVED",
    LEARNING: "LEARNING",
    GRADUATED: "GRADUATED",
    ENTRYTESTPASSED: "ENTRYTESTPASSED",
    ENTRYTESTFAILED: "ENTRYTESTFAILED",
    DROPOUT: "DROPOUT",
    REJECTED: "REJECTED",
    CANCELLED: "CANCELLED",
}


const ALLOWED_STATUS_ADMIN = {
    APPLIED: "APPLIED",
    APPROVED: "APPROVED",
    ENTRYTESTPASSED: "ENTRYTESTPASSED",

};


module.exports = {
    COURSE_STATUS,
    ALLOWED_STATUS_ADMIN
}