import AuthActions from '../actions/authActions'
import axios from 'axios'
import Path from '../../config/path'
import LocalStorageManager from '../../services/localStorageManager'
import QuarterConfigActions from '../actions/quarterConfigActions'

class QuarterConfigMiddleware {
  static addQuarterConfig (configData) {
    return async dispatch => {
      const user = LocalStorageManager.getUser()
      if (!user || !user.databaseToken) {
        dispatch(AuthActions.authenticationFailed({ errorMessage: 'Authentication Expired, Login Again' }))
      } else {
        try {
          dispatch(QuarterConfigActions.addQuarterConfig())
          const { data } = await axios.post(Path.ADD_QUARTER_CONFIG, {
            databaseToken: user.databaseToken,
            quarterConfigId: configData.quarterConfigId,
            links: configData.links,
            textbooks: configData.textbooks
          })

          if (!data.success) {
            return dispatch(QuarterConfigActions.addQuarterConfigFail({ success: false, message: data.message }))
          }

          dispatch(QuarterConfigActions.addQuarterConfigSuccess({ success: true, message: data.message }))
        } catch (err) {
          dispatch(QuarterConfigActions.addQuarterConfigFail({ success: false, message: 'Something went wrong, please try again' }))
        }
      }
    }
  }
}

export default QuarterConfigMiddleware
