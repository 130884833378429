class LessonActions {


    static GET_LESSON = "GET_LESSON";
    static GET_LESSON_SUCCESSFUL = "GET_LESSON_SUCCESSFUL";
    static GET_LESSON_FAILED = "GET_LESSON_FAILED";

    static GET_TOPICS = "GET_TOPICS";
    static GET_TOPICS_SUCCESSFUL = "GET_TOPICS_SUCCESSFUL";
    static GET_TOPICS_FAILED = "GET_TOPICS_FAILED";



    static DELETE_LESSON = "DELETE_LESSON";
    static DELETE_LESSON_SUCCESSFUL = "DELETE_LESSON_SUCCESSFUL";
    static DELETE_LESSON_FAILED = "DELETE_LESSON_FAILED";


    static EDIT_LESSON = "EDIT_LESSON";
    static EDIT_LESSON_SUCCESSFUL = "EDIT_LESSON_SUCCESSFUL";
    static EDIT_LESSON_FAILED = "EDIT_LESSON_FAILED";

    static ADD_LESSON = "ADD_LESSON";
    static ADD_LESSON_SUCCESSFUL = "ADD_LESSON_SUCCESSFUL";
    static ADD_LESSON_FAILED = "ADD_LESSON_FAILED";

   


    static getLessons(data) {
        return {
            type: this.GET_LESSON,
            data: data
        }
    }
    static getLessonsSuccessul(data) {
        return {
            type: this.GET_LESSON_SUCCESSFUL,
            data: data.data,
        }
    }
    static getLessonsFailed(data) {
        return {
            type: this.GET_LESSON_FAILED,
            data: data
        }
    }

    static getTopics(data) {
        return {
            type: this.GET_TOPICS,
            data: data
        }
    }
    static getTopicsSuccessul(data) {
        return {
            type: this.GET_TOPICS_SUCCESSFUL,
            data: data.data,
        }
    }
    static getTopicsFailed(data) {
        return {
            type: this.GET_TOPICS_FAILED,
            data: data
        }
    }



    static deleteLessons(data) {
        return {
            type: this.DELETE_LESSON,
            data: data
        }
    }
    static deleteLessonsSuccessul(data) {
        return {
            type: this.DELETE_LESSON_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static deleteLessonsFailed(data) {
        return {
            type: this.DELETE_LESSON_FAILED,
            data: data
        }
    }




    static editLessons(data) {
        return {
            type: this.EDIT_LESSON,
            data: data,
        }
    }
    static editLessonsSuccessul(data) {
        return {
            type: this.EDIT_LESSON_SUCCESSFUL,
            data: data.data,
            _id: data._id,
            success:data.success            
        }
    }
    static editLessonsFailed(data) {
        return {
            type: this.EDIT_LESSON_FAILED,
            data: data
        }
    }




    static addLessons(data) {
        return {
            type: this.ADD_LESSON,
            data: data
        }
    }
    static addLessonsSuccessul(data) {
        return {
            type: this.ADD_LESSON_SUCCESSFUL,
            data: data.data,
            success:data.success            
        }
    }
    static addLessonsFailed(data) {
        return {
            type: this.ADD_LESSON_FAILED,
            data: data
        }
    }
}

export default LessonActions