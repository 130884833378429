import dashboardAction from '../actions/dashboardAction';

function dashboardReducer(state = {
    stats: null,
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null
}, action) {
    switch (action.type) {
        
        case dashboardAction.STATS:
            return {
                ...state,
                errorMessage:null,
                successMessage:null,
                isLoading : true,
            };
        case dashboardAction.STATS_SUCCESSFUL:
            return {
                ...state,
                isError:false,
                isLoading : false,
                stats:action.data.data,
                errorMessage:null,
            };
        case dashboardAction.STATS_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage:null,
                isError: true,
                isLoading : false
            };        
        default:
            return state;
    }
}
export default dashboardReducer;