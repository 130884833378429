import DashboardActions from '../actions/dashboardAction';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'

class DashboardMiddleware {

    static getStats(data) {
        return dispatch => {
            dispatch(DashboardActions.stats(data))
            var user = LocalStorageManager.getUser();
            axios.post(`${Path.GET_STATS}`, {
                databaseToken: user.databaseToken
            })
            .then((response) => {
                console.log(response.data)
                if (!response.data.success) {
                    dispatch(DashboardActions.statsFailed({errorMessage:response.data.message}));
                }
                else {
                    dispatch(DashboardActions.statsSuccessul({success: true, data: response.data.stats}));
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(DashboardActions.statsFailed({errorMessage:"Something Went Wrong, Please Try Again!"}));
            });
        }
    }   
}

export default DashboardMiddleware;