import React, { Component } from "react";
import { Input } from "../Input/input"

class StudentData extends Component {

    render() {
        const { disabled, onChange, newEmail, errors, onSubmit, cancelEdit } = this.props;
        const { course, distanceLearning, rank, rollNo, fullName, studentCnic, fatherName, fatherCnic,  phoneNumber, homeAddress
            , province, lastQualification, status, dob, city, gender, imageUrl, rejectreason
        } = this.props.data;

        return (
            <div className="container-fluid p-0">
                <div className="container">
                    <div className="form-wrapper">
                        <form onSubmit={(ev) => onSubmit(ev)} method="post">
                            <div className="admit-card-image-con">
                                <div className="admit-card-image" style={{ backgroundImage: `url(${imageUrl})` }}>
                                </div>
                            </div>
                            <div className="table-wrapper" >
                                <br /><br /><br />
                                <table className="table  my-table" >
                                    <tbody>
                                        <tr><th >Status</th><td>{status}</td></tr>
                                        <tr><th >Roll Number</th><td>{rollNo}</td></tr>
                                        <tr><th >Rank</th><td>{rank}</td></tr>
                                        <tr><th >Full Name</th><td>{fullName}</td></tr>
                                        <tr><th >CNIC</th><td>{studentCnic}</td></tr>
                                        <tr><th >Father Name</th><td>{fatherName}</td></tr>
                                        <tr><th >Father Cnic</th><td>{fatherCnic}</td></tr>
                                        <tr><th >Phone Number</th><td>{phoneNumber}</td></tr>
                                        <tr><th >Home Address</th><td>{homeAddress}</td></tr>
                                        <tr><th >Date Of Birth</th><td>{dob}</td></tr>
                                        <tr><th >City</th><td>{city}</td></tr>
                                        <tr><th >Gender</th><td>{gender}</td></tr>
                                        <tr><th >last Qualification</th><td>{lastQualification}</td></tr>
                                        <tr><th >Province</th><td>{province}</td></tr>
                                        <tr><th >Course</th><td>{course}</td></tr>
                                        <tr><th >Distance Learning</th><td>{distanceLearning ? "true" : "false"}</td></tr>
                                        {rejectreason && <tr><th >Reject Reason</th><td>{rejectreason}</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <Input
                                label="Email"
                                type="email"
                                name="newEmail"
                                id="email"
                                value={newEmail}
                                onChange={(ev) => onChange(ev)}
                                disabled={disabled}
                                errors={errors}
                            />

                            {status === "Approved" && <button className="my-btn-2" type="button" onClick={() => this.props.resendEmail()}>Resend Email</button>}
                            {disabled && status === "Approved" && <button className="my-btn-2" type="button" onClick={() => this.props.enable()}>Edit Email</button>}
                            {!disabled && <div><button className="my-btn-1" type="submit">Update</button>
                                <button onClick={() => cancelEdit()} className="my-btn-1" type="button">Cancel</button></div>}
                        </form>
                    </div>
                </div>
            </div >
        )
    }
}

export default StudentData;