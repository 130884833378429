/*eslint-disable */
import React from "react";

function validateForm(check, data) {
    const {
        schedulerName,
        noOfEmails,
        minutesPerBatch,
    } = data;

    var errors = {
        hasError: false,
        errorsObj: {}
    }


    let Validation = {
        schedulerName: {
            Validate: [{
                condition: !schedulerName.length,
                message: " Please Specify Scheduler Name",
            }, {
                condition: /\d/.test(schedulerName) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(schedulerName),
                message: " Scheduler Name Can Not Contain Numbers Or Any Special Character . ",
            }
            ],
            elem: "schedulerName"
        },

        noOfEmails: {
            Validate: [{
                condition: noOfEmails < 1,
                message: " No Of Emails should be more than 1",
            }],
            elem: "noOfEmails"
        },

        minutesPerBatch: {
            Validate: [{
                condition: minutesPerBatch > 40,
                message: " Minutes per batch cannot exceed 40 Minutes ",
            }],
            elem: "minutesPerBatch"
        },

    }

    if (check === "all") {
        for (var i in Validation) {
            var conArray = Validation[i].Validate;
            errors.errorsObj[Validation[i].elem] = { message: [] }
            for (var j = 0; j < conArray.length; j++) {
                if (conArray[j].condition) {
                    errors.hasError = true
                    errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
                }
            }
            if (!errors.errorsObj[Validation[i].elem].message.length) {
                delete errors.errorsObj[Validation[i].elem];
            }
        }
    }

    return Object.keys(errors).length > 1 ? errors : {
        hasError: false
    }
}




export { validateForm };



