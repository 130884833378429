import StudentCourseActions from "../actions/studentCourseActions";

function studentCourseReducer(state = {
    isError: false,
    isLoading: false,
    errorMessage: false,
    studentCourses: [],
    fetched: false,
    successMessage: null,
}, action) {
    switch (action.type) {

        case StudentCourseActions.SEARCH_STUDENT_COURSE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                fetched: false,
                studentCourses: [],
            };
        case StudentCourseActions.SEARCH_STUDENT_COURSE_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                studentCourses: action.data,
                fetched: true,
                errorMessage: null,
            };
        case StudentCourseActions.SEARCH_STUDENT_COURSE_FAILED:
            return {
                ...state,
                studentCourses: [],
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                fetched: true,
                isLoading: false
            };

        case StudentCourseActions.UPDATE_STUDENT_COURSE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                fetched: false,
                studentCourses: [],
            };
        case StudentCourseActions.UPDATE_STUDENT_COURSE_SUCCESSFUL:
            state.studentCourses[action.data.updateIndex] = { courseStatus: action.data.updatedStatus }
            return {
                ...state,
                isError: false,
                isLoading: false,
                successMessage: action.data.message,
                fetched: true,
                errorMessage: null,
            };
        case StudentCourseActions.UPDATE_STUDENT_COURSE_FAILED:
            return {
                ...state,
                studentCourses: [],
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                fetched: true,
                isLoading: false
            };


        case StudentCourseActions.ADD_STUDENT_IN_COURSE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                fetched: false,
                studentCourses: [],
            };
        case StudentCourseActions.ADD_STUDENT_IN_COURSE_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                successMessage: action.data.message,
                fetched: true,
                errorMessage: null,
            };
        case StudentCourseActions.ADD_STUDENT_IN_COURSE_FAILED:
            return {
                ...state,
                studentCourses: [],
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                fetched: true,
                isLoading: false
            };

        case StudentCourseActions.CLEAR_STUDENT_COURSES:
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: false,
                studentCourses: [],
                fetched: false,
                successMessage: null,
            }
        default:
            return state;
    }
}
export default studentCourseReducer;