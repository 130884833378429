import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Modal.css";
import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile
} from "../ReUtilities";


import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: {
        aspect: 1,
        width: 10,
        x: 0,
        y: 0
      }
    }
  }

  onImageLoaded = (image) => {
  }
  onCropChange = (crop) => {
    this.setState({ crop })
  }

  onCropComplete = (crop, pixelCrop) => {
    const { imageRef, image } = this.props;
    image64toCanvasRef(imageRef, image, pixelCrop);
  }

  sendImgInProps = () => {
    const { imageRef, image } = this.props;
    const fileExtension = extractImageFileExtensionFromBase64(image);
    const imageData64 = imageRef.toDataURL('image/' + fileExtension);

    const croppedFileName = `preview.${fileExtension}`
    const myCroppedFile = base64StringtoFile(imageData64, croppedFileName)
    console.log("myCroppedFile", myCroppedFile);

    this.props.setCroppedImage(myCroppedFile);

  }
  clickOn = () => {
    const th = this;
    // Detect all clicks on the document
    document.addEventListener("click", function (event) {
      // If user clicks inside the element, do nothing
      if (event.target.closest(".modal-contents2")) return;
      th.props.hideModal();
    });
  };



  render() {
    const { condition } = this.props;
    const { crop } = this.state;


    return (
      <div>
        {condition && (
          <div className="bg-modal2" onClick={this.clickOn}>
            <div className="modal-contents2 App">


              <div>

                <button className="selectImageButton"
                  onClick={this.sendImgInProps}
                >Select</button>
              </div>
              {this.props.image && (
                <ReactCrop
                  src={this.props.image}
                  crop={crop}
                  maxWidth={100}
                  onChange={this.onCropChange}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                />
              )}


            </div>
          </div>
        )}
      </div>
    );

  }
}

export default Modal;
