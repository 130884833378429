import React, { Component } from 'react';
import { Input, Select } from '../../Input/input';
import { connect } from "react-redux"

import CourseMiddleware from '../../../store/middleware/courseMiddleware';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';
import AllowCourseMiddleware from '../../../store/middleware/AllowCourseMiddleware';
import { Loader } from '../../StudentManagment/helper';



class AllowContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            batch: "",
            quarter: "",
            courseContent: [],
            sectionId: "Select",
            courseOutline: "Select",
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            searchCourseContent: "",
            allowCourseContent: [],
            searchedCourseContents: [],
            filteredCourse: {
                sections: []
            },
            allowedCourseContent: []
        }
    }
    componentDidMount() {
        this.props.getAllCourseContent();
        this.props.getAllowedCourseContent();
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.courseContent);

        this.setState({
            courseContent: nextProps.courseContent ? nextProps.courseContent : [],
            allowedCourseContent: nextProps.allowedCourseContent ? nextProps.allowedCourseContent : [],
        })
    }

    setSections = (_id) => {
        const { courseContent } = this.state;
        var filteredCourse = courseContent.filter((item) => {
            return _id === item._id
        })
        if (filteredCourse.length) {
            this.setState({ filteredCourse: filteredCourse[0], courseOutline: filteredCourse[0]._id, })
        }

    }

    allowCourse = (ev) => {
        // ev.preventDefault();
        const { batch, quarter, filteredCourse, sectionId, courseOutline } = this.state;
        // this.setState({ search: "", searchedLessons: [] })
        // edit ? this.props.editCourseContent({ courseContentId: _id, _id, data: { title, courseId: course } }).then((success) => {
        //     if (success) {
        //         this.setState({ title: "", edit: false, _id: "" })
        //     }
        // }) :
        if (filteredCourse && filteredCourse._id &&  sectionId && batch && quarter ) {
            this.props.allowCourseContent({ batch, quarter, sectionId, courseId: filteredCourse.course._id, courseContentId: filteredCourse._id, courseName: filteredCourse.course.initial });
            this.setState({batch: "", quarter: "", courseOutline: "Select"});
        } else {
            alert('Please fill all fields');
        }
    }

    searchCourseContent = (keyword) => {
        const { allowedCourseContent } = this.state;
        const searchedCourseContents = allowedCourseContent.filter((item, index) => {
            return item.courseContent.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            searchCourseContent: keyword,
            searchedCourseContents
        })
    }


    deleteCourseContent = (allowedContentId, index) => {
        const { allowCourseContent } = this.state;
        this.props.deleteCourseContent({ allowedContentId }).then((data) => {
            if (data.success) {
                allowCourseContent.map((item, index2) => {
                    if (item._id === allowedContentId) {
                        allowCourseContent.splice(index2, 1)
                    }
                })
                this.setState({ allowCourseContent })
            }
        })
    }


    render() {
        const { batch, quarter, errors, filteredCourse, allowedCourseContent, courseContent, sectionId, searchCourseContent, searchedCourseContents, courseOutline } = this.state;
        const { isLoading, errorMessage } = this.props;
        console.log({ sectionId, courseId: filteredCourse.course, batch, quarter });
        console.log('State', courseContent);
        const finalCourseContents = searchCourseContent ? searchedCourseContents : allowedCourseContent;


        return (
            <div className="container-fluid">
                <div className="container">
                    {isLoading && <Loader />}
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="legends-container">
                        <fieldset>
                            <legend>Video Details</legend>
                            <div className="legend-input-container">
                                <Input
                                    label="Batch"
                                    type="text"
                                    name="batch"
                                    id="batch"
                                    value={batch}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="Quarter"
                                    type="text"
                                    name="quarter"
                                    id="quarter"
                                    value={quarter}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />

                                {
                                    <Select
                                        label="Course Outline"
                                        name="courseOutline"
                                        id="courseOutline"
                                        value={courseOutline}
                                        options={
                                            courseContent.length ? courseContent.map((course, index) => {
                                                return { name: course.title, value: course._id }
                                            }) : []
                                        }
                                        onChange={(ev) => this.setSections(ev.target.value)}
                                        errors={errors}
                                    />
                                }
                            </div>
                            <div className="btn-center">
                                <button className="my-btn-3" onClick={() => { this.allowCourse() }}>Allow Course</button>
                            </div>
                        </fieldset>
                    </div>

                    {allowedCourseContent.length ? <div>
                        <div className="legends-container">
                            <fieldset>
                                <legend>Allowed-Course-Content</legend>
                                <div className="legend-input-container-3">
                                    <div style={{ alignSelf: 'center' }}>
                                        <Input
                                            label=" Search Course Content Here "
                                            type="text"
                                            name="searchCourseContent"
                                            id="searchCourseContent"
                                            value={searchCourseContent}
                                            onChange={(ev) => this.searchCourseContent(ev.target.value)}
                                            errors={errors}
                                        />
                                    </div>

                                    <div>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Course Title</th>
                                                    <th>Batch</th>
                                                    <th>Quarter</th>
                                                    <th>View More</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {finalCourseContents.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {item.courseContent.title} ( {item.course.initial} )
                                                            </td>
                                                            <td>
                                                                {item.batch}
                                                            </td>
                                                            <td>
                                                                {item.quarter}
                                                            </td>
                                                            <td>
                                                                <button className="my-btn-3"
                                                                    onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewallowedcoursecontent", state: { allowedCourseContent: item } })}
                                                                    type="button">View</button>
                                                            </td>
                                                            <td>
                                                                <button className="my-btn-3" onClick={() => {
                                                                    this.setState({ searchCourseContent: "", searchedCourseContents: [] })
                                                                    window.confirm("Are You Sure You Want To Delete?") && this.deleteCourseContent(item._id, index)
                                                                }} type="button">Delete</button>
                                                            </td>
                                                        </tr>
                                                    )

                                                })}
                                            </tbody>

                                        </table>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div> :
                        <div className="text-Lesson">No Course Content yet to show :(</div>
                    }

                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        isLoading: state.allowCourseReducer.isLoading,
        isError: state.allowCourseReducer.isError,
        errorMessage: state.allowCourseReducer.errorMessage,
        success: state.allowCourseReducer.success,
        courseContent: state.courseReducer.courseSections,
        allowedCourseContent: state.allowCourseReducer.allowedCourseContent

    }
}
function mapDispatchToProps(dispatch) {
    return {
        getAllCourseContent: data => {
            dispatch(CourseMiddleware.getSection(data))
        },
        getAllowedCourseContent: data => {
            dispatch(AllowCourseMiddleware.getAllowedCourseContent(data))
        },
        allowCourseContent: data => {
            return dispatch(AllowCourseMiddleware.allowCourseContent(data))
        },
        deleteCourseContent: data => {
            return dispatch(AllowCourseMiddleware.deleteCourseContent(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllowContent)
