class SectionActions {


    static GET_SECTION = "GET_SECTION";
    static GET_SECTION_SUCCESSFUL = "GET_SECTION_SUCCESSFUL";
    static GET_SECTION_FAILED = "GET_SECTION_FAILED";


    static DELETE_SECTION = "DELETE_SECTION";
    static DELETE_SECTION_SUCCESSFUL = "DELETE_SECTION_SUCCESSFUL";
    static DELETE_SECTION_FAILED = "DELETE_SECTION_FAILED";

    static REMOVE_SECTION_LESSON = "REMOVE_SECTION_LESSON";
    static REMOVE_SECTION_LESSON_SUCCESSFUL = "REMOVE_SECTION_LESSON_SUCCESSFUL";
    static REMOVE_SECTION_LESSON_FAILED = "REMOVE_SECTION_LESSON_FAILED";

    static ADD_SECTION_LESSON = "ADD_SECTION_LESSON";
    static ADD_SECTION_LESSON_SUCCESSFUL = "ADD_SECTION_LESSON_SUCCESSFUL";
    static ADD_SECTION_LESSON_FAILED = "ADD_SECTION_LESSON_FAILED";


    static EDIT_SECTION = "EDIT_SECTION";
    static EDIT_SECTION_SUCCESSFUL = "EDIT_SECTION_SUCCESSFUL";
    static EDIT_SECTION_FAILED = "EDIT_SECTION_FAILED";

    static ADD_SECTION = "ADD_SECTION";
    static ADD_SECTION_SUCCESSFUL = "ADD_SECTION_SUCCESSFUL";
    static ADD_SECTION_FAILED = "ADD_SECTION_FAILED";

   


    static getSections(data) {
        return {
            type: this.GET_SECTION,
            data: data
        }
    }
    static getSectionsSuccessul(data) {
        return {
            type: this.GET_SECTION_SUCCESSFUL,
            data: data.data,
        }
    }
    static getSectionsFailed(data) {
        return {
            type: this.GET_SECTION_FAILED,
            data: data
        }
    }

    static removeSectionLesson(data) {
        return {
            type: this.REMOVE_SECTION_LESSON,
            data: data
        }
    }
    static removeSectionLessonSuccessul(data) {
        return {
            type: this.REMOVE_SECTION_LESSON_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static removeSectionLessonFailed(data) {
        return {
            type: this.REMOVE_SECTION_LESSON_FAILED,
            data: data
        }
    }


    static addSectionLesson(data) {
        return {
            type: this.ADD_SECTION_LESSON,
            data: data
        }
    }
    static addSectionLessonSuccessul(data) {
        return {
            type: this.ADD_SECTION_LESSON_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static addSectionLessonFailed(data) {
        return {
            type: this.ADD_SECTION_LESSON_FAILED,
            data: data
        }
    }

    
    static deleteSections(data) {
        return {
            type: this.DELETE_SECTION,
            data: data
        }
    }
    static deleteSectionsSuccessul(data) {
        return {
            type: this.DELETE_SECTION_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static deleteSectionsFailed(data) {
        return {
            type: this.DELETE_SECTION_FAILED,
            data: data
        }
    }




    static editSections(data) {
        return {
            type: this.EDIT_SECTION,
            data: data,
        }
    }
    static editSectionsSuccessul(data) {
        return {
            type: this.EDIT_SECTION_SUCCESSFUL,
            data: data.data,
            _id: data._id,
            success:data.success            
        }
    }
    static editSectionsFailed(data) {
        return {
            type: this.EDIT_SECTION_FAILED,
            data: data
        }
    }




    static addSections(data) {
        return {
            type: this.ADD_SECTION,
            data: data
        }
    }
    static addSectionsSuccessul(data) {
        return {
            type: this.ADD_SECTION_SUCCESSFUL,
            data: data.data,
            success:data.success            
        }
    }
    static addSectionsFailed(data) {
        return {
            type: this.ADD_SECTION_FAILED,
            data: data
        }
    }
}

export default SectionActions