/* eslint-disable*/
import React, { Component } from "react";
import { Select, Input } from "../Input/input";
import { Loader } from "../StudentManagment/helper";
import { connect } from "react-redux";
import { validateForm } from "./helper";
import CourseMiddleware from "../../store/middleware/courseMiddleware";
import CourseToBatchMiddleware from "../../store/middleware/courseToBatchMiddleware";
import QuaterMiddleware from "../../store/middleware/quaterMiddleware";
import moment from 'moment'

class AddCourseToBatch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {
				hasError: false,
				errorsObj: {}
			},
			errorMessage: "",
			courseName: "",
			active: false,
			courseId: "",
			edit: false,
			isLoading: false,
			showQuater: false,
			courseName: "",
			//quater sections
			quaterSectionTitle: "",
			editQuaters: false,
			successMessageForQuater: "",
			name: "",
			successQuater: false,
			_id: null,

		};
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			courses: nextProps.courses ? [...nextProps.courses] : [],
			errorMessage: nextProps.errorMessage,
			success: nextProps.success,
			edit: nextProps.edit,
			quaters: nextProps.quaters ? [...nextProps.quaters] : [],
			successQuater: nextProps.successQuater,
			successMessageForQuater: nextProps.successMessageForQuater,
		});
	}
	componentDidMount() {
		this.props.getAllCourses();
		this.props.getAll(this.props.location.state.batch);
	}

	check = data => {
		var course = JSON.parse(data);
		let search = this.props.batchCourse ? this.props.batchCourse.filter(rec => rec.name == course.name) : [];
		return search;
	};

	Submit = ev => {
		ev.preventDefault();
		const { courseName } = this.state;
		var err = validateForm("all", { courseName });
		console.log(err);
		if (err.hasError) {
			this.setState({ errors: err });
			return;
		}

		let a = this.check(courseName);
		if (a.length > 0) {
			this.setState({
				errorMessage: "Course is Already in Batch",
				successMessage: "",
				errors: {
					hasError: false,
					errorsObj: {}
				}
			});
			return;
		}

		this.setState({
			errors: {
				hasError: false,
				errorsObj: {}
			}
		});
		const batchId = this.props.location.state.batch._id;

		let course = JSON.parse(courseName);
		this.props
			.addCourseToBatch({ batchId, course })
			.then(success => {
				if (success) {
					this.setState({
						success: true,
						name: "",
						errorMessage: "",
						successMessage: "Course Added Successfully"

					});
				}
			});
	};


	showAddButtun = (batch) => {
		if (batch.regStartDate) {
			return <button className="my-btn-2" type="submit"
				disabled
				style={{ cursor: "no-drop" }}>
				Add Course
			    </button>
		}
		return <button className="my-btn-2" type="submit">
			Add Course
             </button>
	}
	render() {
		const {
			isLoading,
			errors,
			courses,
			errorMessage,
			success,
			successMessage,
			showQuater,

		} = this.state;
		const { batch } = this.props.location.state
		return (
			<div className="container-fluid">
				{isLoading && <Loader />}
				<div className="container">
					<div className="form-container  align-items-center">
						<div className="form-wrapper">
							{!batch.batchEndDate && <form onSubmit={ev => this.Submit(ev)} method="post">
								<h3>{batch.name}</h3>
								{errorMessage && <p className="error">{errorMessage}</p>}
								{success && <p className="success">{successMessage}</p>}
								<Select
									label="Course Name"
									type="text"
									name="courseName"
									id="courseName"
									options={
										courses && courses.length
											? courses.map((course, index) => {
												return {
													name: `${course.name}`,
													value: `${JSON.stringify(course)}`,
													id: `${course}`
												};
											})
											: []
									}
									onChange={ev => {
										return this.setState({ [ev.target.name]: ev.target.value });
									}}
									errors={errors}
								/>
								{this.showAddButtun(batch)}
							</form>}
						</div>
						{this.props.batchCourse != null && !!this.props.batchCourse.length && (
							<div className="table-wrapper">
								<br />
								<br />
								<br />
								<table className="table  my-table">
									<tbody>
										<tr>
											<th>Course Name</th>
											<th>View</th>
											<th>Delete</th>
										</tr>

										{this.props.batchCourse.map((course, index) => {
											return (
												<tr key={index}>
													<td>{course.name}</td>
													<td>
														<button
															type="button"
															className="my-btn-3"
															onClick={() =>
																this.view(
																	this.props.location.state.batch,
																	course,
																	index
																)
															}

														>
															ShowQuaters
														</button>
													</td>
													<td>
														<button
															type="button"
															className="my-btn-3"
															onClick={() =>
																window.confirm(
																	"Are You Sure You Want To Delete This"
																) &&
																this.props.deleteCourseFromBatch(
																	course._id,
																	this.props.batch._id,
																	index
																)
															}
														>
															Delete
                            							</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						{showQuater && this.showQuaters()}
					</div>
				</div>
			</div>
		);
	}




	//quater table section 
	view = (batch, course, index) => {
		this.props.getAllQuaters({
			courseId: course._id,
			batchId: batch._id
		});
		this.setState({ showQuater: true, quaterSectionTitle: course.name, courseId: course._id })
	};
	editQuaters = (quater, index) => {
		console.log("index", index);
		this.setState({
			name: quater.quarterName,
			_id: quater._id,
			editQuaters: true,
			index,
			errors: {
				hasError: false,
				errorsObj: {}
			}
		});
	};
	checkQuater = data => {
		let search = this.props.quaters ? this.props.quaters.filter(rec => rec.quarterName == data) : [];
		return search;
	};
	submitQuater = (ev) => {
		ev.preventDefault();
		const { editQuaters, name, index, courseId, _id } = this.state;
		console.log("name ===>", name);
		let a = this.checkQuater(name);
		if (a.length > 0) {
			this.setState({
				errorMessage: "Quater is Already in Batch",
				successMessage: "",
				errors: {
					hasError: false,
					errorsObj: {}
				}
			});
			return;
		}
		this.setState({
			errors: {
				hasError: false,
				errorsObj: {}
			}
		});
		if (editQuaters) {
			this.props
				.editQuater({
					index,
					name,
					_id
				})
				.then(success => {
					if (success) {
						this.setState({
							success: true,
							name: "",
							editQuaters: false,
							successMessageForQuater: "Updated Successfully"
						});
					}
				})
		} else {
			this.props
				.addQuater({
					name,
					courseId,
					batchId: this.props.location.state.batch._id
				})
				.then(success => {
					if (success) {
						this.setState({
							success: true,
							name: "",
							successMessageForQuater: "Created Successfully"
						});
					}
				});

		}
	}
	handleRegistrationCheck = (quater) => {
		if (quater.isRegistrationActive) {
			return <td>Yes</td>
		}

		return <td>No</td>

	}
	checkClassCompletion = (quater) => {
		if (quater.isClassStarted) {
			return (
				<td>
					class started at{" "}
					{moment(quater.classStartDate).format("L")}
				</td>
			)
		}
		return <td>...........</td>
	}
	handleClassStartedCheck = (quater) => {
		if (!quater.isClassCompleted) {
			return this.checkClassCompletion(quater);
		}
		return (
			<td>
				Class Completed at{" "}
				{moment(quater.classEndDate).format("L")}
			</td>
		)
	}
	handleClassOpenCloseButtunDisable = (quater, checker, index) => {
		console.log(checker)
		if (!checker) {
			return <button
				type="button"
				className="my-btn-3"
				onClick={() =>
					this.props.startQuaterReg(
						quater,
						index
					)
				}
			>
				Start
			</button>
		}

		return <button
			type="button"
			className="my-btn-3"
			disabled
			onClick={() =>
				this.props.startQuaterReg(
					quater,
					index
				)
			}
		>
			Start
			</button>

	}
	handleClassOpenClose = (quater, index) => {
		let checker = false;
		if (quater.isRegistrationActive) {
			console.log("hohohohoho")
			return (<td>
				{(checker = true)}
				<button
					type="button"
					className="my-btn-3"
					onClick={() =>
						this.props.closeQuaterReg(quater, index)
					}
				>
					Close
					</button>
			</td>)
		}
		return <td>
			{this.handleClassOpenCloseButtunDisable(quater, checker, index)}
		</td>


	}
	handleClassOpenAndClose = (quater, index) => {
		if (!quater.regEndDate) {
			console.log("inside if");
			return this.handleClassOpenClose(quater, index)
		}
		return (
			<td>Registarations are closed</td>
		)
	}
	handleStartAndCompleteButtun = (quater, index) => {

		if (quater.isClassStarted) {
			return (
				<td>
					<button
						type="button"
						className="my-btn-3"
						onClick={() =>
							this.props.completeClass(quater, index)
						}
					>
						Complete Class
					</button>
				</td>)
		}

		return <td>
			<button
				type="button"
				className="my-btn-3"
				onClick={() =>
					this.props.startClass(quater, index)
				}
			>
				Start Class
			</button>
		</td>


	}
	handleStartAndComplete = (quater, index) => {
		if (!quater.isClassCompleted) {
			return this.handleStartAndCompleteButtun(quater, index);
		}

		return (
			<td>
				<button
					type="button"
					className="my-btn-3"
					disabled={true}
				>
					Class completed
</button>
			</td>
		)
	}
	handleHeadings = (batch) => {
		if (!batch.batchEndDate) {
			if (!batch.regEndDate) {
				return (
					<tr>
						<th>Quater Name</th>
						<th>Registration active</th>
						<th>View</th>
						<th>Edit</th>
						<th>Delete</th>
					</tr>
				)
			}
			return (
				<tr>
					<th>Quater Name</th>
					<th>Registration active</th>
					<th>Current Class Status</th>
					<th>Open/Close Registration</th>
					<th>Change Class Status </th>
					<th>View</th>
					<th>Edit</th>
					<th>Delete</th>
				</tr>
			)
		}
		return (
			<tr>
				<th>Quater Name</th>
				<th>Registration active</th>
				<th>View</th>
				<th>Edit</th>
				<th>Delete</th>
			</tr>
		)

	}
	handleBody = (batch, quater, index) => {
		if (!batch.batchEndDate) {
			console.log("inside......................<> ");
			return (<React.Fragment>
				{batch.regEndDate && this.handleClassStartedCheck(quater, index)}
				{batch.regEndDate && this.handleClassOpenAndClose(quater, index)}
				{batch.regEndDate && this.handleStartAndComplete(quater, index)}
			</React.Fragment>
			)

		}
	}

	showQuatersTable = () => {
		const { batch } = this.props.location.state
		return (
			<div className="table-wrapper">
				<br />
				<br />
				<br />
				<table className="table  my-table">
					<tbody>
						{this.handleHeadings(batch)}
						{this.props.quaters.map((quater, index) => {
							return (
								<tr key={index}>
									<td>{quater.quarterName}</td>
									{this.handleRegistrationCheck(quater)}
									{this.handleBody(batch, quater, index)}
									<td>
										<button
											type="button"
											className="my-btn-3"
											onClick={() => this.props.history.push("/viewQuarter", { quater })}
										>
											View
								 		</button>
									</td>
									<td>
										<button
											type="button"
											className="my-btn-3"
											onClick={() => this.editQuaters(quater, index)}
										>
											Edit
								 		</button>
									</td>
									<td>
										<button
											type="button"
											className="my-btn-3"
											onClick={() =>
												window.confirm(
													"Are You Sure You Want To Delete This"
												) && this.props.deleteQuater(quater, index)
											}
										>
											Delete
										</button>
									</td>
								</tr>
							);


						})}
					</tbody>
				</table>
			</div>
		)
	}
	//quater 
	showQuaters = () => {
		const { quaterSectionTitle, errors, errorMessage, successQuater, successMessageForQuater, name, editQuaters } = this.state
		return (
			<div className="legend-input-container-3">
				<div className="legend-upper-group">
					<div className="container-fluid">
						<div className="container">
							<div className="form-container  align-items-center">
								<div className="form-wrapper">
									<form onSubmit={ev => this.submitQuater(ev)} method="post">
										{errorMessage && <p className="error">{errorMessage}</p>}
										{successQuater && <p className="success">{successMessageForQuater}</p>}
										<h2>{quaterSectionTitle}</h2>
										<Input
											label="Quater Name"
											type="text"
											name="name"
											id="name"
											value={name}
											onChange={ev => {
												return this.setState({
													[ev.target.name]: ev.target.value
												});
											}}
											errors={errors}
										/>

										<button className="my-btn-2" type="submit">
											{editQuaters ? "Update Quater" : "Add Quater"}
										</button>
										{editQuaters && (
											<button
												className="my-btn-2"
												type="button"
												onClick={() =>
													this.setState({
														_id: "",
														errors: {
															hasError: false,
															errorsObj: {},
															simpleError: ""
														},
														edit: false,
														index: null
													})
												}
											>
												Cancel Update
                                                </button>
										)}
									</form>

								</div>
								{this.props.quaters != null && !!this.props.quaters.length && this.showQuatersTable()}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}






}
function mapStateToProps(state) {
	return {
		isLoading: state.courseToBatchReducer.isLoading,
		isError: state.courseToBatchReducer.isError,
		errorMessage: state.courseToBatchReducer.errorMessage,
		success: state.courseToBatchReducer.success,
		courses: state.courseReducer.courses,
		batch: state.courseToBatchReducer.batches,
		batchCourse: state.courseToBatchReducer.courses,
		//quaters section 

		quaters: state.quaterReducer.quaters,
		successQuater: state.quaterReducer.success,
		successMessageForQuater: state.quaterReducer.successMessage

	};
}
function mapDispatchToProps(dispatch) {
	return {
		getAllCourses: data => {
			return dispatch(CourseMiddleware.getCourses(data));
		},
		addCourseToBatch: data => {
			return dispatch(CourseToBatchMiddleware.addCourseToBatch(data));
		},
		getAll: data => {
			return dispatch(CourseToBatchMiddleware.searchCoursesByBatch(data));
		},
		deleteCourseFromBatch: (courseId, batchId, index) => {
			return dispatch(
				CourseToBatchMiddleware.deleteCourseFromBatch(courseId, batchId, index)
			);
		},



		//quater section 
		addQuater: data => {
			return dispatch(QuaterMiddleware.addQuater(data));
		},
		getAllQuaters: data => {
			return dispatch(QuaterMiddleware.searchQuateres(data));
		},
		deleteQuater: (data, index) => {
			return dispatch(QuaterMiddleware.deleteQuater(data, index));
		},
		editQuater: data => {
			return dispatch(QuaterMiddleware.editQuater(data));
		},
		startQuaterReg: (data, index) => {
			return dispatch(QuaterMiddleware.startQuaterReg(data, index));
		},
		closeQuaterReg: (data, index) => {
			return dispatch(QuaterMiddleware.closeQuaterReg(data, index));
		},
		completeClass: (data, index) => {
			return dispatch(QuaterMiddleware.completeClass(data, index));
		},
		startClass: (data, index) => {
			return dispatch(QuaterMiddleware.startClass(data, index));
		}
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCourseToBatch);
