//This route and its middle needs to be removed
import React, { Component } from "react";
import EmailMiddleware from "../../store/middleware/emailMiddleware";
import { connect } from "react-redux"
import { Loader } from "../StudentManagment/helper"



class Emails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            csvs: "",
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            isLoggedIn : false
        }
    }

    componentWillMount() {
       
    }
      
    componentWillReceiveProps(nextProps) {
        const { errors } = this.state;
        
        if (nextProps.isError && nextProps.errorMessage) {
            errors.hasError = true;
            errors.simpleError = nextProps.errorMessage
            this.setState({ errors })
        }
    }

    Submit = (ev) => {
        ev.preventDefault();
        const { csvs, errors } = this.state;
        if (!csvs) {
            errors.hasError = true;
            errors.simpleError = "Please Provide Valid Informations"
            this.setState({ errors })
            return;
        }
        console.log("CSVS",csvs);
        this.props.sendEmail({ csvs })
    }

    render() {
        const { errors,  csvs} = this.state;
        const { isLoading } = this.props
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <div style={{height:"60vh"}} className="form-container">
                        <div className="form-wrapper">
                            <form onSubmit={(ev) => this.Submit(ev)} method="post">
                                <h3>Email for Entry Test</h3>
                                {errors.hasError && <p className="error">{errors.simpleError}</p>}
                                {this.props.successMessage || <p className="error">{this.props.successMessage}</p>}
                                <div className="form-wrapper">
                                    <textarea  rows="10"
                                        name="csvs"
                                        value={csvs}
                                        onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}>
                                    </textarea>
                                </div>
                                <br></br>
                                <button className="my-btn-2" type="submit">Send Email</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.emailReducer.isLoading,
        isError: state.emailReducer.isError,
        errorMessage: state.emailReducer.errorMessage,
        isLoggedIn: state.emailReducer.isLoggedIn,
        successMessage: state.emailReducer.successMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendEmail: data => {
            dispatch(EmailMiddleware.sendEmail(data));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
//export default Emails;