/*eslint-disable*/
import React, { Component } from 'react';
import { Loader } from "../StudentManagment/helper"
import { connect } from "react-redux"
import { Select } from "../Input/input"
import timeSlotMiddleware from '../../store/middleware/timeSlotMiddleware';
import _ from 'lodash';


class ViewCenters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeSlots: [],
            filteredCenters: [],
            filterBatches: [],
            filterQuarters: [],
            filteredTimeSlots: [],
            filteredTimeSlotsByBatch: [],
            filteredTimeSlotsByQuarter: [],
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            filteredTotalSeats: 0,
            filteredConfirmedSeats: 0,
            filteredBookedSeats: 0,
            filteredCenterStates: [],
            filteredCenterStatesByBatch: [],
            filteredCenterStatesByQuarter: [],
            batchName: 'Select',
            quarterName: 'Select',
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            timeSlots: nextProps.timeSlots ? [...nextProps.timeSlots] : [],
            filteredCenters: nextProps.timeSlots ? this.filterCenters([...nextProps.timeSlots]) : [],
            filteredBatches: nextProps.timeSlots ? this.filterBatches([...nextProps.timeSlots]) : [],
            filteredQuarters: nextProps.timeSlots ? this.filterQuarters([...nextProps.timeSlots]) : []
        })
    }

    filterBatches = timeSlots => {
        if (!timeSlots.length) {
          return;
        }
        var filteredBatches = [
          ...new Set(
            timeSlots.map(item => {
              return item.batch;
            })
          )
        ];
        // console.log(filteredBatches);
        return filteredBatches;
      };

    filterQuarters = timeSlots => {
        if (!timeSlots.length) {
          return;
        }
        var filteredQuarters = [
          ...new Set(
            timeSlots.map(item => {
              return item.quarter;
            })
          )
        ];
        console.log(filteredQuarters);
        return filteredQuarters;
      };
    
      filterCenters = (timeSlots) => {
        if (!timeSlots.length) {
            return;
        }
        
        const filteredCentersObjects = _.uniqBy(timeSlots, (item) => {
          return item.centerId
        })

        console.log(filteredCentersObjects);
        const filterCenters = filteredCentersObjects.map((item) => {
          return item.name
        });
        var centers = [
          ...new Set(
            timeSlots.map(item => {
              return item.name;
            })
          )
        ];
        this.setState({
          centers
        });
        const filterCampuses = filteredCentersObjects.map((item) => {
            return item.campus
        });

        const filteredCenters = [];

        for(let i = 0; i < filterCenters.length; i++){
          filteredCenters.push(`${filterCenters[i]} ${filterCampuses[i]}`);   
        }

        
        return filteredCenters
    }

    componentDidMount() {
        this.props.getTimeSlots();
    }

    filterTimeSlots = async (name) => {
      const { centers } = this.state;
        const centerAndCampusNames = name.split(' ');
        let verified = false;
        let centerName = '';
        let campusName = '';
        for(let i = 0; i < centers.length; i++){
          if(centers[i] === centerAndCampusNames[0]){
            verified = true
            centerName = centerAndCampusNames[0];
            for(let j = 1; j < centerAndCampusNames.length; j++){
              campusName = `${campusName} ${centerAndCampusNames[j]}`;
            }
          }
        };
        if(!verified){
          for(let i = 0; i < centers.length; i++){
            if(centers[i] === `${centerAndCampusNames[0]} ${centerAndCampusNames[1]}`){
              verified = true
              centerName = `${centerAndCampusNames[0]} ${centerAndCampusNames[1]}`
              for(let j = 2; j < centerAndCampusNames.length; j++){
                campusName = `${campusName} ${centerAndCampusNames[j]}`;
              }
            }
          };
        };
        campusName = campusName.trim();
        console.log(campusName);

        if(name !== 'Select'){
          const { timeSlots } = this.state
          var filteredTimeSlotsByCenter = timeSlots.filter((item) => {
              return item.name === centerName
          })
          var filteredTimeSlots = filteredTimeSlotsByCenter.filter((item) => {
              return item.campus === campusName
          })

        var filteredTotalSeats = 0;
        var filteredConfirmedSeats = 0;
        var filteredBookedSeats = 0;
        var centersState = [];



        filteredTimeSlots.map((center) => {
            filteredTotalSeats += center.totalSeats;
            filteredConfirmedSeats += center.confirmedSeats;
            filteredBookedSeats += center.bookedSeats;
            var matchfound = false;
            var matchingIndex = null
            centersState.map((state, index) => {
                console.log("Chal Rha He");
                if (center.campus === state.campus) {
                    matchfound = true;
                    matchingIndex = index
                }

            })
            if (matchfound) {
                if (!centersState[matchingIndex].courses[center.course]) {
                    centersState[matchingIndex].courses[center.course] = {
                        totalSeats: center.totalSeats,
                        confirmedSeats: center.confirmedSeats,
                        bookedSeats: center.bookedSeats,
                        name: center.course
                    }
                } else {
                    centersState[matchingIndex].courses[center.course].totalSeats += center.totalSeats;
                    centersState[matchingIndex].courses[center.course].confirmedSeats += center.confirmedSeats;
                    centersState[matchingIndex].courses[center.course].bookedSeats += center.bookedSeats;
                }

            } else {
                centersState.push({
                    campus: center.campus,
                    courses: {
                        [center.course]: {
                            totalSeats: center.totalSeats,
                            confirmedSeats: center.confirmedSeats,
                            bookedSeats: center.bookedSeats,
                            name: center.course
                        }
                    }
                })
            }
        })
        console.log(centersState);

        
        await this.setState({ filteredTimeSlots, centerName: name, filteredTotalSeats, filteredConfirmedSeats, filteredBookedSeats, filteredCenterStates: centersState })
        // Rerendering the component if the user select center after the batch
        await this.filterTimeSlotsByBatch(this.state.batchName);
         this.filterTimeSlotsByQuarter(this.state.quarterName);
    }
        
        else {
            this.setState({
                filteredTimeSlots: [],
                filteredTotalSeats: 0,
                filteredBookedSeats: 0,
                filteredConfirmedSeats: 0,
                filteredCenterStates:[],
                filteredTimeSlotsByQuarter: 0,
                filteredCenterStatesByBatch: [],
                centerName: name,
                batchName: "Select",
                quarterName: "Select"
            })
        }
    }


    filterTimeSlotsByBatch = async (name) => {

        let { filteredTimeSlots } = this.state;
        // console.log(filteredTimeSlots);
        const filteredTimeSlotsByBatch = filteredTimeSlots.filter(item => {
          return item.batch === name;
        });
        
        // console.log(filteredTimeSlotsByBatch);
  
        var filteredTotalSeats = 0;
        var filteredConfirmedSeats = 0;
        var filteredBookedSeats = 0;
        var centersStateByBatch = [];

        filteredTimeSlotsByBatch.map(batch => {
          filteredTotalSeats += batch.totalSeats;
          filteredConfirmedSeats += batch.confirmedSeats;
          filteredBookedSeats += batch.bookedSeats;
          var matchfound = false;
          var matchingIndex = null;
          centersStateByBatch.map((state, index) => {
            // console.log("Chal Rha He");
            if (batch.campus === state.campus) {
              matchfound = true;
              matchingIndex = index;
            }
          });
          if (matchfound) {
            if (!centersStateByBatch[matchingIndex].courses[batch.course]) {
              centersStateByBatch[matchingIndex].courses[batch.course] = {
                totalSeats: batch.totalSeats,
                confirmedSeats: batch.confirmedSeats,
                bookedSeats: batch.bookedSeats,
                name: batch.course
              };
            } else {
              centersStateByBatch[matchingIndex].courses[batch.course].totalSeats +=
                batch.totalSeats;
              centersStateByBatch[matchingIndex].courses[batch.course].confirmedSeats +=
                batch.confirmedSeats;
              centersStateByBatch[matchingIndex].courses[batch.course].bookedSeats +=
                batch.bookedSeats;
            }
          } else {
            centersStateByBatch.push({
              campus: batch.campus,
              courses: {
                [batch.course]: {
                  totalSeats: batch.totalSeats,
                  confirmedSeats: batch.confirmedSeats,
                  bookedSeats: batch.bookedSeats,
                  name: batch.course
                }
              }
            });
          }
        });
        console.log(centersStateByBatch);
  
       await this.setState({
          filteredTimeSlotsByBatch,
          batchName: name,
          filteredTotalSeats,
          filteredConfirmedSeats,
          filteredBookedSeats,
          filteredCenterStatesByBatch: centersStateByBatch
        });
        // Rerendering the component if the user select batch after the quarter
        this.filterTimeSlotsByQuarter(this.state.quarterName);

  }

    filterTimeSlotsByQuarter = (name) => {
        let { filteredTimeSlotsByBatch } = this.state;
        // console.log(filteredTimeSlots);
        const filteredTimeSlotsByQuarter = filteredTimeSlotsByBatch.filter(item => {
          return item.quarter === name;
        });
        
        // console.log(filteredTimeSlotsByBatch);
  
        var filteredTotalSeats = 0;
        var filteredConfirmedSeats = 0;
        var filteredBookedSeats = 0;
        var centersStateByQuarter = [];

        filteredTimeSlotsByQuarter.map(quarter => {
          filteredTotalSeats += quarter.totalSeats;
          filteredConfirmedSeats += quarter.confirmedSeats;
          filteredBookedSeats += quarter.bookedSeats;
          var matchfound = false;
          var matchingIndex = null;
          centersStateByQuarter.map((state, index) => {
            // console.log("Chal Rha He");
            if (quarter.campus === state.campus) {
              matchfound = true;
              matchingIndex = index;
            }
          });
          if (matchfound) {
            if (!centersStateByQuarter[matchingIndex].courses[quarter.course]) {
              centersStateByQuarter[matchingIndex].courses[quarter.course] = {
                totalSeats: quarter.totalSeats,
                confirmedSeats: quarter.confirmedSeats,
                bookedSeats: quarter.bookedSeats,
                name: quarter.course
              };
            } else {
              centersStateByQuarter[matchingIndex].courses[quarter.course].totalSeats +=
                quarter.totalSeats;
              centersStateByQuarter[matchingIndex].courses[quarter.course].confirmedSeats +=
                quarter.confirmedSeats;
              centersStateByQuarter[matchingIndex].courses[quarter.course].bookedSeats +=
                quarter.bookedSeats;
            }
          } else {
            centersStateByQuarter.push({
              campus: quarter.campus,
              courses: {
                [quarter.course]: {
                  totalSeats: quarter.totalSeats,
                  confirmedSeats: quarter.confirmedSeats,
                  bookedSeats: quarter.bookedSeats,
                  name: quarter.course
                }
              }
            });
          }
        });
        console.log(centersStateByQuarter);
  
        this.setState({
          filteredTimeSlotsByQuarter,
          quarterName: name,
          filteredTotalSeats,
          filteredConfirmedSeats,
          filteredBookedSeats,
          filteredCenterStatesByQuater: centersStateByQuarter
        });
  }

    render() {
        const { isLoading, timeSlots, centerName, batchName,quarterName, filteredCenters, filteredBatches, filteredQuarters,
            filteredTotalSeats, filteredCenterStatesByQuarter, filteredTimeSlotsByQuarter, filteredConfirmedSeats, filteredBookedSeats,
            errors } = this.state;
        console.log(timeSlots);

        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="form-wrapper-7">

                    <Select
                        label="Center Name"
                        name="centerName"
                        id="centerName"
                        value={centerName}
                        options={
                            timeSlots.length ? filteredCenters.map((center, index) => {
                                return { name: center, value: center }
                            }) : []
                        }
                        onChange={(ev) => this.filterTimeSlots(ev.target.value)}
                        errors={errors}
                    />
                    <Select
                        label="Batch Name"
                        name="batchName"
                        id="batchName"
                        value={batchName}
                        options={
                          timeSlots.length
                            ? filteredBatches.map((batch, index) => {
                                return { name: batch, value: batch };
                              })
                            : []
                        }
                        onChange={ev =>
                          this.filterTimeSlotsByBatch(ev.target.value)
                        }
                        errors={errors}
                    />
                    <Select
                        label="Quarter Name"
                        name="quarterName"
                        id="quarterName"
                        value={quarterName}
                        options={
                          timeSlots.length
                            ? filteredQuarters.map((quarter, index) => {
                                return { name: quarter, value: quarter };
                              })
                            : []
                        }
                        onChange={ev =>
                          this.filterTimeSlotsByQuarter(ev.target.value)
                        }
                        errors={errors}
                    />
                    {!!filteredTimeSlotsByQuarter.length &&
                        <div style={{ alignSelf: "flex-start", marginLeft: "20px" }}>
                            <p>Total Seats : {filteredTotalSeats}</p>
                            <p>Confirmed Seats : {filteredConfirmedSeats}</p>
                            <p>Booked Seats : {filteredBookedSeats}</p>
                        </div>
                    }

                    {!!filteredCenterStatesByQuarter.length && filteredCenterStatesByQuarter.map((center, index) => {
                        return (
                            <div key={index} className="campusDetails">
                                <h3>{center.campus}</h3>
                                {Object.values(center.courses).map((course, index2) => {
                                    return (
                                        <div key={index2}>
                                            <span>{course.name}</span>
                                            <span>Total Seats:</span>
                                            <span>{course.totalSeats}</span>
                                            <span>Booked Seats:</span>
                                            <span>{course.bookedSeats}</span>
                                            <span>Confirmed Seats:</span>
                                            <span>{course.confirmedSeats}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    <div className="table-responsive">
                        {!!filteredTimeSlotsByQuarter.length &&
                            <table className="table my-table" >
                                <tbody>
                                    <tr>
                                        <th>Center Name</th>
                                        <th>Campus</th>
                                        <th>City</th>
                                        <th>Batch</th>
                                        <th>Course</th>
                                        <th>Location</th>
                                        <th>Year</th>
                                        <th>Day</th>
                                        <th>Time</th>
                                        <th>Quarter</th>
                                        <th>Gender</th>
                                        <th>Fee Deadline</th>
                                        <th>Fee</th>
                                        <th>Booked Seats</th>
                                        <th>Confirmed Seats</th>
                                        <th>Total Seats</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                    {!!filteredTimeSlotsByQuarter.length && filteredTimeSlotsByQuarter.map((center, index) => {
                                        return (
                                            <tr key={index} >
                                                <td>{center.name}</td>
                                                <td>{center.campus}</td>
                                                <td>{center.city}</td>
                                                <td>{center.batch}</td>
                                                <td>{center.course}</td>
                                                <td><a href={center.location} target="_blank">View Location</a></td>
                                                <td>{center.year}</td>
                                                <td>{center.day}</td>
                                                <td>{center.time}</td>
                                                <td>{center.quarter}</td>
                                                <td>{center.gender}</td>
                                                <td>{new Date(center.feeDeadline).toISOString().slice(0, 10)}</td>
                                                <td>{center.quarterFee}</td>
                                                <td>{center.bookedSeats}</td>
                                                <td>{center.confirmedSeats}</td>
                                                <td>{center.totalSeats}</td>
                                                <td>
                                                    <button type="button" className="my-btn-3" onClick={() => this.props.history.push({ pathname: "/time-slot", state: { ...center, edit: true, index, success: false } })} >
                                                        Edit
                                                            </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="my-btn-3" onClick={() => window.confirm("Are You Sure You Want To Delete This") && this.props.deleteTimeSlot({ _id: center._id, index })} >
                                                        Delete
                                                            </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>

                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        isLoading: state.timeSlotReducer.isLoading,
        isError: state.timeSlotReducer.isError,
        errorMessage: state.timeSlotReducer.errorMessage,
        successMessage: state.timeSlotReducer.successMessage,
        timeSlots: state.timeSlotReducer.timeSlots,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        getTimeSlots: data => {
            dispatch(timeSlotMiddleware.getTimeSlot(data))
        },
        deleteTimeSlot: data => {
            dispatch(timeSlotMiddleware.deleteTimeSlot(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCenters);