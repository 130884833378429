import React, { Component, Fragment } from 'react'
import StudentCourseManagementButtons from '../StudentCourseManagementButtons';
import { Input, Select } from '../../Input/input';
import { connect } from "react-redux"
import { Loader } from '../../StudentManagment/helper';
import StudentCourseMiddleware from '../../../store/middleware/studentCourseMiddleware';
import { COURSE_STATUS } from '../../../config/helper'

class UpdateCourseStatus extends Component {
    state = {
        searchStudentCourse: "",
        fail: "",
        updateFail: "",
        showLogs: false,
        statusLogs: [],
        errors: {
            hasError: false,
            errorsObj: {},
            simpleError: "",
        },
        courseStatus: "",
        updateReason: "",
        showUpdate: false,
        courseIndex: "",
        updateInfo: {},
        rowIndex: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.successMessage) {
            this.setState({ showUpdate: false })
        }
    }

    handleSearch = () => {
        const { searchStudentCourse } = this.state
        if (!searchStudentCourse) {
            return this.setState({ fail: "Please provide roll No" })
        }
        this.setState({ rowIndex: null })
        this.props.searchStudentCourse(searchStudentCourse)
    }

    showUpdate = (currentCourse, index) => {
        this.setState({ showUpdate: true, updateInfo: { ...currentCourse, index } })
    }

    handleUpdate = (event) => {
        event.preventDefault()
        const { updateReason, courseStatus, updateInfo } = this.state
        if (!updateReason || !courseStatus) {
            return this.setState({ updateFail: "Incomplete details" })
        }
        this.setState({ updateReason: "", courseStatus: "", updateInfo: {} })
        this.props.updateStudentCourse({ studentCourseId: updateInfo._id, updateReason, updatedStatus: courseStatus, updateIndex: updateInfo.index })
    }

    showLogs = (event, courseObj, rowIndex) => {
        event.preventDefault();
        this.setState({ showLogs: true, statusLog: courseObj.statusLog, rowIndex })
    }

    render() {
        const { searchStudentCourse,
            errors,
            fail,
            courseStatus,
            updateReason,
            updateInfo,
            showUpdate,
            updateFail } = this.state
        const { studentCourses, studentCourses: { student }, isLoading, isError, errorMessage, successMessage } = this.props
        return (
            <div className="container-fluid">
                <div className="container">
                    {isLoading && <Loader />}
                    <StudentCourseManagementButtons />
                    {successMessage && <p className="success text-center">{successMessage}</p>}
                    {showUpdate && updateInfo &&
                        <div className="container test-border" style={{ border: '1px solid rgba(96, 96, 96, 0.468)', padding: '25px' }}>
                            {/* {errorMessage && <p className="error text-center">{errorMessage}</p>} */}
                            {updateFail && <p className="error text-center">{updateFail}</p>}
                            <h3 className="text-center">Update Course Status</h3>
                            <div className="legend-input-container">
                                <Select
                                    label="Course Status"
                                    name="courseStatus"
                                    id="courseStatus"
                                    value={courseStatus}
                                    options={
                                        Object.keys(COURSE_STATUS).map((item, index) => {
                                            return { name: item, value: item }
                                        })
                                    }
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="Update Reason"
                                    type="updateReason"
                                    name="updateReason"
                                    id="updateReason"
                                    value={updateReason}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <div style={{ width: '80%' }}>
                                    <table className="table  my-table" >
                                        <tbody>
                                            <tr><th >Roll No</th><td>{updateInfo.rollNo}</td></tr>
                                            <tr><th >Batch</th><td>{updateInfo.batchName}</td></tr>
                                            <tr><th >Course </th><td>{updateInfo.courseName}</td></tr>
                                            <tr><th >Current Status </th><td>{updateInfo.courseStatus}</td></tr>

                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <div className="btn-center">
                                <button className="my-btn-3" onClick={(event) => this.handleUpdate(event)} >Update Course Status</button>
                            </div>
                        </div>
                    }


                    <div>
                        <div className="legends-container">
                            <fieldset>
                                <legend>Search-Student-Courses</legend>
                                {fail && <p className="error text-center">{fail}</p>}
                                {errorMessage && <p className="error text-center">{errorMessage}</p>}
                                <div className="legend-input-container-3">
                                    <div className="w-100 flexer" style={{ alignSelf: 'center', flexDirection: 'column' }}>
                                        <Input
                                            label=" Enter Student Roll Number: "
                                            type="text"
                                            name="searchStudentCourse"
                                            id="searchStudentCourse"
                                            value={searchStudentCourse}
                                            onChange={(event) => this.setState({ searchStudentCourse: event.target.value.toUpperCase() })}
                                            errors={errors}
                                        />
                                        <button className="my-btn-1" style={{ width: '230px' }} onClick={this.handleSearch} type="submit">Search</button>
                                    </div>

                                    <div>
                                        <br />
                                        {studentCourses.student &&
                                            <div className="flexer" style={{ flexDirection: 'column' }}>
                                                <h4 className="text-center font-weight-bold ">Student Information</h4>
                                                <div className="admit-card-image" style={{ backgroundImage: `url(${student.imageUrl})` }}></div>


                                                <div style={{ width: '80%' }}>
                                                    <table className="table  my-table" >
                                                        <tbody>
                                                            <tr><th >Student Name</th><td>{student.fullName}</td></tr>
                                                            <tr><th >Father Name</th><td>{student.fatherName}</td></tr>
                                                            <tr><th >Roll No</th><td>{student.rollNo}</td></tr>
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                </div>
                                            </div>
                                        }

                                        <br />
                                        {studentCourses.studentCourse && studentCourses.studentCourse.length === 0 &&
                                            <p className="error text-center">This student is not enrolled in any course yet</p>
                                        }

                                        {studentCourses.studentCourse && Boolean(studentCourses.studentCourse.length) &&
                                            <div>
                                                <h4 className="text-center font-weight-bold ">Student Courses</h4>
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th>Student Course</th>
                                                            <th>Batch</th>
                                                            <th>Completed Quarters</th>
                                                            <th>Current Status</th>
                                                            <th>Onsite</th>
                                                            <th>Graduated</th>
                                                            <th>Student Active</th>
                                                            <th>Course log</th>
                                                            <th>Update Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {studentCourses.studentCourse.map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={item._id}>
                                                                        <td>{item.courseName}</td>
                                                                        <td>{item.batchName}</td>
                                                                        <td>{item.completedQuarters}</td>
                                                                        <td>{item.courseStatus}</td>
                                                                        {item.currentlyOnsite ? <td>Yes</td> : <td>No</td>}
                                                                        {item.isGraduated ? <td>Yes</td> : <td>No</td>}
                                                                        {item.isActive ? <td>Yes</td> : <td>No</td>}
                                                                        {/* {item.dropOut ? <td>Yes</td> : <td>No</td>} */}
                                                                        {/* <td><button onClick={() => this.showLogs(item.statusLog)} type="button" className="my-btn-3">Show log</button></td> */}
                                                                        <td><button onClick={(event) => this.showLogs(event, item, index)} type="button" className="my-btn-3">Show log</button></td>
                                                                        <td><button type="button" onClick={() => this.showUpdate(item, index)} className="my-btn-3">Update</button></td>
                                                                    </tr>

                                                                    {/* <tr style={{ width: "100%" }}> */}
                                                                    {this.state.showLogs && this.state.rowIndex === index &&

                                                                        <tr>
                                                                            <td colSpan={9}>
                                                                                <table className='table table-striped' style={{ width: "100%" }}>
                                                                                    <tr>
                                                                                        <th>Date</th>
                                                                                        <th>Status</th>
                                                                                        <th>Reason</th>
                                                                                    </tr>

                                                                                    {this.state.statusLog.map(log => (
                                                                                        <tr>
                                                                                            <td>{new Date(log.statusDate).toDateString()}</td>
                                                                                            <td>{log.status}</td>
                                                                                            <td>{log.statusDesc}</td>
                                                                                        </tr>

                                                                                    ))}

                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {/* </tr> */}
                                                                </Fragment>

                                                            )
                                                        })}
                                                    </tbody>

                                                </table>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.studentCourseReducer.isLoading,
        isError: state.studentCourseReducer.isError,
        errorMessage: state.studentCourseReducer.errorMessage,
        success: state.studentCourseReducer.success,
        studentCourses: state.studentCourseReducer.studentCourses,
        successMessage: state.studentCourseReducer.successMessage
    }
}
function mapDispatchToProps(dispatch) {
    return {
        searchStudentCourse: data => {
            return dispatch(StudentCourseMiddleware.searchStudentCourse(data))
        },
        updateStudentCourse: data => {
            return dispatch(StudentCourseMiddleware.updateStudentCourse(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCourseStatus);
