class AuthActions {

    static AUTHENTICATION = "AUTHENTICATION";
    static AUTHENTICATION_SUCCESSFUL = "AUTHENTICATION_SUCCESSFUL";
    static AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";

    static AUTHENTICATE_TOKEN = "AUTHENTICATE_TOKEN";

    static LOGOUT_ADMIN = "LOGOUT_ADMIN";
    static LOGOUT_ADMIN_SUCCESSFUL = "LOGOUT_ADMIN_SUCCESSFUL";
    static LOGOUT_ADMIN_FAILED = "LOGOUT_ADMIN_FAILED";


    
    static  authentication(data){
        return {
            type : this.AUTHENTICATION,
            data : data
        }
    }
    static authenticationSuccessul(data){
        return {
            type : this.AUTHENTICATION_SUCCESSFUL,
            data: data
        }
    }
    static authenticationFailed(data){
        return {
            type : this.AUTHENTICATION_FAILED ,
            data: data
        }
    }

    static authenticateToken(data){
        return {
            type : this.AUTHENTICATE_TOKEN ,
            data: data
        }
    }

    static  logoutAdmin(data){
        return {
            type : this.LOGOUT_ADMIN,
            data : data
        }
    }
    static logoutAdminSuccessul(data){
        return {
            type : this.LOGOUT_ADMIN_SUCCESSFUL,
            data: data
        }
    }
    static logoutAdminFailed(data){
        return {
            type : this.LOGOUT_ADMIN_FAILED,
            data: data
        }
    }

}

export default AuthActions;