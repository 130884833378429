import AllowCourseActions from '../actions/allowCourseActions';

function allowCourseReducer(state = {
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null,
    success: false,
    edit: false,
    allowedCourseContent: []
}, action) {

    console.log('allowCourseReducer=====>', action);
    switch (action.type) {

        case AllowCourseActions.ALLOW_COURSE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
            };
        case AllowCourseActions.ALLOW_COURSE_SUCCESSFULL:
            console.log(action.data);
            state.allowedCourseContent.push(action.data.data)
            // state.allowedCourseContent.push(action.data);
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                success: action.success,
            };
        case AllowCourseActions.ALLOW_COURSE_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,
            };


        case AllowCourseActions.REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
            };
        case AllowCourseActions.REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                success: action.success,
            };
        case AllowCourseActions.REMOVE_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,
            };


        case AllowCourseActions.ADD_SECTION_IN_ALLOWED_COURSE_CONTENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
            };
        case AllowCourseActions.ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                success: action.success,
            };
        case AllowCourseActions.ADD_SECTION_IN_ALLOWED_COURSE_CONTENT_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,
            };


        case AllowCourseActions.GET_ALLOWED_COURSE_CONTENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
            };
        case AllowCourseActions.GET_ALLOWED_COURSE_CONTENT_SUCCESSFULL:
            console.log(action.data);
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                allowedCourseContent: action.data,
                success: action.success,
            };
        case AllowCourseActions.GET_ALLOWED_COURSE_CONTENT_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,
            };






        case AllowCourseActions.DELETE_ALLOWED_COURSE_CONTENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case AllowCourseActions.DELETE_ALLOWED_COURSE_CONTENT_SUCCESSFUL:

            state.allowedCourseContent.map((item, index2) => {
                if (item._id === action._id) {
                    state.allowedCourseContent.splice(index2, 1)
                }
            })

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case AllowCourseActions.DELETE_ALLOWED_COURSE_CONTENT_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };


        default:
            return state;
    }
}
export default allowCourseReducer;