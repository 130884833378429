class CourseActions {
    static GET_COURSES = "GET_COURSES";
    static GET_COURSES_SUCCESSFULL = "GET_COURSES_SUCCESSFULL";
    static GET_COURSES_FAILED = "GET_COURSES_FAILED";

    static ADD_COURSES = "ADD_COURSES"; 
    static ADD_COURSES_FAILED = "ADD_COURSES_FAILED";
    static ADD_COURSES_SUCCESSFULL = "ADD_COURSES_SUCCESSFULL";


    static EDIT_COURSES = 'EDIT_COURSES';
    static EDIT_COURSES_FAILED = 'EDIT_COURSES_FAILED';
    static EDIT_COURSES_SUCCESSFULL = 'EDIT_COURSES_SUCCESSFULL';
    

    static DELETE_COURSE = "DELETE_COURSE"; 
    static DELETE_COURSE_FAILED = "DELETE_COURSE_FAILED";
    static DELETE_COURSE_SUCCESSFULL = "DELETE_COURSE_SUCCESSFULL";
    

    static GET_SECTIONS = "GET_SECTIONS";
    static GET_SECTIONS_SUCCESSFULL = "GET_SECTIONS_SUCCESSFULL";
    static GET_SECTIONS_FAILED = "GET_SECTIONS_FAILED";

    static DELETE_COURSE_CONTENT = "DELETE_COURSE_CONTENT";
    static DELETE_COURSE_CONTENT_SUCCESSFUL = "DELETE_COURSE_CONTENT_SUCCESSFUL";
    static DELETE_COURSE_CONTENT_FAILED = "DELETE_COURSE_CONTENT_FAILED";

    static ADD_COURSE_CONTENT = "ADD_COURSE_CONTENT";
    static ADD_COURSE_CONTENT_SUCCESSFUL = "ADD_COURSE_CONTENT_SUCCESSFUL";
    static ADD_COURSE_CONTENT_FAILED = "ADD_COURSE_CONTENT_FAILED";


    static REMOVE_COURSE_SECTION = "REMOVE_COURSE_SECTION";
    static REMOVE_COURSE_SECTION_SUCCESSFUL = "REMOVE_COURSE_SECTION_SUCCESSFUL";
    static REMOVE_COURSE_SECTION_FAILED = "REMOVE_COURSE_SECTION_FAILED";

    static ADD_COURSE_SECTION = "ADD_COURSE_SECTION";
    static ADD_COURSE_SECTION_SUCCESSFUL = "ADD_COURSE_SECTION_SUCCESSFUL";
    static ADD_COURSE_SECTION_FAILED = "ADD_COURSE_SECTION_FAILED";

    static GET_COURSES_BY_BATCH_ID = "GET_COURSES_BY_BATCH_ID";
    static GET_COURSES_BY_BATCH_ID_SUCCESSFUL = "GET_COURSES_BY_BATCH_ID_SUCCESSFUL";
    static GET_COURSES_BY_BATCH_ID_FAILED = "GET_COURSES_BY_BATCH_ID_FAILED";
    
    static addCourseSection(data) {
        return {
            type: this.ADD_COURSE_SECTION,
            data: data
        }
    }
    static addCourseSectionSuccessul(data) {
        return {
            type: this.ADD_COURSE_SECTION_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static addCourseSectionFailed(data) {
        return {
            type: this.ADD_COURSE_SECTION_FAILED,
            data: data
        }
    }




    // ============================

        // ADD COURSE ACTIONS

    // ============================


    static addCourses(data){ 
        return { 
            type: this.ADD_COURSES, 
            data
        }
    }

    static addCoursesSuccessfull(data){ 
        return { 
            type: this.ADD_COURSES_SUCCESSFULL, 
            data
        }
    }



    static addCoursesFailed(data){ 
        return { 
            type: this.ADD_COURSES_FAILED, 
            data
        }
    }



    static editCourse(data) {
        return {
            type: this.EDIT_COURSES,
            data: data
        }
    }
    static editCourseSuccessful(data) {
        return {
            type: this.EDIT_COURSES_SUCCESSFULL,
            data: data.data,
            index: data.index,
            success:data.success
        }
    }
    static editCourseFailed(data) {
        return {
            type: this.EDIT_COURSES_FAILED,
            data: data
        }
    }


    // ============================

        // DELETE COURSE ACTIONS

    // ============================

    static deleteCourses(data){ 
        return { 
            type: this.DELETE_COURSE, 
            data, 
        }
    }


    static deleteCourseSuccessful(data){ 
        return { 
            type: this.DELETE_COURSE_SUCCESSFULL,
            data, 
            _id: data.courseId, 
            index: data.index

        }
    }

    static deleteCourseFailed(data){ 
        return { 
            type: this.DELETE_COURSE_FAILED,
            data
        }
    }


    // ============================

        // GET COURSE ACTIONS

    // ============================
    static getCourses(data) {
        return {
            type: this.GET_COURSES,
            data
        }
    }



    static getCoursesSuccessfull(data) {
        return {
            type: this.GET_COURSES_SUCCESSFULL,
            data: data.data
        }
    }
    static getCoursesFailed(data) {
        return {
            type: this.GET_COURSES_FAILED,
            data
        }
    }


    static removeCourseSection(data) {
        return {
            type: this.REMOVE_COURSE_SECTION,
            data: data
        }
    }
    static removeCourseSectionSuccessul(data) {
        return {
            type: this.REMOVE_COURSE_SECTION_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static removeCourseSectionFailed(data) {
        return {
            type: this.REMOVE_COURSE_SECTION_FAILED,
            data: data
        }
    }


    static getSections(data) {
        return {
            type: this.GET_SECTIONS,
            data
        }
    }
    static getSectionsSuccessfull(data) {
        return {
            type: this.GET_SECTIONS_SUCCESSFULL,
            data: data.data
        }
    }
    static getSectionsFailed(data) {
        return {
            type: this.GET_SECTIONS_FAILED,
            data
        }
    }


    static addCourseContent(data) {
        return {
            type: this.ADD_COURSE_CONTENT,
            data: data
        }
    }
    static addCourseContentSuccessul(data) {
        return {
            type: this.ADD_COURSE_CONTENT_SUCCESSFUL,
            data: data,
        }
    }
    static addCourseContentFailed(data) {
        return {
            type: this.ADD_COURSE_CONTENT_FAILED,
            data: data
        }
    }

    
    static deleteCourseContents(data) {
        return {
            type: this.DELETE_COURSE_CONTENT,
            data: data
        }
    }
    static deleteCourseContentsSuccessul(data) {
        return {
            type: this.DELETE_COURSE_CONTENT_SUCCESSFUL,
            data: data,
            _id: data._id
        }
    }
    static deleteCourseContentsFailed(data) {
        return {
            type: this.DELETE_COURSE_CONTENT_FAILED,
            data: data
        }
    }
     // ============================

        // GET COURSES BY BATCH ACTIONS

    // ============================
    static getCoursesByBatchId(data) {
        return {
            type: this.GET_COURSES_BY_BATCH_ID,
            data
        }
    }



    static getCoursesByBatchIdSuccessfull(data) {
        return {
            type: this.GET_COURSES_BY_BATCH_ID_SUCCESSFUL,
            data: data.data
        }
    }
    static getCoursesByBatchIdFailed(data) {
        return {
            type: this.GET_COURSES_BY_BATCH_ID_FAILED,
            data
        }
    }



}

export default CourseActions;