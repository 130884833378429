class QuaterAction {
    static COMPLETE_CLASS ="COMPLETE_CLASS"
    static COMPLETE_CLASS_SUCCESSFUL="COMPLETE_CLASS_SUCCESSFUL"
    static COMPLETE_CLASS_FAILED = "COMPLETE_CLASS_FAILED"

    static START_CLASS ="START_CLASS"
    static START_CLASS_SUCCESSFUL="START_CLASS_SUCCESSFUL"
    static START_CLASS_FAILED = "START_CLASS_FAILED"

    static START_QUATER_REG ="START_QUATER_REG"
    static START_QUATER_REG_SUCCESSFUL="START_QUATER_REG_SUCCESSFUL"
    static START_QUATER_REG_FAILED = "START_QUATER_REG_FAILED"
    

    static CLOSE_QUATER_REG ="CLOSE_QUATER_REG"
    static CLOSE_QUATER_REG_SUCCESSFUL="CLOSE_QUATER_REG_SUCCESSFUL"
    static CLOSE_QUATER_REG_FAILED = "CLOSE_QUATER_REG_FAILED"
   
    static GET_QUATERS = "GET_QUATERS";
    static GET_QUATERS_SUCCESSFUL = "GET_QUATERS_SUCCESSFUL";
    static GET_QUATERS_FAILED = "GET_QUATERS_FAILED";

    static DELETE_QUATER = "DELETE_QUATER";
    static DELETE_QUATER_SUCCESSFUL = "DELETE_QUATER_SUCCESSFUL";
    static DELETE_QUATER_FAILED = "DELETE_QUATER_FAILED";

    static EDIT_QUATER = "EDIT_QUATER";
    static EDIT_QUATER_SUCCESSFUL = "EDIT_QUATER_SUCCESSFUL";
    static EDIT_QUATER_FAILED = "EDIT_QUATER_FAILED";

    static ADD_QUATER = "ADD_QUATER";
    static ADD_QUATER_SUCCESSFUL = "ADD_QUATER_SUCCESSFUL";
    static ADD_QUATER_FAILED = "ADD_QUATER_FAILED";

    static completeClass(data){
        return{
            type:this.COMPLETE_CLASS,
            data:data,

        }
    }
    static completeClassSuccessfull(data){
        return {
            type:this.COMPLETE_CLASS_SUCCESSFUL,
            data:data.data,
            index:data.index,
        }
    }
    static completeClassFailed(data){
        return {
            type:this.COMPLETE_CLASS_FAILED,
            data:data,
        }
    }
    static startClass(data){
        return{
            type:this.START_CLASS,
            data:data,

        }
    }
    static startClassSuccessfull(data){
        return {
            type:this.START_CLASS_SUCCESSFUL,
            data:data.data,
            index:data.index,
        }
    }
    static startClassFailed(data){
        return {
            type:this.START_CLASS_FAILED,
            data:data,
        }
    }
    
    static closeQuaterReg(data){
        return {
            type:this.CLOSE_QUATER_REG,
            data:data
        }
    }
    static closeQuaterRegSuccessfull(data){
        return{
            type:this.CLOSE_QUATER_REG_SUCCESSFUL,
            data:data.data,
            index:data.index
        }
    }
    static closeQuaterRegFailed(data){
        return{
            type:this.CLOSE_QUATER_REG_FAILED,
            data:data,
        }
    }
    static startQuaterReg(data){
        return {
            type:this.START_QUATER_REG,
            data:data,
        }
    }
    static startQuaterRegSuccessfull(data){
        return {
            type:this.START_QUATER_REG_SUCCESSFUL,
            data:data.data,
            index:data.index,
            success:true,
        }
    }
    static startQuaterRegFailed(data){
        return{
            type:this.START_QUATER_REG_FAILED,
            data:data.errorMessage
        }
    }
   

    static getQuaters(data) {
        return {
            type: this.GET_QUATERS,
            data: data
        }
    }
    static getQuatersSuccessul(data) {
        return {
            type: this.GET_QUATERS_SUCCESSFUL,
            data: data.data
        }
    }
    static getQuatersFailed(data) {
        return {
            type: this.GET_QUATERS_FAILED,
            data: data
        }
    }


    static deleteQuater(data) {
        return {
            type: this.DELETE_QUATER,

        }
    }
    static deleteQuaterSuccessul(data) {
        return {
            type: this.DELETE_QUATER_SUCCESSFUL,
            data: data.data,
            index: data.index,
            success: true,
        }
    }
    static deleteQuaterFailed(data) {
        return {
            type: this.DELETE_QUATER_FAILED,
            data: data
        }
    }

    static addQuater(data) {
        return {
            type: this.ADD_QUATER,
            data: data
        }
    }
    static addQuaterSuccessul(data) {
        return {
            type: this.ADD_QUATER_SUCCESSFUL,
            data: data.data,
            success: data.success
        }
    }
    static addQuaterFailed(data) {
        return {
            type: this.ADD_QUATER_FAILED,
            data: data
        }
    }

    static editQuater(data) {
        return {
            type: this.EDIT_QUATER,
            data: data
        }
    }
    static editQuaterSuccessul(data) {
        console.log("action successs edit data==========>", data.data);
        return {
            type: this.EDIT_QUATER_SUCCESSFUL,
            data: data.data,
            index: data.index,
            success: data.success
        }
    }
    static editQuaterFailed(data) {
        return {
            type: this.EDIT_QUATER_FAILED,
            data: data
        }
    }




}

export default QuaterAction