function validateForm(data) {
    const { time, batch, quarter, course, emailSubject, center, day,
        schedulerName, minutesPerBatch, noOfEmailsInOneBatch } = data


    var errors = {
        hasError: false,
        errorsObj: {}
    }

    let Validation = {
        time: {
            Validate: [
                {
                    condition: !time.length || time === "Select",
                    message: " Please Select timing. ",
                }
            ],
            elem: "time"
        },
        batch: {
            Validate: [
                {
                    condition: !batch.length || batch === "Select",
                    message: " Please Select  batch. ",
                }
            ],
            elem: "batch"
        },
        quarter: {
            Validate: [
                {
                    condition: !quarter.length || quarter === "Select",
                    message: " Please Select quarter. ",
                }
            ],
            elem: "quarter"
        },
        course: {
            Validate: [
                {
                    condition: !course.length || course === "Select",
                    message: " Please Select course. ",
                }
            ],
            elem: "course"
        },

        emailSubject: {
            Validate: [
                {
                    condition: !emailSubject.length,
                    message: " Please Specify email Subject. ",
                }
            ],
            elem: "emailSubject"
        },
        center: {
            Validate: [
                {
                    condition: !center.length,
                    message: " Please Select center. ",
                }
            ],
            elem: "center"
        },
        day: {
            Validate: [
                {
                    condition: !day.length,
                    message: " Please Select a day. ",
                }
            ],
            elem: "day"
        },
        schedulerName: {
            Validate: [
                {
                    condition: !schedulerName.length || typeof (schedulerName) !== "string",
                    message: " Please enter a valid scheduler name . ",
                }
            ],
            elem: "schedulerName"
        },

        noOfEmailsInOneBatch: {
            Validate: [{
                condition: noOfEmailsInOneBatch < 1,
                message: " No Of Emails should be more than 1",
            }],
            elem: "noOfEmailsInOneBatch"
        },

        minutesPerBatch: {
            Validate: [{
                condition: minutesPerBatch > 40,
                message: " Minutes per batch cannot exceed 40 Minutes ",
            }],
            elem: "minutesPerBatch"
        },
        

    }



    for (var i in Validation) {
        var conArray = Validation[i].Validate;
        errors.errorsObj[Validation[i].elem] = { message: [] }
        for (var j = 0; j < conArray.length; j++) {
            if (conArray[j].condition) {
                errors.hasError = true
                errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
            }
        }
        if (!errors.errorsObj[Validation[i].elem].message.length) {
            delete errors.errorsObj[Validation[i].elem];
        }
    }
    return Object.keys(errors).length > 1 ? errors : {
        hasError: false
    }
}

export default validateForm