import BatchAction from "../actions/batchActions";
import AuthActions from "../actions/authActions";
import axios from "axios";
import Path from "../../config/path";
import LocalStorageManager from "../../services/localStorageManager";

import QuaterAction from "../actions/quaterActions";

class QuaterMiddleware {
    static searchQuateres(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.getQuaters(data));
                axios
                    .post(`${Path.GET_QUATERS}`, {
                        courseId: data.courseId,
                        batchId: data.batchId,
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        if (!response.data.success) {
                            dispatch(
                                QuaterAction.getQuatersFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            dispatch(
                                QuaterAction.getQuatersSuccessul({
                                    success: true,
                                    data: response.data.data
                                })
                            );
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(
                            QuaterAction.getQuatersFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }
    static addQuater(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.addQuater(data));
                return axios
                    .post(`${Path.ADD_QUATER}`, {
                        courseId: data.courseId,
                        batchId: data.batchId,
                        quarterName: data.name,
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        console.log("success response=============>", response);

                        if (!response.data.success) {
                            console.log("failed !!!!!!!!!!!!!!", response);
                            dispatch(
                                QuaterAction.addQuaterFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            dispatch(
                                QuaterAction.addQuaterSuccessul({
                                    success: true,
                                    data: response.data.data
                                })
                            );
                        }
                        return response.data.success;
                    })
                    .catch(err => {
                        console.log("error...........................s=====>", err);
                        dispatch(
                            QuaterAction.addQuaterFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }
    static editQuater(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.editQuater(data));
                return axios
                    .post(`${Path.UPDATE_QUATER}`, {
                        quarterId: data._id,
                        data: {
                            quarterName: data.name,
                        },
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(
                                QuaterAction.editQuaterFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            dispatch(
                                QuaterAction.editQuaterSuccessul({
                                    success: true,
                                    data: response.data.data,
                                    index: data.index
                                })
                            );
                        }
                        return response.data.success;
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(
                            QuaterAction.editQuaterFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }





    static deleteQuater(data, index) {
        console.log("delete index=====>", index);
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.deleteQuater(data));
                axios
                    .post(`${Path.DELETE_QUATER}`, {
                        quarterId: data._id,
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        console.log("delete response=====>", response);

                        if (!response.data.success) {
                            dispatch(
                                QuaterAction.deleteQuaterFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            dispatch(
                                QuaterAction.deleteQuaterSuccessul({
                                    success: true,
                                    data: response.data,
                                    index: index
                                })
                            );
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(
                            QuaterAction.deleteQuaterFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }

    static startQuaterReg(data, index) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.startQuaterReg(data));
                axios
                    .post(`${Path.REG_START_QUATER}`, {
                        quarterId: data._id,
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        if (!response.data.success) {
                            dispatch(
                                QuaterAction.startQuaterRegFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            dispatch(
                                QuaterAction.startQuaterRegSuccessfull({
                                    success: true,
                                    data: response.data,
                                    index: index
                                })
                            );
                        }
                    })
                    .catch(err => {

                        dispatch(
                            QuaterAction.startQuaterRegFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }
    static closeQuaterReg(data, index) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.closeQuaterReg(data));
                axios
                    .post(`${Path.REG_CLOSE_QUATER}`, {
                        quarterId: data._id,
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        console.log("end batch response=====>", response);

                        if (!response.data.success) {
                            dispatch(
                                QuaterAction.closeQuaterRegFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            console.log("response===>", response.data);
                            dispatch(
                                QuaterAction.closeQuaterRegSuccessfull({
                                    success: true,
                                    data: response.data,
                                    index: index
                                })
                            );
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(
                            QuaterAction.closeQuaterRegFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }

    static completeClass(data, index) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.completeClass(data));
                axios
                    .post(`${Path.TERMINATE_CLASS}`, {
                        quarterId: data._id,
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        console.log("end batch response=====>", response);

                        if (!response.data.success) {
                            dispatch(
                                QuaterAction.completeClassFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            console.log("response===>", response.data);
                            dispatch(
                                QuaterAction.completeClassSuccessfull({
                                    success: true,
                                    data: response.data,
                                    index: index
                                })
                            );
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(
                            QuaterAction.completeClassFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }

    static startClass(data, index) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(
                    AuthActions.authenticationFailed({
                        errorMessage: "Authentication Expired, Login Again"
                    })
                );
            } else {
                dispatch(QuaterAction.startClass(data));
                axios
                    .post(`${Path.START_CLASS}`, {
                        quarterId: data._id,
                        databaseToken: user.databaseToken
                    })
                    .then(response => {
                        console.log("end batch response=====>", response);

                        if (!response.data.success) {
                            dispatch(
                                QuaterAction.startClassFailed({
                                    errorMessage: response.data.message
                                })
                            );
                        } else {
                            console.log("response===>", response.data);
                            dispatch(
                                QuaterAction.startClassSuccessfull({
                                    success: true,
                                    data: response.data,
                                    index: index
                                })
                            );
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(
                            QuaterAction.startClassFailed({
                                errorMessage: "Something Went Wrong, Please Try Again!"
                            })
                        );
                    });
            }
        };
    }


}

export default QuaterMiddleware;
