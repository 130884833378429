import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'
import EntryTestEmailActions from '../actions/entryTestEmailActions';

class StudetnMiddleware {

    static search(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(EntryTestEmailActions.searchStudent(data));
                axios.post(`${Path.STUDENT_EMAIL_SEARCH}`, {
                    type: data.type,
                    value: data.value,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(EntryTestEmailActions.searchStudentFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(EntryTestEmailActions.searchStudentSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(EntryTestEmailActions.searchStudentFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static updateEmail(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(EntryTestEmailActions.editEmail(data));
                return axios.post(`${Path.UPDATE_EMAIL}`, {
                    data: data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response.data.data);
                        
                        if (!response.data.success) {
                            dispatch(EntryTestEmailActions.editEmailFailed({ errorMessage: response.data.message }));
                            return false
                        }
                        else {
                            dispatch(EntryTestEmailActions.editEmailSuccessul({ success: true, data: { successMessage: "Email updated successfully", data: response.data.data } }));
                            return true
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(EntryTestEmailActions.editEmailFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }




    static resendEmail(data) {
        return dispatch => {

            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(EntryTestEmailActions.resendEmail(data));
                axios.post(`${Path.RESEND_EMAIL}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response.data);

                        if (!response.data.success) {
                            dispatch(EntryTestEmailActions.resendEmailFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(EntryTestEmailActions.resendEmailSuccessul({ success: true, data: { message: response.data.message } }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(EntryTestEmailActions.resendEmailFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


}

export default StudetnMiddleware;