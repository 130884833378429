import BatchAction from "../actions/batchActions";
import AuthActions from "../actions/authActions";
import axios from "axios";
import Path from "../../config/path";
import LocalStorageManager from "../../services/localStorageManager";


class BatchMiddleware {
	static searchBatches(data) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(BatchAction.getBatch(data));
				axios
					.post(`${Path.GET_BATCHES}`, {
						data,
						databaseToken: user.databaseToken
					})
					.then(response => {
						if (!response.data.success) {
							dispatch(
								BatchAction.getBatchFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								BatchAction.getBatchSuccessul({
									success: true,
									data: response.data.data
								})
							);
						}
					})
					.catch(err => {
						dispatch(
							BatchAction.getBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
	static addBatch(data) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(BatchAction.addBatch(data));
				return axios
					.post(`${Path.ADD_BATCH}`, {
						name: data.name,
						city: data.city,
						isEntryTestMandatory: data.isEntryTestMandatory,
						isRestricted: data.isRestricted,
						databaseToken: user.databaseToken
					})
					.then(response => {
						if (!response.data.success) {
							dispatch(
								BatchAction.addBatchFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								BatchAction.addBatchSuccessul({
									success: true,
									data: response.data.data
								})
							);
						}
						return response.data.success;
					})
					.catch(err => {
						console.log("error...........................s=====>", err);
						dispatch(
							BatchAction.addBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
	static editBatch(data) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(BatchAction.editBatch(data));
				return axios
					.post(`${Path.EDIT_BATCH}`, {
						batchId: data._id,
						data: {
							name: data.name,
							city: data.city,
							isRestricted: data.isRestricted,
							isEntryTestMandatory: data.isEntryTestMandatory
						},
						databaseToken: user.databaseToken
					})
					.then(response => {
						console.log(response);

						if (!response.data.success) {
							dispatch(
								BatchAction.editBatchFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								BatchAction.editBatchSuccessul({
									success: true,
									data: response.data.data,
									index: data.index
								})
							);
						}
						return response.data.success;
					})
					.catch(err => {
						console.log(err);
						dispatch(
							BatchAction.editBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
	static deleteBatch(data, index) {
		console.log("delete index=====>", index);
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(BatchAction.deleteBatch(data));
				axios
					.post(`${Path.DELETE_BATCH}`, {
						batchId: data._id,
						databaseToken: user.databaseToken
					})
					.then(response => {
						console.log("delete response=====>", response);

						if (!response.data.success) {
							dispatch(
								BatchAction.deleteBatchFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								BatchAction.deleteBatchSuccessul({
									success: true,
									data: response.data,
									index: index
								})
							);
						}
					})
					.catch(err => {
						console.log(err);
						dispatch(
							BatchAction.deleteBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
	static startBatchReg(data, index) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(BatchAction.startBatchReg(data));
				axios
					.post(`${Path.START_REG}`, {
						batchId: data._id,
						databaseToken: user.databaseToken
					})
					.then(response => {
						if (!response.data.success) {
							dispatch(
								BatchAction.startBatchRegFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								BatchAction.startBatchRegSuccessfull({
									success: true,
									data: response.data,
									index: index
								})
							);
						}
					})
					.catch(err => {
						dispatch(
							BatchAction.startBatchRegFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
	static closeBatchReg(data, index) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(BatchAction.closeBatchReg(data));
				axios
					.post(`${Path.CLOSE_REG}`, {
						batchId: data._id,
						databaseToken: user.databaseToken
					})
					.then(response => {
						console.log("end batch response=====>", response);

						if (!response.data.success) {
							dispatch(
								BatchAction.closeBatchRegFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							console.log("response===>", response.data);
							dispatch(
								BatchAction.closeBatchRegSuccessfull({
									success: true,
									data: response.data,
									index: index
								})
							);
						}
					})
					.catch(err => {
						console.log(err);
						dispatch(
							BatchAction.closeBatchRegFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}

	static completeBatch(data, index) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(BatchAction.completeBatch(data));
				axios
					.post(`${Path.BATCH_COMPLETE}`, {
						batchId: data._id,
						databaseToken: user.databaseToken
					})
					.then(response => {
						console.log("end batch response=====>", response);

						if (!response.data.success) {
							dispatch(
								BatchAction.completeBatchFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							console.log("response===>", response.data);
							dispatch(
								BatchAction.completeBatchSuccessfull({
									success: true,
									data: response.data,
									index: index
								})
							);
						}
					})
					.catch(err => {
						console.log(err);
						dispatch(
							BatchAction.completeBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}

}

export default BatchMiddleware;
