import React from 'react';
import { withRouter } from "react-router-dom";
const OnlineVideosManagementButtons = (props) => {
    return (
        <div className="btn-container">
            <div className="innerbtngroup">
                <button className="my-btn-3 publishBtn" onClick={() => props.history.push("/onlinevideosmanagement/section")} type="button">Section</button>
                <button className="my-btn-3 publishBtn" onClick={() => props.history.push({ pathname: "/onlinevideosmanagement/lesson" })} type="button">Lesson</button>
                <button className="my-btn-3 publishBtn" onClick={() => props.history.push({ pathname: "/onlinevideosmanagement/courseoutline" })} type="button">Course Outline</button>
                <button className="my-btn-3 publishBtn" onClick={() => props.history.push("/onlinevideosmanagement/allowcourseoutline")} type="button">Allow Course Outline</button>
            </div>
        </div>
    )
}


export default withRouter(OnlineVideosManagementButtons);