import React, { Component } from 'react';
import OnlineVideosManagementButtons from './OnlineVideosManagementButtons';

class OnlineVideosManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: "lesson"
        }
    }
    render() {

        return (
            <div className="container-fluid">
                <div className="container">
                    <OnlineVideosManagementButtons />
                </div>
            </div>
        );
    }
}

export default OnlineVideosManagement;