import AuthActions from '../actions/authActions';

function authReducer(state = {
    user: null,
    isError: false,
    isLoading: false,
    errorMessage: false,
    userId: null,
    isLoggedIn:false,
    successMessage: null,
    authToken: null,
    allowedRoutes: [],
}, action) {
    switch (action.type) {
        
        case AuthActions.AUTHENTICATION:
            return {
                ...state,
                errorMessage:null,
                successMessage:null,
                userId: action.data.userId,
                isLoading : true,
                isLoggedIn:false,
                authToken : null,
                allowedRoutes: [],
            };
        case AuthActions.AUTHENTICATION_SUCCESSFUL:
            return {
                ...state,
                isError:false,
                isLoading : false,
                authToken:action.data.data.databaseToken,
                user: action.data.data,
                isLoggedIn:true,
                errorMessage:null,
                successMessage: action.data.successMessage,
                allowedRoutes: action.data.allowedRoutes,
            };
        case AuthActions.AUTHENTICATION_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage:null,
                isError: true,
                isLoading : false,
                isLoggedIn:false,
                allowedRoutes: [],
            };
        case AuthActions.AUTHENTICATE_TOKEN:
            return {
                ...state,
                isError:false,
                isLoading : false,
                authToken:action.data.databaseToken,
                user: action.data,
                isLoggedIn:true,
                errorMessage:null,
                successMessage: null,
                allowedRoutes: [],
            };
            case AuthActions.LOGOUT_ADMIN:
            return {
                ...state,
                errorMessage:null,
                successMessage:null,
                isLoading : true,
                isLoggedIn:true,
            };
        case AuthActions.LOGOUT_ADMIN_SUCCESSFUL:
            return {
                ...state,
                isError:false,
                isLoading : false,
                authToken:null,
                user: null,
                isLoggedIn:false,
                errorMessage:null,
                successMessage: action.data.message,
                allowedRoutes: [],
            };
        case AuthActions.LOGOUT_ADMIN_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage:null,
                isError: true,
                isLoading : false,
                isLoggedIn:true,
                allowedRoutes: [],
            };
        default:
            return state;
    }
}
export default authReducer;