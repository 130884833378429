/*eslint-disable */
import React from "react";

function validateForm(check, data) {
    const {
        centerName,
        gender,
        course,
        day,
        batch,
        year,
        month,
        time,
        quarter,
        totalSeats,
        quarterFee,
        feeDeadline
    } = data;

    var errors = {
        hasError: false,
        errorsObj: {}
    }

    let Validation = {
        centerName: {
            Validate: [
                {
                    condition: !centerName.length || centerName === "Select",
                    message: " Please Specify Center Name . ",
                }
            ],
            elem: "centerName"
        },
        quarter: {
            Validate: [{
                condition: !quarter.length || quarter === "Select",
                message: " Please Specify Quarter . ",
            }
            ],
            elem: "quarter"
        },
        course: {
            Validate: [{
                condition: !course.length || course === "Select",
                message: " Please Specify Course Name . ",
            }
            ],
            elem: "course"
        },
        day: {
            Validate: [{
                condition: !day.length || day === "Select",
                message: " Please Specify Day . ",
            }
            ],
            elem: "day"
        },
        batch: {
            Validate: [{
                condition: !batch.length,
                message: " Please Specify Batch . ",
            }
            ],
            elem: "batch"
        },
        year: {
            Validate: [{
                condition: !year.length,
                message: " Please Specify Year . ",
            }, {
                condition: year.length !== 4,
                message: " Year Contains 4 Digits . ",
            }, {
                condition: !/\d/.test(year) || /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$]*)?$/.test(year),
                message: " Year Should Have Numbers Only. ",
            }, {
                condition: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(year),
                message: " No Space Hyphens '-' Or Any Special Character. ",
            },
            ],
            elem: "year"
        },
        month: {
            Validate: [{
                condition: !month.length,
                message: " Please Select month . ",
            }
            ],
            elem: "month"
        },
        time: {
            Validate: [{
                condition: !time.length,
                message: " Please Specify time . ",
            }
            ],
            elem: "time"
        },
        gender: {
            Validate: [
                {
                    condition: !gender,
                    message: "Please Select Your Gender",
                }
            ],
            elem: "gender"
        },
        totalSeats: {
            Validate: [
                {
                    condition: !totalSeats,
                    message: " Please Specify Total Seats . ",
                }, {
                    condition: !/\d/.test(totalSeats) || /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$]*)?$/.test(totalSeats),
                    message: " Total Seats Should Have Numbers Only. ",
                }, {
                    condition: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(totalSeats),
                    message: " No Space Hyphens '-' Or Any Special Character. ",
                },
            ],
            elem: "totalSeats"
        },
        quarterFee: {
            Validate: [{
                condition: !/\d/.test(quarterFee) || /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$]*)?$/.test(quarterFee),
                message: " Fee Should Have Numbers Only. ",
            }],
            elem: "quarterFee"
        },
        feeDeadline: {
            Validate: [{
                condition: feeDeadline === 0,
                message: " Please Specify Fee Deadline . ",
            }],
            elem: "feeDeadline"
        }
    }
    if (check === "all") {
        for (var i in Validation) {
            var conArray = Validation[i].Validate;
            errors.errorsObj[Validation[i].elem] = { message: [] }
            for (var j = 0; j < conArray.length; j++) {
                if (conArray[j].condition) {
                    errors.hasError = true
                    errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
                }
            }
            if (!errors.errorsObj[Validation[i].elem].message.length) {
                delete errors.errorsObj[Validation[i].elem];
            }
        }
    }
    console.log("errors", errors);


    return Object.keys(errors).length > 1 ? errors : {
        hasError: false
    }
}



const Loader = () => {
    return (
        <div className="LoaderContainer">
            <div className="loader">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    )
}

export { validateForm, Loader };



