import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import LocalStorageManager from '../../services/localStorageManager';
import Path from '../../config/path';
import { Input} from "../Input/input";
import { Loader } from '../StudentManagment/helper';
import { validateForm } from './helper'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html'
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import './Announcements.css';


class Announcements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageno:1,
            announcements: [],
            editorState: EditorState.createEmpty(),
            success: "",
            fail: "",
            postId:"",
            postTitle: "",
            postDesc: '',
            postUrl: '',
            edit: false,
            loader: false,
            moreAnnouncements:true,
            errors: {
                hasError: false,
                errorsObj: {}
            }
        }
    }
    componentWillMount() {
        this.getAnnouncements(this.state.pageno);
    }
    onEditorStateChange = (editorState) => {
        console.log(editorState)
        let contentState = editorState.getCurrentContent();
        let html = stateToHTML(contentState);

        this.setState({
            editorState,
            postDesc: html
        })

    }

    editAnnouncement = async (postId, postTitle, postDesc, postUrl, databaseToken) => {
        try {
            if (postId && postTitle || postDesc || postUrl && databaseToken.length) {
                console.log(postId)
                const data = await axios.post(Path.UPDATE_ANNOUNCEMENT, { postId, data: { title: postTitle, desc: postDesc, url: postUrl },databaseToken })
                console.log(data.data.data)
                if (data.data.success) {
                    this.setState({
                        loader: false,
                        success: 'Your Post Is Updated successfully',
                        postTitle: '',
                        postDesc: '',
                        postUrl: '',
                        editorState: EditorState.createEmpty()
                    })
                }
                this.getAnnouncements(this.state.pageno)
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    submit = (e) => {
        e.preventDefault();
        const { postTitle, postDesc, postUrl, errors, edit, postId } = this.state;

        var user = LocalStorageManager.getUser();

        const err = validateForm('all', { postTitle, postDesc, postUrl })

        this.setState({
            errors: err
        })

        if (err.hasError) {
            return
        }

        if (!postDesc.length && !postUrl.length) {
            errors.hasError = true
            errors.errorsObj = {
                postDesc: {
                    message: " Please Specify Post Description. "
                },

                postUrl: {
                    message: " Please Specify Post Url. "
                }

            };
            this.setState({
                errors
            })

            return
        }
        if (edit) {
            this.editAnnouncement(postId, postTitle, postDesc, postUrl, user.databaseToken)
            return
        }
        else {
            this.postData(postTitle, postDesc, postUrl, user.databaseToken)
        }
    }

    getAnnouncements = async (pageNo) => {
        try {
            const data = await axios.post(Path.GET_ALL_ANNOUNCEMENTS, { pageNo: pageNo })
            console.log(data)
            if (data.data.success) {
                if(pageNo!=1){
                    const announcements=[...this.state.announcements,...data.data.data.data]
                this.setState({ announcements})
                }
                else{
                    this.setState({announcements:data.data.data.data})
                }
            }
            if(data.data.data.nextPageNo === ""){
                this.setState({
                    moreAnnouncements: false
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    getMoreAnnouncements = () => { 
        this.setState({
            pageno: this.state.pageno + 1
        }, () => {
            this.getAnnouncements(this.state.pageno)
        })
    }
    deleteAnnouncement = async (id) => {
        var user = LocalStorageManager.getUser();
        const databaseToken = user.databaseToken;
        const AnnouncementId = id;
        try {
            const data = await axios.post(Path.DELETE_ANNOUNCEMENT, { AnnouncementId, databaseToken })
            if (data.data.success) {
                console.log("deleted")
                this.setState((ps) => {
                    return { announcements: ps.announcements.filter(announcement => announcement._id !== id && id !== announcement._id) }
                })
            }
        }
        catch{
            console.log("error")
        }
    }
    onEditAnnouncement = (announcement) => {
        this.setState({
            edit: true,
            pageno:1,
            moreAnnouncements:true,
            postId: announcement._id,
            postTitle: announcement.title,
            postUrl: announcement.url,
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(announcement.desc)
                )
            ),
        })
    }

    postData = async (title, desc, url, token) => {
        if (desc === "<p><br></p>") {
            desc = ""
        }
        try {
            if (title.length && desc.length && url.length && token.length) {
                const data = await axios.post(Path.ADD_ANNOUNCEMENT, { title, desc, url, databaseToken: token, })

                if (data.data.success) {
                    this.setState({
                        loader: false,
                        success: 'Your Post Is Published',
                        postTitle: '',
                        postDesc: '',
                        postUrl: '',
                        editorState: EditorState.createEmpty()
                    })
                }

            } else if (!desc.length && url.length || !url.length && desc.length) {
                const data = await axios.post(Path.ADD_ANNOUNCEMENT, { title, desc, url, databaseToken: token, })

                if (data.data.success) {
                    this.setState({
                        loader: false,
                        success: 'Your Post Is Published',
                        postTitle: '',
                        postDesc: '',
                        postUrl: '',
                        editorState: EditorState.createEmpty()
                    })
                }
            }
            this.getAnnouncements(this.state.pageno)
        } catch (err) {
            console.log(err)
        }
    }
    tableSection = (announcements) => {
        return !!announcements.length && (
            <div className="table-wrapper">
                <br />
                <br />
                <br />
                <table className="announcement-table">
                    <tbody>
                        <tr>
                            <th>Title</th>
                            <th>Created Data</th>
                            <th>Description</th>
                            <th>URL</th>
                            <th>Update</th>
                            <th>Delete</th>
                        </tr>

                        {announcements.map((announcement,index) => {
                            return (
                                <tr key={index}>
                                    <td>{announcement.title}</td>
                                    <td>{moment(announcement.createdDate).format('LL')}</td>
                                    <td>{<p>{ReactHtmlParser(announcement.desc)}</p>}</td>
                                    <td>{announcement.url}</td>
                                    <td><button type="button" className="my-btn-3" onClick={() => this.onEditAnnouncement(announcement)}>Update</button></td>
                                    <td><button type="button" className="my-btn-3" onClick={() => window.confirm("Are You Sure You Want To Delete This") && this.deleteAnnouncement(announcement._id)}>Delete</button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
    render() {
        const { success, fail, loader, postTitle, postUrl, errors, editorState, announcements,moreAnnouncements } = this.state;
        return (
            <div className="form-container align-items-center">
                {
                    loader
                    &&
                    <Loader />
                }
                <div className="form-wrapper">
                    <form onSubmit={this.submit} ref={(el) => this.myFormRef = el}>
                        <h3  >
                            Add A Post
                        </h3>

                        {fail && <p className="error">{fail}</p>}
                        {success && <p className="success">{success}</p>}

                        <Input
                            label="Post Title"
                            type="text"
                            name="postTitle"
                            id="postTitle"
                            value={postTitle}
                            length='100'
                            onChange={(ev) => { this.setState({ [ev.target.name]: ev.target.value }) }}
                            errors={errors}
                        />

                        <Input
                            label="Post URL"
                            type="text"
                            name="postUrl"
                            id="postUrl"
                            value={postUrl}
                            onChange={(ev) => { this.setState({ [ev.target.name]: ev.target.value }) }}
                            errors={errors}
                        />

                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarWrapper"
                            wrapperClassName="editorWrapper"
                            editorClassName="emailWrapper"
                            onEditorStateChange={this.onEditorStateChange}
                        />

                        {errors.errorsObj['postDesc'] && errors.errorsObj['postDesc'].message && < p className="error">{errors.errorsObj['postDesc'].message}</p>}

                        <button type="submit" className="my-btn-2">
                            Post
                        </button>
                    </form>
                </div>
                {this.tableSection(announcements)}
                {moreAnnouncements ? <button onClick={this.getMoreAnnouncements} className="loadmore-btn">Load more announcements</button> : null}
            </div>
        );
    }

}




export default (Announcements);