/*eslint-disable */
import React from "react";

function validateForm(check, data) {
    const {
        name,
        initial,
        shortDescription, 
        longDescription
    } = data;

    var errors =  {
        hasError: false,
        errorsObj: {}
    }


    let Validation = {
        name: {
            Validate: [{
                condition: !name.length,
                message: " Please Specify Your Course Name . ",
            }, {
                condition: /\d/.test(name) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(name),
                message: " Course Name Can Not Contain Numbers Or Any Special Character . ",
            }
            ],
            elem: "name"
        },

        initial: {
            Validate: [{
                condition: !initial.length,
                message: " Please Specify Course Initials . ",
            }, {
                condition: /\d/.test(initial) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(initial),
                message: " Initials Can Not Contain Numbers Or Any Special Character . ",
            }
            ],
            elem: "initial"
        },

        shortDescription: {
            Validate: [{
                condition: !shortDescription.length,
                message: " Please Specify Some Short Description . ",
            }, {
                condition: /\d/.test(shortDescription) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(shortDescription),
                message: " Description Can Not Contain Numbers Or Any Special Character . ",
            }
            ],
            elem: "shortDescription"
        },

        longDescription: {
            Validate: [{
                condition: !longDescription.length,
                message: " Please Specify Some Long Description . ",
            }, {
                condition: /\d/.test(longDescription) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(longDescription),
                message: " Description Can Not Contain Numbers Or Any Special Character . ",
            }
            ],
            elem: "longDescription"
        },

        
    }
    
    if (check === "all") {
        for (var i in Validation) {
            var conArray = Validation[i].Validate;
            errors.errorsObj[Validation[i].elem] = { message: [] }
            for (var j = 0; j < conArray.length; j++) {
                if (conArray[j].condition) {
                    errors.hasError = true
                    errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
                }
            }
            if (!errors.errorsObj[Validation[i].elem].message.length) {
                delete errors.errorsObj[Validation[i].elem] ;
            }
        }
    }
    console.log("errors",errors);
    

    return Object.keys(errors).length>1 ? errors : {
        hasError: false
    }
}




export { validateForm };



