import BatchAction from "../actions/batchActions";

function batchReducer(
	state = {
		batches: null,
		isError: false,
		isLoading: false,
		errorMessage: false,
		successMessage: null,
		edit: false,
		success: false
	},
	action
) {
	switch (action.type) {
		case BatchAction.COMPLETE_BATCH:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case BatchAction.COMPLETE_BATCH_SUCCESSFUL:
			state.batches[action.index] = { ...action.data.data };
			console.log("log data of action ", action.data);
			return {
				...state,
				isError: false,
				isLoading: false,
				errorMessage: null
			};
		case BatchAction.COMPLETE_BATCH_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		case BatchAction.CLOSE_BATCH_REG:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case BatchAction.CLOSE_BATCH_REG_SUCCESSFUL:
			state.batches[action.index] = { ...action.data.data };
			console.log("log data of action ", action.data);
			return {
				...state,
				isError: false,
				isLoading: false,
				errorMessage: null
			};
		case BatchAction.CLOSE_BATCH_REG_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		case BatchAction.START_BATCH_REG:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case BatchAction.START_BATCH_REG_SUCCESSFUL:
			state.batches[action.index] = { ...action.data.data };
			console.log("log data of action ", action.data);
			return {
				...state,
				isError: false,
				isLoading: false,
				errorMessage: null
			};
		case BatchAction.START_BATCH_REG_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		case BatchAction.GET_BATCH:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};

		case BatchAction.GET_BATCH:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case BatchAction.GET_BATCH_SUCCESSFUL:
			return {
				...state,
				isError: false,
				isLoading: false,
				batches: action.data,
				errorMessage: null
			};
		case BatchAction.GET_BATCH_FAILED:
			return {
				...state,
				batches: null,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		case BatchAction.ADD_BATCH:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				isLoading: true,
				success: false
			};
		case BatchAction.ADD_BATCH_SUCCESSFUL:
			console.log(action.data);

			state.batches.push({ ...action.data });
			return {
				...state,
				isError: false,
				isLoading: false,
				success: action.success,
				errorMessage: null
			};
		case BatchAction.ADD_BATCH_FAILED:
			console.log(action.data);

			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				isLoading: false,
				success: false
			};
		case BatchAction.EDIT_BATCH:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				edit: true,
				success: false,
				isLoading: true
			};
		case BatchAction.EDIT_BATCH_SUCCESSFUL:
			state.batches[action.index] = { ...action.data };
			return {
				...state,
				isError: false,
				isLoading: false,
				errorMessage: null,
				edit: false,
				success: action.success
			};
		case BatchAction.EDIT_BATCH_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				isLoading: false,
				edit: true,
				success: false
			};

		case BatchAction.DELETE_BATCH:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				isLoading: true
			};
		case BatchAction.DELETE_BATCH_SUCCESSFUL:
			console.log("batch ation delete =====> ", action.index);
			state.batches.splice(action.index, 1);

			return {
				...state,
				isError: false,
				isLoading: false,
				errorMessage: null
			};
		case BatchAction.DELETE_BATCH_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				isLoading: false
			};

		default:
			return state;
	}
}
export default batchReducer;
