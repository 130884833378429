import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'

class AuthMiddleware {

    static authentication(data) {
        return dispatch => {
            dispatch(AuthActions.authentication(data))
            axios.post(`${Path.AUTHENTICATE}`, {
                userName: data.userName,
                password: data.password,
            })
                .then((response) => {
                    if (!response.data.success) {
                        dispatch(AuthActions.authenticationFailed({ errorMessage: response.data.message }));
                    }
                    else {
                        LocalStorageManager.setUser(response.data.userData);
                        LocalStorageManager.setRoutes(response.data.routes);
                        dispatch(AuthActions.authenticationSuccessul({ success: true, data: response.data.userData, successMessage: response.data.message, allowedRoutes: response.data.routes }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(AuthActions.authenticationFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                });
        }
    }

    static logoutAdmin(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(AuthActions.logoutAdmin(data));
                axios.post(`${Path.LOGOUT}`, {
                    _id: user._id
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(AuthActions.logoutAdminFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            LocalStorageManager.clearLocalStorage();
                            dispatch(AuthActions.logoutAdminSuccessul({ success: true, message: response.data.message }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(AuthActions.logoutAdminFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default AuthMiddleware;