import SectionAction from '../actions/sectionActions';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'


class SectionMiddleware {
    static getSection(data) {

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(SectionAction.getSections(data));
                axios.post(`${Path.GET_ALL_SECTIONS}`, {
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);


                        if (!response.data.success) {
                            dispatch(SectionAction.getSectionsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(SectionAction.getSectionsSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(SectionAction.getSectionsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static deleteSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(SectionAction.deleteSections(data));
                return axios.post(`${Path.DELETE_SECTION}`, {
                    sectionId: data.sectionId,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(SectionAction.deleteSectionsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(SectionAction.deleteSectionsSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data.success
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(SectionAction.deleteSectionsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static removeSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(SectionAction.removeSectionLesson(data));
                return axios.post(`${Path.REMOVE_SECTION_LESSON}`, {
                    data: {
                        sectionId: data.sectionId,
                        lessonId: data.lessonId,
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(SectionAction.removeSectionLessonFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(SectionAction.removeSectionLessonSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(SectionAction.removeSectionLessonFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static addSectionLesson(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(SectionAction.addSectionLesson(data));
                return axios.post(`${Path.ADD_SECTION_LESSON}`, {
                    data: {
                        sectionId: data.sectionId,
                        lessonId: data.lessonId,
                        sequence:data.sequence
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(SectionAction.addSectionLessonFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(SectionAction.addSectionLessonSuccessul({ success: true, data: response.data.data, _id: data.sectionId }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(SectionAction.addSectionLessonFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static addSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(SectionAction.addSections(data));
                return axios.post(`${Path.ADD_SECTION}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {

                        if (!response.data.success) {
                            dispatch(SectionAction.addSectionsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(SectionAction.addSectionsSuccessul({ success: true, data: response.data.data }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(SectionAction.addSectionsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static editSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(SectionAction.editSections(data));
                return axios.post(`${Path.EDIT_SECTION}`, {
                    sectionId: data.sectionId,
                    data: { ...data.data },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);
                        
                        if (!response.data.success) {
                            dispatch(SectionAction.editSectionsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(SectionAction.editSectionsSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(SectionAction.editSectionsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default SectionMiddleware;