class StudentActions {

    static SEARCH_STUDENT = "SEARCH_STUDENT";
    static SEARCH_STUDENT_SUCCESSFUL = "SEARCH_STUDENT_SUCCESSFUL";
    static SEARCH_STUDENT_FAILED = "SEARCH_STUDENT_FAILED";

    static EDIT_STUDENT = "EDIT_STUDENT";
    static EDIT_STUDENT_SUCCESSFUL = "EDIT_STUDENT_SUCCESSFUL";
    static EDIT_STUDENT_FAILED = "EDIT_STUDENT_FAILED";

    static EDIT_STUDENT = "EDIT_STUDENT";
    static EDIT_STUDENT_SUCCESSFUL = "EDIT_STUDENT_SUCCESSFUL";
    static EDIT_STUDENT_FAILED = "EDIT_STUDENT_FAILED";

    static STATUS_STUDENT_UPDATE = "STATUS_STUDENT_UPDATE";
    static STATUS_STUDENT_UPDATE_SUCCESSFUL = "STATUS_STUDENT_UPDATE_SUCCESSFUL";
    static STATUS_STUDENT_UPDATE_FAILED = "STATUS_STUDENT_UPDATE_FAILED";

    static SEARCH_STUDENT_FOR_DELETE = "SEARCH_STUDENT_FOR_DELETE";
    static SEARCH_STUDENT_FOR_DELETE_SUCCESSFUL = "SEARCH_STUDENT_FOR_DELETE_SUCCESSFUL";
    static SEARCH_STUDENT_FOR_DELETE_FAILED = "SEARCH_STUDENT_FOR_DELETE_FAILED";

    static DELETE_STUDENT = "DELETE_STUDENT";
    static DELETE_STUDENT_SUCCESSFUL = "DELETE_STUDENT_SUCCESSFUL";
    static DELETE_STUDENT_FAILED = "DELETE_STUDENT_FAILED";

    static searchStudent(data) {
        return {
            type: this.SEARCH_STUDENT,
            data: data
        }
    }
    static searchStudentSuccessul(data) {
        return {
            type: this.SEARCH_STUDENT_SUCCESSFUL,
            data: data
        }
    }
    static searchStudentFailed(data) {
        return {
            type: this.SEARCH_STUDENT_FAILED,
            data: data
        }
    }

    static editStudent(data) {
        return {
            type: this.EDIT_STUDENT,
            data: data
        }
    }
    static editStudentSuccessul(data) {
        return {
            type: this.EDIT_STUDENT_SUCCESSFUL,
            data: data
        }
    }
    static editStudentFailed(data) {
        return {
            type: this.EDIT_STUDENT_FAILED,
            data: data
        }
    }

    static statusStudentUpdate(data) {
        return {
            type: this.STATUS_STUDENT_UPDATE,
            data: data
        }
    }
    static statusStudentUpdateSuccessul(data) {
        return {
            type: this.STATUS_STUDENT_UPDATE_SUCCESSFUL,
            data: data.data
        }
    }
    static statusStudentUpdateFailed(data) {
        return {
            type: this.STATUS_STUDENT_UPDATE_FAILED,
            data: data
        }
    }

    static searchStudentForDelete(data) {
        return {
            type: this.SEARCH_STUDENT_FOR_DELETE,
            data: data
        }
    }

    static searchStudentForDeleteSuccess(data) {
        return {
            type: this.SEARCH_STUDENT_FOR_DELETE_SUCCESSFUL,
            data: data
        }
    }

    static searchStudentForDeleteFailed(data) {
        return {
            type: this.SEARCH_STUDENT_FOR_DELETE_FAILED,
            data: data
        }
    }

    static deleteStudent(data) {
        return {
            type: this.DELETE_STUDENT,
            data: data
        }
    }
    static deleteStudentSuccess(data) {
        return {
            type: this.DELETE_STUDENT_SUCCESSFUL,
            data: data
        }
    }
    static deleteStudentFailed(data) {
        return {
            type: this.DELETE_STUDENT_FAILED,
            data: data
        }
    }

}

export default StudentActions;