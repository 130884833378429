class BatchConfig {

    static GET_BATCH_CONFIG = "GET_BATCH_CONFIG";
    static GET_BATCH_CONFIG_SUCCESSFUL = "GET_BATCH_CONFIG_SUCCESSFUL";
    static GET_BATCH_CONFIG_FAILED = "GET_BATCH_CONFIG_FAILED";

    static DELETE_BATCH_CONFIG = "DELETE_BATCH_CONFIG";
    static DELETE_BATCH_CONFIG_SUCCESSFUL = "DELETE_BATCH_CONFIG_SUCCESSFUL";
    static DELETE_BATCH_CONFIG_FAILED = "DELETE_BATCH_CONFIG_FAILED";

    static EDIT_BATCH_CONFIG = "EDIT_BATCH_CONFIG";
    static EDIT_BATCH_CONFIG_SUCCESSFUL = "EDIT_BATCH_CONFIG_SUCCESSFUL";
    static EDIT_BATCH_CONFIG_FAILED = "EDIT_BATCH_CONFIG_FAILED";

    static ADD_BATCH_CONFIG = "ADD_BATCH_CONFIG";
    static ADD_BATCH_CONFIG_SUCCESSFUL = "ADD_BATCH_CONFIG_SUCCESSFUL";
    static ADD_BATCH_CONFIG_FAILED = "ADD_BATCH_CONFIG_FAILED";

    static ADD_INSTRUCTIONS = "ADD_INSTRUCTIONS";
    static ADD_INSTRUCTIONS_SUCCESSFUL = "ADD_INSTRUCTIONS_SUCCESSFUL";
    static ADD_INSTRUCTIONS_FAILED = "ADD_INSTRUCTIONS_FAILED ";

    static REMOVE_INSTRUCTIONS = "REMOVE_INSTRUCTIONS";
    static REMOVE_INSTRUCTIONS_SUCCESSFUL = "REMOVE_INSTRUCTIONS_SUCCESSFUL";
    static REMOVE_INSTRUCTIONS_FAILED = "REMOVE_INSTRUCTIONS_FAILED";
    
    
    static removeInstructions(data) {
        return {
            type: this.REMOVE_INSTRUCTIONS,
            data: data
        }
    }
    static removeInstructionsSuccessul(data) {
        return {
            type: this.REMOVE_INSTRUCTIONS_SUCCESSFUL,
            data: data,
            index: data.index
        }
    }
    static removeInstructionsFailed(data) {
        return {
            type: this.REMOVE_INSTRUCTIONS_FAILED,
            data: data
        }
    }

    static addInstructions(data) {
        return {
            type: this.ADD_INSTRUCTIONS,
            data: data
        }
    }
    static addInstructionsSuccessful(data) {
        return {
            type: this.ADD_INSTRUCTIONS_SUCCESSFUL,
            data: data.data,
            index: data.index,
        }
    }
    static addInstructionsFailed(data) {
        return {
            type: this.ADD_INSTRUCTIONS_FAILED,
            data: data
        }
    }

    static getBatchConfig(data) {
        return {
            type: this.GET_BATCH_CONFIG,
            data: data
        }
    }
    static getBatchConfigSuccessul(data) {
        return {
            type: this.GET_BATCH_CONFIG_SUCCESSFUL,
            data: data.data
        }
    }
    static getBatchConfigFailed(data) {
        return {
            type: this.GET_BATCH_CONFIG_FAILED,
            data: data
        }
    }


    static deleteBatchConfig(data) {
        return {
            type: this.DELETE_BATCH_CONFIG,
            data: data
        }
    }
    static deleteBatchConfigSuccessul(data) {
        return {
            type: this.DELETE_BATCH_CONFIG_SUCCESSFUL,
            data: data,
            index: data.index
        }
    }
    static deleteBatchConfigFailed(data) {
        return {
            type: this.DELETE_BATCH_CONFIG_FAILED,
            data: data
        }
    }

    static addBatchConfig(data) {
        return {
            type: this.ADD_BATCH_CONFIG,
            data: data
        }
    }
    static addBatchConfigSuccessul(data) {
        return {
            type: this.ADD_BATCH_CONFIG_SUCCESSFUL,
            data: data.data,
            success: data.success
        }
    }
    static addBatchConfigFailed(data) {
        return {
            type: this.ADD_BATCH_CONFIG_FAILED,
            data: data
        }
    }

    static editBatchConfig(data) {
        return {
            type: this.EDIT_BATCH_CONFIG,
            data: data
        }
    }
    static editBatchConfigSuccessul(data) {
        return {
            type: this.EDIT_BATCH_CONFIG_SUCCESSFUL,
            data: data.data,
            index: data.index,
            success: data.success
        }
    }
    static editBatchConfigFailed(data) {
        return {
            type: this.EDIT_BATCH_CONFIG_FAILED,
            data: data
        }
    }

}

export default BatchConfig