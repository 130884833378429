import React, { Component } from 'react'
import LocalStorageManager from "../../services/localStorageManager";
import Path from '../../config/path';
import Axios from "axios";
import { Loader } from '../StudentManagment/helper';

class Status extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedulers:[],
            status:null,
            loading:false,
            err:null,
            schedulerType: this.props.schedulerType
        }
    }
  componentWillReceiveProps(nextProps){
    this.getAllScheduler(nextProps.schedulerType)
  }
    getAllScheduler = async (schedulerType) => {
        this.setState({
            loading:true
        })
        var user = LocalStorageManager.getUser();
        var databaseToken = user.databaseToken
        try {
            let res = await Axios.post(Path.GET_SCHEDULER, { databaseToken, schedulerType:schedulerType })
            console.log(res)
            this.setState({
                schedulers: res.data.data,
                loading:false
            })
        }
        catch (err) {
            console.log(err)
            this.setState({
                loading:false,
                err:err.message
            })
        }
    }
    viewStatus = async (scheduler)=>{
        this.setState({
            loading:true
        })
        console.log(scheduler._id)
        var user = LocalStorageManager.getUser();
        var databaseToken = user.databaseToken
        try {
            let res = await Axios.post(Path.GET_STATUS, { databaseToken, schedulerId:scheduler._id })
            console.log(res)
            scheduler.status=res.data.data.status
            this.setState({
                loading:false
            })
        }
        catch (err) {
            console.log(err)
            this.setState({
                loading:false,
                err:err.message
            })
        }
    }
    tableSection = () => {
        return this.state.schedulers.length>0 && (
            <div className="table-wrapper">
                <br />
                <br />
                <br />
                <table className="status-table">
                    <tbody>
                        <tr>
                            <th className="name">Name</th>
                            <th>Batch Size</th>
                            <th>Minutes to wait</th>
                            <th>Completed</th>
                            <th>sent Status</th>
                            <th>View Status</th>
                        </tr>
    
                        {this.state.schedulers.map((scheduler) => {
                            return (
                                <tr key={scheduler._id}>
                                    <td className="name">{scheduler.name}</td>
                                    <td>{scheduler.batchSize}</td>
                                    <td>{scheduler.minutesToWait}</td>
                                    <td>{scheduler.completed?"true":"false"}</td>
                                    <td>{!scheduler.status && !this.state.loading? "--" : scheduler.status}</td>
                                    <td><button type="button" className="my-btn-3" onClick={() => this.viewStatus(scheduler)}>view</button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
    componentDidMount(){
        this.getAllScheduler(this.props.schedulerType)
      }
    render() {
        const {loading, err} = this.state
        return (
            <div>
                {loading ? <Loader/>:''}
                {err && <p style={{color:'red', textAlign:"center"}}>{err}</p>}
                {this.tableSection()}
            </div>
        );
    }
}

export default Status;