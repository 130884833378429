class CenterAction {

    static GET_CENTER = "GET_CENTER";
    static GET_CENTER_SUCCESSFUL = "GET_CENTER_SUCCESSFUL";
    static GET_CENTER_FAILED = "GET_CENTER_FAILED";

    static DELETE_CENTER = "DELETE_CENTER";
    static DELETE_CENTER_SUCCESSFUL = "DELETE_CENTER_SUCCESSFUL";
    static DELETE_CENTER_FAILED = "DELETE_CENTER_FAILED";

    static EDIT_CENTER = "EDIT_CENTER";
    static EDIT_CENTER_SUCCESSFUL = "EDIT_CENTER_SUCCESSFUL";
    static EDIT_CENTER_FAILED = "EDIT_CENTER_FAILED";

    static ADD_CENTER = "ADD_CENTER";
    static ADD_CENTER_SUCCESSFUL = "ADD_CENTER_SUCCESSFUL";
    static ADD_CENTER_FAILED = "ADD_CENTER_FAILED";


    static getCenter(data) {
        return {
            type: this.GET_CENTER,
            data: data
        }
    }
    static getCenterSuccessul(data) {
        return {
            type: this.GET_CENTER_SUCCESSFUL,
            data: data.data
        }
    }
    static getCenterFailed(data) {
        return {
            type: this.GET_CENTER_FAILED,
            data: data
        }
    }


    static deleteCenter(data) {
        return {
            type: this.DELETE_CENTER,
            data: data
        }
    }
    static deleteCenterSuccessul(data) {
        return {
            type: this.DELETE_CENTER_SUCCESSFUL,
            data: data,
            index: data.index
        }
    }
    static deleteCenterFailed(data) {
        return {
            type: this.DELETE_CENTER_FAILED,
            data: data
        }
    }

    static addCenter(data) {
        return {
            type: this.ADD_CENTER,
            data: data
        }
    }
    static addCenterSuccessul(data) {
        return {
            type: this.ADD_CENTER_SUCCESSFUL,
            data: data.data,
            success:data.success
        }
    }
    static addCenterFailed(data) {
        return {
            type: this.ADD_CENTER_FAILED,
            data: data
        }
    }

    static editCenter(data) {
        return {
            type: this.EDIT_CENTER,
            data: data
        }
    }
    static editCenterSuccessul(data) {
        return {
            type: this.EDIT_CENTER_SUCCESSFUL,
            data: data.data,
            index: data.index,
            success:data.success
        }
    }
    static editCenterFailed(data) {
        return {
            type: this.EDIT_CENTER_FAILED,
            data: data
        }
    }




}

export default CenterAction