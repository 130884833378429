/* eslint-disable*/
import React, { Component } from "react";
import { Input } from "../Input/input";
import { Loader } from "../StudentManagment/helper";
import { connect } from "react-redux";
import { validateForm } from "./helper";
import BatchMiddleware from "../../store/middleware/batchMidleware";

class AddBatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {}
            },
            errorMessage: "",
            city: "",
            active: false,
            name: "",
            edit: false,
            isLoading: false,
            batches: [],
            isRestricted: false,
            isEntryTestMandatory: false
        };
    }
    componentWillReceiveProps(nextProps) {
        console.log("component will recieve the props ", nextProps);
        this.setState({
            batches: nextProps.batches ? [...nextProps.batches] : [],
            errorMessage: nextProps.errorMessage,
            edit: nextProps.edit
        });
    }
    componentDidMount() {
        this.props.getAll();
    }
    check = data => {
        let search = this.props.batches ? this.props.batches.filter(rec => rec.name == data.name && rec.city == data.city) : [];
        return search;
    };
    resetState = () => {
        this.setState({
            success: true,
            name: "",
            city: "",
            edit: false,
            isEntryTestMandatory: false,
            isRestricted: false,
            successMessage: "Operation  Successfully Executed"
        });
    }
    Submit = ev => {
        ev.preventDefault();
        const {
            edit,
            name,
            index,
            active,
            city,
            _id,
            isEntryTestMandatory,
            isRestricted
        } = this.state;
        var err = validateForm("all", { name, city });
        console.log(err);
        if (err.hasError) {
            this.setState({ errors: err })
            return;
        }
        let a = this.check({ name, city });
        if (a.length > 0) {
            this.setState({
                errorMessage: "This Batch is already present in data ",
                successMessage: "",
                errors: {
                    hasError: false,
                    errorsObj: {}
                }
            });
            return;
        }
        this.setState({
            errors: {
                hasError: false,
                errorsObj: {}
            }
        })
        if (edit) {
            this.props.editBatch({
                index,
                active,
                name,
                city,
                _id,
                isEntryTestMandatory,
                isRestricted
            })
                .then(success => {
                    if (success) {
                        this.resetState()
                    }
                })
        }
        else {
            this.props.addBatch({ name, city, isEntryTestMandatory, isRestricted })
                .then(success => {
                    if (success) {
                        this.resetState()
                    }
                })
        }
    };

    edit = (batch, index) => {
        console.log("index", index);
        this.setState({
            name: batch.name,
            city: batch.city,
            _id: batch._id,
            edit: true,
            index,
            isRestricted: batch.isRestricted,
            isEntryTestMandatory: batch.isEntryTestMandatory,
            errors: {
                hasError: false,
                errorsObj: {}
            },
            errorMessage: "",
            successMessage: ""

        });
    };

    view = (batch, index) => {
        this.props.history.push({
            pathname: "/add-course-to-batch",
            state: { batch, index }
        });
    };
    batchRegistrationStatus = (batch) => {
        if (batch.isRegistrationActive) {
            return <td>Yes</td>
        }
        return <td>No</td>

    }
    handleOpenCloseRegistration = (batch, index) => {
        if (batch.isRegistrationActive) {
            return (<td>
                <button
                    type="button"
                    className="my-btn-3"
                    onClick={() =>
                        this.props.closeBatchReg(batch, index)
                    }
                >
                    Close
                </button>
            </td>)
        }
        if (batch.courses.length == 0) {
            return <td>
                <button
                    type="button"
                    className="my-btn-3"
                    disabled
                    style={{cursor:"no-drop"}}
                    onClick={() =>
                        this.props.startBatchReg(batch, index)
                    }
                >
                    Start
            </button>
            </td>
        }
        return <td>
            <button
                type="button"
                className="my-btn-3"
                onClick={() =>
                    this.props.startBatchReg(batch, index)
                }
            >
                Start
            </button>
        </td>

    }
    handleBatchRegistrationStatus = (batch, index) => {
        if (!batch.regEndDate) {
            return this.handleOpenCloseRegistration(batch, index)
        }
        return <td>registartions are closed</td>

    }
    handleIsBatchCompleted = (batch, index) => {
        if (batch.isBatchCompleted) {
            return <td>
                batch completed
                    </td>
        }
        return (<td>
            <button
                type="button"
                className="my-btn-3"
                onClick={() =>
                    this.props.completeBatch(batch, index)
                }
            >
                End Batch
            </button>
        </td>)

    }
    handleBatchCompletion = (batch, index) => {
        if (batch.regEndDate) {
            return this.handleIsBatchCompleted(batch, index)
        } return (<td>...</td>)

    }
    tableSection = (batches) => {
        return !!batches.length && (
            <div className="table-wrapper">
                <br />
                <br />
                <br />
                <table className="table  my-table">
                    <tbody>
                        <tr>
                            <th>Batch Name</th>
                            <th>City</th>
                            <th>Registration active</th>
                            <th>Open/Close Registration</th>
                            <th>Change Batch Status </th>
                            <th>View</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        {this.props.batches.map((batch, index) => {
                            return (
                                <tr key={index}>
                                    <td>{batch.name}</td>
                                    <td>{batch.city}</td>
                                    {this.batchRegistrationStatus(batch, index)}
                                    {this.handleBatchRegistrationStatus(batch, index)}
                                    {this.handleBatchCompletion(batch, index)}
                                    <td>
                                        <button
                                            type="button"
                                            className="my-btn-3"
                                            onClick={() => this.view(batch, index)}
                                        >
                                            View
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="my-btn-3"
                                            onClick={() => this.edit(batch, index)}
                                        >
                                            Edit
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="my-btn-3"
                                            onClick={() =>
                                                window.confirm(
                                                    "Are You Sure You Want To Delete This"
                                                ) && this.props.deleteBatch(batch, index)
                                            }
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
    render() {
        const {
            isLoading,
            errors,
            batches,
            name,
            city,
            edit,
            errorMessage,
            success,
            successMessage,
            isRestricted,
            isEntryTestMandatory
        } = this.state;
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <div className="form-container  align-items-center">
                        <div className="form-wrapper">
                            <form onSubmit={ev => this.Submit(ev)} method="post">
                                <h3>Add a Batch</h3>
                                {errorMessage && <p className="error">{errorMessage}</p>}
                                {success && <p className="success">{successMessage}</p>}
                                <Input
                                    label="Batch Name"
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={name}
                                    onChange={ev => {
                                        return this.setState({ [ev.target.name]: ev.target.value })
                                    }
                                    }
                                    errors={errors}
                                />
                                <Input
                                    label="City Name"
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={city}
                                    onChange={ev =>
                                        this.setState({ [ev.target.name]: ev.target.value })
                                    }
                                    errors={errors}
                                />
                                <div className="radio-container mt-3 " style={{ display: "flex", justifyContent: "flex-start", alignContent: "flex-start", justifySelf: "flex-start", width: "40vw", flexDirection: "row" }}>
                                    <div className="radio-wrapper radio-wrapper-student-form flexer mt-0 mb-3  w-50" >
                                        <label htmlFor="isRestricted " className="">
                                            <span className="label mt-1">Is Ristricted Batch</span>
                                            <input type="checkbox"
                                                checked={isRestricted}
                                                name="isRestricted"
                                                id="isRestricted"
                                                value={isRestricted}
                                                // checked={gender === "male"}
                                                // disabled={disabled}
                                                onChange={ev => this.setState((ps) => ({ isRestricted: !ps.isRestricted }))}
                                            />
                                        </label>
                                        <label htmlFor="isEntryTestMandatory">
                                            <span className="label mt-1">Is Entry Test Mandatory</span>
                                            <input type="checkbox"
                                                checked={isEntryTestMandatory}
                                                name="isEntryTestMandatory"
                                                id="isEntryTestMandatory"
                                                value={isEntryTestMandatory}
                                                // checked={gender === "female"}
                                                // disabled={disabled}
                                                onChange={ev => this.setState((ps) => ({ isEntryTestMandatory: !ps.isEntryTestMandatory }))}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <button className="my-btn-2" type="submit">
                                    {edit ? "Update Batch" : "Add Batch"}
                                </button>
                                {edit && (
                                    <button
                                        className="my-btn-2"
                                        type="button"
                                        onClick={() =>
                                            this.setState({
                                                _id: "",
                                                errors: {
                                                    hasError: false,
                                                    errorsObj: {},
                                                    simpleError: ""
                                                },
                                                edit: false,
                                                index: null
                                            })
                                        }
                                    >
                                        Cancel Update
                                    </button>
                                )}
                            </form>
                        </div>
                        {this.tableSection(batches)}
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    console.log("map state to props batches ", state.batchReducer.batches);
    return {
        isLoading: state.batchReducer.isLoading,
        isError: state.batchReducer.isError,
        errorMessage: state.batchReducer.errorMessage,
        success: state.batchReducer.success,
        batches: state.batchReducer.batches
    };
}
function mapDispatchToProps(dispatch) {
    return {
        addBatch: data => {
            return dispatch(BatchMiddleware.addBatch(data));
        },
        getAll: data => {
            return dispatch(BatchMiddleware.searchBatches(data));
        },
        deleteBatch: (data, index) => {
            return dispatch(BatchMiddleware.deleteBatch(data, index));
        },
        editBatch: (data, index) => {
            return dispatch(BatchMiddleware.editBatch(data, index));
        },
        startBatchReg: (data, index) => {
            return dispatch(BatchMiddleware.startBatchReg(data, index));
        },
        closeBatchReg: (data, index) => {
            return dispatch(BatchMiddleware.closeBatchReg(data, index));
        },
        completeBatch: (data, index) => {
            return dispatch(BatchMiddleware.completeBatch(data, index));
        }
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBatch);
