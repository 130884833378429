class TOPICActions {


    static DELETE_TOPIC = "DELETE_TOPIC";
    static DELETE_TOPIC_SUCCESSFUL = "DELETE_TOPIC_SUCCESSFUL";
    static DELETE_TOPIC_FAILED = "DELETE_TOPIC_FAILED";


    static EDIT_TOPIC = "EDIT_TOPIC";
    static EDIT_TOPIC_SUCCESSFUL = "EDIT_TOPIC_SUCCESSFUL";
    static EDIT_TOPIC_FAILED = "EDIT_TOPIC_FAILED";

    static ADD_TOPIC = "ADD_TOPIC";
    static ADD_TOPIC_SUCCESSFUL = "ADD_TOPIC_SUCCESSFUL";
    static ADD_TOPIC_FAILED = "ADD_TOPIC_FAILED";

    static deleteTopic(data) {
        return {
            type: this.DELETE_TOPIC,
            data: data
        }
    }
    static deleteTopicSuccessul(data) {
        return {
            type: this.DELETE_TOPIC_SUCCESSFUL,
            data: data,
        }
    }
    static deleteTopicFailed(data) {
        return {
            type: this.DELETE_TOPIC_FAILED,
            data: data
        }
    }

    static editTopic(data) {
        return {
            type: this.EDIT_TOPIC,
            data: data,
        }
    }
    static editTopicSuccessul(data) {
        return {
            type: this.EDIT_TOPIC_SUCCESSFUL,
            data: data.data,
            success:data.success            
        }
    }
    static editTopicFailed(data) {
        return {
            type: this.EDIT_TOPIC_FAILED,
            data: data
        }
    }


    static addTopic(data) {
        return {
            type: this.ADD_TOPIC,
            data: data
        }
    }
    static addTopicSuccessul(data) {
        return {
            type: this.ADD_TOPIC_SUCCESSFUL,
            data: data.data,
            success:data.success            
        }
    }
    static addTopicFailed(data) {
        return {
            type: this.ADD_TOPIC_FAILED,
            data: data
        }
    }
}

export default TOPICActions