import axios from 'axios';
import Path from '../../config/path';
import AuthActions from '../actions/authActions';
import LocalStorageManager from '../../services/localStorageManager'
import CourseActions from '../actions/courseActions';


class CourseMiddleware {
    static getCourses(data) {
        console.log("searchCenters");

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            } else {
                dispatch(CourseActions.getCourses(data));
                axios.post(`${Path.GET_COURSES}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CourseActions.getCoursesFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.getCoursesSuccessfull({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.getCoursesFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static addCourses(data) {
        return dispatch => {

            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: 'Authentication Expired, Login Again' }));

            } else {

                console.log(data)
                dispatch(CourseActions.addCourses(data));

                axios.post(`${Path.ADD_COURSE}`, {
                    data,
                    databaseToken: user.databaseToken
                }).then(response => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CourseActions.addCoursesFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.addCoursesSuccessfull({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.addCoursesFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }

    }




    static deleteCourses(data) {
        console.log(data) // course id and index is coming here
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CourseActions.deleteCourses(data));



                return axios.post(`${Path.DELETE_COURSE}`, {
                    courseId: data.courseId,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(CourseActions.deleteCourseFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.deleteCourseSuccessful({ success: true, data: response.data.data, index: data.index }));
                        }

                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.deleteCourseFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static addCourseContent(data) {
        return async dispatch => {
            console.log('Middleware add course content', data);
            const user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            } else {
                axios.post(`${Path.ADD_COURSE_CONTENT}`, {
                    data: {
                        title: data.title,
                        courseId: data.courseId
                    },
                    databaseToken: user.databaseToken
                }).then(res => {
                    console.log(res);

                    if (!res.success) {
                        dispatch(CourseActions.addCourseSectionFailed({ success: false, errorMessage: res.data.message }))
                    }

                    dispatch(CourseActions.addCourseContentSuccessul({ success: true, data: res.data.data }))
                }).catch(err => {
                    dispatch(CourseActions.addCourseContentFailed({ success: false, errorMessage: "Something went wrong please try again" }));
                })
            }
        }
    }


    static editCourse(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CourseActions.editCourse(data));
                console.log(data);
                return axios.post(`${Path.EDIT_COURSE}`, {
                    courseId: data._id,
                    data: {
                        name: data.name,
                        initial: data.initial,
                        shortDescription: data.shortDescription,
                        longDescription: data.longDescription
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CourseActions.editCourseFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.editCourseSuccessful({ success: true, data: response.data.data, index: data.index }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.editCourseFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }






    static deleteCourseContent(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CourseActions.deleteCourseContents(data));
                return axios.post(`${Path.DELETE_COURSE_CONTENT}`, {
                    courseContentId: data.courseContentId,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CourseActions.deleteCourseContentsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.deleteCourseContentsSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.deleteCourseContentsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static addCourseSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CourseActions.addCourseSection(data));
                return axios.post(`${Path.ADD_COURSE_SECTION}`, {
                    data: {
                        sectionId: data.sectionId,
                        courseContentId: data.courseContentId,
                        sequence: data.sequence
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CourseActions.addCourseSectionFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.addCourseSectionSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.addCourseSectionFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static removeSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CourseActions.removeCourseSection(data));
                return axios.post(`${Path.REMOVE_COURSE_SECTION}`, {
                    data: {
                        sectionId: data.sectionId,
                        courseContentId: data.courseContentId,
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CourseActions.removeCourseSectionFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.removeCourseSectionSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.removeCourseSectionFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static getSection(data) {

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CourseActions.getSections(data));
                axios.post(`${Path.GET_ALL_COURSE_SECTIONS}`, {
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);


                        if (!response.data.success) {
                            dispatch(CourseActions.getSectionsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.getSectionsSuccessfull({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.getSectionsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static getCoursesByBatchId(data) {
        console.log("searchCourses by batch ");

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            } else {
                dispatch(CourseActions.getCoursesByBatchId(data));
                axios.post(`${Path.GET_COURSES_BY_BATCHID}`, {
                    batchId: data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CourseActions.getCoursesByBatchIdFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(CourseActions.getCoursesByBatchIdSuccessfull({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CourseActions.getCoursesFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



}

export default CourseMiddleware;