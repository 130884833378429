/* eslint-disable*/
import React, { Component } from 'react';
import { Input } from "../Input/input"
import { Loader } from "../StudentManagment/helper"
import { connect } from "react-redux"
import { validateForm } from "./helper"
import CenterMiddleware from '../../store/middleware/centerMiddleware';

class AddCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
            },
            errorMessage: "",
            centers: [],
            name: "",
            campus: "",
            city: "",
            location: "",
            edit: false,
            isLoading: false
        }
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            centers: nextProps.centers ? [...nextProps.centers] : [],
            errorMessage: nextProps.errorMessage,
            edit: nextProps.edit
        })
    }

    componentDidMount() {
        this.props.getCenters();
    }



    Submit = (ev) => {
        ev.preventDefault()
        const { edit, name, city, campus, location, _id, index } = this.state;
        console.log({_id});
        
        var err = validateForm("all", { name, city, campus, location });
        console.log(err);
        if (err.hasError) {
            this.setState({ errors: err })
            return;
        }
        this.setState({
            errors: {
                hasError: false,
                errorsObj: {}
            }
        })


        edit ? this.props.editCenter({ name, city, campus, location, _id, index }).then((success) => {
            if (success) {
                this.setState({
                    success: true,
                    name: "",
                    campus: "",
                    city: "",
                    location: "",
                    edit: false,
                    successMessage: "Updated Successfully"
                })
            }
        }) : this.props.addCenter({ name, city, campus, location }).then((success) => {
            if (success) {
            this.setState({
                success: true,
                name: "",
                campus: "",
                city: "",
                location: "",
                successMessage: "Created Successfully"
            })
        }
        })
    }
    edit = (center, index) => {
        this.setState({
            ...center,
            location: center.location ? center.location : "",
            edit: true,
            index,
            errors: {
                hasError: false,
                errorsObj: {}
            }
        })
    }

    render() {
        const { isLoading, errors, centers, name, campus, city, location, edit, errorMessage, success, successMessage } = this.state;
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <div className="form-container  align-items-center">
                        <div className="form-wrapper">
                            <form onSubmit={(ev) => this.Submit(ev)} method="post">
                                <h3>Add a Center</h3>
                                {errorMessage && <p className="error">{errorMessage}</p>}
                                {success && <p className="success">{successMessage}</p>}
                                <Input
                                    label="Center Name"
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={name}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />

                                <Input
                                    label="Campus"
                                    type="text"
                                    name="campus"
                                    id="campus"
                                    value={campus}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="City"
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={city}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="Location"
                                    type="text"
                                    name="location"
                                    id="location"
                                    value={location}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <button className="my-btn-2" type="submit">{edit ? "Update Center" : "Add Center"}</button>
                                {edit && <button className="my-btn-2" type="button"
                                    onClick={() => this.setState({
                                        _id: "",
                                        name: "",
                                        city: "",
                                        campus: "",
                                        errors: {
                                            hasError: false,
                                            errorsObj: {},
                                            simpleError: "",
                                        },
                                        edit: false,
                                        index: null
                                    })}
                                >Cancel Update</button>}


                            </form>
                        </div>
                        {!!centers.length && <div className="table-wrapper" >
                            <br /><br /><br />
                            <table className="table  my-table" >
                                <tbody>
                                    <tr><th >Center Name</th><th>Campus</th><th>City</th><th>Location</th><th>Edit</th><th>Delete</th></tr>

                                    {centers.map((center, index) => {
                                        return (
                                            <tr key={index} ><td>{center.name}</td><td>{center.campus}</td><td>{center.city}</td><td><a href={center.location} target="_blank">View Location</a></td>
                                                <td>
                                                    <button type="button" className="my-btn-3" onClick={() => this.edit(center, index)} >
                                                        Edit
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="my-btn-3" onClick={() => window.confirm("Are You Sure You Want To Delete This") && this.props.deleteCenter({ _id: center._id, index })} >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.centerReducer.isLoading,
        isError: state.centerReducer.isError,
        errorMessage: state.centerReducer.errorMessage,
        success: state.centerReducer.success,
        centers: state.centerReducer.centers,
        edit: state.centerReducer.edit,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getCenters: data => {
            dispatch(CenterMiddleware.searchCenters(data))
        },
        deleteCenter: data => {
            dispatch(CenterMiddleware.deleteCenter(data))
        },
        editCenter: data => {
            return dispatch(CenterMiddleware.editCenter(data))
        },
        addCenter: data => {
            return dispatch(CenterMiddleware.addCenter(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCenter);