import React, { Component } from 'react';
import { Input, Select } from "../Input/input"
import { Loader } from "../StudentManagment/helper"
import { connect } from "react-redux"
import timeSlotMiddleware from '../../store/middleware/timeSlotMiddleware';
import CenterMiddleware from '../../store/middleware/centerMiddleware';
import CourseMiddleware from '../../store/middleware/courseMiddleware';
import { validateForm } from './helper';
import BatchMiddleware from '../../store/middleware/batchMidleware';
import QuaterMiddleware from '../../store/middleware/quaterMiddleware';

class TimeSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            name: "",
            totalSeats: "",
            year: "",
            month: "",
            quarter: "",
            time: "",
            day: "",
            course: "",
            batch: "",
            centerName: "",
            gender: "",
            feeDeadline: 0,
            feeDeadlineDate: "",
            quarterFee: "",
            centers: [],
            courses: [],
            isLoading: false,
            filteredCenters: [],
            selectedBatch: "",
            quarters: [],
            BatchQuarter: "",
            currentCourse: "",
            timeslotStatus:false
        }
    }

    componentDidMount() {
        this.props.getBatches()
        this.props.getCenters();
        this.props.getCourses();
    }




    componentWillReceiveProps(nextProps) {
        const { edit } = this.state;
        if (nextProps.location.state && !edit) {
            this.setState({
                centers: nextProps.centers ? [...nextProps.centers] : [],
                // courses: nextProps.courses ? [...nextProps.courses] : [],
                batches: nextProps.batches ? [...nextProps.batches] : [],
                quarters: nextProps.quarters ? [...nextProps.quarters] : [],
                ...nextProps.location.state,
                errorMessage: nextProps.errorMessage,
                centerName: `${nextProps.location.state.name}!@#${nextProps.location.state.campus}!@#${nextProps.location.state.city}!@#${nextProps.location.state.centerId}!@#${nextProps.location.state.location}`,
            })
        } else {
            this.setState({
                centers: nextProps.centers ? [...nextProps.centers] : [],
                // courses: nextProps.courses ? [...nextProps.courses] : [],
                batches: nextProps.batches ? [...nextProps.batches] : [],
                quarters: nextProps.quarters ? [...nextProps.quarters] : [],
                errorMessage: nextProps.errorMessage,
            })
        }
    }




    Submit = (ev) => {
        ev.preventDefault()
        const { totalSeats, year, month, quarter, time, batch, centerName, gender, edit, course, day, _id, quarterFee, feeDeadline, 
                feeDeadlineDate, selectedBatch, currentCourse, batchQuaterId, timeslotStatus } = this.state;
        console.log(feeDeadlineDate);

        var err = validateForm("all", {
            centerName,
            year,
            gender,
            quarter,
            time,
            day,
            course,
            month,
            totalSeats,
            batch,
            quarterFee,
            feeDeadline,
            feeDeadlineDate
        })

        if (err.hasError) {
            this.setState({ errors: err })
            return;
        }
        this.setState({
            errors: {
                hasError: false,
                errorsObj: {}
            }
        })

        edit ? this.props.editTimeSlot({
            _id,
            name: centerName.split("!@#")[0],
            campus: centerName.split("!@#")[1],
            city: centerName.split("!@#")[2],
            centerId: centerName.split("!@#")[3],
            location: centerName.split("!@#")[4],
            year,
            gender,
            quarter,
            time,
            day,
            course,
            month,
            totalSeats,
            batch,
            quarterFee,
            feeDeadline,
            feeDeadlineDate,
            courseId: currentCourse._id,
            batchId: selectedBatch._id,
            batchQuaterId,
            isActive:timeslotStatus
        }).then(() => {
            this.setState({
                name: "", totalSeats: "", year: "", month: "", quarter: "", time: "", day: "",
                course: "", batch: "", centerName: "", gender: "", success: true, edit: false, successMessage: "Updated Successfully",
                quarterFee: "", feeDeadline: 0, feeDeadlineDate: "", timeslotStatus:false
            })
        }) : this.props.addTimeSlot({
            name: centerName.split("!@#")[0],
            campus: centerName.split("!@#")[1],
            city: centerName.split("!@#")[2],
            centerId: centerName.split("!@#")[3],
            location: centerName.split("!@#")[4],
            year,
            gender,
            quarter,
            time,
            day,
            course,
            month,
            totalSeats,
            batch,
            quarterFee,
            feeDeadline,
            feeDeadlineDate,
            courseId: currentCourse._id,
            batchId: selectedBatch._id,
            batchQuaterId,
            isActive:timeslotStatus
        }).then(() => {
            this.setState({
                name: "", totalSeats: "", year: "", month: "", quarter: "", time: "", day: "",
                course: "", batch: "", centerName: "", gender: "", success: true, successMessage: "Created Successfully",
                quarterFee: "", feeDeadline: 0, feeDeadlineDate: "", timeslotStatus:false
            })
        })

    }
    filterCityBasedOnBatch = (batch) => {
        const { centers, batches } = this.state
        // console.clear()
        // console.log("BATCH IN FILTER", batch)
        this.setState({ batch })
        const selectedBatch = batches.find(item => item.name === batch)
        // console.log("SELECTED", selectedBatch)
        const filteredCenters = centers.filter(center => center.city.toLowerCase() === selectedBatch.city.toLowerCase())

        // console.log("CENTERS FILTERD", filteredCenters)
        this.setState({ filteredCenters, courses: selectedBatch.courses, selectedBatch })
    }
    getQuatersbasedOnCourse = (course) => {
        const { selectedBatch, courses } = this.state

        // console.log("COURSE", course)
        this.setState({ course })
        const currentCourse = courses.find(item => item.initial === course)
        // console.log("CURRENT COURSE ", currentCourse)
        this.setState({ currentCourse })
        if(currentCourse){
            this.props.getQuarters({ courseId: currentCourse._id, batchId: selectedBatch._id })
        }

    }
    setQuarter = (quarter) => {
        const { quarters, currentCourse, selectedBatch } = this.state
        this.setState({ quarter })
        const BatchQuarter = quarters.find(item => { return item.quarterName === quarter && item.courseId._id === currentCourse._id && item.batchId._id === selectedBatch._id })
        // console.log("SELECTED QUARTER", BatchQuarter)
        this.setState({ batchQuaterId: BatchQuarter._id })
    }
    render() {
        const { errors, filteredCenters, centerName
            , batch, quarter, totalSeats, year, month,
            time, course, day, gender, edit, errorMessage,
            success, courses, successMessage, quarterFee, feeDeadlineDate, batches, quarters,
            timeslotStatus } = this.state;
        const { isLoading } = this.props
        // console.clear()
        // console.log("BATCHES ", batches)
        // console.log("CENTERS", filteredCenters)
        // console.log("COURSES", courses)
        // console.log("QUARTES", this.props.quarters)

        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <div className="form-container">
                        <div className="form-wrapper">

                            <form onSubmit={(ev) => this.Submit(ev)} method="post">
                                <h3>Schedule a Time Slot</h3>
                                {errorMessage && <p className="error">{errorMessage}</p>}
                                {success && <p className="success">{successMessage}</p>}
                                <Select
                                    label="Batches"
                                    name="batch"
                                    id="batch"
                                    value={batch}
                                    options={batches ? batches.map((batch) => (
                                        { name: batch.name }))
                                        : []}
                                    onChange={({ target: { value } }) => this.filterCityBasedOnBatch(value)}
                                    errors={errors}
                                />
                                <Select
                                    label="Center Name"
                                    type="text"
                                    name="centerName"
                                    id="centerName"
                                    value={centerName}
                                    options={
                                        filteredCenters.length ? filteredCenters.map((center, index) => {
                                            return { name: `${center.name} ${center.campus} ${center.city}`, value: `${center.name}!@#${center.campus}!@#${center.city}!@#${center._id}!@#${center.location}` }
                                        }) : []
                                    }
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Select
                                    label="Course"
                                    name="course"
                                    id="course"
                                    value={course}
                                    options={
                                        courses.length ? courses.map((course, index) => {
                                            return { name: course.name, value: course.initial }
                                        }) : []
                                    }
                                    onChange={({ target: { value } }) => this.getQuatersbasedOnCourse(value)}
                                    errors={errors}
                                />
                                <Select
                                    label="Quarter"
                                    name="quarter"
                                    id="quarter"
                                    value={quarter}
                                    options={
                                        quarters.length ? quarters.map((quarter, index) => {
                                            return { name: quarter.quarterName, value: quarter.quarterName }
                                        }) : []
                                    }
                                    onChange={({ target: { value } }) => this.setQuarter(value)}
                                    errors={errors}
                                />
                                <Input
                                    label="Year"
                                    type="text"
                                    name="year"
                                    id="year"
                                    value={year}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Select
                                    label="Month"
                                    name="month"
                                    id="month"
                                    value={month}
                                    options={
                                        [
                                            { name: "Jan", value: "Jan" },
                                            { name: "Feb", value: "Feb" },
                                            { name: "March", value: "March" },
                                            { name: "April", value: "April" },
                                            { name: "May", value: "May" },
                                            { name: "June", value: "June" },
                                            { name: "July", value: "July" },
                                            { name: "August", value: "August" },
                                            { name: "Sep", value: "Sep" },
                                            { name: "Oct", value: "Oct" },
                                            { name: "Nov", value: "Nov" },
                                            { name: "Dec", value: "Dec" },
                                        ]}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                {/* <Input
                                    label={`Batch Number`}
                                    type="text"
                                    name="batch"
                                    id="batch"
                                    value={batch}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                /> */}
                                <Select
                                    label="Day"
                                    name="day"
                                    id="day"
                                    value={day}
                                    options={

                                        [
                                            { name: "Monday", value: "Monday" },
                                            { name: "Tuesday", value: "Tuesday" },
                                            { name: "Wednesday", value: "Wednesday" },
                                            { name: "Thursday", value: "Thursday" },
                                            { name: "Friday", value: "Friday" },
                                            { name: "Saturday", value: "Saturday" },
                                            { name: "Sunday", value: "Sunday" },
                                        ]}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="Time"
                                    type="text"
                                    name="time"
                                    id="time"
                                    value={time}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />

                                <Input
                                    label="Total Seats"
                                    type="text"
                                    name="totalSeats"
                                    id="totalSeats"
                                    value={totalSeats}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="Quarter Fee"
                                    type="text"
                                    name="quarterFee"
                                    id="quarterFee"
                                    value={quarterFee}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="Fee Deadline"
                                    type="date"
                                    name="feeDeadline"
                                    id="feeDeadline"
                                    value={feeDeadlineDate}
                                    onChange={(ev) => {
                                        this.setState({ feeDeadline: new Date(ev.target.value).getTime(), feeDeadlineDate: new Date(ev.target.value).toISOString().slice(0, 10) })
                                    }}
                                    errors={errors}
                                />
                                <div className="flexer mt-3" style={{justifyContent:"space-between" ,width:"80%"}}>
                                <div className="radio-container">
                                <div className="radio-wrapper radio-wrapper-2"> 
                                    <h5 className="flexer">TimeSlot Status</h5>
                                        {
                                            errors.errorsObj["gender"] && <p className="error"  >{errors.errorsObj["gender"].message}</p>
                                        }
                                        <label htmlFor="Is Active">
                                            <span className="label">Active</span>
                                            <input type="checkbox"
                                                name="timeslotStatus"
                                                id="timeslotStatus"
                                                value={timeslotStatus}
                                                checked={timeslotStatus}
                                                onChange={(ev) => this.setState((ps)=>({timeslotStatus:!ps.timeslotStatus}))}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="radio-container">
                                    <div className="radio-wrapper radio-wrapper-2">
                                        <h5 className="flexer">Gender</h5>
                                        {
                                            errors.errorsObj["gender"] && <p className="error"  >{errors.errorsObj["gender"].message}</p>
                                        }
                                        <label htmlFor="male">
                                            <span className="label">Male</span>
                                            <input type="radio"
                                                name="gender"
                                                id="male"
                                                value="male"
                                                checked={gender === "male"}
                                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.id })}
                                            />
                                        </label>
                                        <label htmlFor="female">
                                            <span className="label">Female</span>
                                            <input type="radio"
                                                name="gender"
                                                id="female"
                                                value="female"
                                                checked={gender === "female"}
                                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.id })}
                                            />
                                        </label>
                                        <label htmlFor="all">
                                            <span className="label">All</span>
                                            <input type="radio"
                                                name="gender"
                                                id="all"
                                                value="all"
                                                checked={gender === "all"}
                                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.id })}
                                            />
                                        </label>
                                    </div>  
                                </div>
                                </div>
                                <button className="my-btn-2" type="submit">{edit ? "Update Time Slot" : "Add Time Slot"}</button>
                                {edit && <button className="my-btn-2" type="button"
                                    onClick={() => {
                                        this.setState({
                                            name: "",
                                            totalSeats: "",
                                            year: "",
                                            month: "",
                                            quarter: "",
                                            time: "",
                                            day: "",
                                            course: "",
                                            batch: "",
                                            centerName: "",
                                            gender: "",
                                            feeDeadline: 0,
                                            feeDeadlineDate: "",
                                            quarterFee: "",
                                            edit: false,
                                            errors: {
                                                hasError: false,
                                                errorsObj: {},
                                                simpleError: "",
                                            },

                                        })
                                    }}

                                >Cancle Update</button>}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.timeSlotReducer.isLoading,
        isError: state.timeSlotReducer.isError,
        errorMessage: state.timeSlotReducer.errorMessage,
        success: state.timeSlotReducer.success,
        centers: state.centerReducer.centers,
        courses: state.courseReducer.courses,
        edit: state.timeSlotReducer.edit,
        batches: state.batchReducer.batches,
        quarters: state.quaterReducer.quaters

    }
}
function mapDispatchToProps(dispatch) {
    return {
        getCenters: data => {
            dispatch(CenterMiddleware.searchCenters(data))
        },
        addTimeSlot: data => {
            return dispatch(timeSlotMiddleware.addTimeSlot(data))
        },
        editTimeSlot: data => {
            return dispatch(timeSlotMiddleware.editTimeSlot(data))
        },
        getCourses: data => {
            dispatch(CourseMiddleware.getCourses(data))
        },
        getBatches: () => {
            dispatch(BatchMiddleware.searchBatches())
        },
        getQuarters: data => {
            dispatch(QuaterMiddleware.searchQuateres(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlot);