/*eslint-disable*/
import sectionAction from '../actions/sectionActions';

function sectionReducer(state = {
    sections: [],
    sectionLesson: [],
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null,
    success: false,
    edit: false
}, action) {

    switch (action.type) {
        case sectionAction.GET_SECTION:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case sectionAction.GET_SECTION_SUCCESSFUL:
            console.log(action.data);

            return {
                ...state,
                isError: false,
                isLoading: false,
                sections: action.data,
                errorMessage: null,
            };
        case sectionAction.GET_SECTION_FAILED:
            return {
                ...state,
                sections: [],
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };




        case sectionAction.REMOVE_SECTION_LESSON:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case sectionAction.REMOVE_SECTION_LESSON_SUCCESSFUL:
            console.log(action.data);

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case sectionAction.REMOVE_SECTION_LESSON_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };


        case sectionAction.ADD_SECTION_LESSON:
            
            
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case sectionAction.ADD_SECTION_LESSON_SUCCESSFUL:
            // state.sections.map((item, index) => {
            //     if (item._id == action._id) {
            //         state.sections[index] = action.data.data;
            //     }
            // })
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case sectionAction.ADD_SECTION_LESSON_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };




        case sectionAction.DELETE_SECTION:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case sectionAction.DELETE_SECTION_SUCCESSFUL:

            var index = null;
            state.sections.map((item, index2) => {
                if (item._id === action._id) {
                    index = index2
                }
            })
            state.sections.splice(index, 1)

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case sectionAction.DELETE_SECTION_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };


        case sectionAction.EDIT_SECTION:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
                edit: true,
            };
        case sectionAction.EDIT_SECTION_SUCCESSFUL:
            console.log(action.data);

            var index = null;
            state.sections.map((item, index2) => {
                if (item._id === action._id) {
                    index = index2
                }
            })
            state.sections[index] = { ...action.data }

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                success: action.success,
                edit: false,
            };
        case sectionAction.EDIT_SECTION_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,
                edit: true,
            };


        case sectionAction.ADD_SECTION:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                success: false,
                isLoading: true,
            };
        case sectionAction.ADD_SECTION_SUCCESSFUL:
            state.sections.push(action.data)
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null,
            };
        case sectionAction.ADD_SECTION_FAILED:

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };


        default:
            return state;
    }
}
export default sectionReducer;