import TimeSlotAction from '../actions/timeSlotAction';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'


class timeSlotMiddleware {
    static getTimeSlot(data) {
        
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(TimeSlotAction.getTimeSlots(data));
                axios.post(`${Path.GET_TIME_SLOTS}`, {
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(TimeSlotAction.getTimeSlotsFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(TimeSlotAction.getTimeSlotsSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(TimeSlotAction.getTimeSlotsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static deleteTimeSlot(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(TimeSlotAction.deleteTimeSlots(data));
                axios.post(`${Path.DELETE_TIME_SLOTS}`, {
                    _id: data._id,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(TimeSlotAction.deleteTimeSlotsFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(TimeSlotAction.deleteTimeSlotsSuccessul({ success: true, data: response.data.data, index: data.index }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(TimeSlotAction.deleteTimeSlotsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static addTimeSlot(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(TimeSlotAction.addTimeSlots(data));
                return axios.post(`${Path.ADD_TIME_SLOTS}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(TimeSlotAction.addTimeSlotsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(TimeSlotAction.addTimeSlotsSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(TimeSlotAction.addTimeSlotsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static editTimeSlot(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(TimeSlotAction.editTimeSlots(data));
               return axios.post(`${Path.EDIT_TIME_SLOTS}`, {
                    _id: data._id,
                    data: { ...data },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(TimeSlotAction.editTimeSlotsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(TimeSlotAction.editTimeSlotsSuccessul({ success: true, data: response.data.data, index: data.index }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(TimeSlotAction.editTimeSlotsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default timeSlotMiddleware;