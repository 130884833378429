import CenterAction from '../actions/centerActions';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'


class CenterMiddleware {
    static searchCenters(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CenterAction.getCenter(data));
                axios.post(`${Path.GET_CENTERS}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(CenterAction.getCenterFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(CenterAction.getCenterSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CenterAction.getCenterFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static addCenter(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CenterAction.addCenter(data));
                return axios.post(`${Path.ADD_CENTERS}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CenterAction.addCenterFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(CenterAction.addCenterSuccessul({ success: true, data: response.data.data }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CenterAction.addCenterFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static editCenter(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CenterAction.editCenter(data));
                return axios.post(`${Path.EDIT_CENTER}`, {
                    _id: data._id,
                    data: {
                        name: data.name,
                        city: data.city,
                        campus: data.campus,
                        location:data.location
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CenterAction.editCenterFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(CenterAction.editCenterSuccessul({ success: true, data: response.data.data, index: data.index }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CenterAction.editCenterFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
    static deleteCenter(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(CenterAction.deleteCenter(data));
                axios.post(`${Path.DELETE_CENTER}`, {
                    _id: data._id,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(CenterAction.deleteCenterFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(CenterAction.deleteCenterSuccessul({ success: true, data: response.data.data, index: data.index }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(CenterAction.deleteCenterFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default CenterMiddleware;