import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import { Input, Select } from '../../Input/input';
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import CourseMiddleware from '../../../store/middleware/courseMiddleware';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';


class CourseContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            search: "",
            searchCourseContent: "",
            title: "",
            courses: [],
            courseContent: [],
            searchedLessons: [],
            searchedCourseContents: [],
            _id: "",
        }
    }
    componentDidMount = () => {
        this.props.getCenters();
        this.props.getCourseSection();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            courseContent: nextProps.courseContent ? nextProps.courseContent : [],
            courses: nextProps.courses ? nextProps.courses : [],
        })
    }
    onSubmit = (ev) => {
        ev.preventDefault();
        const { edit, title, course, _id } = this.state;
        if (title && course) {

            this.setState({ search: "", searchedLessons: [] })
            edit ? this.props.editCourseContent({ courseContentId: _id, _id, data: { title, courseId: course } }).then((success) => {
                if (success) {
                    this.setState({ title: "", edit: false, _id: "" })
                }
            }) : this.props.addCourseContent({ title, courseId: course })
        } else {
            alert('Please fill fields');
        }
    }


    searchCourseContent = (keyword) => {
        const { courseContent } = this.state;
        const searchedCourseContents = courseContent.filter((item, index) => {
            return item.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            searchCourseContent: keyword,
            searchedCourseContents
        })
    }

    deleteCourseContent = (courseContentId, index) => {
        console.log(courseContentId);

        const { courseContent } = this.state;
        this.props.deleteCourseContent({ courseContentId }).then((data) => {
            if (data.success) {
                console.log(index);

                courseContent.splice(index, 1);
                this.setState({ courseContent })
            }
        })
    }




    render() {
        const { errors, edit, course, courses, title, searchCourseContent, courseContent, searchedCourseContents } = this.state;

        const { isLoading, errorMessage } = this.props;
        const finalCourseContents = searchCourseContent ? searchedCourseContents : courseContent;
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    <div className="main-box-container">
                        <form onSubmit={(ev) => this.onSubmit(ev)}>
                            {errorMessage && <p className="error text-center">{errorMessage}</p>}
                            <div className="legends-container">
                                <fieldset>
                                    <legend>{edit ? "Update CourseContent | Must Fill Al Details" : "Create CourseContent | Must Fill All Details"}</legend>
                                    <div className="legend-input-container-3">
                                        <Input
                                            label="Title"
                                            type="text"
                                            name="title"
                                            id="title"
                                            value={title}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                        <Select
                                            label="Course"
                                            name="course"
                                            id="course"
                                            value={course}
                                            options={
                                                courses.length ? courses.map((course, index) => {
                                                    return { name: course.name, value: course._id }
                                                }) : []
                                            }
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />

                                        <div className="text-right pt-3">
                                            <button className="my-btn-3 publishBtn" type="submit">{edit ? "Update CourseContent" : "Create CourseContent "}</button>
                                            {edit && <button className="my-btn-3 publishBtn" onClick={() => this.setState({ edit: false, title: "", description: "", _id: "" })} type="button">Cancel</button>}
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>




                    {courseContent.length ? <div>
                        <div className="legends-container">
                            <fieldset>
                                <legend>Course-Content</legend>
                                <div className="legend-input-container-3">
                                    <Input
                                        label=" Search Course Content Here "
                                        type="text"
                                        name="searchCourseContent"
                                        id="searchCourseContent"
                                        value={searchCourseContent}
                                        onChange={(ev) => this.searchCourseContent(ev.target.value)}
                                        errors={errors}
                                    />
                                    <div className="collection-of-lesson">
                                        {finalCourseContents.map((item, index) => {
                                            return (
                                                <div key={index} className="lesson-li">
                                                    <div className="lecture-title">{item.title} ( {item.course.initial} )</div>
                                                    <div className="zIndexBtn">
                                                        <button className="my-btn-3"
                                                            onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewcoursecontent", state: { courseContent: item } })}
                                                            type="button">View</button>
                                                        <button className="my-btn-3" onClick={() => {
                                                            this.setState({ searchCourseContent: "", searchedCourseContents: [] })
                                                            window.confirm("Are You Sure You Want To Delete?") && this.deleteCourseContent(item._id, index)
                                                        }} type="button">Delete</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div> :
                        <div className="text-Lesson">No Course Content yet to show :(</div>
                    }
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.courseReducer.isLoading,
        isError: state.courseReducer.isError,
        errorMessage: state.courseReducer.errorMessage,
        success: state.courseReducer.success,
        courses: state.courseReducer.courses,
        courseContent: state.courseReducer.courseSections

    }
}
function mapDispatchToProps(dispatch) {
    return {
        getCourseSection: data => {
            dispatch(CourseMiddleware.getSection(data))
        },
        getCenters: data => {
            dispatch(CourseMiddleware.getCourses(data))
        },
        addCourseContent: data => {
            return dispatch(CourseMiddleware.addCourseContent(data))
        },
        // editCourseContent: data => {
        //     return dispatch(CourseMiddleware.editCourseContent(data))
        // },
        deleteCourseContent: data => {
            return dispatch(CourseMiddleware.deleteCourseContent(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseContent));