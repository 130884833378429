/*eslint-disable */
function validateForm(check, data) {
    const {
        postTitle,
    } = data;

    var errors = {
        hasError: false,
        errorsObj: {}
    }


    let Validation = {
        postTitle: {
            Validate: [{
                condition: !postTitle.length,
                message: " Please Specify Post Title. ",
            }],
            elem: "postTitle"
        },
    }

    if (check === "all") {
        for (var i in Validation) {
            var conArray = Validation[i].Validate;
            errors.errorsObj[Validation[i].elem] = { message: [] }
            for (var j = 0; j < conArray.length; j++) {
                if (conArray[j].condition) {
                    errors.hasError = true
                    errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
                }
            }
            if (!errors.errorsObj[Validation[i].elem].message.length) {
                delete errors.errorsObj[Validation[i].elem];
            }
        }
    }

    return Object.keys(errors).length > 1 ? errors : {
        hasError: false
    }
}

export { validateForm };



