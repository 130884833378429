import React, { Component } from 'react';
import './feeCollection.css';
import { Input } from "../Input/input";
import { connect } from "react-redux"
import { Loader } from "../StudentManagment/helper";
import VoucherMiddleware from "../../store/middleware/voucherMiddleware";
import moment from 'moment';
import Modal from 'react-responsive-modal';


class FeeCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            voucherNo: "",
            voucher: null,
            center: null,
            student: null,
            errors: {
                hasError: false,
                errorsObj: {}
            },
            open: false,
        }
    }
    componentWillReceiveProps(nextProps) {
        const { errors } = this.state;

        if (nextProps.isError && nextProps.errorMessage) {
            errors.hasError = true;
            errors.simpleError = nextProps.errorMessage;
            this.setState({ errors, voucher: null, center: null, student: null, show: true })
            return
        }

        errors.hasError = false;
        errors.simpleError = ""
        if (nextProps.voucher) {
            console.log("VOUCHER ", nextProps.voucher.data);
            this.setState({
                voucher: nextProps.voucher.data.stdVoucher,
                student: nextProps.voucher.data.student,
                center: nextProps.voucher.data.center,
                timeslot: nextProps.voucher.data.timeslot
            })
        }
        if (nextProps.successMessage) {
            console.log(nextProps)
            this.setState({
                voucher: null,
                center: null,
                student: null,
                show: true,
                successMessage: nextProps.successMessage.message
            })
        }
    }
    search = (ev) => {

        ev.preventDefault();
        const { voucherNo } = this.state;
        this.setState({ open: false, show: false, voucher: null, center: null, student: null, successMessage: "" })
        this.props.search({ voucherNo: voucherNo });
    }
    submitPaid = () => {
        const { voucher } = this.state
        this.setState({ open: false, voucherNo: "" })
        this.props.statusVoucherUpdate(voucher)
    }
    render() {
        const { errors, voucher, student, center, timeslot, voucherNo } = this.state
        const { isLoading } = this.props
        // console.log("STATE ", this.state)
        return (
            <div className="container-fluid  flexer" style={{flexDirection:"column" }}>
                <h3 >Todays Collection</h3>
                <div className="flexer" style={{justifyContent:"space-between" , marginBottom:"20px" , BorderBottom:"1px solid" , width:"100%"}}>
                    
                <table className="model-table" style={{fontSize:"24px"}}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Online Collection
                                                </th>


                                                <th>
                                                    Onsite Collection
                                                </th>

                                                <th>
                                                    Total Collection
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {this.props.feeCollected.onlineFeeCollected? this.props.feeCollected.onlineFeeCollected: 0}
                                                </td>
                                                <td>
                                                    {this.props.feeCollected.onsiteFeeCollected ? this.props.feeCollected.onsiteFeeCollected : 0}
                                                </td> <td>
                                                    {this.props.feeCollected.totalFeeCollected ? this.props.feeCollected.totalFeeCollected : 0}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button className="my-btn-3" onClick={()=>{this.props.myCollection()}}>Refresh</button>
                </div>
                {isLoading && <Loader />}
                <div className="container">
                    <div className="form-container  align-items-center">
                        <div className="form-wrapper">
                            {voucher && student && <Modal open={this.state.open} onClose={() => this.setState({ open: false })} styles={{ width: "400vw" }} closeOnOverlayClick={false}>
                                <div className="model-heading"><h4>Please verify voucher and student information</h4></div>
                                <div className="model-table-div">
                                    <table className="model-table">
                                        <tbody>
                                            <tr>
                                                <th>Roll Number</th>
                                                <td>{voucher.rollNo}</td>
                                            </tr>
                                            <tr>
                                                <th>Student Name</th>
                                                <td>{student.fullName}</td>
                                            </tr>
                                            <tr>
                                                <th>Father Name</th>
                                                <td>{student.fatherName}</td>
                                            </tr>
                                            <tr>
                                                <th>Voucher Number</th>
                                                <td>{voucher.fee_voucher_no}</td>
                                            </tr>
                                            <tr>
                                                <th>Batch </th>
                                                <td>{voucher.batch}</td>
                                            </tr>
                                            <tr>
                                                <th>Course</th>
                                                <td>{voucher.course}</td>
                                            </tr>
                                            <tr>
                                                <th>Quarter</th>
                                                <td>{voucher.quarter}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <div className="model-btns">
                                    <button className="model-btn-2" onClick={() => this.setState({ open: false })} type="button">No</button>
                                    <button className="model-btn-1" onClick={() => this.submitPaid()} type="button">Yes, Mark Payment</button>
                                </div>
                            </Modal>}
                            {(this.state.successMessage || this.state.errors) && <Modal closeOnOverlayClick={false} open={this.state.show} onClose={() => this.setState({ show: false })}>
                                {this.state.successMessage && <div className="model-heading"><h4>{this.state.successMessage}</h4></div>}
                                {errors.hasError && <div className="model-heading"><h5 className="error">{errors.simpleError}</h5></div>}
                            </Modal>
                            }
                            <form onSubmit={(ev) => this.search(ev)} method="post">
                                <h4>Search Student Voucher</h4>

                                <Input
                                    label="Enter Voucher Number"
                                    type="text"
                                    name="voucherNo"
                                    id="voucherNo"
                                    value={voucherNo}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}

                                />
                                    <button className="my-btn-2" style={{ backgroundColor: '#106357', color: 'white' }} type="submit">Search</button>
                                {voucher && voucher.valid && <h4 className={voucher.paid ? "voucher-text-2" : "voucher-text-3"} disabled={true}>VOUCHER IS {voucher.paid ? "PAID" : "UNPAID"}</h4>}
                                {voucher && !voucher.valid && <h4 className="voucher-text-3" disabled={true}>VOUCHER IS INVALID</h4>}
                            </form>
                        </div>
                    </div>

                    {voucher && student &&
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {voucher.valid && !voucher.paid && <button className="voucher-btn-1" type="submit" disabled={voucher.paid && !voucher.valid} onClick={() => { this.setState({ open: true }) }}>PAY VOUCHER</button>}
                            </div>

                            <div className="voucher-table-div">

                                <table className="voucher-table">
                                    <tbody>
                                        <tr>
                                            <th>Roll Number</th>
                                            <td>{voucher.rollNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Student Name</th>
                                            <td>{student.fullName}</td>
                                        </tr>
                                        <tr>
                                            <th>Father Name</th>
                                            <td>{student.fatherName}</td>
                                        </tr>
                                        <tr>
                                            <th>Voucher Number</th>
                                            <td>{voucher.fee_voucher_no}</td>
                                        </tr>
                                        <tr>
                                            <th>Fee Amount</th>
                                            <td>{voucher.feeAmount}</td>
                                        </tr>


                                        <tr>
                                            <th>Voucher Generation Date</th>
                                            <td>{new Date(voucher.createDate).toDateString()}</td>
                                        </tr>
                                        <tr>
                                            <th>Due Date</th>
                                            <td>{moment(voucher.paymentDeadline, "x").format("DD MMM YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <th>Batch</th>
                                            <td>{voucher.batch}</td>
                                        </tr>
                                        <tr>
                                            <th>Quarter</th>
                                            <td>{voucher.quarter}</td>
                                        </tr>
                                        <tr>
                                            <th>Course</th>
                                            <td>{voucher.course}</td>
                                        </tr>
                                        <tr>
                                            <th>Distance Learning</th>
                                            <td>{voucher.distanceLearning ? "True" : "False"}</td>
                                        </tr>

                                    </tbody>
                                </table>



                            </div>


                        </div>
                    }
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    //console.log("REDUX STATE", state)
    return {
        isLoading: state.voucherReducer.isLoading,
        isError: state.voucherReducer.isError,
        errorMessage: state.voucherReducer.errorMessage,
        successMessage: state.voucherReducer.successMessage,
        voucher: state.voucherReducer.voucher,
        fetched: state.voucherReducer.fetched,
        feeCollected: state.voucherReducer.feeCollected
    }
}
function mapDispatchToProps(dispatch) {
    return {
        search: data => {
            dispatch(VoucherMiddleware.searchVoucherByNo(data));
        },
        statusVoucherUpdate: (data) => {
            dispatch(VoucherMiddleware.payVoucher(data));
        },
        myCollection: () => {
            dispatch(VoucherMiddleware.myCollection());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FeeCollection);