import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import { Input } from '../../Input/input';
import { connect } from "react-redux"
// import LessonMiddleware from '../../../store/middleware/lessonsMiddleware';
import SectionMiddleware from '../../../store/middleware/sectionMiddleware';

import CourseMiddleware from '../../../store/middleware/courseMiddleware';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';

class ShowCourseContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            search: "",
            searchSection: "",
            searchedSections: [],
            sections: [

            ],
            filteredSections: [],
            courseSections: [],
            addedSearchedSection: [],
            showLesson: false,
            _id: "",
        }
    }



    componentDidMount = () => {
        this.props.getSection();
        const { history } = this.props;
        console.log(history);

        if (history && history.location) {
            const { courseContent } = history.location.state;
            const { title, _id, sections } = courseContent;

            this.setState({ title, courseContentId: _id, courseSections: sections })
        } else {
            this.props.history.push("/onlinevideosmanagement")
        }
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            sections: nextProps.sections ? nextProps.sections : [],
        })
    }

    search = (keyword) => {
        const { sections } = this.state;
        const searchedSections = sections.filter((item, index) => {
            return item.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            search: keyword,
            searchedSections
        })
    }

    addedSearchedSection = (keyword) => {
        const { courseSections } = this.state;

        const addedSearchedSection = courseSections.filter((item, index) => {
            return item.section.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            searchSection: keyword,
            addedSearchedSection
        })
    }

    removeSection = (sectionId, index) => {
        const { courseContentId, courseSections } = this.state;
        this.props.removeSection({ sectionId, courseContentId }).then((data) => {
            if (data.success) {
                courseSections.splice(index, 1)
                this.setState({ courseSections });
            }
        })
    }

    maintainSection = (_id) => {
        const { courseContentId } = this.state;
        var sequence = prompt("Add Sequence here of Section");
        if (!sequence) {
            return;
        }
        this.setState({ showLesson: false })
        this.props.addCourseSection({ sectionId: _id, courseContentId, sequence }).then((data) => {
            if (data.success) {
                this.setState({ courseSections: data.data.sections });

            }
        })
    }


    render() {
        const { errors, search, title, searchedSections, addedSearchedSection, searchSection, sections, showLesson, courseSections } = this.state;
        const { isLoading, errorMessage } = this.props;
        const finalSections = search ? searchedSections : sections;
        const finalCourseSection = searchSection ? addedSearchedSection : courseSections;


        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="legends-container">
                        <fieldset>
                            <legend>Course-Content</legend>
                            <div className="legend-input-container-3">
                                {title}
                            </div>
                        </fieldset>
                    </div>
                    <div>



                        {courseSections.length ? <div>
                            <div className="legends-container">
                                <fieldset>
                                    <legend>Course-Sections</legend>
                                    <div className="legend-input-container-3">
                                        <Input
                                            label=" Search Section Here "
                                            type="text"
                                            name="searchSection"
                                            id="searchSection"
                                            value={searchSection}
                                            onChange={(ev) => this.addedSearchedSection(ev.target.value)}
                                            errors={errors}
                                        />
                                        <div className="collection-of-lesson">

                                            {finalCourseSection.map((item, index) => {
                                                return (
                                                    <div key={index} className="lesson-li">
                                                        <div className="lecture-title">{item.sequence}. {item.section.title}</div>
                                                        <div className="zIndexBtn">
                                                            <button className="my-btn-3"
                                                                onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewsection/outline", state: { section: item.section } })}
                                                                type="button">View</button>
                                                            <button className="my-btn-3" onClick={() => {
                                                                this.setState({ search: "", addedSearchedSection: [] })
                                                                window.confirm("Are You Sure You Want To Delete?") && this.removeSection(item.section._id, index)
                                                            }} type="button">Remove</button>
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>

                                    </div>
                                </fieldset>
                            </div>
                        </div> :
                            <div className="text-Lesson">No Section in course content yet to show :(</div>
                        }






                        <button className="my-btn-3"
                            onClick={() => this.setState({ showLesson: true })}
                            type="button">Add Section</button>
                        {showLesson &&
                            <div>
                                {sections.length ? <div>
                                    <div className="legends-container">
                                        <fieldset>
                                            <legend>Section</legend>
                                            <div className="legend-input-container-3">
                                                <Input
                                                    label=" Search Section Here "
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    value={search}
                                                    onChange={(ev) => this.search(ev.target.value)}
                                                    errors={errors}
                                                />
                                                <div className="collection-of-lesson">
                                                    {finalSections.map((item, index) => {
                                                        return (
                                                            <div key={index} className="lesson-li">
                                                                <div className="lecture-title">{item.title}</div>
                                                                <div className="zIndexBtn">
                                                                    <button className="my-btn-3"
                                                                        onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewsection/section", state: { section: item } })}
                                                                        type="button">View</button>
                                                                    {<button className="my-btn-3" onClick={() => this.maintainSection(item._id)} type="button">Add</button>}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </fieldset>
                                    </div>
                                </div> :
                                    <div className="text-Lesson">No Section yet to show :(</div>}
                            </div>
                        }

                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.sectionReducer.isLoading,
        isError: state.sectionReducer.isError,
        errorMessage: state.sectionReducer.errorMessage,
        success: state.sectionReducer.success,
        sections: state.sectionReducer.sections,
        courses: state.courseReducer.courses,
        courseContent: state.courseReducer.courseSections
    }
}
function mapDispatchToProps(dispatch) {
    return {

        getSection: data => {
            dispatch(SectionMiddleware.getSection(data))
        },

        removeSection: data => {
            return dispatch(CourseMiddleware.removeSection(data))
        },
        addCourseSection: data => {
            return dispatch(CourseMiddleware.addCourseSection(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowCourseContent);
