/*eslint-disable*/

import React, { Component } from 'react';
import './home.css';

class Home extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <div>
          This is home page
        </div>
      </div>
    );
  }
}

export default Home;
