/*eslint-disable*/
import CourseActions from "../actions/courseActions";

function courseReducer(state = {
    isError: false,
    isLoading: false,
    errorMessage: "",
    successMessage: "",
    courses: [],
    courseSections:[],
    coursesByBatch:[],
}, action) {
    switch (action.type) {
        case CourseActions.GET_COURSES:

            return {
                ...state,
                isLoading: true,
                isError: false,
                successMessage: "",
                errorMessage: ""
            }

        case CourseActions.GET_COURSES_SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                isError: false,
                successMessage: action.data.message,
                errorMessage: "",
                courses:action.data
            }
        case CourseActions.GET_COURSES_FAILED:
            return {
                ...state,
                isLoading: false,
                isError: true,
                successMessage: "",
                errorMessage: action.data.errorMessage
            }

        case CourseActions.ADD_COURSES: 
            return { 
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            }


        case CourseActions.ADD_COURSES_SUCCESSFULL: 

        state.courses.push({ ...action.data.data })


        console.log(action.data)
            return { 
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            }


        case CourseActions.ADD_COURSES_FAILED: 
            return { 
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            }



        case CourseActions.DELETE_COURSE: 
            return { 
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            }


        case CourseActions.DELETE_COURSE_SUCCESSFULL: 
            state.courses.splice(action.index, 1)
            return { 
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            }


        case CourseActions.DELETE_COURSE_FAILED: 
            return { 
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            }
            

        case CourseActions.EDIT_COURSES:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                edit: true,
                success: false,
                isLoading: true,
            };
            
        case CourseActions.EDIT_COURSES_SUCCESSFULL:
            state.courses[action.index] = { ...action.data }
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                edit: false,
                success: action.success,
            };
        case CourseActions.EDIT_COURSES_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                edit: true,
                success: false,
            };






        case CourseActions.GET_SECTIONS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                successMessage: "",
                errorMessage: "",
                courseSections:[]
            }

        case CourseActions.GET_SECTIONS_SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                isError: false,
                successMessage: action.data.message,
                errorMessage: "",
                courseSections:action.data
            }
            
        case CourseActions.GET_SECTIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                isError: true,
                successMessage: "",
                courseSections:[],
                errorMessage: action.data.errorMessage
            }


            case CourseActions.REMOVE_COURSE_SECTION:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };

        case CourseActions.REMOVE_COURSE_SECTION_SUCCESSFUL:
            console.log(action.data);

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case CourseActions.REMOVE_COURSE_SECTION_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };



            case CourseActions.ADD_COURSE_SECTION:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case CourseActions.ADD_COURSE_SECTION_SUCCESSFUL:
            console.log(action.data);

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case CourseActions.ADD_COURSE_SECTION_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };



            case CourseActions.ADD_COURSE_CONTENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case CourseActions.ADD_COURSE_CONTENT_SUCCESSFUL:
            state.courseSections.push(action.data.data)

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case CourseActions.ADD_COURSE_CONTENT_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };




        case CourseActions.DELETE_SECTION:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case CourseActions.DELETE_SECTION_SUCCESSFUL:

            var index = null;
            state.sections.map((item, index2) => {
                if (item._id === action._id) {
                    index = index2
                }
            })
            state.sections.splice(index, 1)

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
            };
        case CourseActions.DELETE_SECTION_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };
            case CourseActions.GET_COURSES_BY_BATCH_ID:
            return {
                ...state,
                isLoading: true,
                isError: false,
                successMessage: "",
                errorMessage: ""
            }

        case CourseActions.GET_COURSES_BY_BATCH_ID_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                isError: false,
                successMessage: action.data.message,
                errorMessage: "",
                coursesByBatch:action.data.courses
            }
        case CourseActions.GET_COURSES_BY_BATCH_ID_FAILED:
            state["coursesByBatch"]=[];
            return {
                ...state,
                isLoading: false,
                isError: true,
                successMessage: "",
                errorMessage: action.data.errorMessage
            }


        default:
            return state
    }
}

export default courseReducer;