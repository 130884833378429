import React, { Component } from "react";
import { Select, Input } from "../Input/input"
import StudentMiddleware from "../../store/middleware/studentMiddleware";
import { connect } from "react-redux"
import StudentData from "./StudentData"
import { validateForm } from "./helper"
import Modal from "./Modal/Modal";
import "./student.css"
import { Loader } from "../StudentManagment/helper"



class StudentManagment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModal: false,
            identity: "",
            identityValue: "",
            disabled: true,
            errors: {
                hasError: false,
                errorsObj: {}
            },
            studentData: null,
            originalData: null,
            showOriginal: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const { errors } = this.state;

        if (nextProps.isError && nextProps.errorMessage) {
            errors.hasError = true;
            errors.simpleError = nextProps.errorMessage;
            this.setState({ errors, studentData: null })
            return
        }

        errors.hasError = false;
        errors.simpleError = ""
        this.setState({
            studentData: { ...nextProps.student },
            originalData: { ...nextProps.student },
            originalCnic: nextProps.student
                ? nextProps.student.studentCnic
                : null,
            newImage: null,
            imageFile: null,
            disabled: true
        })
    }




    submit = (ev) => {
        ev.preventDefault();
        const { identity, identityValue } = this.state;
        this.props.search({ type: identity, value: identityValue });
    }


    edit = (ev) => {
        const { studentData, errors, showOriginal } = this.state;
        if (showOriginal) {
            this.setState({
                showOriginal: false
            })
        }

        switch (ev.target.name) {
            case "imageSelector":

                if (ev.target.files && ev.target.files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener("load", () =>
                        this.setState({ blob: reader.result })
                    );
                    reader.readAsDataURL(ev.target.files[0]);
                }

                studentData.image = this.imageFile.files[0]

                this.setState({
                    isModal: true,
                    errors: validateForm("each", studentData, ev.target.name, errors)
                })
                delete this.imageFile.files[0];
                break;
            case "cancelImage":
                delete errors.errorsObj.imageSelector;
                this.setState({
                    imageFile: null,
                    newImage: null,
                })
                break;
            case "rejectreason":
                studentData[ev.target.name] = ev.target.value;
                this.setState({
                    studentData
                })
                break;
            case "distanceLearning":
                studentData['distanceLearning'] = !studentData.distanceLearning;
                this.setState({
                    studentData
                });
                break;

            default:
                studentData[ev.target.name] = ev.target.value;
                let selectedCourse = this.props.coursesByBatch.filter(rec => rec._id == ev.target.value);
               if(selectedCourse && selectedCourse.length>0 ){
                   studentData["course"] = selectedCourse[0].initial;
                }
                this.setState({
                    studentData,
                    errors: validateForm("each", studentData, ev.target.name, errors)
                });
                break;
        }

    }
    setCroppedImage = (image) => {
        const { studentData, errors } = this.state;
        console.log(image);

        this.setState({
            newImage: image
                ? URL.createObjectURL(image)
                : null,
            imageFile: image,
            isModal: false,
            errors: validateForm("each", studentData, "imageSelector", errors)
        })
        delete this.imageFile.files[0];
    }

    submitEdit = (ev) => {
        ev.preventDefault();
        let { studentData, imageFile, newImage, originalCnic } = this.state;
        studentData.image = imageFile;
        var validate = validateForm("all", studentData);
        if (validate.hasError) {
            this.setState({ errors: validate });
            return
        }
        if (imageFile && newImage) {
            var formData = new FormData();
            formData.append("image", imageFile);

            const dataWithImage = {
                userData: studentData,
                originalCnic,
            };

            this.props.withPicUpdate(formData, dataWithImage);
            return;
        }

        const data = {
            userData: studentData,
            originalCnic,
        };



        this.props.update(data);


    }

    statusUpdate = (status) => {
        const { errors, studentData } = this.state;
        if (status === "Reject" && (!studentData.rejectreason || studentData.rejectreason === "Select")) {
            errors.hasError = true;
            errors.errorsObj.rejectreason = { message: [] }
            errors.errorsObj.rejectreason.message.push("Please Specify Reason")
            this.setState({ errors })
            return;
        }
        console.log(studentData);

        errors.hasError = false;
        delete errors.errorsObj.rejectreason
        this.setState({ errors })
        const data = {
            _id: studentData._id,
            rejectreason: status === "Reject" ? studentData.rejectreason : undefined
        }
        console.log(data);

        this.props.statusUpdate(data)
    }
    cancelEdit = () => {
        const { originalData } = this.state;
        this.setState({
            showOriginal: true,
            imageFile: null,
            newImage: "",
            studentData: { ...originalData },
            disabled: true
        })
    }
    render() {
        const { studentData, disabled, errors, newImage, isModal, blob, originalData, showOriginal } = this.state;
        const { isLoading } = this.props

        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <Modal
                    image={blob}
                    condition={isModal}
                    imageRef={this.canvas}
                    setCroppedImage={this.setCroppedImage}
                    hideModal={() => this.setState({ isModal: false })}
                />
                <canvas ref={(el) => this.canvas = el}
                    style={{ display: "none" }}
                ></canvas>
                <div className="container">
                    <div className="student-form-wrapper">
                        <form onSubmit={(ev) => this.submit(ev)} method="post">
                            <h3>Search Student Data</h3>
                            {errors.hasError && <p className="error">{errors.simpleError}</p>}
                            <Select
                                label="Select Identity"
                                name="identity"
                                id="identity"
                                options={[
                                    { name: "CNIC", value: "cnic" },
                                    { name: "Number", value: "phoneNumber" },
                                    { name: "Roll Number", value: "rollNo" },
                                    { name: "Email", value: "email" },

                                ]}
                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                errors={errors}
                            />

                            <Input
                                label="Value"
                                type="text"
                                name="identityValue"
                                id="identityValue"
                                onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                errors={errors}

                            />
                            <button className="my-btn-2" type="submit">Search</button>
                        </form>
                    </div>
                </div>
                {this.props.student && studentData && <StudentData
                    onSubmit={this.submitEdit}
                    updateImage={this.updateImage}
                    newImage={newImage}
                    errors={errors}
                    enable={() => this.setState({ disabled: false })}
                    cancelEdit={() => this.cancelEdit()}
                    data={showOriginal ? originalData : studentData}
                    parentThis={this}
                    onChange={(ev) => this.edit(ev)}
                    disabled={disabled}
                    statusUpdate={this.statusUpdate}
                />}

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        isLoading: state.studentReducer.isLoading,
        isError: state.studentReducer.isError,
        errorMessage: state.studentReducer.errorMessage,
        successMessage: state.studentReducer.successMessage,
        student: state.studentReducer.student,
        coursesByBatch: state.courseReducer.coursesByBatch,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        search: data => {
            dispatch(StudentMiddleware.search(data));
        },
        update: data => {
            dispatch(StudentMiddleware.update(data));
        },
        withPicUpdate: (data, studentData) => {
            dispatch(StudentMiddleware.withPicUpdate(data, studentData));
        },
        statusUpdate: (data) => {
            dispatch(StudentMiddleware.statusUpdate(data));
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentManagment);