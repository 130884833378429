/*eslint-disable */
import React from "react";

function validateForm(check, data) {
    const {
        admitCardMessage,
        batch,
        telegramLinks:{iosLink , groupName , androidLink}
    } = data;
    console.log(groupName , androidLink , iosLink)
    console.log("batch",batch.value)
    console.log("ms", admitCardMessage.length)
    var errors = {
        hasError: false,
        errorsObj: {}
    }

    let Validation = {
        batch: {
            Validate: [
                {
                    condition: !batch.length || batch === "Select",
                    message: " Please Specify batch Name . ",
                }
            ],
            elem: "batch"
        },
        admitCardMessage: {
            Validate: [{
                condition: !admitCardMessage.length,
                message: " Please Specify Your admit card Message . ",
            }
            ],
            elem: "admitCardMessage"
        },
        groupName: {
            Validate: [{
                condition: !groupName.length,
                message: " Please Specify Group Name . ",
            }
            ],
            elem: "groupName"
        },
        iosLink: {
            Validate: [{
                condition: !iosLink.length,
                message: " Please Specify IOS Link . ",
            }
            ],
            elem: "iosLink"
        },
        androidLink: {
            Validate: [{
                condition: !androidLink.length,
                message: " Please Specify Android Link . ",
            }
            ],
            elem: "androidLink"
        },


    }
    console.log("Working1");

    if (check === "all") {
        for (var i in Validation) {
            var conArray = Validation[i].Validate;
            errors.errorsObj[Validation[i].elem] = { message: [] }
            for (var j = 0; j < conArray.length; j++) {
                if (conArray[j].condition) {
                    errors.hasError = true
                    errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
                }
            }
            if (!errors.errorsObj[Validation[i].elem].message.length) {
                delete errors.errorsObj[Validation[i].elem];
            }
        }
    }
    console.log("errors", errors);


    return Object.keys(errors).length > 1 ? errors : {
        hasError: false
    }
}




export { validateForm };



