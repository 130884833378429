import React, { Component } from 'react'
import { Loader } from '../StudentManagment/helper'
import { Input } from '../Input/input'
import { connect } from 'react-redux'
import './style.css'
import QuarterConfigMiddleware from '../../store/middleware/quarterConfigMiddleware'
class ViewQuarter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            links: {
                facebook: "",
                telegram: "",
                slack: "",
                syllabus: ""
            },
            textBooks: [
                {
                    name: "",
                    coverImage: "",
                    url: ""

                }
            ],
            currentQuarter: {},
            errors: {
                hasError: false,
                errorsObj: {}
            },

            fail: "",
            success: ""
        }

    }
    componentDidMount() {
        if (!this.props.location.state) {
            return this.props.history.goBack()
        }
        const { state: { quater } } = this.props.location
        if (quater.config) {
            this.setState({ links: quater.config.links, textBooks: quater.config.textbooks })
        }
        this.setState({ currentQuarter: quater })
    }

    handleChange = ({ target: { name, value } }) => this.setState({ [name]: value })

    handleBooks = (event, index) => {
        const { textBooks: books } = this.state
        books[index] = Object.assign({}, books[index], { [event.target.name]: event.target.value })
        this.setState({ textBooks: books })
    }

    addRow = (event) => {
        event.preventDefault();
        const newItem = this.state.textBooks.concat([{ name: "", coverImage: "", url: "" }])
        this.setState({ textBooks: newItem });
    }

    updateQuarter = (event) => {
        event.preventDefault();
        const { links, textBooks: textbooks, currentQuarter } = this.state
        this.props.addQuarterConfig({
            links, textbooks, quarterConfigId: currentQuarter._id
        })

    }


    render() {
        const { currentQuarter , links, textBooks, errors, fail, success } = this.state
        const { loading, isError, successMessage, errorMessage } = this.props
        console.log("UPDATED PROPS", this.props)
        return (
            <div>

                {
                    loading
                    &&
                    <Loader />
                }
                <div className="form-wrapper">
                    <form onSubmit={this.updateQuarter}>
                        <h3>
                        { currentQuarter.quarterName } Quarter Config 
                        </h3>

                        {/* {fail && <p className="error">{fail}</p>}
                        {success && <p className="success">{success}</p>} */}
                        {isError && errorMessage && <p className="error">{errorMessage}</p>}
                        {successMessage && <p className="success">{successMessage}</p>}

                        {/* <Input
                            label="Quarter Name"
                            disabled={true}
                            type="text"
                            name="quarterName"
                            id="quarterName"
                            value={currentQuarter.quarterName}
                            length='30'
                            onChange={(ev) => { this.setState({ [ev.target.name]: ev.target.value }) }}
                            errors={errors}
                        /> */}

                        {Object.keys(links).map((linkItem, index) => (
                            <Input
                                key={index}
                                label={linkItem}
                                type="text"
                                name={links[linkItem]}
                                id={links[linkItem]}
                                value={links[linkItem]}
                                length='30'
                                onChange={(ev) => {
                                    links[linkItem] = ev.target.value
                                    this.setState({ links })
                                }}
                                errors={errors}
                            />
                        ))}
                        {/* <img src="https://doc-10-4o-docs.googleusercontent.com/docs/securesc/tjac2i5g5v088ltevunuapc20bfu4n58/gda2eubbvcu2r1gd41b7pthubai3i728/1581154200000/11626288818798209826/11626288818798209826/1LDSPczgRxgTdfatlr16RzQl8GdwG1uja?e=download&authuser=0&nonce=0ihl9pgn7pngk&user=11626288818798209826&hash=m2v981gjefe7b62o07n9m5l80r2esr0q" alt="cover"/> */}
                        <div className="flexer-col">
                            <table>
                                <thead>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th>Name</th>
                                        <th>Url</th>
                                        <th>Cover Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {textBooks && textBooks.map(({ name, coverImage, url }, index) =>
                                        <tr key={index} >
                                            <td><input className="inputWrapper" type="text" name="name" value={name} onChange={(event) => this.handleBooks(event, index)} placeholder="Book Name" /></td>
                                            <td><input className="inputWrapper" type="text" name="url" value={url} onChange={(event) => this.handleBooks(event, index)} placeholder="Book Url" /></td>
                                            <td><input className="inputWrapper" type="text" name="coverImage" value={coverImage} onChange={(event) => this.handleBooks(event, index)} placeholder="Cover Image Url" /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div>
                                <button className="my-btn-2" onClick={this.addRow}> Add Book</button>
                            </div>
                        </div>
                        <div className="flexer-col">
                            <button type="submit" className="my-btn-2">
                                Update Quarter
                        </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    console.log("QUARTER CONFIG STATE", state.quarterConfigReducer)
    return {
        loading: state.quarterConfigReducer.loading,
        isError: state.quarterConfigReducer.isError,
        errorMessage: state.quarterConfigReducer.errorMessage,
        successMessage: state.quarterConfigReducer.successMessage
    
    }
}, (dispatch) => ({
    addQuarterConfig: (data) => dispatch(QuarterConfigMiddleware.addQuarterConfig(data))

}))(ViewQuarter)
