/*eslint-disable*/
import React, { Component } from 'react';
import './Navbar.css';
import logo from '../../assets/logos/piaic-logo-new.svg';
import { Link } from 'react-router-dom';
// import withRouter from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
// import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from "react-redux";
import LocalStorageManager from '../../services/localStorageManager';
import AuthMiddleware from "../../store/middleware/authMiddleware"



import dropMenu from '../../assets/svgs/drop-down-arrow.svg'


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
          isLoggedIn:nextProps.isLoggedIn
        }
      }
    componentDidMount() {
        var user = LocalStorageManager.getUser();
        if (user && user.databaseToken) {
            this.setState({
                isLoggedIn: true
            })
        }
        else {
            this.setState({ isLoggedIn: false })
        }
    }
    logOutAdmin = (ev) => {
        ev.preventDefault();
        this.props.logoutAdmin()
    }


    render() {

        return (
            <div className="navbar">
                    <div className="col-sm-6 col-6" >
                        <Link to='/'>
                            <img width={70} height={70} src={logo} />
                        </Link>
                    </div>
                    <div className="col-sm-6 col-6" >
                        {(this.state.isLoggedIn || this.props.isLoggedIn)
                            ? <button className="logout-btn" onClick={(ev) => { this.logOutAdmin(ev) }} >Log Out</button>
                            : ""}
                    </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
        isLoading: state.authReducer.isLoading
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logoutAdmin: (data) => {
            dispatch(AuthMiddleware.logoutAdmin(data));
        }
    }
}


// export default withRouter(Navbar);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar)