/*eslint-disable */
function validateForm(check, data) {
    const {
        name,
        city,
    } = data;

    var errors = {
        hasError: false,
        errorsObj: {}
    }
    let Validation = {
        name: {
            Validate: [{
                condition: !name.length,
                message: " Please Specify Your Center Name . ",
            }
            ],
            elem: "name"
        },
        city: {
            Validate: [{
                condition: !city.length,
                message: " Please Specify City Name . ",
            }, {
                condition: /\d/.test(city) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(city),
                message: " City Name Can Not Contain Numbers Or Any Special Character . ",
            }
            ],
            elem: "city"
        },

    }
    if (check === "all") {
        for (var i in Validation) {
            var conArray = Validation[i].Validate;
            errors.errorsObj[Validation[i].elem] = { message: [] }
            for (var j = 0; j < conArray.length; j++) {
                if (conArray[j].condition) {
                    errors.hasError = true
                    errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
                }
            }
            if (!errors.errorsObj[Validation[i].elem].message.length) {
                delete errors.errorsObj[Validation[i].elem];
            }
        }
    }
    console.log("errors", errors);
    return Object.keys(errors).length > 1 ? errors : {
        hasError: false
    }
}




export { validateForm };



