import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import { Input, Select } from '../../Input/input';
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SectionMiddleware from '../../../store/middleware/sectionMiddleware';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            searchSection: "",
            title: "",
            keywords: "",
            description: "",
            searchedSections: [],
            sections: [],
            _id: "",
            searchType: "title"

        }
    }
    componentDidMount = () => {
        this.props.getSection();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            sections: nextProps.sections ? nextProps.sections : []
        })
    }
    onSubmit = (ev) => {
        ev.preventDefault();
        const { edit, title, description, _id, keywords } = this.state;
        edit ? this.props.editSection({ sectionId: _id, _id, data: { title, keywords, description } }).then((success) => {
            if (success) {
                this.setState({ title: "", keywords: "", description: "", edit: false, _id: "" })
            }
        }) : this.props.addSection({ title, description, keywords }).then((success) => {
            if (success) {
                this.setState({ title: "", keywords: "", description: "", edit: false, _id: "" })
            }
        })
    }


    searchSection = (keyword) => {
        const { sections, searchType } = this.state;
        console.log(searchType)
        const searchedSections = sections.filter((item) => {
            return item[searchType].toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            searchSection: keyword,
            searchedSections
        })
    }






    render() {
        const { errors, edit, title, description, searchSection, searchedSections, sections, keywords } = this.state;

        const { isLoading, errorMessage } = this.props;
        const finalSections = searchSection ? searchedSections : sections;
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="main-box-container">
                        <form onSubmit={(ev) => this.onSubmit(ev)}>
                            <div className="legends-container">
                                <fieldset>
                                    <legend>{edit ? "Update Section | Must Fill Al Details" : "Create Section | Must Fill All Details"}</legend>
                                    <div className="legend-input-container-3">
                                        <Input
                                            label="Title"
                                            type="text"
                                            name="title"
                                            id="title"
                                            value={title}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                        <Input
                                            label="keywords"
                                            type="text"
                                            name="keywords"
                                            id="keywords"
                                            value={keywords}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                        <label htmlFor="description">
                                            <span className="label">Description</span>
                                        </label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            value={description}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                        >
                                        </textarea>
                                        <div className="text-right pt-3">
                                            <button className="my-btn-3 publishBtn" type="submit">{edit ? "Update Section" : "Create Section "}</button>
                                            {edit && <button className="my-btn-3 publishBtn" onClick={() => this.setState({ edit: false, title: "", description: "", _id: "" })} type="button">Cancel</button>}
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>




                    {sections.length ? <div>
                        <div className="legends-container">
                            <fieldset>
                                <legend>Section</legend>
                                <div className="legend-input-container-3">
                                    <Input
                                        label=" Search Section Here "
                                        type="text"
                                        name="searchSection"
                                        id="searchSection"
                                        value={searchSection}
                                        onChange={(ev) => this.searchSection(ev.target.value)}
                                        errors={errors}
                                    />
                                    <div className="Search-select">
                                        <Select
                                            label="Select search type"
                                            name="searchType"
                                            id="searchType"
                                            selected="title"
                                            options={[
                                                { name: "title", value: "title" },
                                                { name: "keywords", value: "keywords" },
                                            ]}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="collection-of-lesson">
                                        {finalSections.map((item, index) => {
                                            return (
                                        <div className="lesson-list">
                                                    <div key={index} className="lesson-li">
                                                    <div className="lecture-title">{item.title}</div>
                                                    <div className="zIndexBtn">
                                                        <button className="my-btn-3"
                                                            onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewsection/section", state: { section: item } })}
                                                            type="button">View</button>
                                                        <button className="my-btn-3" onClick={() => this.setState({ edit: true, index, title: item.title, keywords: item.keywords, description: item.description, _id: item._id })} type="button">Edit</button>
                                                        <button className="my-btn-3" onClick={() => {
                                                            this.setState({ searchSection: "", searchedSections: [] })
                                                            window.confirm(`Are you sure you want to delete this ?. It contains ${item.lessons.length} lessons ,these will be deleted`)
                                                                && this.props.deleteSection({ sectionId: item._id, _id: item._id })
                                                        }} type="button">Delete</button>
                                                    </div>
                                                </div>
                                                 <div className="keywords">
                                                        <p>
                                                        keywords:  {item.keywords && item.keywords}
                                                        </p>
                                             </div>
                                        </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div> :
                        <div className="text-Lesson">No Section yet to show :(</div>
                    }
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.sectionReducer.isLoading,
        isError: state.sectionReducer.isError,
        errorMessage: state.sectionReducer.errorMessage,
        success: state.sectionReducer.success,
        sections: state.sectionReducer.sections,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getSection: data => {
            dispatch(SectionMiddleware.getSection(data))
        },
        addSection: data => {
            return dispatch(SectionMiddleware.addSection(data))
        },
        editSection: data => {
            return dispatch(SectionMiddleware.editSection(data))
        },
        deleteSection: data => {
            dispatch(SectionMiddleware.deleteSection(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Section));