import React, { Component } from 'react';
import './SideBar.css';
import { Link } from 'react-router-dom';
import LocalStorageManager from '../../services/localStorageManager'

import 'bootstrap/dist/css/bootstrap.min.css';


class SideBar extends Component {
    render() {
        var routes = LocalStorageManager.getRoutes();
        return (
            <div className="col-sm-3 side-menu-list">
                <div id="sidebar-list-wrapper">
                    <ul id="sidebar-parent-list">
                        {
                            routes ? routes.map((item, index) => {
                                return (
                                    <li key={index} className={`sidebar-parent-li`}>
                                        <div className="group-link">
                                            <i className="fa fa-circle"></i>
                                            <Link to={item.path}>{item.title}</Link>
                                        </div>
                                    </li>
                                );
                            }) : null
                        }
                    </ul>

                </div>
            </div>

        );
    }
}

export default SideBar;