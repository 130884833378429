import React, { Component } from 'react';
import { connect } from "react-redux"
import { Select, Input } from "../Input/input"
import VoucherMiddleware from "../../store/middleware/voucherMiddleware";
import { Loader } from "../StudentManagment/helper"


class FindVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentName:"",
            studentFatherName:"",
            studentCnic:"",
            isLoading: false,
            errors: {
                hasError: false,
                errorsObj: {}
            },
            vouchers: []
        }
    }

    componentWillReceiveProps(nextProps) {
        const { errors } = this.state;

        if (nextProps.isError && nextProps.errorMessage) {
            errors.hasError = true;
            errors.simpleError = nextProps.errorMessage;
            this.setState({ errors, vouchers: [] })
            return
        }

        errors.hasError = false;
        errors.simpleError = ""
        console.log(nextProps.vouchers);
        if(nextProps.vouchers.voucherArray){
            this.setState({
                vouchers: nextProps.vouchers.voucherArray,
                studentName: nextProps.vouchers.studentInfo.fullName,
                studentCnic: nextProps.vouchers.studentInfo.studentCnic,
                studentFatherName: nextProps.vouchers.studentInfo.fatherName,
                fetched: nextProps.fetched,
                errors
            })
        }
    }
    submitPaid = (valid, data) => {
        if (!valid) {
            alert("Voucher Is Invalid")
            return;
        }
        this.props.statusVoucherUpdate(data)
    }

    markInvalid = (valid, data) => {
        if (!valid) {
            alert("Voucher Is Invalid")
            return;
        }
        this.props.markVoucherInvalid(data)
    }

    search = (ev) => {
        ev.preventDefault();
        const { identity, identityValue } = this.state;
        this.setState({ studentName: "" });
        this.props.search({ type: identity, value: identityValue });
    }

    render() {
        const { isLoading, errors, vouchers, fetched, studentName, studentFatherName, studentCnic  } = this.state;
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <div className="form-container  align-items-center">
                        <div className="form-wrapper">
                            <form onSubmit={(ev) => this.search(ev)} method="post">
                                <h3>Search Student Voucher</h3>
                                {errors.hasError && <p className="error">{errors.simpleError}</p>}
                                <Select
                                    label="Select Identity"
                                    name="identity"
                                    id="identity"
                                    options={[
                                        /*{ name: "CNIC", value: "cnic" },
                                        { name: "Number", value: "phoneNumber" },*/
                                        { name: "Roll Number", value: "rollNo" },
                                        /*{ name: "Email", value: "email" },*/
                                    ]}
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}
                                />
                                <Input
                                    label="Value"
                                    type="text"
                                    name="identityValue"
                                    id="identityValue"
                                    onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                    errors={errors}

                                />
                                <button className="my-btn-2" type="submit">Search</button>
                            </form>
                        </div>
                        {studentName &&
                            // <div>
                            <div className="" >
                                <table className="table  my-table" >
                                    <tbody>
                                        <tr><th >Name</th><td>{studentName}</td></tr>
                                        <tr><th >Father Name</th><td>{studentFatherName}</td></tr>
                                        {/*<tr><th >Student Cnic</th><td>{studentCnic}</td></tr>*/}
                                    </tbody>
                                </table>
                                <br /> <br />
                            </div>
                        }
                        {!!vouchers.length &&

                            <div className="form-wrapper-7">
                                {/* <h3>{voucher.quarter}</h3> */}
                                <div className="table-responsive" >
                                    <table className="table my-table" >
                                        <tbody>
                                            <tr>
                                                <th>Voucher Number</th>
                                                <th>Valid</th>
                                                <th>Status</th>
                                                <th>Roll No</th>
                                                <th>Course</th>
                                                <th>Batch</th>
                                                <th>Distance Learning</th>
                                                <th>Center Name</th>
                                                <th>Center Campus</th>
                                                <th>Class Time</th>
                                                <th>City Of Study</th>
                                                <th>Fee Amount</th>
                                                <th>Quarter</th>
                                                <th>Payment Deadline</th>
                                                <th></th>
                                            </tr>
                                            {
                                                vouchers.map((voucher, index) => {
                                                    return (

                                                        <tr key={index}>
                                                            <td>{voucher.fee_voucher_no}</td>
                                                            <td>{voucher.valid ? "valid" : "date-expired"}</td>
                                                            <td>{voucher.paid ? "Paid" : "Un-Paid"}</td>
                                                            <td>{voucher.rollNo}</td>
                                                            <td>{voucher.course}</td>
                                                            <td>{voucher.batch}</td>
                                                            <td>{`${voucher.distanceLearning}`}</td>
                                                            <td>{voucher.centerName}</td>
                                                            <td>{voucher.centerCampus}</td>
                                                            <td>{voucher.centerTime}</td>
                                                            <td>{voucher.cityOfStudy}</td>
                                                            <td>{voucher.feeAmount}</td>
                                                            <td>{voucher.quarter}</td>
                                                            <td>{voucher.paymentDeadline}</td>
                                                            {/* As payment will be collected manually that's why we are commenting this */}
                                                            {/* <td>
                                                                {
                                                                    voucher.paid || !voucher.valid ?
                                                                        <span></span>
                                                                        :
                                                                        <button className="btn btn-primary" onClick={() => { this.submitPaid(voucher.valid, { studentId: voucher.studentId, quarter: voucher.quarter, index }) }}>Paid</button>
                                                                }

                                                            </td> */}
                                                            {voucher.valid && !voucher.paid &&
                                                                <td>
                                                                    <button className="my-btn-3" onClick={() => { this.markInvalid(voucher.valid, { studentId: voucher.studentId, quarter: voucher.quarter, index }) }}>Mark Invalid</button>
                                                                </td>
                                                            }

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {!vouchers.length && fetched && "No Voucher Generated Yet"}
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.voucherReducer.isLoading,
        isError: state.voucherReducer.isError,
        errorMessage: state.voucherReducer.errorMessage,
        successMessage: state.voucherReducer.successMessage,
        vouchers: state.voucherReducer.vouchers,
        fetched: state.voucherReducer.fetched
    }
}
function mapDispatchToProps(dispatch) {
    return {
        search: data => {
            dispatch(VoucherMiddleware.search(data));
        },
        statusVoucherUpdate: (data) => {
            dispatch(VoucherMiddleware.statusVoucherUpdate(data));
        },
        markVoucherInvalid: (data) => {
            dispatch(VoucherMiddleware.markInvalid(data));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindVoucher);