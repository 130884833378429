import React, { Component, Fragment } from 'react'
import { Input, Select } from '../../Input/input'
import { connect } from 'react-redux'
import BatchMiddleware from '../../../store/middleware/batchMidleware'
import StudentCourseManagementButtons from '../StudentCourseManagementButtons';
import StudentCourseMiddleware from '../../../store/middleware/studentCourseMiddleware';
import { ALLOWED_STATUS_ADMIN } from '../../../config/helper'
import { Loader } from '../../StudentManagment/helper';

class AddStudentInCourse extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {
        hasError: false,
        errorsObj: {}
      },
      fail: "",
      courses: [],
      errorMessage: "",
      rollNo: "",
      course: "",
      batch: "",
      courseStatus: "",
      statusReason: "",
      addFail: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorMessage || nextProps.studentCourses) {
      this.setState({ addFail: "", fail:"" })
    }
  }

  componentDidMount() {
    this.props.getAllBatches()
  }

  handleFieldChange = event => {
    const { batches } = this.props
    const selectedName = event.target.name;
    const selectedValue = event.target.value;
    switch (selectedName) {
      case "batch":

        if (selectedValue !== "Select") {
          const filteredCourses = batches.find(item => item._id === selectedValue).courses
          this.setState({ courses: filteredCourses })
        }

      default:
        this.setState({ [selectedName]: selectedValue })
    }

  }


  searchStudent = (event, rollNo) => {
    event.preventDefault();
    if (!this.state.rollNo) {
      return this.setState({ fail: "field cannot be empty" })
    }
    this.props.searchStudentCourse(rollNo)
  }

  handleAddCourse = (event) => {
    event.preventDefault();
    const { course, batch, rollNo, statusReason, courseStatus } = this.state
    if (!course || !batch || !rollNo || !statusReason || !courseStatus) {
      return this.setState({ addFail: "Field cannot be empty" })
    }

    const isStudentEnrolled = this.props.studentCourses.studentCourse.some(item => {
      return item.courseId._id === course && item.batchId._id === batch
    });

    if (isStudentEnrolled) {
      this.setState({ addFail: "Student is already enrolled" })
      return
    }

    this.setState({
      course: "",
      batch: "",
      rollNo: "",
      statusReason: "",
      courseStatus: "",
      fail: "",
      addFail: ""
    })

    this.props.addStudentInCourse({ course, batch, rollNo, statusReason, courseStatus })

  }

  render() {
    const { errors, rollNo, batch, course, courses, statusReason, courseStatus, fail, addFail } = this.state
    const { batches, studentCourses,studentCourses: { student }, errorMessage, isLoading, successMessage } = this.props
    return (
      <div className="container-fluid">
        <div className="container">
          {isLoading && <Loader />}
          <StudentCourseManagementButtons />
          {successMessage && <p className="success text-center">{successMessage}</p>}
          {fail && <p className="error text-center">{fail}</p>}
          {errorMessage && <p className="error text-center">{errorMessage}</p>}
          <div className="form-container  align-items-center">
            <h4 className="text-center">Enroll Student In Course</h4>
            <div className="form-wrapper">
              <form>
                <Input
                  label="Roll No"
                  type="text"
                  name="rollNo"
                  id="rollNo"
                  value={rollNo.toUpperCase()}
                  onChange={this.handleFieldChange}
                  errors={errors}
                />
                <button className="my-btn-2" onClick={(event, roll) => this.searchStudent(event, rollNo)} >Search</button>

                {studentCourses.student &&
                  <Fragment>
                    {addFail && <p className="error text-center font-weight-bold">{addFail}</p>}
                    <Select
                      label="Batch"
                      name="batch"
                      id="batch"
                      value={batch}
                      onChange={this.handleFieldChange}
                      options={
                        batches && batches.length ? batches.map((batch, index) => {
                          return { name: `${batch.name}`, value: `${batch._id}` }
                        }) : []
                      }
                      errors={errors}
                    />

                    <Select
                      label="Course"
                      name="course"
                      id="course"
                      value={course}
                      onChange={this.handleFieldChange}
                      options={courses.length ? courses.map((course, index) => {
                        return { name: `${course.name}`, value: `${course._id}` }
                      }) : []}
                      errors={errors}
                    />

                    <Select
                      label="Course Status"
                      name="courseStatus"
                      id="courseStatus"
                      value={courseStatus}
                      options={
                        Object.keys(ALLOWED_STATUS_ADMIN).map((item, index) => {
                          return { name: item, value: item }
                        })
                      }
                      onChange={this.handleFieldChange}
                      errors={errors}
                    />
                    <Input
                      label="Status Reason"
                      type="statusReason"
                      name="statusReason"
                      id="statusReason"
                      value={statusReason}
                      onChange={this.handleFieldChange}
                      errors={errors}
                    />

                    <button onClick={(event) => this.handleAddCourse(event)} className="my-btn-2" type="submit">Add Student In Course</button>
                  </Fragment>
                }
              </form>
            </div>
          </div>
          <br />

          {studentCourses.student &&
            <div className="flexer" style={{ flexDirection: 'column' }}>
              <h4 className="text-center font-weight-bold ">Student Information</h4>
              <div className="admit-card-image" style={{ backgroundImage: `url(${student.imageUrl})` }}></div>


              <div style={{ width: '80%' }}>
                <table className="table  my-table" >
                  <tbody>
                    <tr><th >Student Name</th><td>{student.fullName}</td></tr>
                    <tr><th >Father Name</th><td>{student.fatherName}</td></tr>
                    <tr><th >Roll No</th><td>{student.rollNo}</td></tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          }

          {studentCourses.studentCourse && studentCourses.studentCourse.length === 0 &&
            <p className="error text-center">This student is not enrolled in any course yet</p>
          }



          {studentCourses.studentCourse && Boolean(studentCourses.studentCourse.length) &&
            <div>
              <h4 className="text-center font-weight-bold ">Student Courses</h4>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>Student Course</th>
                    <th>Batch</th>
                    <th>Completed Quarters</th>
                    <th>Current Status</th>
                    <th>Onsite</th>
                    <th>Graduated</th>
                    <th>Student Active</th>
                  </tr>
                </thead>
                <tbody>
                  {studentCourses.studentCourse.map((item, index) => {
                    return (
                      <tr key={item._id}>
                        <td>{item.courseName}</td>
                        <td>{item.batchName}</td>
                        <td>{item.completedQuarters}</td>
                        <td>{item.courseStatus}</td>
                        {item.currentlyOnsite ? <td>Yes</td> : <td>No</td>}
                        {item.isGraduated ? <td>Yes</td> : <td>No</td>}
                        {item.isActive ? <td>Yes</td> : <td>No</td>}
                      </tr>
                    )
                  })}
                </tbody>

              </table>
            </div>
          }
        </div>
      </div>
    )
  }
}



function mapStateToProps(state) {
  return {
    isLoading: state.studentCourseReducer.isLoading,
    isError: state.studentCourseReducer.isError,
    errorMessage: state.studentCourseReducer.errorMessage,
    success: state.studentCourseReducer.success,
    batches: state.batchReducer.batches,
    studentCourses: state.studentCourseReducer.studentCourses,
    successMessage: state.studentCourseReducer.successMessage
  };
}


function mapDispatchToProps(dispatch) {
  return {

    searchStudentCourse: data => {
      return dispatch(StudentCourseMiddleware.searchStudentCourse(data))
    },

    getAllBatches: data => {
      return dispatch(BatchMiddleware.searchBatches(data));
    },

    addStudentInCourse: data => {
      return dispatch(StudentCourseMiddleware.addStudentInCourse(data))
    }

  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStudentInCourse);




