import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Loader } from '../StudentManagment/helper';
import { Link } from 'react-router-dom';
import SendEmailViaRoll from './SendEmailViaRoll';
import EmailStudent from '../SendEmailTimeslots/SendEmailTimeslot'
import Status from '../schedulerStatus/SchedulerStatus'



class SendEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            screen: null
        }
    }



    handleScreen = (screen) => {

        this.setState({
            screen
        })

    }

    renderScreen = () => {
        const { screen } = this.state

        if (screen === "sendemailviaroll") {
            return <SendEmailViaRoll />
        } else if (screen === "sendemailviatime") {
            return <EmailStudent />
        }
        else if(screen === "basedOnRollNo"){
            return <Status schedulerType={ "EmailToNewlyRegisteredStudents" }/>
        }
        else if(screen === "basedOnTimeSlot"){
            return <Status schedulerType={ "EmailToStudentsByTimeSlot" }/>
        }
    }

    render() {
        const { loader } = this.state;
        return (
            <div>
                {
                    loader
                    &&
                    <Loader />
                }
                <div className="form-wrapper">

                    <button onClick={() => this.handleScreen('sendemailviaroll')} type="submit" className="my-btn-2">
                        Send email<br/> based on Roll No
                    </button>


                    <button onClick={() => this.handleScreen('sendemailviatime')} type="submit" className="my-btn-2">
                        Send email <br/>based on timeslots
                    </button>
                    <button type="submit" className="my-btn-2" onClick={() => this.handleScreen("basedOnRollNo")}>
                        Check status<br />based on roll No
                     </button>
                    <button type="submit" className="my-btn-2" onClick={() => this.handleScreen("basedOnTimeSlot")}>
                        Check status<br />based on timeslots
                     </button>
                </div>


                {this.renderScreen()}
            </div>
        );
    }

}



export default (SendEmail);