import BatchAction from "../actions/batchActions";
import AuthActions from "../actions/authActions";
import axios from "axios";
import Path from "../../config/path";
import LocalStorageManager from "../../services/localStorageManager";
import CourseToBatchActions from "../actions/courseTobatchActions";
class CourseToBatchMiddleware {
	static searchCoursesByBatch(data) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(CourseToBatchActions.getCourseByBatchId(data));
				axios
					.post(`${Path.GET_COURSES_BY_BATCHID}`, {
						batchId: data._id,
						databaseToken: user.databaseToken
					})
					.then(response => {
						if (!response.data.success) {
							dispatch(
								CourseToBatchActions.getCourseByBatchIdFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								CourseToBatchActions.getCourseByBatchIdSuccessul({
									success: true,
									data: response.data
								})
							);
						}
					})
					.catch(err => {
						console.log(err);
						dispatch(
							BatchAction.getBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
	static addCourseToBatch(data) {
		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(CourseToBatchActions.addCourseToBatch(data));
				return axios
					.post(`${Path.ADD_COURSE_TO_BATCH}`, {
						courseId: data.course._id,
						batchId: data.batchId,
						databaseToken: user.databaseToken
					})
					.then(response => {

						if (!response.data.success) {
							console.log("failed !!!!!!!!!!!!!!", response);
							dispatch(
								CourseToBatchActions.addCourseToBatchFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								CourseToBatchActions.addCourseToBatchSuccessul({
									success: true,
									data: data.course,
									index: data.index
								})
							);
						}
						return response.data.success;
					})
					.catch(err => {
						console.log("error...........................s=====>", err);
						dispatch(
							CourseToBatchActions.addCourseToBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
	static deleteCourseFromBatch(courseId, batchId, index) {

		return dispatch => {
			var user = LocalStorageManager.getUser();
			if (!user || !user.databaseToken) {
				dispatch(
					AuthActions.authenticationFailed({
						errorMessage: "Authentication Expired, Login Again"
					})
				);
			} else {
				dispatch(CourseToBatchActions.deleteCourseFromBatch());
				axios
					.post(`${Path.DELETE_COURSE_FROM_BATCH}`, {
						batchId,
						courseId,
						databaseToken: user.databaseToken
					})
					.then(response => {
						if (!response.data.success) {
							dispatch(
								CourseToBatchActions.deleteCourseFromBatchFailed({
									errorMessage: response.data.message
								})
							);
						} else {
							dispatch(
								CourseToBatchActions.deleteCourseFromBatchSuccessul({
									success: true,
									index: index
								})
							);
						}
					})
					.catch(err => {
						dispatch(
							CourseToBatchActions.deleteCourseFromBatchFailed({
								errorMessage: "Something Went Wrong, Please Try Again!"
							})
						);
					});
			}
		};
	}
}

export default CourseToBatchMiddleware;
