import BatchAction from "../actions/batchActions";
import CourseToBatchActions from "../actions/courseTobatchActions";

function courseToBatchReducer(
    state = {
        batches: null,
        courses: null,
        isError: false,
        isLoading: false,
        errorMessage: false,
        successMessage: null,
        edit: false,
        success: false
    },
    action
) {
    switch (action.type) {
        case CourseToBatchActions.GET_COURSE_BY_BATCHID:
            return {
                ...state,
                isError: false,
                isLoading: false,
                batches: action.data,
                courses: action.data.courses,
                errorMessage: null
            };
        case CourseToBatchActions.GET_COURSE_BY_BATCHID_FAILED:
            return {
                ...state,
                batches: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };
        case CourseToBatchActions.DELETE_COURSE_FROM_BATCH:
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null
            };
        case CourseToBatchActions.DELETE_COURSE_FROM_BATCH_SUCCESSFUL:
            state.courses.splice(action.data.index, 1)
            return {
                ...state,
                successMessage: null,
                isError: true,
                success: action.data.success,
                isLoading: false
            };
        case CourseToBatchActions.DELETE_COURSE_FROM_BATCH_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };
        case CourseToBatchActions.GET_COURSE_BY_BATCHID_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: true,
                errorMessage: null,
            };
        case CourseToBatchActions.ADD_COURSE_TO_BATCH:
            console.log(action.data.course);

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null
            };
        case CourseToBatchActions.ADD_COURSE_TO_BATCH_SUCCESSFUL:
            state.courses.push(action.data)
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null
            };
        case CourseToBatchActions.ADD_COURSE_TO_BATCH_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };
        default:
            return state;
    }
}
export default courseToBatchReducer;
