import VoucherActions from '../actions/voucherActions';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'

class VoucherMiddleware {

    static search(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(VoucherActions.searchVoucher(data));
                axios.post(`${Path.FIND_VOUCHER}`, {
                    type: data.type,
                    value: data.value,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(VoucherActions.searchVoucherFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(VoucherActions.searchVoucherSuccessul({ success: true, data: response.data.data}));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(VoucherActions.searchVoucherFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static statusVoucherUpdate(data) {
        return dispatch => {

            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(VoucherActions.statusVoucherUpdate(data));
                axios.post(Path.UPDATE_VOUCHER_STATUS, {
                    paymentId: data.paymentId,
                    fee_voucher_no: data.fee_voucher_no,
                    studentId: data.studentId,
                    quarter: data.quarter,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(VoucherActions.statusVoucherUpdateFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(VoucherActions.statusVoucherUpdateSuccessul({ success: true, data: response.data.data, index: data.index  }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(VoucherActions.statusVoucherUpdateFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static markInvalid(data) {
        return dispatch => {

            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(VoucherActions.statusVoucherUpdate(data));
                axios.post(Path.MARK_INVALID_VOUCHER, {
                    studentId: data.studentId,
                    quarter: data.quarter,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(VoucherActions.statusVoucherUpdateFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(VoucherActions.statusVoucherUpdateSuccessul({ success: true, data: response.data.data, index: data.index  }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(VoucherActions.statusVoucherUpdateFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
static searchVoucherByNo(data){
    return dispatch => {
        var user = LocalStorageManager.getUser();
        if (!user || !user.databaseToken) {
            dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
        }
        else {
            dispatch(VoucherActions.searchVoucher(data));
            axios.post(`${Path.FIND_VOUCHER_BY_NO}`, {
                voucherNo: data.voucherNo,
                databaseToken: user.databaseToken
            })
                .then((response) => {
                    if (!response.data.success) {
                        dispatch(VoucherActions.searchVoucherFailed({ errorMessage: response.data.message }));
                    }
                    else {
                        dispatch(VoucherActions.searchVoucherByNoSuccessul({ success: true, data: response.data.data}));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(VoucherActions.searchVoucherFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                });
        }
    }
}
static payVoucher(voucher){
    console.log("VOUCHER " , voucher)
    return dispatch => {
        var user = LocalStorageManager.getUser();
        if (!user || !user.databaseToken) {
            dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
        }
        else {
            dispatch(VoucherActions.statusVoucherUpdate(voucher));
            axios.post(`${Path.PAY_VOUCHER}`, {
                voucher:voucher,
                adminId:user.adminId,
                databaseToken: user.databaseToken
            })
                .then((response) => {
                    if (!response.data.success) {
                        dispatch(VoucherActions.statusVoucherUpdateFailed({ errorMessage: response.data.message }));
                    }
                    else {
                        dispatch(VoucherActions.voucherPaySuccess({ success: true, data: response.data.data }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(VoucherActions.statusVoucherUpdateFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                });
        }
    }
}
static myCollection(){
    let startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);

    let endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    return dispatch => {
        var user = LocalStorageManager.getUser();
        if (!user || !user.databaseToken) {
            dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
        }
        else {
            dispatch(VoucherActions.myCollection());
            axios.post(`${Path.MY_COLLECTION}`, {
                databaseToken: user.databaseToken,
                startOfDay,
                endOfDay
            })
                .then((response) => {
                    console.log(response)
                    if (!response.data.success) {
                        dispatch(VoucherActions.myCollectionFailed({ errorMessage: response.data.message }));
                    }
                    else {
                        dispatch(VoucherActions.myCollectionSuccessful({ success: true, feeCollected: response.data.feeCollected }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(VoucherActions.myCollectionFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                });
        }
    }
}
}

export default VoucherMiddleware;