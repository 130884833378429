/* eslint-disable*/
import React, { Component } from "react";
import { Input } from "../Input/input";
import { Loader } from "../StudentManagment/helper";
import { connect } from "react-redux";
import { validateForm } from "./helper";
import CourseMiddleware from "../../store/middleware/courseMiddleware";

class AddCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        hasError: false,
        errorsObj: {}
      },
      errorMessage: "",
      courses: [],
      name: "",
      initial: "",
      message: '',
      shortDescription: "",
      longDescription: "",
      edit: false,
      isLoading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      courses: nextProps.courses ? [...nextProps.courses] : [],
      errorMessage: nextProps.errorMessage,
      edit: nextProps.edit
    });
  }

  componentDidMount() {
    this.props.getCourses();
  }

  Submit = async ev => {
    ev.preventDefault();
    const {
      edit,
      name,
      initial,
      shortDescription,
      longDescription,
      _id,
      index
    } = this.state;


    var err = validateForm("all", {
      name,
      initial,
      shortDescription,
      longDescription
    });
    if (err.hasError) {
      this.setState({ errors: err });
      return;
    }

    this.setState({
      errors: {
        hasError: false,
        errorsObj: {}
      }
    });


    try { 
      if (edit) {
        await this.props.editCourse({
           name,
           initial,
           shortDescription,
           longDescription,
           _id,
           index
         });
   
         this.setState({
           success: true,
           name: "",
           initial: "",
           shortDescription: "",
           longDescription: "",
           edit: false,
           successMessage: "Updated Successfully"
         });
       } else {
         await this.props.addCourses({
           name,
           initial,
           shortDescription,
           longDescription
         });
         this.setState({
           success: true,
           name: "",
           initial: "",
           shortDescription: "",
           longDescription: "",
           successMessage: "Created Successfully"
         });
       }
   
    } catch(err){ 
      this.setState({ 
        errorMessage: 'Cannot Complete request, Please try again'
      })
    }
  };

  edit = (course, index) => {
    this.setState({
      ...course,
      edit: true,
      index,
      errors: {
        hasError: false,
        errorsObj: {}
      }
    });
  };

  render() {
    const {
      isLoading,
      errors,
      name,
      errorMessage,
      success,
      courses,
      successMessage,
      initial,
      shortDescription,
      longDescription,
      edit
    } = this.state;

    return (
      <div className="container-fluid">
        {isLoading && <Loader />}
        <div className="container">
          <div className="form-container  align-items-center">
            <div className="form-wrapper">
              <form onSubmit={ev => this.Submit(ev)} method="post">
                <h3>Add a Course</h3>
                {errorMessage && <p className="error">{errorMessage}</p>}
                {success && <p className="success">{successMessage}</p>}
                <Input
                  label="Course Name"
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={ev =>
                    this.setState({ [ev.target.name]: ev.target.value })
                  }
                  errors={errors}
                />

                <Input
                  label="Initial"
                  type="text"
                  name="initial"
                  id="initial"
                  value={initial}
                  onChange={ev =>
                    this.setState({ [ev.target.name]: ev.target.value })
                  }
                  errors={errors}
                />

                <Input
                  label="Short Description"
                  type="text"
                  name="shortDescription"
                  id="shortDescription"
                  value={shortDescription}
                  onChange={ev =>
                    this.setState({ [ev.target.name]: ev.target.value })
                  }
                  errors={errors}
                />

                <Input
                  label="Long Description"
                  type="text" 
                  name="longDescription"
                  id="longDescription"
                  value={longDescription}
                  onChange={ev =>
                    this.setState({ [ev.target.name]: ev.target.value })
                  }
                  errors={errors}
                />

                <button className="my-btn-2" type="submit">{edit ? "Update Course" : "Add Course"}</button>
                {edit && (<button className="my-btn-2" type="button"
                    onClick={() =>this.setState({
                        _id: "",
                        name: "",
                        initial: "",
                        shortDescription: "",
                        longDescription: "",
                        successMessage: '',
                        errors: {
                          hasError: false,
                          errorsObj: {},
                          simpleError: ""
                        },
                        edit: false,
                        index: null
                      })
                    }
                  >
                    Cancel Update</button>
                )}
              </form>
            </div>

            {!!courses.length && (
              <div className="table-wrapper">
                <br />
                <br />
                <br />
                <table className="table  my-table">
                  <tbody>
                    <tr>
                      <th>Course Name</th>
                      <th>Initials</th>
                      <th>Short Description</th>
                      <th>Long Description</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>

                    {courses.map((course, index) => {
                      return (
                        <tr key={index}>
                          <td>{course.name}</td>
                          <td>{course.initial}</td>
                          <td>{course.shortDescription}</td>
                          <td>{course.longDescription}</td>
                          <td>
                            <button
                              type="button"
                              className="my-btn-3"
                              onClick={() => this.edit(course, index)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="my-btn-3"
                              onClick={() =>
                                window.confirm(
                                  "Are You Sure You Want To Delete This"
                                ) &&
                                this.props.deleteCourses({
                                  courseId: course._id,
                                  index
                                })
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoading: state.courseReducer.isLoading,
    isError: state.courseReducer.isError,
    errorMessage: state.courseReducer.errorMessage,
    success: state.courseReducer.success,
    courses: state.courseReducer.courses,
    courseSections: state.courseReducer.courseSections
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCourses: data => {
      dispatch(CourseMiddleware.getCourses(data));
    },

    addCourses: data => {
      return dispatch(CourseMiddleware.addCourses(data));
    },

    editCourse: data => {
      dispatch(CourseMiddleware.editCourse(data));
    },

    deleteCourses: data => {
      dispatch(CourseMiddleware.deleteCourses(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCourse);
