import QuarterConfigActions from '../actions/quarterConfigActions'
const initialState = {
  isError: false,
  isQuarterUpdated: false,
  loading: false,
  errorMessage: '',
  successMessage: ''
}

const quarterConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case QuarterConfigActions.ADD_QUARTER_CONFIG:
      return Object.assign({}, state, {
        loading: true,
        successMessage: '',
        errorMessage: '',
        isError: false
      })

    case QuarterConfigActions.ADD_QUARTER_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        isError: false,
        loading: false,
        isQuarterUpdated: true,
        successMessage: action.payload.message,
        errorMessage: ''
      })

    case QuarterConfigActions.ADD_QUARTER_CONFIG_FAIL:
      return Object.assign({}, state, {
        isError: true,
        loading: false,
        isQuarterUpdated: false,
        errorMessage: action.payload.message,
        successMessage: ''
      })

    default:
      return state
  }
}

export default quarterConfigReducer
