import LessonAction from '../actions/lessonActions';
import AuthActions from '../actions/authActions';
import topicAction from '../actions/topicActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'


class LessonMiddleware {
    static getLesson(data) {

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(LessonAction.getLessons(data));
                axios.post(`${Path.GET_ALL_LESSONS}`, {
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(LessonAction.getLessonsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(LessonAction.getLessonsSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(LessonAction.getLessonsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static getTopics(data) {

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(LessonAction.getTopics(data));
                axios.post(`${Path.GET_TOPICS}`, {
                    lessonId: data._id,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(LessonAction.getTopicsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(LessonAction.getTopicsSuccessul({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(LessonAction.getTopicsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static deleteTopic(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(topicAction.deleteTopic(data));
                return axios.post(`${Path.DELETE_TOPIC}`, {
                    topicId: data.topicId,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(topicAction.deleteTopicFailed({ errorMessage: response.data.message }));
                        }
                        else {

                            dispatch(topicAction.deleteTopicSuccessul({ success: true, data: response.data.data }));
                        }
                        return response.data.success
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(topicAction.deleteTopicFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static deleteLesson(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(LessonAction.deleteLessons(data));
               return axios.post(`${Path.DELETE_LESSONS}`, {
                    lessonId: data.lessonId,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(LessonAction.deleteLessonsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(LessonAction.deleteLessonsSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data.success
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(LessonAction.deleteLessonsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static addLesson(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(LessonAction.addLessons(data));
                return axios.post(`${Path.ADD_LESSONS}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {

                        if (!response.data.success) {
                            dispatch(LessonAction.addLessonsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(LessonAction.addLessonsSuccessul({ success: true, data: response.data.data }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(LessonAction.addLessonsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static editLesson(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(LessonAction.editLessons(data));
                return axios.post(`${Path.EDIT_LESSONS}`, {
                    lessonId: data.lessonId,
                    data: { ...data.data },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(LessonAction.editLessonsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(LessonAction.editLessonsSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(LessonAction.editLessonsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default LessonMiddleware;