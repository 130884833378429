import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import { Input } from '../../Input/input';
import { connect } from "react-redux"
import LessonMiddleware from '../../../store/middleware/lessonsMiddleware';
/*eslint-disable*/

import SectionMiddleware from '../../../store/middleware/sectionMiddleware';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';

class ShowSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            search: "",
            searchedLessons: [],
            searchSectionLesson: "",
            searchedSectionLesson: [],
            sections: [],
            lessons: [],
            crrSectionIndex: null,
            sectionLesson: [

            ],
            showLesson: false,
            _id: "",
        }
    }


    componentDidMount = () => {
        const { history } = this.props;

        if (history && history.location) {
            const { section } = history.location.state;
            const { title, description, _id } = section;
            this.props.getLesson();

            this.setState({ title, description, sectionId: _id, sectionLesson: section.lessons })
        } else {
            this.props.history.push("/onlinevideosmanagement")
        }
    }


    componentWillReceiveProps(nextProps) {
        const { sectionLesson } = this.state;
        console.log("componentWillReceiveProps");

        this.setState({
            lessons: nextProps.lessons ? nextProps.lessons : [],
        })
        if (nextProps.lessons) {
            const lessons = nextProps.lessons
            var filter = []
            lessons.map((item, index) => {
                console.log("item", item);
                return sectionLesson.map((item1, index) => {
                    console.log("item1", item1);

                    if ((item1.lesson && item1.lesson._id === item._id) || item1.lesson === item._id) {
                        item.sequence = item1.sequence;
                        filter.push(item)
                    }
                })
            })
            console.log("filter", filter);



            this.setState({ sectionLesson: filter })
        }
    }


    searchSection = (keyword) => {
        const { sectionLesson } = this.state;
        const searchedSectionLesson = sectionLesson.filter((item, index) => {
            return item.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            searchSectionLesson: keyword,
            searchedSectionLesson
        })
    }



    search = (keyword) => {
        const { lessons } = this.state;
        const searchedLessons = lessons.filter((item, index) => {
            return item.title.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            search: keyword,
            searchedLessons
        })
    }

    removeLesson = (_id, index) => {
        const { sectionId, lessons } = this.state;
        this.props.removeLesson({ lessonId: _id, sectionId }).then((data) => {
            if (data.success) {
                var filter = []
                lessons.map((item, index) => {
                    console.log("item", item);
                    return data.data.lessons.map((item1, index) => {
                        console.log("item1", item1);

                        if ((item1.lesson && item1.lesson._id === item._id) || item1.lesson === item._id) {
                            item.sequence = item1.sequence;
                            filter.push(item)
                        }
                    })
                })
                console.log("filter", filter);
                this.setState({ sectionLesson: filter })
            }
        })
    }

    maintainLesson = (_id) => {
        const { sectionId, lessons } = this.state;
        var sequence = prompt("Add Sequence here of lesson");
        if (!sequence) {
            return;
        }
        this.setState({ showLesson: false })
        this.props.addSectionLesson({ lessonId: _id, sectionId, sequence }).then((data) => {
            if (data.success) {
                var filter = []
                lessons.map((item, index) => {
                    console.log("item", item);
                    return data.data.lessons.map((item1, index) => {
                        console.log("item1", item1);

                        if ((item1.lesson && item1.lesson._id === item._id) || item1.lesson === item._id) {
                            item.sequence = item1.sequence;
                            filter.push(item)
                        }
                    })
                })
                console.log("filter", filter);



                this.setState({ sectionLesson: filter })
            }
        })
    }



    render() {
        const { errors, search, searchedLessons, lessons, title, description, showLesson, sectionLesson, searchedSectionLesson, searchSectionLesson } = this.state;
        const { isLoading, errorMessage } = this.props;

        const finalLessons = search ? searchedLessons : lessons;
        const finalSectionLesson = searchSectionLesson ? searchedSectionLesson : sectionLesson;


        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="legends-container">
                        <fieldset>
                            <legend>Section</legend>
                            <div className="legend-input-container-3">
                                <span className="show-lesson-title">{title}</span>
                                <div className="show-lesson-des">
                                    <span >{description}</span>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div>



                        {sectionLesson.length ? lessons.length && <div>
                            <div className="legends-container">
                                <fieldset>
                                    <legend>Sections-Lessons</legend>
                                    <div className="legend-input-container-3">
                                        <Input
                                            label=" Search Lesson Here "
                                            type="text"
                                            name="searchSectionLesson"
                                            id="searchSectionLesson"
                                            value={searchSectionLesson}
                                            onChange={(ev) => this.searchSection(ev.target.value)}
                                            errors={errors}
                                        />
                                        <div className="collection-of-lesson">
                                            {finalSectionLesson.map((item, index) => {
                                                console.log(item);
                                                return (
                                                    <div key={index} className="lesson-li">
                                                        <div className="lecture-title">{item.sequence && item.sequence + ".  "} {item.title}</div>
                                                        <div className="zIndexBtn">
                                                            <button className="my-btn-3"
                                                                onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewlesson", state: { title: item.title, description: item.description, _id: item._id } })}
                                                                type="button">View</button>
                                                            <button className="my-btn-3" onClick={() => {
                                                                this.setState({ search: "", searchedSectionLesson: [] })
                                                                window.confirm("Are You Sure You Want To Delete?") && this.removeLesson(item._id, index)
                                                            }} type="button">Remove</button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                </fieldset>
                            </div>
                        </div> :
                            <div className="text-Lesson">No lesson in section yet to show :(</div>
                        }




                        <button className="my-btn-3"
                            onClick={() => this.setState({ showLesson: true })}
                            type="button">Add Lesson</button>

                        {showLesson &&
                            <div>{
                                lessons.length ? <div>
                                    <div className="legends-container">
                                        <fieldset>
                                            <legend>Lessons</legend>
                                            <div className="legend-input-container-3">
                                                <Input
                                                    label=" Search Lesson Here "
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    value={search}
                                                    onChange={(ev) => this.search(ev.target.value)}
                                                    errors={errors}
                                                />
                                                <div className="collection-of-lesson">
                                                    {finalLessons.map((item, index) => {
                                                        return (
                                                            <div key={index} className="lesson-li">
                                                                <div className="lecture-title">{item.title}</div>
                                                                <div className="zIndexBtn">
                                                                    <button className="my-btn-3"
                                                                        onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewlesson", state: { title: item.title, description: item.description, _id: item._id } })}
                                                                        type="button">View</button>
                                                                    {<button className="my-btn-3" onClick={() => this.maintainLesson(item._id)} type="button">Add</button>}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </fieldset>

                                    </div>
                                </div> :
                                    <div className="text-Lesson">No lesson yet to show :(</div>
                            }
                            </div>}

                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.lessonReducer.isLoading,
        isError: state.lessonReducer.isError,
        errorMessage: state.lessonReducer.errorMessage,
        success: state.lessonReducer.success,
        lessons: state.lessonReducer.lessons,


        isLoading: state.sectionReducer.isLoading,
        isError: state.sectionReducer.isError,
        errorMessage: state.sectionReducer.errorMessage,
        success: state.sectionReducer.success,
        sections: state.sectionReducer.sections


    }
}
function mapDispatchToProps(dispatch) {
    return {
        getLesson: data => {
            dispatch(LessonMiddleware.getLesson(data))
        },
        addSectionLesson: data => {
            return dispatch(SectionMiddleware.addSectionLesson(data))
        },
        removeLesson: data => {
            return dispatch(SectionMiddleware.removeSection(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowSection);
