import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'
import StudentCourseActions from '../actions/studentCourseActions';

class StudentCourseMiddleware {

    static searchStudentCourse(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentCourseActions.searchCourse(data));
                axios.post(`${Path.FIND_STUDENT_COURSES}`, {
                    rollNo: data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(StudentCourseActions.searchCourseFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(StudentCourseActions.searchCourseSuccessful({ success: true, data: response.data.studentData }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentCourseActions.searchCourseFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static updateStudentCourse(data) {
        return dispatch => {
            const { studentCourseId, updateReason, updatedStatus, updateIndex } = data
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentCourseActions.updateStudentCourse(data));
                axios.post(`${Path.UPDATE_STUDENT_COURSE}`, {
                    studentCourseId,
                    updateReason,
                    updatedStatus,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(StudentCourseActions.updateStudentCourseFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(StudentCourseActions.updateStudentCourseSuccessful({ success: true, message: response.data.message, updateIndex, updatedStatus }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentCourseActions.updateStudentCourseFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static addStudentInCourse(data) {
        return dispatch => {
            console.log("DATA IN MIDDLEWARE",data)
            const {batch, course, courseStatus, rollNo, statusReason } = data
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(StudentCourseActions.addStudentInCourse(data));
                axios.post(`${Path.ADD_STUDENT_IN_COURSE}`, {
                    rollNo,
                    batchId: batch,
                    courseId: course,
                    courseStatus,
                    statusReason,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(StudentCourseActions.addStudentInCourseFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(StudentCourseActions.addStudentInCourseSuccessful({ success: true, message: response.data.message }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(StudentCourseActions.addStudentInCourseFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

}

export default StudentCourseMiddleware;