import React, { Component } from 'react';
import { Loader } from "../../StudentManagment/helper"
import { connect } from "react-redux"
import TopicMiddleware from '../../../store/middleware/topicMiddleware';
import { Input } from "../../Input/input"


import "summernote/dist/summernote.min"
import "summernote/dist/summernote.css"
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import $ from "jquery";

import "./Topic.css"
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';

class Topic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            edit: false,
            contentType: "MIX",
            title: "",
            description: "",
            _id: "",
            sequence: "",
            videoURL: "",
            lesson: {}
        }
    }
    componentDidMount() {

        $(document).ready(function () {
            $("#courseContentEditor").summernote();
        });

        const { history } = this.props;
        console.log(history);

        if (history && history.location && history.location.state) {
            const { lesson, topic } = history.location.state;
            console.log(lesson);

            if (topic) {
                const { topic } = history.location.state;
                console.log(topic);
                const { title, description, sequence, _id, contentType, videoURL } = topic;
                $('#courseContentEditor').summernote('code', description)
                this.setState({ edit: true, title, description, lessonId: topic.lesson, lesson, _id, sequence, contentType, videoURL })
            } else {
                this.setState({ edit: false, title: "", description: "", _id: "", lesson, lessonId: lesson._id, sequence: "", contentType: "MIX", videoURL: "" })
            }
        } else {
            this.props.history.push("/onlinevideosmanagement")
        }
    }


    onSubmit = (ev) => {
        ev.preventDefault();
        var html = $('#courseContentEditor').summernote('code');


        const { edit, title, _id, lesson, lessonId, contentType, videoURL, sequence, } = this.state;
        console.log(lessonId);

        this.setState({ search: "", searchedLessons: [] })
        edit ? this.props.editTopic({ _id, data: { title, description: html, contentType, videoURL, sequence, lessonId } }).then((success) => {
            if (success) {
                $('#courseContentEditor').summernote('code', "");
                this.setState({ title: "", description: "", edit: false, _id: "", videoURL: "", sequence: "", })
            }
        }) : this.props.addTopic({ title, description: html, contentType, videoURL, sequence, lessonId }).then((success) => {
            if (success) {
                this.props.history.push({ pathname: "/onlinevideosmanagement/viewlesson", state: { ...lesson } })
                $('#courseContentEditor').summernote('code', "");
                this.setState({ title: "", description: "", edit: false, _id: "" })
            }
        })
    }

    cancelEdit = () => {
        $('#courseContentEditor').summernote('code', "");
        this.setState({
            edit: false,
            contentType: "MIX",
            title: "",
            _id: "",
            description: "",
            sequence: "",
            videoURL: ""
        })
    }

    render() {
        const { errors, edit, videoURL, sequence, title, contentType, lesson } = this.state;
        const { isLoading, errorMessage } = this.props;
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="main-box-container">
                        <form onSubmit={(ev) => this.onSubmit(ev)}>
                            <div className="legends-container">
                                <h3>Upload Lecture</h3>
                                <div className="radio-container">
                                    <div className="radio-wrapper radio-wrapper-2">
                                        <label htmlFor="video">
                                            <span className="label">Video</span>
                                            <input type="checkbox"
                                                name="content"
                                                id="video"
                                                value="video"
                                                checked={contentType === "MIX"}
                                                onChange={(ev) => this.setState({ contentType: contentType === "MIX" ? "PLANE" : "MIX" })}
                                            />
                                        </label>
                                        <label htmlFor="courseContent">
                                            <span className="label">Course Content</span>
                                            <input type="checkbox"
                                                name="content"
                                                id="courseContent"
                                                value="courseContent"
                                                checked={true}
                                                onChange={(ev) => { }}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <button className="my-btn-3 publishBtn" type="submit">{edit ? "Update" : "Publish"}</button>
                                {edit && <button onClick={() => this.cancelEdit()} className="my-btn-3 publishBtn" type="button">Cancel</button>}

                            </div>
                            <div className="legends-container">
                                <fieldset>
                                    <legend>Lesson Details</legend>
                                    <div className="legend-input-container-3">
                                        <span className="show-lesson-title">{lesson.title}</span>
                                        <div className="show-lesson-des">
                                            <span >{lesson.description}</span>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            {contentType === "MIX" && <div className="legends-container">
                                <fieldset>
                                    <legend>Video Details</legend>
                                    <div className="legend-input-container">
                                        <Input
                                            label="Video Title"
                                            type="text"
                                            name="title"
                                            id="title"
                                            value={title}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                        <Input
                                            label="Video URL"
                                            type="text"
                                            name="videoURL"
                                            id="videoURL"
                                            value={videoURL}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                        <Input
                                            label="Sequence #"
                                            type="text"
                                            name="sequence"
                                            id="sequence"
                                            value={sequence}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                    </div>
                                </fieldset>
                            </div>}
                            <div className="legends-container">
                                <fieldset>
                                    <legend>Course Content</legend>
                                    <div className="legend-input-container-2">
                                        {!(contentType === "MIX") && <Input
                                            label="Title"
                                            type="text"
                                            name="title"
                                            id="title"
                                            value={title}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />}

                                        {!(contentType === "MIX") && <Input
                                            label="Sequence #"
                                            type="text"
                                            name="sequence"
                                            id="sequence"
                                            value={sequence}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />}
                                        <div id="courseContentEditor"></div>
                                    </div>
                                </fieldset>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        isLoading: state.topicReducer.isLoading,
        isError: state.topicReducer.isError,
        errorMessage: state.topicReducer.errorMessage,
        success: state.topicReducer.success,
        edit: state.topicReducer.edit

    }
}
function mapDispatchToProps(dispatch) {
    return {
        addTopic: data => {
            return dispatch(TopicMiddleware.addTopic(data))
        },
        editTopic: data => {
            return dispatch(TopicMiddleware.editTopic(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topic);
