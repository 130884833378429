import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import { Input, Select } from '../../Input/input';
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import LessonsMiddleware from '../../../store/middleware/lessonsMiddleware';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';

class Lesson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            search: "",
            searchType: "title",
            title: "",
            keywords: "",
            description: "",
            searchedLessons: [],
            lessons: [

            ],
            _id: "",
        }
    }
    componentDidMount = () => {
        this.props.getLessons();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            lessons: nextProps.lessons ? nextProps.lessons : []
        })
    }
    onSubmit = (ev) => {
        ev.preventDefault();
        console.log(this.state)
        const { edit, title, description, _id, keywords } = this.state;
        this.setState({ search: "", searchedLessons: [] })
        edit ? this.props.editLesson({ lessonId: _id, _id, data: { title, keywords, description } }).then((success) => {
            if (success) {
                this.setState({ title: "", keywords: "", description: "", edit: false, _id: "" })
            }
        }) : this.props.addLesson({ title, keywords, description }).then((success) => {
            if (success) {
                this.setState({ title: "", keywords: "", description: "", edit: false, _id: "" })
            }
        })
    }


    search = (keyword) => {
        const { lessons, searchType } = this.state;
        console.log(searchType)
        const searchedLessons = lessons.filter((item) => {
            return item[searchType].toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
        })
        this.setState({
            search: keyword,
            searchedLessons
        })
    }
    render() {
        const { errors, edit, title, description, search, searchedLessons, lessons, keywords } = this.state;
        const { isLoading, errorMessage } = this.props;
        const finalLessons = search ? searchedLessons : lessons
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    <div className="main-box-container">
                        <form onSubmit={(ev) => this.onSubmit(ev)}>
                            {errorMessage && <p className="error text-center">{errorMessage}</p>}
                            <div className="legends-container">
                                <fieldset>
                                    <legend>{edit ? "Update Lesson | Must Fill Al Details" : "Create Lesson | Must Fill Al Details"}</legend>
                                    <div className="legend-input-container-3">
                                        <Input
                                            label="Title"
                                            type="text"
                                            name="title"
                                            id="title"
                                            value={title}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                        <Input
                                            label="keywords"
                                            type="text"
                                            name="keywords"
                                            id="keywords"
                                            value={keywords}
                                            onChange={(ev) => {
                                                console.log(ev.target.value)
                                                this.setState({ [ev.target.name]: ev.target.value })
                                            }}
                                            errors={errors}
                                        />
                                        <label htmlFor="description">
                                            <span className="label">Description</span>
                                        </label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            value={description}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                        >

                                        </textarea>
                                        <div className="text-right pt-3">
                                            <button className="my-btn-3 publishBtn" type="submit">{edit ? "Update Lesson" : "Create Lesson "}</button>
                                            {edit && <button className="my-btn-3 publishBtn" onClick={() => this.setState({ edit: false, title: "", keywords: "", description: "", _id: "" })} type="button">Cancel</button>}
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>


                    {lessons.length ? <div>
                        <div className="legends-container">
                            <fieldset>
                                <legend>Lectures</legend>
                                <div className="legend-input-container-3">
                                    <Input
                                        label=" Search Lesson Here "
                                        type="text"
                                        name="search"
                                        id="search"
                                        value={search}
                                        onChange={(ev) => this.search(ev.target.value)}
                                        errors={errors}
                                    />
                                    <div className="Search-select">
                                        <Select
                                            label="Select search type"
                                            name="searchType"
                                            id="searchType"
                                            selected="title"
                                            options={[
                                                { name: "title", value: "title" },
                                                { name: "keywords", value: "keywords" },
                                            ]}
                                            onChange={(ev) => this.setState({ [ev.target.name]: ev.target.value })}
                                            errors={errors}
                                        />
                                    </div>
                                    {finalLessons.map((item, index) => {
                                        return (
                                            <div className="lesson-list">
                                                <div key={index} className="lesson-li">
                                                    <div className="lecture-title">{item.title}</div>
                                                    <div className="zIndexBtn">
                                                        <button className="my-btn-3"
                                                            onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/viewlesson", state: { title: item.title, description: item.description, _id: item._id } })}
                                                            type="button">View</button>
                                                        <button className="my-btn-3" onClick={() => this.setState({ edit: true, index, title: item.title, keywords: item.keywords, description: item.description, _id: item._id })} type="button">Edit</button>
                                                        <button className="my-btn-3" onClick={() => {
                                                            this.setState({ search: "", searchedLessons: [] })
                                                            window.confirm("Are You Sure You Want To Delete?") && this.props.deleteLesson({ lessonId: item._id, _id: item._id })
                                                        }} type="button">Delete</button>
                                                    </div>
                                                </div>
                                                <div className="keywords">
                                                        <p>
                                                        keywords:  {item.keywords && item.keywords}
                                                        </p>
                                             </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </fieldset>
                        </div>
                    </div> :
                        <div className="text-Lesson">No lesson yet to show :(</div>
                    }
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.lessonReducer.isLoading,
        isError: state.lessonReducer.isError,
        errorMessage: state.lessonReducer.errorMessage,
        success: state.lessonReducer.success,
        lessons: state.lessonReducer.lessons,
        edit: state.lessonReducer.edit

    }
}
function mapDispatchToProps(dispatch) {
    return {
        getLessons: data => {
            dispatch(LessonsMiddleware.getLesson(data))
        },
        addLesson: data => {
            return dispatch(LessonsMiddleware.addLesson(data))
        },
        editLesson: data => {
            return dispatch(LessonsMiddleware.editLesson(data))
        },
        deleteLesson: data => {
            dispatch(LessonsMiddleware.deleteLesson(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lesson));