class VoucherActions {

    static SEARCH_VOUCHER = "SEARCH_VOUCH";
    static SEARCH_VOUCHER_SUCCESSFUL = "SEARCH_VOUCH_SUCCESSFUL";
    static SEARCH_VOUCHER_FAILED = "SEARCH_VOUCH_FAILED";
    static SEARCH_VOUCHER_BY_NO_SUCCESSFUL = "SEARCH_VOUCHER_BY_NO_SUCCESSFUL" 
    static VOUCHER_PAYMENT_SUCCESSFUL = "VOUCHER_PAYMENT_SUCCESSFUL"

    static STATUS_VOUCHER_UPDATE = "STATUS_VOUCHER_UPDATE";
    static STATUS_VOUCHER_UPDATE_SUCCESSFUL = "STATUS_VOUCHER_UPDATE_SUCCESSFUL";
    static STATUS_VOUCHER_UPDATE_FAILED = "STATUS_VOUCHER_UPDATE_FAILED";

    static MY_COLLECTION = "MY_COLLECTION";
    static MY_COLLECTION_SUCCESSFUL = "MY_COLLECTION_SUCCESSFUL";
    static MY_COLLECTION_FAILED = "MY_COLLECTION_FAILED";


    static searchVoucher(data) {
        return {
            type: this.SEARCH_VOUCHER,
            data: data
        }
    }
    static searchVoucherSuccessul(data) {
        return {
            type: this.SEARCH_VOUCHER_SUCCESSFUL,
            data: data.data,
        }
    }
    static searchVoucherFailed(data) {
        return {
            type: this.SEARCH_VOUCHER_FAILED,
            data: data
        }
    }

    static searchVoucherByNoSuccessul(data){
        return {
            type: this.SEARCH_VOUCHER_BY_NO_SUCCESSFUL,
            data: data
        }
    } 

    static statusVoucherUpdate(data) {
        return {
            type: this.STATUS_VOUCHER_UPDATE,
            data: data
        }
    }
    static statusVoucherUpdateSuccessul(data) {
        return {
            type: this.STATUS_VOUCHER_UPDATE_SUCCESSFUL,
            data: data.data,
            index:data.index
        }
    }
    static statusVoucherUpdateFailed(data) {
        return {
            type: this.STATUS_VOUCHER_UPDATE_FAILED,
            data: data
        }
    }

    static voucherPaySuccess(data){
        return{
            type:this.VOUCHER_PAYMENT_SUCCESSFUL,
            data:data.data
        }
    }


    static myCollection() {
        return {
            type: this.MY_COLLECTION,
        }
    }
    static myCollectionSuccessful(data) {
        return {
            type: this.MY_COLLECTION_SUCCESSFUL,
            data,
        }
    }
    static myCollectionFailed(data) {
        return {
            type: this.MY_COLLECTION_FAILED,
            data: data
        }
    }


}

export default VoucherActions;