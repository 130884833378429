export default class path {

    static BASE_URL = "https://api.piaic.org";

    // static BASE_URL = "http://localhost:3001";

    static REGISTRATION_FORM = path.BASE_URL + "/form";

    static CONTACT_US_FORM = path.BASE_URL + "/contactform"

    static TEST = path.BASE_URL + "/api/rest/test";

    static FIND_ID_CARD = path.BASE_URL + '/findIdCard';

    static AUTHENTICATE = path.BASE_URL + '/adminauth';

    static STUDENT_SEARCH = path.BASE_URL + '/admin/student/find';

    static STUDENT_EDIT = path.BASE_URL + '/admin/student/edit';

    static STUDENT_PIC_EDIT = path.BASE_URL + '/image'

    static AUTHENTICATE_TOKEN = path.BASE_URL + '/authtoken';

    static STATUS_APPROVED = path.BASE_URL + '/admin/student/approve';

    static STATUS_REJECTED = path.BASE_URL + '/admin/student/reject';

    static ADMIN_DELETE = path.BASE_URL + '/admin/delete';

    static ADMIN_ADD = path.BASE_URL + '/admin/add';

    static ADMIN_GETALL = path.BASE_URL + '/admin/getall';

    static ADMIN_EDIT = path.BASE_URL + '/admin/edit';

    static LOGOUT = path.BASE_URL + '/adminauth/logout';

    static GET_STATS = path.BASE_URL + '/admin/dashboard/stats';

    static ADD_CENTERS = path.BASE_URL + '/admin/center/add';

    static GET_CENTERS = path.BASE_URL + '/admin/center/getall';

    static DELETE_CENTER = path.BASE_URL + '/admin/center/delete';

    static EDIT_CENTER = path.BASE_URL + '/admin/center/edit';

    static GET_COURSES = path.BASE_URL + '/admin/courses/getall';

    static ADD_COURSE = path.BASE_URL + '/admin/courses/add';

    static DELETE_COURSE = path.BASE_URL + '/admin/courses/delete';

    static EDIT_COURSE = path.BASE_URL + '/admin/courses/edit';

    static GET_TIME_SLOTS = path.BASE_URL + '/admin/timeslot/getall';

    static DELETE_TIME_SLOTS = path.BASE_URL + '/admin/timeslot/delete';

    static EDIT_TIME_SLOTS = path.BASE_URL + '/admin/timeslot/edit';

    static ADD_TIME_SLOTS = path.BASE_URL + '/admin/timeslot/add';

    static FIND_VOUCHER = path.BASE_URL + '/admin/voucher/getvouchers';

    static UPDATE_VOUCHER_STATUS = path.BASE_URL + '/admin/voucher/updatestatus';

    static MARK_INVALID_VOUCHER = path.BASE_URL + '/admin/voucher/markinvalid';

    static SEND_EMAIL_ROLL = path.BASE_URL + '/email/newly-registered';

    static SEND_EMAIL = path.BASE_URL + '/admin/email/entrytest';

    static STUDENT_EMAIL_SEARCH = path.BASE_URL + '/admin/resententrytestmail/findstudent';

    static RESEND_EMAIL = path.BASE_URL + '/admin/resententrytestmail/resentemail';

    static UPDATE_EMAIL = path.BASE_URL + '/admin/resententrytestmail/updatemail';

    static SEND_EMAIL = path.BASE_URL + '/admin/email/entrytest';

    static ENTRY_TEST_RESULT = path.BASE_URL + "/examresult/uploadEntrytestResult";

    static ENTRY_TEST_RESULT_EMAIL = path.BASE_URL + "/admin/entrytest/sendEmailToPass";

    static UPLOAD_FEE_VOUCHER_DATA = path.BASE_URL + "/feevoucher/upload";

    static GET_ALL_LESSONS = path.BASE_URL + "/admin/coursecontent/lesson/getall";

    static ADD_LESSONS = path.BASE_URL + "/admin/coursecontent/lesson/add";

    static EDIT_LESSONS = path.BASE_URL + "/admin/coursecontent/lesson/edit";

    static DELETE_LESSONS = path.BASE_URL + "/admin/coursecontent/lesson/delete";

    static GET_TOPICS = path.BASE_URL + "/admin/coursecontent/topic/getlessontopics";

    static DELETE_TOPIC = path.BASE_URL + "/admin/coursecontent/topic/delete";

    static ADD_TOPIC = path.BASE_URL + "/admin/coursecontent/topic/add";

    static EDIT_TOPIC = path.BASE_URL + "/admin/coursecontent/topic/edit";

    static GET_ALL_SECTIONS = path.BASE_URL + "/admin/coursecontent/section/getall";

    static ADD_SECTION = path.BASE_URL + "/admin/coursecontent/section/add";

    static EDIT_SECTION = path.BASE_URL + "/admin/coursecontent/section/edit";

    static DELETE_SECTION = path.BASE_URL + "/admin/coursecontent/section/delete";

    static ADD_SECTION_LESSON = path.BASE_URL + "/admin/coursecontent/section/addlesson";

    static REMOVE_SECTION_LESSON = path.BASE_URL + "/admin/coursecontent/section/removelesson";

    static ADD_COURSE_CONTENT = path.BASE_URL + "/admin/coursecontent/add";

    static DELETE_COURSE_CONTENT = path.BASE_URL + "/admin/coursecontent/delete";

    static REMOVE_COURSE_SECTION = path.BASE_URL + "/admin/coursecontent/removesection";

    static ADD_COURSE_SECTION = path.BASE_URL + "/admin/coursecontent/addsection";

    static GET_ALL_COURSE_SECTIONS = path.BASE_URL + "/admin/coursecontent/getall";

    static ALLOW_COURSE = path.BASE_URL + "/admin/coursecontent/allowedcontent/add";

    static GET_ALLOWED_COURSE_CONTENT = path.BASE_URL + "/admin/coursecontent/allowedcontent/getall";

    static DELETE_ALLOWED_COURSE_CONTENT = path.BASE_URL + "/admin/coursecontent/allowedcontent/delete";

    static ADD_ALLOWED_COURSE_SECTION = path.BASE_URL + "/admin/coursecontent/allowedcontent/addsection";

    static REMOVE_ALLOWED_COURSE_SECTION = path.BASE_URL + "/admin/coursecontent/allowedcontent/removesection";

    static ADD_BATCH_CONFIG = path.BASE_URL + "/admin/batchconfig/add";

    static GET_ALL_BATCH_CONFIG = path.BASE_URL + "/admin/batchconfig/getAll";

    static DELETE_BATCH_CONFIG = path.BASE_URL + "/admin/batchconfig/delete";

    static UPDATE_BATCH_CONFIG = path.BASE_URL + "/admin/batchconfig/update";

    static ADD_INSTRUCTIONS = path.BASE_URL + "/admin/batchconfig/addInstruction";

    static REMOVE_INSTRUCTIONS = path.BASE_URL + "/admin/batchconfig/removeInstruction"

    static ADD_BATCH = path.BASE_URL + "/admin/batch/addBatch";

    static GET_BATCHES = path.BASE_URL + "/admin/batch/getAll";

    static DELETE_BATCH = path.BASE_URL + "/admin/batch/deleteBatch";

    static EDIT_BATCH = path.BASE_URL + "/admin/batch/updateBatch";

    static CLOSE_REG = path.BASE_URL + "/admin/batch/regClose";

    static START_REG = path.BASE_URL + "/admin/batch/regStart";

    static BATCH_COMPLETE = path.BASE_URL + "/admin/batch/batchComplete";

    static UPDATE_NEW_STUDENT_DATA = path.BASE_URL + "/updateNewStudentData";

    static GET_COURSES_BY_BATCHID = path.BASE_URL + "/admin/batch/getbatchbyid";

    static ADD_COURSE_TO_BATCH = path.BASE_URL + "/admin/batch/addCourse"

    static DELETE_COURSE_FROM_BATCH = path.BASE_URL + "/admin/batch/removeCourse"

    static GET_QUATERS = path.BASE_URL + "/admin/batchQuarter/getQuatersBatchCourse";

    static ADD_QUATER = path.BASE_URL + "/admin/batchQuarter/addQuater";

    static UPDATE_QUATER = path.BASE_URL + "/admin/batchQuarter/updateQuater";

    static DELETE_QUATER = path.BASE_URL + "/admin/batchQuarter/deleteQuaterById";

    static REG_START_QUATER = path.BASE_URL + "/admin/batchQuarter/regStart";

    static REG_CLOSE_QUATER = path.BASE_URL + "/admin/batchQuarter/regClose"

    static START_CLASS = path.BASE_URL + "/admin/batchQuarter/classStart"

    static TERMINATE_CLASS = path.BASE_URL + "/admin/batchQuarter/classComplete"

    static SEND_EMAIL_TIMESLOT = path.BASE_URL + "/email/byTimeSlot"

    static ADD_ANNOUNCEMENT = path.BASE_URL + "/admin/announcement/addAnnouncement";

    static DELETE_ANNOUNCEMENT = path.BASE_URL + "/admin/announcement/deleteAnnouncement";

    static GET_ALL_ANNOUNCEMENTS = path.BASE_URL + "/public/resource/announcement/getAnnouncements";

    static UPDATE_ANNOUNCEMENT = path.BASE_URL + "/admin/announcement/update";

    static SEND_SMS = path.BASE_URL + "/sms/sendSms";

    static GET_SCHEDULER = path.BASE_URL + "/admin/schedulerStatus/getAllScheduler";

    static GET_STATUS = path.BASE_URL + "/admin/schedulerStatus/getstatus";

    static FIND_VOUCHER_BY_NO = path.BASE_URL + "/admin/getvoucher/byvoucherno";

    static PAY_VOUCHER = path.BASE_URL + "/admin/getvoucher/payvoucher"

    static MY_COLLECTION = path.BASE_URL + '/admin/myFeeCollection'

    static FIND_STUDENT_COURSES = path.BASE_URL + '/admin/findStudentCourse'

    static UPDATE_STUDENT_COURSE = path.BASE_URL + '/admin/updateStudentCourse'

    static ADD_STUDENT_IN_COURSE = path.BASE_URL + '/admin/addStudentCourse'

    static ADD_QUARTER_CONFIG = path.BASE_URL + '/admin/add-quarter-config'

    static SEARCH_STIDENT_FOR_DELETE = path.BASE_URL + '/admin/remove-student/find'

    static DELETE_STUDENT = path.BASE_URL + '/admin/remove-student/delete'
}
