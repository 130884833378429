import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import LocalStorageManager from '../../services/localStorageManager';
import Path from '../../config/path';
import { Input } from "../Input/input";
import { Loader } from '../StudentManagment/helper';



class ExamResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            success: "",
            fail: "",
            file: null,
            src: "",
            emailTemplate: "",
            schedulerName: "",
            emailSubject: "",
            loader: false,
            errors: {
                hasError: false,
                errorsObj: {}
            }
        }
    }


    changeData = (ev) => {
        let file = ev.target.files[0];
        console.log(file.name.match(/\.(csv)$/i))
        if (ev.target.files[0].name.match(/\.(csv)$/i)) {
            if (ev.target.files && ev.target.files.length > 0) {
                console.log(ev.target.files)
                const reader = new FileReader();
                reader.addEventListener("load", () =>
                    this.setState({ src: reader.result })
                );
                reader.readAsDataURL(ev.target.files[0]);
            }
            console.log("file change22")
            console.log(URL.createObjectURL(this.refs.csvpicker.files[0]));
            console.log(this.refs.csvpicker.files[0]);
            this.setState({
                file: this.refs.csvpicker.files[0]
            })
            return this.setState({ fail: "", success: "", loader: false });
        } else {
            this.myFormRef.reset();
            return this.setState({ fail: "Please Select A Valid CSV File", success: "", loader: false });
        }
    }

    submit = (e) => {
        e.preventDefault();
        const { file, emailTemplate, schedulerName, emailSubject } = this.state;
        var user = LocalStorageManager.getUser();
        console.log(file);
        if (!emailTemplate) {
            return this.setState({ success: "", fail: "Please Create A Template" });
        }
        if (!emailSubject) {
            return this.setState({ success: "", fail: "Please Enter Email Subject" });
        }
        if (!file) {
            return this.setState({ fail: "Please Select a valid CSV File", success: "", loader: false });
        }

        this.setState({
            loader: true
        });



        var formData = new FormData();

        formData.append("file", file);
        formData.append("template", emailTemplate);
        formData.append("schedulerName", schedulerName);
        formData.append("emailSubject", emailSubject);
        formData.append("databaseToken", user.databaseToken);
        console.log(formData);

        console.log(user);
        fetch(Path.ENTRY_TEST_RESULT, {
            method: 'POST',
            body: formData
        }).then(data => data.json())
            .then(data => {
                console.log(data)
                if (!data.success) {
                    this.myFormRef.reset();
                    return this.setState({ fail: data.message, success: "", loader: false, file: null });
                }
                this.setState({ success: data.message, fail: "", loader: false, file: null, });
                this.myFormRef.reset();
                console.log(data);
            }).catch(err => {
                console.log(err);
                this.myFormRef.reset();
                this.setState({ fail: err.message, success: "", loader: false, file: null });
            })
    }

    render() {
        const { success, fail, loader, file, emailTemplate, schedulerName, errors, emailSubject } = this.state;
        return (
            <div>
                {
                    loader
                    &&
                    <Loader />
                }
                <div className="form-wrapper">


                    <form onSubmit={this.submit} ref={(el) => this.myFormRef = el} >
                        <div className="form-wrapper-2">
                            <h3  >
                                Enty Test Result Upload
                        </h3>
                            {fail && <p className="error">{fail}</p>}
                            {success && <p className="success">{success}</p>}
                            <div className="text-center">
                                <input type="file" name="csvpicker" ref="csvpicker"
                                    id="csvpicker" onChange={(ev) => this.changeData(ev)}
                                    accept="csv"
                                    style={{ display: "none" }}
                                />
                                <button
                                    type="button"
                                    className="my-btn-1"
                                    onClick={() => this.refs.csvpicker.click()}
                                >Select CSV File</button>
                                {file && <p>{file.name}</p>}

                            </div>

                            <Input
                                label="scheduler Name "
                                type="text"
                                name="Schedular Name"
                                id="username"
                                value={schedulerName}
                                length='30'
                                onChange={(ev) => { this.setState({ schedulerName: ev.target.value }) }}
                                errors={errors}
                            />

                            <Input
                                label="Email Subject"
                                type="text"
                                name="Email Subject"
                                id="username"
                                value={emailSubject}
                                length='30'
                                onChange={(ev) => { this.setState({ emailSubject: ev.target.value }) }}
                                errors={errors}
                            />

                            <label>
                                Email Template
                        </label>
                            <textarea required value={emailTemplate} onChange={(e) => this.setState({ emailTemplate: e.target.value })} />


                            <button type="submit" className="my-btn-2">
                                Upload Result
                                </button>
                        </div>

                    </form>
                </div>
            </div>
        );
    }

}



export default (ExamResult);