import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import StudentCourseActions from '../../store/actions/studentCourseActions'

const StudentCourseManagementButtons = (props) => {
    return (
        <div className="btn-container">
            <div className="innerbtngroup">
                <button className="my-btn-3 publishBtn" onClick={() => {
                    props.clearStudentCourse()
                    props.history.push("/courseManagement/addstudentincourse")
                }} type="button">Enroll Student In Course</button>
                <button className="my-btn-3 publishBtn" onClick={() => {

                    props.clearStudentCourse()
                    props.history.push({ pathname: "/courseManagement/updatecoursestatus" })
                }} type="button">Update Course Status</button>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    clearStudentCourse: () => dispatch(StudentCourseActions.clearStudentCourses())
})

export default connect(null, mapDispatchToProps)(withRouter(StudentCourseManagementButtons))