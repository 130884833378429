/*eslint-disable */
import React from "react";

function validateForm(check, data) {
    const {
        courseName,
    } = data;

    var errors = {
        hasError: false,
        errorsObj: {}
    }

    let Validation = {
        course: {
            Validate: [{
                condition: !courseName.length || courseName === "Select",
                message: " Please Specify Course Name . ",
            }
            ],
            elem: "courseName"
        },

    }
    console.log("Working1");

    if (check === "all") {
        for (var i in Validation) {
            var conArray = Validation[i].Validate;
            errors.errorsObj[Validation[i].elem] = { message: [] }
            for (var j = 0; j < conArray.length; j++) {
                if (conArray[j].condition) {
                    errors.hasError = true
                    errors.errorsObj[Validation[i].elem].message.push(conArray[j].message)
                }
            }
            if (!errors.errorsObj[Validation[i].elem].message.length) {
                delete errors.errorsObj[Validation[i].elem];
            }
        }
    }
    console.log("errors....", errors);


    return Object.keys(errors).length > 1 ? errors : {
        hasError: false
    }
}

export { validateForm };



