import axios from 'axios';
import Path from '../../config/path';
import AuthActions from '../actions/authActions';
import LocalStorageManager from '../../services/localStorageManager'
import AllowCourseActions from '../actions/allowCourseActions';


class AllowCourseMiddleware {



    static allowCourseContent(data) {
        console.log(data);
        
        console.log("allowCourseContentData", data);

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(AllowCourseActions.allowCourse(data));
                return axios.post(`${Path.ALLOW_COURSE}`, {
                    data: {
                        ...data
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log('allowCourseContent', response);
                        // console.log(response);

                        if (!response.data.success) {
                            dispatch(AllowCourseActions.allowCourseFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(AllowCourseActions.allowCourseSuccessful({ success: true, data: response.data.data }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log('allowCourseContentERRR====>', err);
                        dispatch(AllowCourseActions.allowCourseFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }


    static addCourseSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(AllowCourseActions.addSectionInAllowedCourseContent(data));
                return axios.post(`${Path.ADD_ALLOWED_COURSE_SECTION}`, {
                    data: {
                        sectionId: data.sectionId,
                        allowedContentId: data.allowedContentId,
                        sequence:data.sequence
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(AllowCourseActions.addSectionInAllowedCourseContentFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(AllowCourseActions.addSectionInAllowedCourseContentSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(AllowCourseActions.addSectionInAllowedCourseContentFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static removeSection(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(AllowCourseActions.removeSectionInAllowedCourseContent(data));
                return axios.post(`${Path.REMOVE_ALLOWED_COURSE_SECTION}`, {
                    data: {
                        sectionId: data.sectionId,
                        allowedContentId: data.allowedContentId,
                    },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(AllowCourseActions.removeSectionInAllowedCourseContentFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(AllowCourseActions.removeSectionInAllowedCourseContentSuccessul({ success: true, data: response.data.data, _id: data._id }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(AllowCourseActions.removeSectionInAllowedCourseContentFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }

    static deleteCourseContent(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(AllowCourseActions.deleteAllowedCourseContents(data));
                return axios.post(`${Path.DELETE_ALLOWED_COURSE_CONTENT}`, {
                    allowedContentId: data.allowedContentId,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);

                        if (!response.data.success) {
                            dispatch(AllowCourseActions.deleteAllowedCourseContentsFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(AllowCourseActions.deleteAllowedCourseContentsSuccessul({ success: true, data: response.data.data, _id: data.allowedContentId }));
                        }
                        return response.data
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(AllowCourseActions.deleteCourseContentsFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }




    static getAllowedCourseContent(data) {

        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(AllowCourseActions.getAllowedCourses(data));
                axios.post(`${Path.GET_ALLOWED_COURSE_CONTENT}`, {
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response);


                        if (!response.data.success) {
                            dispatch(AllowCourseActions.getAllowedCoursesFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(AllowCourseActions.getAllowedCoursesSuccessfull({ success: true, data: response.data.data }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(AllowCourseActions.getAllowedCoursesFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }






}

export default AllowCourseMiddleware;