import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import LocalStorageManager from '../../services/localStorageManager';
import Path from '../../config/path';
import { Input, Select } from "../Input/input";
import { Loader } from '../StudentManagment/helper';
import { validateForm } from './helper'



class UpdateStudent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            success: "",
            fail: "",
            file: null,
            src: "",


            schedulerName: "",
            batch: '',
            minutes: '',

            loader: false,
            errors: {
                hasError: false,
                errorsObj: {}
            }
        }
    }


    changeData = (ev) => {
        let file = ev.target.files[0];
        if (ev.target.files[0].name.match(/\.(csv)$/i)) {
            if (ev.target.files && ev.target.files.length > 0) {
                const reader = new FileReader();
                reader.addEventListener("load", () =>
                    this.setState({ src: reader.result })
                );
                reader.readAsDataURL(ev.target.files[0]);
            }
            this.setState({
                file: this.refs.csvpicker.files[0]
            })
            return this.setState({ fail: "", success: "", loader: false });
        } else {
            this.myFormRef.reset();
            return this.setState({ fail: "Please Select A Valid CSV File", success: "", loader: false });
        }
    }

    submit = (e) => {
        e.preventDefault();
        const { file, schedulerName, batch, minutes } = this.state;

        var user = LocalStorageManager.getUser();


        const err = validateForm('all', { schedulerName, batch, minutes, })

        this.setState({
            errors: err
        })


        if (!file) {
            return this.setState({ fail: "Please Select a valid CSV File", success: "", loader: false });
        }


        if (err.hasError) {
            return;
        }


        this.setState({
            loader: true
        });


        var formData = new FormData();

        formData.append("file", file);
        formData.append("schedulerName", schedulerName);
        formData.append("batch", batch);
        formData.append("minutes", minutes);
        formData.append("databaseToken", user.databaseToken);


        fetch(Path.UPDATE_NEW_STUDENT_DATA, {
            method: 'POST',
            body: formData
        }).then(data => data.json())
            .then(data => {
                console.log(data)
                if (!data.success) {
                    this.myFormRef.reset();
                    return this.setState({ fail: data.message, success: "", loader: false, file: null });
                }
                this.setState({ success: data.message, fail: "", loader: false, file: null, });
                this.myFormRef.reset();

            }).catch(err => {
                this.myFormRef.reset();
                this.setState({ fail: err.message, success: "", loader: false, file: null });
            })



    }


    render() {
        const { success, fail, loader, file, schedulerName, errors, batch, minutes, } = this.state;
        return (
            <div>
                {
                    loader
                    &&
                    <Loader />
                }
                <div className="form-wrapper">
                    <form onSubmit={this.submit} ref={(el) => this.myFormRef = el}>
                        <h3  >
                            Update Student
                        </h3>

                        {fail && <p className="error">{fail}</p>}
                        {success && <p className="success">{success}</p>}

                        <div className="text-center">
                            <input type="file" name="csvpicker" ref="csvpicker"
                                id="csvpicker" onChange={(ev) => this.changeData(ev)}
                                accept="csv"
                                style={{ display: "none" }}
                            />
                            <button
                                type="button"
                                className="my-btn-1"
                                onClick={() => this.refs.csvpicker.click()}
                            >Select CSV File</button>
                            {file && <p>{file.name}</p>}

                        </div>


                        <Input
                            label="Scheduler Name"
                            type="text"
                            name="schedulerName"
                            id="schedulerName"
                            value={schedulerName}
                            length='30'
                            onChange={(ev) => { this.setState({ [ev.target.name]: ev.target.value }) }}
                            errors={errors}
                        />

                        <Input
                            label="No of Emails in one batch"
                            type="number"
                            name="batch"
                            id="batch"
                            value={batch}
                            onChange={(ev) => { this.setState({ [ev.target.name]: ev.target.value }) }}
                            errors={errors}
                        />


                        <Input
                            label="Minutes per batch"
                            type="number"
                            name="minutes"
                            id="minutes"
                            value={minutes}
                            onChange={(ev) => { this.setState({ [ev.target.name]: ev.target.value }) }}
                            errors={errors}
                        />



                        <button type="submit" className="my-btn-2">
                            Update Student
                        </button>
                    </form>
                </div>
            </div>
        );
    }

}




export default (UpdateStudent);