import QuaterAction from "../actions/quaterActions";

function quaterReducer(
    state = {
        quaters: [],
        isError: false,
        isLoading: false,
        errorMessage: false,
        successMessage: null,
        edit: false,
        success: false
    },
    action
) {
    switch (action.type) {

        case QuaterAction.COMPLETE_CLASS:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case QuaterAction.COMPLETE_CLASS_SUCCESSFUL:
			state.quaters[action.index] = { ...action.data.data };
			console.log("log data of action ", action.data);
			return {
				...state,
				isError: false,
				isLoading: false,
				errorMessage: null,
				successMessage: action.success,
			};
		case QuaterAction.COMPLETE_CLASS_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		case QuaterAction.START_CLASS:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case QuaterAction.START_CLASS_SUCCESSFUL:
			state.quaters[action.index] = { ...action.data.data };
			console.log("log data of action ", action.data);
			return {
				...state,
				isError: false,
				isLoading: false,
				errorMessage: null,
				successMessage: action.success,
			};
		case QuaterAction.START_CLASS_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		case QuaterAction.CLOSE_QUATER_REG:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case QuaterAction.CLOSE_QUATER_REG_SUCCESSFUL:
			state.quaters[action.index] = { ...action.data.data };
			console.log("log data of action ", action.data);
			return {
				...state,
				isError: false,
				isLoading: false,
                errorMessage: null,
                success: action.data.success,
			};
		case QuaterAction.CLOSE_QUATER_REG_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		case QuaterAction.START_QUATER_REG:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
				success: false,
				isLoading: true
			};
		case QuaterAction.START_QUATER_REG_SUCCESSFUL:
			state.quaters[action.index] = { ...action.data.data };
			console.log("log data of action ", action.data);
			return {
				...state,
				isError: false,
				isLoading: false,
                errorMessage: null,
                success: action.data.success,
			};
		case QuaterAction.START_QUATER_REG_FAILED:
			return {
				...state,
				errorMessage: action.data.errorMessage,
				successMessage: null,
				isError: true,
				success: false,
				isLoading: false
			};
		
        case QuaterAction.GET_QUATERS:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                success: false,
                isLoading: true
            };
        case QuaterAction.GET_QUATERS_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                quaters: action.data,
                errorMessage: null
            };
        case QuaterAction.GET_QUATERS_FAILED:
            return {
                ...state,
                quaters: [],
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };
        case QuaterAction.ADD_QUATER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false
            };
        case QuaterAction.ADD_QUATER_SUCCESSFUL:
            console.log(action.data);
            console.log("state quaters", state.quaters)
            state.quaters.push({ ...action.data });
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null
            };
        case QuaterAction.ADD_QUATER_FAILED:
            console.log(action.data);

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false
            };
        case QuaterAction.EDIT_QUATER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                edit: true,
                success: false,
                isLoading: true
            };
        case QuaterAction.EDIT_QUATER_SUCCESSFUL:
            console.log("aby yar kahan jae ga to ")
            state.quaters[action.index] = { ...action.data };
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                edit: false,
                success: action.success
            };
        case QuaterAction.EDIT_QUATER_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                edit: true,
                success: false
            };

        case QuaterAction.DELETE_QUATER:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true
            };
        case QuaterAction.DELETE_QUATER_SUCCESSFUL:
            console.log("batch ation delete =====> ", action.index);
            console.log("success", action.success)
            state.quaters.splice(action.index, 1);

            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                edit: false,
                success: action.success,
                successMessage:action.data.message,
            };
        case QuaterAction.DELETE_QUATER_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };

        default:
            return state;
    }
}
export default quaterReducer;
