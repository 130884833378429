import StudentActions from '../actions/studentActions';

function studentReducer(state = {
    student: null,
    isError: false,
    isLoading: false,
    errorMessage: false,
    userId: null,
    successMessage: null,
    deleted:false
}, action) {
    switch (action.type) {

        case StudentActions.SEARCH_STUDENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case StudentActions.SEARCH_STUDENT_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                student: action.data.data,
                errorMessage: null,
            };
        case StudentActions.SEARCH_STUDENT_FAILED:
            return {
                ...state,
                student: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };
        case StudentActions.EDIT_STUDENT:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case StudentActions.EDIT_STUDENT_SUCCESSFUL:
            console.log('action ', action.data);
            return {
                ...state,
                isError: false,
                isLoading: false,
                student: action.data.data,
                errorMessage: null,
            };
        case StudentActions.EDIT_STUDENT_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                student: null,
                isError: true,
                isLoading: false
            };

        case StudentActions.STATUS_STUDENT_UPDATE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
            };
        case StudentActions.STATUS_STUDENT_UPDATE_SUCCESSFUL:
            console.log('action ', action.data);
            return {
                ...state,
                isError: false,
                isLoading: false,
                student: action.data.data,
                errorMessage: null,
            };
        case StudentActions.STATUS_STUDENT_UPDATE_FAILED:
            return {
                ...state,
                student: null,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false
            };

        case StudentActions.SEARCH_STUDENT_FOR_DELETE:
            return {
                ...state,
                student:null,
                isError: false,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                deleted: false
            }
        case StudentActions.SEARCH_STUDENT_FOR_DELETE_SUCCESSFUL:
            return {
                ...state,
                successMessage: action.data.successMessage,
                student: action.data.data,
                isLoading: false,
                isError: false
            }
        case StudentActions.SEARCH_STUDENT_FOR_DELETE_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isLoading: false,
                isError: true
            }
        case StudentActions.DELETE_STUDENT:
            return {
                ...state,
                isError: false,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                deleted:false
            }
        case StudentActions.DELETE_STUDENT_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                errorMessage: null,
                successMessage: "Student deleted successfully",
                isLoading: false,
                student: null,
                deleted:true
            }
        case StudentActions.DELETE_STUDENT_FAILED:
            return {
                ...state,
                isError: true,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isLoading: false,
                student: null,
                deleted:false
            }
        default:
            return state;
    }
}
export default studentReducer;