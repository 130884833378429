/*eslint-disable*/

import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import _ from 'lodash';


class EditAdmin extends Component {
    constructor(props) {
        super(props);
        let data = this.props.adminList[this.props.index];
        this.state = {
            adminList: [],
            userName: data.userName,
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber,
            _id: this.props._id
        }
    }



    submitEditAdmin = (e) => {
        e.preventDefault();
        let data = _.pick(this.state, ["userName", "name", "email", "phoneNumber", "_id"]);
        this.props.editAdmin(data)
    }

    render() {
        const { name, userName, phoneNumber, email } = this.state;
        console.log(this.state._id)
        return (
            <Modal open={this.props.open} onClose={this.props.onCloseModal} >
                <div className="container" >
                    <form className="" onSubmit={(e) => this.submitEditAdmin(e)} >

                        <div className="form-row" >
                            <div className="form-group col-md-6">
                                <label >
                                    Full Name:
                                </label>
                                <input
                                    required
                                    value={name}
                                    type='text'
                                    minLength={3}
                                    className="form-control"
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label>
                                    User Name:
                                </label>
                                <input
                                    value={userName}
                                    required
                                    minLength={3}
                                    onChange={(e) => this.setState({ userName: e.target.value })}
                                    type='text'
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-row">

                            <div className="col-md-6">
                                <label>
                                    Email:
                            <input
                                        value={email}
                                        type='email'
                                        required
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        className="form-control"
                                    />
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label>
                                    Phone Number:
                            <input
                                        value={phoneNumber}
                                        required
                                        type='text'
                                        maxLength={11}
                                        className="form-control"
                                        onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                                    />
                                </label>
                            </div>
                        </div>
                        <div style={{ padding: 7 }} >
                            <button style={{ marginRight: 10 }} className="btn btn-success" >Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default EditAdmin;
