import EmailActions from '../actions/emailActions';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'

class AuthMiddleware {

    static sendEmail(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                //this.props.history.replace("/");
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(EmailActions.emailSending(data))
                console.log(">>",data.csvs)
                axios.post(`${Path.SEND_EMAIL}`, {
                    csvs:data.csvs,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        if (!response.data.success) {
                            dispatch(EmailActions.emailSendingFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            console.log("response.data.message",response.data.message)
                            dispatch(EmailActions.emailSendingSuccessul({ success: true, successMessage: response.data.message }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(EmailActions.emailSendingFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default AuthMiddleware;