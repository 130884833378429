import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "typicons.font/src/font/typicons.css"
import AuthMiddleware from "../../store/middleware/authMiddleware";
import { connect } from 'react-redux';
import EditAdmin from './editAdmin';
import Path from '../../config/path';

class CreateAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adminList: [],
            userName: "irfan12",
            name: "irfanali",
            email: "irfan@yahg.com",
            password: "1234",
            phoneNumber: "0364478634",
            role: "Select",
            open: false,
            index: null,
            _id: null
        }
    }

    deleteAdmin(index, _id) {

        const { adminList } = this.state;
        let databaseToken = this.props.authToken;

        //fetch('http://localhost:3001/admin/delete', {
        fetch(Path.ADMIN_DELETE, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ databaseToken: databaseToken, _id })
        })
            .then(res => res.json())
            .then(response => {
                if (!response.success) {
                    return console.log(response);

                }
                console.log(response);
                console.log(adminList);
                adminList.splice(index, 1);
                console.log(adminList);
                this.setState({ adminList });

            }).catch(err => {
                console.log(err)
            })
    }

    createAdmin = (e) => {
        e.preventDefault();
        const { name, userName, password, email, phoneNumber, role } = this.state;



        if (role === "Select") {
            return console.log('Please Slect Your Role');
        }
        let data = {};
        data.name = name;
        data.userName = userName;
        data.password = password;
        data.email = email;
        data.phoneNumber = phoneNumber;
        data.role = role;
        data.databaseToken = this.props.authToken;
        //fetch('http://localhost:3001/admin/add', {
        fetch(Path.ADMIN_ADD, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(adminDetail => {
            if (adminDetail.success) {
                const { adminList } = this.state;

                let newAdmin = adminDetail.message;

                adminList.push(newAdmin);

                this.setState({ adminList })
            }
            else {
                console.log(adminDetail)
            }


        }).catch(err => {
            console.log(err);
        })

    }

    componentDidMount() {

        console.log(this.props.authToken);

        let databaseToken = this.props.authToken;




        //fetch('http://localhost:3001/admin/getall', {
        fetch(Path.ADMIN_GETALL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ databaseToken: databaseToken })
        }).then(dataInJson => {
            return dataInJson.json();
        }).then(response => {
            console.log(response);
            if (response.success) {
                this.setState({ adminList: response.data })
            }
        }).catch(err => {
            console.log(err);
        })
    }



    inputField() {
        const { name, userName, password, phoneNumber, email, role } = this.state;
        return (
            <div className="container" >
                <form className="" onSubmit={this.createAdmin}  >

                    <div className="form-row" >
                        <div className="form-group col-md-4">
                            <label >
                                Full Name:
                                </label>
                            <input
                                required
                                value={name}
                                type='text'
                                minLength={3}
                                className="form-control"
                                onChange={(e) => this.setState({ name: e.target.value })}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>
                                User Name:
                                </label>
                            <input
                                value={userName}
                                required
                                minLength={3}
                                onChange={(e) => this.setState({ userName: e.target.value })}
                                type='text'
                                className="form-control"
                            />
                        </div><div className="form-group col-md-4">
                            <label>
                                Password:
                                </label>
                            <input
                                value={password}
                                required
                                minLength={3}
                                type='text'
                                className="form-control"
                                onChange={(e) => this.setState({ password: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="form-row">

                        <div className="col-md-3">
                            <label>
                                Email:
                            <input
                                    value={email}
                                    type='email'
                                    required
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    className="form-control"
                                />
                            </label>
                        </div>
                        <div className="col-md-3">
                            <label>
                                Phone Number:
                            <input
                                    value={phoneNumber}
                                    required
                                    type='text'
                                    maxLength={11}
                                    className="form-control"
                                    onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                                />
                            </label>
                        </div>
                        <div className="col-md-3">
                            <label>
                                Role:
                            <select required className="form-control" value={role} onChange={(e) => this.setState({ role: e.target.value })}>
                                    <option className="myOptions" value="Select">Select</option>
                                    <option className="myOptions" value="Admin">Admin</option>
                                    <option className="myOptions" value="Accounts">Accounts</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div style={{ padding: 7 }} >
                        <button style={{ marginRight: 10 }} className="btn btn-success" >Submit</button>
                        <button className="btn btn-info" onClick={this.cancle}>Cancle</button>
                    </div>
                </form>
            </div>
        )
    }

    onOpenModal = (index, _id) => {
        this.setState({ open: true, index, _id });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    editAdmin = (data) => {

        const { adminList, index } = this.state;

        data.databaseToken = this.props.authToken;

        // console.log(data, index);
        //fetch('http://localhost:3001/admin/edit', {
        fetch(Path.ADMIN_EDIT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json())
            .then(newData => {
                console.log(newData);
                if (newData.success) {
                    adminList[index] = newData.data;
                    this.setState({ adminList: adminList });
                    this.onCloseModal();
                }
            })
    }


    render() {
        const { adminList, open, index, _id } = this.state;
        return (
            <div>
                {this.inputField()}
                {open &&
                    <EditAdmin open={open} adminList={adminList} editAdmin={this.editAdmin} _id={_id} index={index} onCloseModal={this.onCloseModal} />
                }
                <div>
                    <table className='table table-striped' >
                        <thead>
                            <tr>
                                <th>Admin Name</th>
                                <th>User Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Phone Number</th>
                                <th>Edit</th>
                                <th>Delete Admin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminList.map((adminDetail, index) => {
                                return (

                                    <tr key={index} >
                                        <td>
                                            {adminDetail.name}
                                        </td>
                                        <td>
                                            {adminDetail.userName}
                                        </td>
                                        <td>
                                            {adminDetail.email}
                                        </td>
                                        <td>
                                            {adminDetail.role}
                                        </td>
                                        <td>
                                            {adminDetail.phoneNumber}
                                        </td>
                                        {
                                            adminDetail.role !== "SuperAdmin" &&
                                            <td>
                                                {/* <span style={{color:'red'}}  className="typcn typcn-edit"></span> */}
                                                <button className="btn btn-outline-info btn-edit" onClick={() => this.onOpenModal(index, adminDetail._id)} > <span className="typcn typcn-edit"></span></button>
                                            </td>
                                        }
                                        {
                                            adminDetail.role !== "SuperAdmin" &&
                                            <td>
                                                <button className="btn btn-outline-danger" onClick={() => this.deleteAdmin(index, adminDetail._id)} ><span className="typcn typcn-trash"></span></button>
                                            </td>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        isLoading: state.authReducer.isLoading,
        isError: state.authReducer.isError,
        errorMessage: state.authReducer.errorMessage,
        userId: state.authReducer.userId,
        isLoggedIn: state.authReducer.isLoggedIn,
        authToken: state.authReducer.authToken,
        successMessage: state.authReducer.successMessage,
        user: state.authReducer.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authenticate: data => {
            dispatch(AuthMiddleware.authentication(data));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin);