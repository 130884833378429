import topicAction from '../actions/topicActions';

function topicReducer(state = {
    isError: false,
    isLoading: false,
    errorMessage: false,
    successMessage: null,
    success: false,
    edit: false
}, action) {

    switch (action.type) {
        case topicAction.EDIT_TOPIC:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                isLoading: true,
                success: false,
                edit: true,
            };
        case topicAction.EDIT_TOPIC_SUCCESSFUL:
            console.log(action.data);
            return {
                ...state,
                isError: false,
                isLoading: false,
                errorMessage: null,
                success: action.success,
                edit: false,
            };
        case topicAction.EDIT_TOPIC_FAILED:
            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                isLoading: false,
                success: false,
                edit: true,
            };


        case topicAction.ADD_TOPIC:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
                success: false,
                isLoading: true,
            };
        case topicAction.ADD_TOPIC_SUCCESSFUL:
            return {
                ...state,
                isError: false,
                isLoading: false,
                success: action.success,
                errorMessage: null,
            };
        case topicAction.ADD_TOPIC_FAILED:

            return {
                ...state,
                errorMessage: action.data.errorMessage,
                successMessage: null,
                isError: true,
                success: false,
                isLoading: false
            };


        default:
            return state;
    }
}
export default topicReducer;