import TopicAction from '../actions/topicActions';
import AuthActions from '../actions/authActions';
import axios from 'axios';
import Path from '../../config/path';
import LocalStorageManager from '../../services/localStorageManager'


class TopicMiddleware {

    


    static addTopic(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(TopicAction.addTopic(data));
                return axios.post(`${Path.ADD_TOPIC}`, {
                    data,
                    databaseToken: user.databaseToken
                })
                    .then((response) => {

                        if (!response.data.success) {
                            dispatch(TopicAction.addTopicFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(TopicAction.addTopicSuccessul({ success: true, data: response.data.data }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(TopicAction.addTopicFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }



    static editTopic(data) {
        return dispatch => {
            var user = LocalStorageManager.getUser();
            if (!user || !user.databaseToken) {
                dispatch(AuthActions.authenticationFailed({ errorMessage: "Authentication Expired, Login Again" }));
            }
            else {
                dispatch(TopicAction.editTopic(data));
                return axios.post(`${Path.EDIT_TOPIC}`, {
                    topicId: data._id,
                    data: { ...data.data },
                    databaseToken: user.databaseToken
                })
                    .then((response) => {
                        console.log(response.data);
                        
                        if (!response.data.success) {
                            dispatch(TopicAction.editTopicFailed({ errorMessage: response.data.message }));
                        }
                        else {
                            dispatch(TopicAction.editTopicSuccessul({ success: true, data: response.data.data }));
                        }
                        return response.data.success;
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(TopicAction.editTopicFailed({ errorMessage: "Something Went Wrong, Please Try Again!" }));
                    });
            }
        }
    }
}

export default TopicMiddleware;