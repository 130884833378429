import React, { Component, Fragment } from "react";
import { Input, Select } from "../Input/input"
import cities from "../../cities.json"
import CourseMiddleware from "../../store/middleware/courseMiddleware";
import { connect } from "react-redux";
import ImgsViewer from 'react-images-viewer';

class StudentData extends Component {
    state = {
        viewerIsOpen: false,
        showDocs: false
    }
    componentDidMount = () => {
        this.props.getCoursesByBatchId(this.props.data.batchId);
    }
    componentWillReceiveProps = (nextProps) => {
        if (this.props.data.batchId !== nextProps.data.batchId) {
            this.props.getCoursesByBatchId(nextProps.data.batchId);
        }

    }
    closeViewer = (index = 0) => {
        this.setState((ps) => ({
            viewerIsOpen: !ps.viewerIsOpen,
            currImg: index

        }))
    }
    gotoPrevious = () => {
        this.setState((ps) => ({
            currImg: ps.currImg - 1

        }))
    }
    gotoNext = () => {
        this.setState((ps) => ({
            currImg: ps.currImg + 1

        }))
    }
    setShowDocs = () => {
        this.setState((ps) => ({
            showDocs: !ps.showDocs

        }))
    }

    render() {
        const { disabled, onChange, parentThis, errors, newImage, onSubmit, statusUpdate, cancelEdit, coursesByBatch } = this.props;
        const { distanceLearning, fullName, studentCnic, fatherName, fatherCnic, email, phoneNumber, homeAddress
            , province, lastQualification, status, dob, city, gender, imageUrl, rejectreason, courseId, rollNo, documents } = this.props.data;
        const IMG_SET = documents.map(item => {
            return ({
                src: item.URL,
                caption: item.documentType
            })
        })
        const { showDocs } = this.state
        // const sortedDocuments = [documents[0], documents[2], documents[1]]
        return (
            <div className="container-fluid p-0">
                <div className="container">
                    <div className="form-wrapper">
                        <form onSubmit={(ev) => onSubmit(ev)} method="post">
                            <div className="admit-card-image-con">

                                <div className="admit-card-image" style={{ backgroundImage: `url(${newImage || imageUrl})` }}>

                                </div>
                                {newImage && <div>
                                    <button type="button"
                                        name="cancelImage"
                                        onClick={(ev) => onChange(ev)}>Cancel</button>
                                </div>
                                }

                                <input type="file"
                                    disabled={disabled}
                                    name="imageSelector"
                                    id="imageSelector"
                                    style={{ display: "none" }}
                                    onChange={(ev) => onChange(ev)}
                                    ref={(el) => parentThis.imageFile = el}
                                />
                                <button
                                    type="button"
                                    className="my-btn-1"
                                    onClick={() => parentThis.imageFile.click()}
                                    disabled={disabled}
                                >Change</button>
                                {
                                    errors.errorsObj["imageSelector"] && <p className="error"  >{errors.errorsObj["imageSelector"].message}</p>
                                }

                            </div>
                            <div className="status">
                                Status : {status ? status : "Pending"}
                            </div>
                            <span>email : {email}</span>
                            {/* <div className="status">
                                Email : {email} {emailVerified ? <span style={{color:"green"}}>(Verified)</span> : <span style={{color:"red"}} >(Unverified)</span>}
                            </div> */}
                            <div >
                                {status === "Rejected" && <p className="error">{rejectreason}</p>}
                            </div>
                            {/* <Select
                                label="Course"
                                name="courseId"
                                value={courseId}
                                disabled={disabled}
                                options={
                                    coursesByBatch.length ? coursesByBatch.map((course, index) => {     
                                        return { name: course.name, value: course._id}
                                    }) : []
                                }
                                onChange={onChange}
                                errors={errors}
                            /> */}
                            {/* <div className="check-container">
                                <div className="check-wrapper">
                                    <label htmlFor="distanceLearning">
                                        <span className="label">Online Learning</span>
                                        <input type="checkbox"
                                            name="distanceLearning"
                                            id="distanceLearning"
                                            checked={distanceLearning}
                                            disabled={disabled}
                                            onChange={(ev) => onChange(ev)}
                                        />
                                    </label>
                                </div>
                            </div> */}

                            <Input
                                label="Roll No"
                                type="text"
                                name="rollNo"
                                id="rollNo"
                                value={rollNo}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />

                            <Input
                                label="Full Name"
                                type="text"
                                name="fullName"
                                id="fullName"
                                value={fullName}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />

                            <Input
                                label="CNIC or B-FORM"
                                type="text"
                                name="studentCnic"
                                id="studentCnic"
                                value={studentCnic}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />

                            <Input
                                label="Father Full Name"
                                type="text"
                                name="fatherName"
                                id="fatherName"
                                value={fatherName}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />

                            {/* <Input
                                label="Father CNIC"
                                type="text"
                                name="fatherCnic"
                                id="fatherCnic"
                                value={fatherCnic}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />

                            <Input
                                label="Email"
                                type="email"
                                name="email"
                                id="email"
                                value={email}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            /> */}
                            <Input
                                label="Phone Number"
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={phoneNumber}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />
                            <Input
                                label="Address"
                                type="text"
                                name="homeAddress"
                                id="homeAddress"
                                value={homeAddress}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />
                            <Select
                                label="Province"
                                name="province"
                                id="province"
                                value={province}
                                disabled={disabled}
                                options={[
                                    { name: "Sindh", value: "sindh" },
                                    { name: "Bloachistan", value: "blochistan" },
                                    { name: "Punjab", value: "punjab" },
                                    { name: "KPK", value: "kpk" },
                                    { name: "Islamabad Capital Territory", value: "ict" }

                                ]}
                                onChange={onChange}
                                errors={errors}
                            />
                            <Select
                                label="City"
                                name="city"
                                id="city"
                                value={city}
                                disabled={disabled}
                                options={cities[province].map((item) => {
                                    return { name: item, value: item }
                                })}
                                onChange={onChange}
                                errors={errors}
                            />
                            <Input
                                label="Date Of Birth"
                                type="date"
                                name="dob"
                                id="dob"
                                value={dob}
                                disabled={disabled}
                                onChange={(ev) => onChange(ev)}
                                errors={errors}
                            />
                            <div className="radio-container mt-3" style={{ display: "flex", justifyContent: "flex-start", alignContent: "flex-start", justifySelf: "flex-start", width: "40vw", flexDirection: "row" }}>
                                <div className="radio-wrapper radio-wrapper-student-form flexer  mt-0 mb-3" >
                                    <span className="m-0">Gender</span>
                                    <label htmlFor="male">
                                        <span className="label mt-2 mb-0">Male</span>
                                        <input type="radio"
                                            name="gender"
                                            id="male"
                                            value="male"
                                            checked={gender === "male"}
                                            disabled={disabled}
                                            onChange={(ev) => onChange(ev)}
                                        />
                                    </label>
                                    <label htmlFor="female">
                                        <span className="label mt-2 mb-0 ">Female</span>
                                        <input type="radio"
                                            name="gender"
                                            id="female"
                                            value="female"
                                            checked={gender === "female"}
                                            disabled={disabled}
                                            onChange={(ev) => onChange(ev)}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="flexer" style={{ flexDirection: "column" }}>
                                {showDocs && <div className="flexer" style={{ flexDirection: "row" }}>
                                    {documents.map((item, index) => (
                                        <div className="d-flex justify-content-between align-items-center flex-column" >
                                            <p style={{ fontWeight: 'bold' }}>{item.documentType}</p>
                                            <img src={item.URL} height="200" alt={item.documentType} onClick={() => this.closeViewer(index)} />
                                        </div>
                                    ))}
                                </div>}
                               {Boolean(documents.length) ?
                                <button
                                    type="button"
                                    className={`${showDocs && "mt-3"} my-btn-1`}
                                    onClick={this.setShowDocs}
                                    disabled={false}
                                >{`${showDocs ? "Hide Dcouments" : "Show Documents"}`}
                                </button>
                                
                                :
                                <div className="flexer">
                                <h4>No documents uploaded</h4>
                                </div>
                               }
                                <ImgsViewer
                                    imgs={IMG_SET}
                                    currImg={this.state.currImg}
                                    isOpen={this.state.viewerIsOpen}
                                    onClickPrev={this.gotoPrevious}
                                    onClickNext={this.gotoNext}
                                    onClose={this.closeViewer}
                                />
                            </div>
                            <Select
                                label="Highest Qualification"
                                name="lastQualification"
                                id="lastQualification"
                                value={lastQualification}
                                disabled={disabled}
                                options={[
                                    { name: "Matric", value: "Matric" },
                                    { name: "O Levels", value: "O Levels" },
                                    { name: "Intermediate", value: "Intermediate" },
                                    { name: "A Levels", value: "A Levels" },
                                    { name: "Undergraduate", value: "Undergraduate" },
                                    { name: "Graduate", value: "Graduate" },
                                    { name: "Post-Graduate", value: "Post-Graduate" },
                                ]}
                                onChange={onChange}
                                errors={errors}
                            />
                            {disabled && status !== "Approved" && < button className="my-btn-2" type="button" onClick={() => this.props.enable()}>Edit</button>}
                            {!disabled && <button className="my-btn-1" type="submit">Update</button>}
                            {!disabled && <button onClick={() => cancelEdit()} className="my-btn-1" type="button">Cancel</button>}
                            {disabled && <div>
                                {Boolean(documents.length) && 
                                    <button onClick={() => statusUpdate("Approve")} className="my-btn-1" type="button">Approve</button>
                                }
                                <button onClick={() => statusUpdate("Reject")} className="my-btn-1" type="button">Reject</button>
                                <Select
                                    label="If Rejected Then Please Specify Reason"
                                    name="rejectreason"
                                    id="rejectreason"
                                    value={rejectreason}
                                    options={[
                                        { name: "Picture not Valid", value: "Picture not Valid" },
                                        { name: "Documents original needed with photocopy", value: "Documents original needed with photocopy" },
                                        { name: "2 admit cards printout needed", value: "2 admit cards printout needed" },
                                        { name: "CNIC or B-Form needed", value: "CNIC or B-Form needed" },
                                        { name: "Invalid Admit Card", value: "Invalid Admit Card" }

                                    ]}
                                    onChange={onChange}
                                    errors={errors}
                                />
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        coursesByBatch: state.courseReducer.coursesByBatch,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getCoursesByBatchId: (data) => {
            dispatch(CourseMiddleware.getCoursesByBatchId(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentData)