import React, { Component } from 'react';
import { Loader } from '../../StudentManagment/helper';
import OnlineVideosManagementButtons from '../OnlineVideosManagementButtons';

class ShowTopic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                hasError: false,
                errorsObj: {},
                simpleError: "",
            },
            topic: {},
            lesson: {}
        }
    }
    componentDidMount = () => {
        const { history } = this.props;
        console.log(history);
        if (history && history.location && history.location.state) {
            const { topic, lesson } = history.location.state;
            this.setState({ topic, lesson })
        } else {
            this.props.history.push("/onlinevideosmanagement")
        }
    }




    render() {
        const { topic, lesson } = this.state;
        const { title, description, videoURL } = topic
        const { isLoading, errorMessage } = this.props;
        return (
            <div className="container-fluid">
                {isLoading && <Loader />}
                <div className="container">
                    <OnlineVideosManagementButtons />
                    {errorMessage && <p className="error text-center">{errorMessage}</p>}
                    <div className="legends-container">
                        <fieldset>
                            <legend>Topic</legend>
                            <div className="legend-input-container-3">
                                <div className="legend-upper-group">
                                    <span className="show-lesson-title">{title}</span>
                                    <button className="my-btn-3 mb-2" onClick={() => this.props.history.push({ pathname: "/onlinevideosmanagement/topic", state: { topic, lesson } })} type="button">Edit Topic</button>
                                </div>
                                {videoURL && <div className="show-lesson-video">
                                    <span >{videoURL}</span>
                                    <iframe title="topic-video" width="560" height="315" src={videoURL}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>}
                                <div className="show-lesson-des">
                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }
}


export default ShowTopic;
