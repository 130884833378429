import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { Loader } from "../StudentManagment/helper";
import SendSmsViaRoll from "./SendSmsViaRoll";
import Status from '../schedulerStatus/SchedulerStatus'
class SendSms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      screen: null
    };
  }

  handleScreen = screen => {
    this.setState({
      screen
    });
  };
  renderScreen = () => {
    const { screen } = this.state

    if (screen === "sendsmsviaroll") {
        return <SendSmsViaRoll />
    } else if (screen === "smsStatus") {
        return <Status schedulerType={"SmsToStudents" }/>
    }
}
  render() {
    const { loader, screen } = this.state;
    return (
      <div>
        {loader && <Loader />}
        <div className="form-wrapper">
          <button
            onClick={() => this.handleScreen("sendsmsviaroll")}
            type="submit"
            className="my-btn-2"
          >
            Send sms <br/> based on Roll No
          </button>

          <button type="submit" className="my-btn-2">
            Send sms <br/> based on timeslots
          </button>
          <button type="submit" className="my-btn-2" onClick={() => this.handleScreen("smsStatus")}>
            Check status<br/>based on roll No
          </button>
          <button type="submit" className="my-btn-2">
            Check status<br/>based on timeslots
          </button>
        </div>

        {this.renderScreen()}
      </div>
    );
  }
}

export default SendSms;
